import React, { useState } from 'react'
import FromToAutoComplete, {
  DIRECTIONS,
} from '../../../../shared/components/autoCompletes/FromToAutoComplete'
import { DatePicker } from '@mui/x-date-pickers'
import IconButton from '@mui/material/IconButton'
import RenderIcons from '../RenderIcons'
import { IFilter } from '../../../../shared/types/filterTypes'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

function directionCompare(val: string): string {
  return val === DIRECTIONS.FROM
    ? 'expirationDateFilterStart'
    : 'expirationDateFilterEnd'
}

/// set timezone
dayjs.extend(utc)
interface ISpecialDatePickerProps {
  filter: IFilter
  setFilter: (payload: IFilter) => void
  handleTimeChange: (newValue: Date | string | null, key: string) => void
  handleOrderChange: (key: string) => void
}

const ExpirationDateDatePicker = ({
  filter,
  setFilter,
  handleTimeChange,
  handleOrderChange,
}: ISpecialDatePickerProps) => {
  const [direction, setDirection] = useState('')

  const handleDirection = (newValue: any) => {
    if (!newValue) return
    if (newValue.val === DIRECTIONS.FROM) {
      setDirection(DIRECTIONS.FROM)
      setFilter({
        ...filter,
        expirationDateFilter: {
          ...filter.expirationDateFilter,
          expirationDateFilterEnd: null,
        },
      })
      return
    }
    setDirection(DIRECTIONS.TO)
    setFilter({
      ...filter,
      expirationDateFilter: {
        ...filter.expirationDateFilter,
        expirationDateFilterStart: null,
      },
    })
  }

  const expirationDateValue =
    filter.expirationDateFilter[
      directionCompare(direction) as keyof typeof filter.expirationDateFilter
    ]

  return (
    <>
      <FromToAutoComplete handleDirection={handleDirection} />
      <DatePicker
        label={'Срок годн.'}
        value={expirationDateValue ? dayjs(expirationDateValue) : null}
        format="DD/MM/YYYY"
        onChange={(v) => {
          let keyName = directionCompare(direction)
          // @ts-ignore
          const formattedDate = dayjs(v).format('YYYY-MM-DDTHH:mm:ss')
          handleTimeChange(
            formattedDate.includes('Invalid') ? '' : formattedDate,
            keyName
          )
        }}
        sx={{
          fontSize: '0.875rem',
          '& .MuiOutlinedInput-input': {
            fontSize: '0.875rem',
          },
          '& .MuiInputAdornment-root': {
            marginLeft: 0,
          },
          '& .MuiInputBase-root ': {
            paddingRight: '10px',
          },
        }}
        slotProps={{
          textField: {
            size: 'small',
            InputLabelProps: {
              style: {
                fontSize: '0.875rem',
              },
            },
          },
        }}
      />
      <IconButton
        sx={{ ml: '3px' }}
        onClick={() => handleOrderChange('expirationDateOrderRule')}
      >
        <RenderIcons condition={filter['expirationDateOrderRule']} />
      </IconButton>
    </>
  )
}

export default ExpirationDateDatePicker
