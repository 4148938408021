import { Dispatch } from "redux";

// api
import { deleteSupplierListPriceListSettings, deleteSupplierListSettings, getSupplierList, postSupplierListSettings, postSupplierPriceListSettings } from "../../api/supplierList";

// project
import { AppStateType } from "../../../../../../shared/store"
import { ISupplierListItem } from "../../types/supplierListTypes"
import { SupplierListAction, SupplierListActionTypes } from "./supplierListReduxTypes"
import { ApplicationAction, ApplicationActionTypes, SnackTypes } from "../../../../../../shared/store/application/applicationReduxTypes"

export const fetchSupplierList = (
  page = 1,
  limit = 100,
) => {
  return async (    
    dispatch: Dispatch<SupplierListAction | ApplicationAction>,
    getState: () => AppStateType
    ) => {
    try {
      dispatch({ type: SupplierListActionTypes.LOADING_ENABLE })
      const { data } =  await getSupplierList();
      const content = data.content
      
      dispatch({ 
        type: SupplierListActionTypes.FETCH_SUPPLIER_LIST_SUCCESS, 
        payload: content.items,
      })
    } catch (error) {
      // dispatch({
      //   type: ApplicationActionTypes.SHOW_SNACK,
      //   payload: { 
      //     typeSnack: SnackTypes.ERROR, messageSnack: 'Ошибка получения поставщиков' 
      //   },
      // });
    } finally {
      dispatch({ type: SupplierListActionTypes.LOADING_DISABLE })
    }
  }
}

export const setSelectedSupplier = (supplier: ISupplierListItem) => ({
  type: SupplierListActionTypes.SET_SELECTED_SUPPLIER,
  payload: supplier,
});


export const handleSupplierListCheckBox = (
  event: React.ChangeEvent<HTMLInputElement>,
  item: ISupplierListItem
) => {
  return async (
    dispatch: Dispatch<SupplierListAction | ApplicationAction>,
    getState: () => AppStateType
  ) => {
    const id = event.target.id;
    const checked = event.target.checked;

    try {
      if (checked) {
        await postSupplierListSettings(item);
        item.isActiveInAutoOrder = true;

        const updatedPriceLists = item.priceListTypes.map((priceList) => ({
          ...priceList,
          isActiveInAutoOrder: true,
        }));
        item.priceListTypes = updatedPriceLists;

      } else {
        await deleteSupplierListSettings(id);
        item.isActiveInAutoOrder = false;

        const updatedPriceLists = item.priceListTypes.map((priceList) => ({
          ...priceList,
          isActiveInAutoOrder: false,
        }));
        item.priceListTypes = updatedPriceLists;
      }

      const updatedItems = [...getState().supplierList.items];
      const updatedItem = updatedItems.find((i) => i.supplierId === item.supplierId);
      if (updatedItem) {
        updatedItem.isActiveInAutoOrder = item.isActiveInAutoOrder;
        updatedItem.priceListTypes = item.priceListTypes;
      }

      dispatch({
        type: SupplierListActionTypes.FETCH_SUPPLIER_LIST_SUCCESS,
        payload: updatedItems,
      });


    } catch (e) {
      // dispatch({
      //   type: ApplicationActionTypes.SHOW_SNACK,
      //   payload: { typeSnack: SnackTypes.ERROR, messageSnack: 'Заказ уже рассчитан' },
      // });
    }
  };
};

export const updatePriceListCheckbox = (
  priceListId: number,
  isActiveInAutoOrder: boolean
) => ({
  type: SupplierListActionTypes.UPDATE_PRICE_LIST_CHECKBOX,
  payload: { priceListId, isActiveInAutoOrder },
});

export const savePriceListsWithPreparation = (
  selectedSupplier: ISupplierListItem
) => {
  return async (dispatch: Dispatch) => {
    try {
      const activePriceLists = selectedSupplier.priceListTypes.filter(
        (priceList) => priceList.isActiveInAutoOrder
      );

      const inactivePriceLists = selectedSupplier.priceListTypes.filter(
        (priceList) => !priceList.isActiveInAutoOrder
      );

      if (activePriceLists.length > 0) {
        if (selectedSupplier.isActiveInAutoOrder === false) {
          await postSupplierListSettings(selectedSupplier);
        }
        await postSupplierPriceListSettings(
          selectedSupplier.supplierId,
          activePriceLists
        );
      }

      if (inactivePriceLists.length > 0) {
        await deleteSupplierListPriceListSettings(
          selectedSupplier.supplierId,
          inactivePriceLists
        );
      }

    } catch (error) {
      // dispatch({
      //   type: ApplicationActionTypes.SHOW_SNACK,
      //   payload: { 
      //     typeSnack: SnackTypes.ERROR, messageSnack: 'Произошла ошибка сохранения настроек' 
      //   },
      // });
    }
  };
};