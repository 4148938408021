import { Dispatch } from 'react'

// import types
import { getDepartmentPreOrders } from '../api/departmentPreOrder'
import { IPreOrder, IPreOrders } from '../types/departmentsPreOrderTypes'
import {
  DepartmentPreOrdersActions,
  DepartmentsPreOrdersActionTypes,
} from './departmentsPreOrdersReduxTypes'
import {
  ApplicationAction,
  ApplicationActionTypes,
} from '../../../../../shared/store/application/applicationReduxTypes'

export const fetchDepartmentsPreOrder = (
  orders: string = 'DepartmentName',
  page: number,
  limit: number,
  loadFlag: boolean = true
) => {
  return async (
    dispatch: Dispatch<DepartmentPreOrdersActions | ApplicationAction>
  ) => {
    dispatch({
      type: DepartmentsPreOrdersActionTypes.DEPARTEMNT_REQ,
      payload: {
        loading: true,
        loadFlag: true,
      },
    })
    try {
      dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })
      const reponse = await getDepartmentPreOrders(page, limit, orders)
      const content: IPreOrders = reponse.data.content

      const isEnough = content.items.length >= limit

      dispatch({
        type: DepartmentsPreOrdersActionTypes.SET_ENOUGH,
        payload: isEnough,
      })

      dispatch({
        type: DepartmentsPreOrdersActionTypes.SAVE_DEPARTMENT_PRE_ORDERS,
        payload: {
          content,
          loadFlag,
          loading: false,
        },
      })

      if (content.items.length) {
        const defaultSelectElement = content.items[0]

        dispatch({
          type: DepartmentsPreOrdersActionTypes.SELECT_PRE_ORDER,
          payload: defaultSelectElement,
        })
      }
    } catch (e) {
      // alert('Ошибка получении предзаказов')
    } finally {
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    }
  }
}

export function savePreOrderFilters(payload: string) {
  return {
    type: DepartmentsPreOrdersActionTypes.SAVE_PREORDER_SORT,
    payload: payload,
  }
}

export function saveSelectPreOrder(payload: IPreOrder) {
  return {
    type: DepartmentsPreOrdersActionTypes.SELECT_PRE_ORDER,
    payload: payload,
  }
}

export function selectPreOrder(payload: IPreOrder) {
  return {
    type: DepartmentsPreOrdersActionTypes.SELECT_PRE_ORDER,
    payload: payload,
  }
}

export function setPageDepartmentPage(payload: number) {
  if (payload <= 0) payload = 1
  return {
    type: DepartmentsPreOrdersActionTypes.SET_DEPARTMENT_PAGE,
    payload: payload,
  }
}

export function clearDepartment() {
  return {
    type: DepartmentsPreOrdersActionTypes.CLEAR_DEPARTMENT,
  }
}

export function setNewCostPreOrder(newPreOrder: IPreOrder) {
  return {
    type: DepartmentsPreOrdersActionTypes.SET_NEW_PRE_ORDER,
    payload: newPreOrder,
  }
}

export function showPreOrder(show: boolean) {
  return async (dispatch: Dispatch<DepartmentPreOrdersActions>) => {
    dispatch({
      type: DepartmentsPreOrdersActionTypes.SHOW_PRE_ORDERS,
      payload: show,
    })
  }
}

export function updatePreOrders(update: boolean) {
  return {
    type: DepartmentsPreOrdersActionTypes.UPDATE_REQUEST,
    payload: update,
  }
}

export function tempShow(show: boolean) {
  return {
    type: DepartmentsPreOrdersActionTypes.TEMP_SHOW,
    payload: show,
  }
}
