import { useEffect } from 'react'
import { useActions } from '../hooks/useActions'

export const useInitFetchController = () => {
  const { fetchDefaultDepartment, fetchDefaultUser } = useActions()
  useEffect(() => {
    fetchDefaultDepartment()
    fetchDefaultUser()
  }, [])
}
