import { IProcessingAutoOrderHeadCells, ProcessingAutoOrderFieldTypeMap } from "../types/processingAutoOrderTypes"

export const PROCESSING_AUTO_ORDER = {
  MIN_ITEMS_AFTER_LOADING: 31,

  // сделать динамичными
  START_DOWN_INDEX: 30,
  END_DOWN_INDEX: 90,
  START_UP_INDEX: 0,
  END_UP_INDEX: 59,
}

export const headCellsArray: IProcessingAutoOrderHeadCells[] = [
  {
    name: 'Дата создания',
    align: 'left',
    field: ProcessingAutoOrderFieldTypeMap.date.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
  {
    name: '№ Док.',
    align: 'inherit',
    field: ProcessingAutoOrderFieldTypeMap.code.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
  {
    name: 'Грузополучатель',
    align: 'left',
    field: ProcessingAutoOrderFieldTypeMap.departmentName.value,
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
    disableSort: false,
  },
  {
    name: 'Статус',
    align: 'left',
    field: ProcessingAutoOrderFieldTypeMap.status.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
  {
    name: 'Комментарий',
    align: 'left',
    field: ProcessingAutoOrderFieldTypeMap.description.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
]