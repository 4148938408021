export interface SupplierState {
  supplierIdRange: number[];
  withPLButton: boolean;
}

export enum SupplierActionTypes {
  SAVE_SUPPLIERS_IDS = 'SAVE_SUPPLIERS_IDS',
  RESET_SUPPLIERS_IDS = 'RESET_SUPPLIERS_IDS',
  TOGGLE_WITH_PL_BUTTON_STATE = 'TOGGLE_WITH_PL_BUTTON_STATE',
}

export interface SaveSupplierIdsAction {
  type: SupplierActionTypes.SAVE_SUPPLIERS_IDS
  payload: number[]
}

export interface ResetSupplierIdsAction {
  type: SupplierActionTypes.RESET_SUPPLIERS_IDS
}

export interface toggleWithPLButtonAction {
  type: SupplierActionTypes.TOGGLE_WITH_PL_BUTTON_STATE
  payload: boolean
}

export type SupplierAction = SaveSupplierIdsAction | ResetSupplierIdsAction | toggleWithPLButtonAction

