import React from 'react'

// mui
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableBody from '@mui/material/TableBody'
import { SxProps, Theme } from '@mui/material'

// project
import ErrorPage from '../Product/components/ErrorPage'
import ElementWatch from '../Product/components/ElementWatch'
import AutoOrderBar from './components/AutoOrderBar'
import { AutoOrderRow } from './components/AutoOrderRow'

// types
import {
  AutoOrderFieldType,
  AutoOrderFieldTypeMap,
  IAutoOrderItem,
} from './types/autoOrderTypes'

// controllers
import { useAutoOrderController } from './controllers/useAutoOrderController'

// actions
import { useActions } from '../../shared/hooks/useActions'

// const
import { headCellsArray } from './consts/autoOrder'

const tableHeadStyle: SxProps<Theme> = {
  '& th': {
    whiteSpace: 'nowrap',
    fontSize: '0.75rem',
    color: 'primary.main',
    fontWeight: '700',
    textTransform: 'uppercase',
    borderBottom: 0,
    backgroundColor: 'background.paper',
    position: 'sticky',
    top: 60,
    zIndex: 1,
  },
}

const tableContainerStyle: SxProps<Theme> = {
  border: '1px solid',
  borderColor: 'action.disabled',
  boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
  borderRadius: '8px',
  pb: 2,
  overflowX: 'initial',
}

const AutoOrderMain = () => {
  const {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    items,
    order,
    listOrientation,
  } = useAutoOrderController()

  const { setAutoOrderSort } = useActions()
  const ErorrPage = <ErrorPage {...{ error, firstElement, lastElement }} />
  // const activeBit = useTypedSelector(state => state.autoOrder.)

  const sortHandler = (field: string) => {
    const sortDataMap = AutoOrderFieldTypeMap[field as AutoOrderFieldType]

    if (order === 'asc') {
      setAutoOrderSort({
        orientation: sortDataMap.desc,
        order: 'desc',
      })
    } else {
      setAutoOrderSort({
        orientation: sortDataMap.asc,
        order: 'asc',
      })
    }
  }

  const activeSort = (field: string) => {
    const sortDataMap = AutoOrderFieldTypeMap[field as AutoOrderFieldType]

    return (
      sortDataMap.asc === listOrientation ||
      sortDataMap.desc === listOrientation
    )
  }

  return (
    <>
      <AutoOrderBar />
      {items.length <= 0 ? (
        ErorrPage
      ) : (
        <TableContainer sx={tableContainerStyle}>
          <Table size="small" aria-label="auto-order bids table">
            <TableHead>
              <TableRow sx={tableHeadStyle}>
                <TableCell />
                {headCellsArray.map((item) => (
                  <TableCell
                    key={item.name}
                    align={item.align}
                    sx={{
                      padding: item.enablePaddings
                        ? '0.5rem 1rem 0 1rem'
                        : '0.5rem 0 0 0',
                    }}
                  >
                    <TableSortLabel
                      active={activeSort(item.field)}
                      direction={order}
                      onClick={() => sortHandler(item.field)}
                    >
                      <Box
                        sx={{
                          pl: item.enableLeftPadding ? '18px' : 'unset',
                        }}
                      >
                        {item.name}
                      </Box>
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <ElementWatch
                componentNode={'tr'}
                element={firstElement}
                isLoading={loading}
              />
              {items.map((row: IAutoOrderItem, index) => (
                // todo check
                // @ts-ignore
                <React.Fragment key={row.id + index}>
                  <AutoOrderRow row={row} />
                  <tr
                    // @ts-ignore
                    ref={(el) => (inputRef.current[row.id] = el)}
                  />
                </React.Fragment>
              ))}
              <ElementWatch
                componentNode={'tr'}
                element={lastElement}
                isLoading={loading}
              />
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default AutoOrderMain
