import React from 'react'

/// MUI
import {
  Autocomplete,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

/// MM icons
import SvgBlueLogo from '../../../../shared/components/UI/svgIcons/SvgBlueLogo'
import SvgRedLogo from '../../../../shared/components/UI/svgIcons/SvgRedLogo'
import SvgOrangeLogo from '../../../../shared/components/UI/svgIcons/SvgOrangeLogo'
import SvgGreenLogo from '../../../../shared/components/UI/svgIcons/SvgGreenLogo'
import SvgHealthIcon from '../../../../shared/components/UI/svgIcons/SvgHealth'
import RenderIcons from '../RenderIcons'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

enum MarketingEvents {
  Any = 0,
  Proapt = 1,
  Matrix = 2,
  FirstTableBonus = 3,
  HighMargin = 4,
  CompensatedDiscount = 5,
}

const maketingOptions = [
  { val: MarketingEvents.Any, desc: 'Любой тип' },
  {
    val: MarketingEvents.Proapt,
    desc: 'ПроАптека',
    icon: <SvgBlueLogo renderCondition />,
  },
  {
    val: MarketingEvents.Matrix,
    desc: 'Матрица',
    icon: <SvgGreenLogo renderCondition />,
  },
  {
    val: MarketingEvents.FirstTableBonus,
    desc: 'Бонус первостольнику',
    icon: <SvgOrangeLogo renderCondition />,
  },
  {
    val: MarketingEvents.HighMargin,
    desc: 'ВМТ',
    icon: <SvgRedLogo renderCondition />,
  },
  {
    val: MarketingEvents.CompensatedDiscount,
    desc: 'Компенсированные скидки',
    icon: <SvgHealthIcon renderCondition />,
  },
]

export const MarketingEventsAutoComplete = ({
  filter,
  nameOrder,
  handleOrderChange,
  handleChangeMarketingEvents,
}: {
  filter: any
  nameOrder: string
  handleOrderChange: (key: string) => void
  handleChangeMarketingEvents: (newValue: Array<{ id: number }>) => void
}) => {
  const defaultFilters: number[] = filter.marketingEventTypesFilter?.map(
    (el: any) => el.id
  )

  const defaultValue = maketingOptions.filter((option) =>
    defaultFilters.includes(option.val)
  )

  return (
    <>
      <Autocomplete
        sx={{
          '& .MuiInputLabel-formControl': {
            fontSize: '0.875rem',
            mt: '3px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#B5C3DC',
          },
        }}
        fullWidth
        disablePortal
        size="small"
        multiple
        getOptionLabel={(option) => option.desc}
        isOptionEqualToValue={(option, value) => option.val === value.val}
        onChange={(_: any, newValue: any) => {
          const numberValues: Array<{ id: number }> = newValue.map(
            (element: any) => {
              return { id: element.val }
            }
          )
          handleChangeMarketingEvents(numberValues)
        }}
        value={defaultValue}
        options={maketingOptions}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 1 }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
              checked={selected || defaultFilters.includes(option.val)}
            />
            <Stack
              sx={{
                width: 20,
                height: 12,
              }}
            >
              {option.icon}
            </Stack>
            <Typography style={{ fontSize: '12px' }}>{option.desc}</Typography>
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Маркетинговые мероприятия" />
        )}
      />
      <IconButton
        sx={{ ml: '3px' }}
        onClick={() => handleOrderChange(nameOrder)}
      >
        <RenderIcons condition={filter[nameOrder]} />
      </IconButton>
    </>
  )
}
