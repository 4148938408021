import React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { useTypedSelector } from '../../hooks/useTypedSelector'

const ProgressBar = () => {
  const { loading } = useTypedSelector((state) => state.app)

  return (
    <Box sx={{ width: '100%' }}>
      {loading && (
        <LinearProgress
          color="warning"
          sx={{ position: 'fixed', top: '64px', width: '100%', height: '7px' }}
        />
      )}
    </Box>
  )
}

export default ProgressBar
