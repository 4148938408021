import { Order } from '../../../../shared/services/commonService'
import { SaleDetailsFieldType } from '../../types/saleDetailsTypes'
import {
  ErrorInfo,
  IDepartmentsNeedsParams,
  IStopListParams,
  LoadDirections,
} from '../../../../shared/types/types'

export interface SaleDetailsState {
  enough: boolean
  direction: LoadDirections.DOWN | LoadDirections.UP
  error: ErrorInfo | {}
  loading: boolean
  page: number
  limit: number
  total: number
  filter: any
  products: any
  orderBy: SaleDetailsFieldType
  order: Order
  startSearch: boolean
}

export enum SaleDetailsActionTypes {
  LOADING_ENABLE = 'LOADING_ENABLE',
  LOADING_DISABLE = 'LOADING_DISABLE',
  SET_FILTER_PRODUCTS = 'SET_FILTER_SALE_DETAILS',
  RESET_FILTER_PRODUCTS = 'RESET_FILTER_SALE_DETAILS',
  FETCH_PRODUCTS = 'FETCH_SALE_DETAILS',
  FETCH_PRODUCTS_SUCCESS = 'FETCH_SALE_DETAILS_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_SALE_DETAILS_ERROR',
  SET_PRODUCT_PAGE = 'SET_SALE_DETAILS_PAGE',
  FETCH_PRODUCTS_ENOUGH = 'FETCH_SALE_DETAILS_ENOUGH',
  SET_PRODUCT_SORT = 'SET_SALE_DETAILS_SORT',
  SAVE_SALE_DETAILS_QUANTITY_FOR_ORDER = 'SAVE_SALE_DETAILS_QUANTITY_FOR_ORDER',
  ADD_SALE_DETAILS_STOP_LIST_ITEM = 'ADD_SALE_DETAILS_STOP_LIST_ITEM',
  DELETE_SALE_DETAILS_STOP_LIST_ITEM = 'DELETE_SALE_DETAILS_STOP_LIST_ITEM',
  START_SALE_DETAILS_SEARCH = 'START_SALE_DETAILS_SEARCH',
}

interface SearchingSaleDetailsAction {
  type: SaleDetailsActionTypes.START_SALE_DETAILS_SEARCH
}

interface DeleteStopListsItemAction {
  type: SaleDetailsActionTypes.DELETE_SALE_DETAILS_STOP_LIST_ITEM
  payload: IStopListParams
}

interface AddStopListsItemAction {
  type: SaleDetailsActionTypes.ADD_SALE_DETAILS_STOP_LIST_ITEM
  payload: IStopListParams
}

interface SaveSaleDetailsQuantityForOrderAction {
  type: SaleDetailsActionTypes.SAVE_SALE_DETAILS_QUANTITY_FOR_ORDER
  payload: IDepartmentsNeedsParams
}

interface LoadingSaleDetailsEnableAction {
  type: SaleDetailsActionTypes.LOADING_ENABLE
}

interface LoadingSaleDetailsDisableAction {
  type: SaleDetailsActionTypes.LOADING_DISABLE
}

interface FetchSaleDetailsEnoughAction {
  type: SaleDetailsActionTypes.FETCH_PRODUCTS_ENOUGH
  payload: boolean
}

interface SetFilterSaleDetailsAction {
  type: SaleDetailsActionTypes.SET_FILTER_PRODUCTS
  payload: Object
}

interface ResetFilterSaleDetailsAction {
  type: SaleDetailsActionTypes.RESET_FILTER_PRODUCTS
}

interface FetchSaleDetailsAction {
  type: SaleDetailsActionTypes.FETCH_PRODUCTS
}

export interface FetchSaleDetailsSuccessAction {
  type: SaleDetailsActionTypes.FETCH_PRODUCTS_SUCCESS
  payload: { content: any; loadFlag: boolean }
}

export interface FetchSaleDetailsErrorAction {
  type: SaleDetailsActionTypes.FETCH_PRODUCTS_ERROR
  payload: ErrorInfo | {}
}

interface SetSaleDetailsPage {
  type: SaleDetailsActionTypes.SET_PRODUCT_PAGE
  payload: number
}

export interface ISaleDetailsPayload {
  orderBy: SaleDetailsFieldType
  order: Order
}

interface SetSaleDetailsSort {
  type: SaleDetailsActionTypes.SET_PRODUCT_SORT
  payload: ISaleDetailsPayload
}

export type SaleDetailsAction =
  | FetchSaleDetailsEnoughAction
  | SetFilterSaleDetailsAction
  | ResetFilterSaleDetailsAction
  | FetchSaleDetailsAction
  | FetchSaleDetailsSuccessAction
  | FetchSaleDetailsErrorAction
  | SetSaleDetailsPage
  | SetSaleDetailsSort
  | LoadingSaleDetailsEnableAction
  | LoadingSaleDetailsDisableAction
  | SaveSaleDetailsQuantityForOrderAction
  | DeleteStopListsItemAction
  | AddStopListsItemAction
  | SearchingSaleDetailsAction
