import React, { useMemo, useState } from 'react'

// mui
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { SxProps, TableSortLabel, Theme } from '@mui/material'

// project
import SubAutoOrderProductRow from './SubAutoOrderProductRow'
import { ISubAutoOrderProduct } from '../types/subAutoOrderTypes'

const tableRowStyle: SxProps<Theme> = {
  '& td,th': {
    whiteSpace: 'nowrap',
    fontSize: '0.7rem',
    color: 'primary.main',
    fontWeight: '700',
    textTransform: 'uppercase',
    borderBottom: 0,
  },
}

const SubAutoOrderProduct = ({
  products,
  open,
}: {
  products: any
  open: boolean
}) => {
  const [isSorted, setIsSorted] = useState(true)

  const productsWithSort = useMemo(() => {
    if (isSorted) return products.sort((a: any, b: any) => a.rating - b.rating)
    else return products.sort((a: any, b: any) => b.rating - a.rating)
  }, [isSorted])

  const handleChangeRating = () => setIsSorted(!isSorted)

  return (
    <>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <Typography
            paddingLeft={8}
            gutterBottom
            fontWeight={500}
            fontSize={'0.825rem'}
            component="div"
          >
            Товары этой группы
          </Typography>
          <Table aria-label="purchases">
            <TableHead>
              <TableRow sx={tableRowStyle}>
                <TableCell align="center">Товар заказан</TableCell>
                <TableCell align="center">УС-код</TableCell>
                <TableCell align="center">Название</TableCell>
                <TableCell align="center">Производитель</TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={isSorted}
                    direction={isSorted ? 'asc' : 'desc'}
                    onClick={handleChangeRating}
                  >
                    <Box>Рейтинг</Box>
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">Цена</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsWithSort.map((product: ISubAutoOrderProduct) => (
                <SubAutoOrderProductRow
                  key={product.esCode}
                  product={product}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </>
  )
}

export default SubAutoOrderProduct
