import { IStopListParams } from '../../types/types'

export interface SaleStopListsState {
  error: string
  loading: boolean
  page: number
  products: any
}

export enum SaleStopListsActionTypes {
  FETCH_PRODUCTS_SUCCESS = 'FETCH_SALE_STOP_LISTS_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_SALE_STOP_LISTS_ERROR',
  SET_PRODUCT_PAGE = 'SET_SALE_STOP_LISTS_PAGE',
  DELETE_STOP_LIST_ITEM = 'DELETE_STOP_LIST_ITEM',
}

export interface FetchStopListsSuccessAction {
  type: SaleStopListsActionTypes.FETCH_PRODUCTS_SUCCESS
  payload: any[] //todo
}

export interface FetchStopListsErrorAction {
  type: SaleStopListsActionTypes.FETCH_PRODUCTS_ERROR
  payload: string
}

interface SetStopListsPage {
  type: SaleStopListsActionTypes.SET_PRODUCT_PAGE
  payload: number
}

interface DeleteStopListsItem {
  type: SaleStopListsActionTypes.DELETE_STOP_LIST_ITEM
  payload: IStopListParams
}

export type SaleStopListsAction =
  | FetchStopListsSuccessAction
  | FetchStopListsErrorAction
  | SetStopListsPage
  | DeleteStopListsItem
