import React from 'react'
import Typography from '@mui/material/Typography'
import { Box, CircularProgress } from '@mui/material'

const ItemsLoader = ({
  isEmpty,
  loading,
  children,
}: {
  isEmpty?: boolean
  loading?: boolean
  children?: React.ReactNode
}) => {
  if (isEmpty && !loading) {
    return (
      <Typography marginTop="16px" align="center" component="h2">
        Поиск не дал результатов
      </Typography>
    )
  }

  if (loading) {
    return (
      <Box marginTop="16px" display="flex" justifyContent="center">
        <CircularProgress size={60} color="secondary" />
      </Box>
    )
  }

  return <>{children}</>
}

export default ItemsLoader
