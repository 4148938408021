import React from "react";
import Typography from "@mui/material/Typography";

const ErrorTypography = ({ error }: { error: string }) => {
  return (
    <Typography
      sx={{
        textTransform: "uppercase",
        fontSize: "18px",
        fontWeight: 600,
        maxWidth: "350px",
        color: "text.secondary",
        marginTop: "10px",
        textAlign: "center",
      }}
    >
      {error}
    </Typography>
  );
};

export default ErrorTypography;
