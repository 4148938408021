import { Dispatch } from 'redux'
import {
  ISaleDepartmentsPayload,
  SaleDepartmentsAction,
  SaleDepartmentsActionTypes,
  SaleDepartmentsProducts,
} from './saleDepartmentsReduxTypes'

import noResultImage from '../../../../../../shared/assets/images/search.png'
import {
  deleteSaleDepartmentsItems,
  getSaleDepartmentsItems,
  postSaleDepartmentsItems,
} from '../../../../api/sale'
import { getDepartments } from '../../../../../../shared/api/department'
import { AppStateType } from '../../../../../../shared/store'
import { SaleComplexAction } from '../../../SaleComplex/model/saleComplex/saleComplexReduxTypes'
import {
  ApplicationAction,
  SnackTypes,
} from '../../../../../../shared/store/application/applicationReduxTypes'
import { showSnack } from '../../../../../../shared/store/application/applicationAction'
import { enableSaleComplexBlockAction } from '../../../SaleComplex/model/saleComplex/saleComplexAction'
import { postConsigneeDepartment } from '../../../SaleCostBonuses/api/saleCostBonuses'
import { IConsigneeDepartment } from '../../../SaleCostBonuses/modals/UnionDepartmentModal/UnionDepartmentModalContent'

export const fetchSaleDepartmentsProducts = (
  page = 1,
  limit = 30,
  filter = {},
  loadFlag = true
) => {
  return async (dispatch: Dispatch<SaleDepartmentsAction>) => {
    try {
      dispatch({ type: SaleDepartmentsActionTypes.LOADING_ENABLE })
      const { data } = await getSaleDepartmentsItems(page, limit, filter)
      const content = data.content
      const isEmpty = content.departments.length === 0 && page === 1

      if (isEmpty) {
        dispatch({
          type: SaleDepartmentsActionTypes.FETCH_PRODUCTS_ERROR,
          payload: { message: 'Поиск не дал результатов', img: noResultImage },
        })
        return
      }

      const isEnough = content.departments.length >= limit

      dispatch({
        type: SaleDepartmentsActionTypes.FETCH_PRODUCTS_ENOUGH,
        payload: isEnough,
      })
      dispatch({
        type: SaleDepartmentsActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: { content, loadFlag },
      })
    } catch (e) {
      dispatch({
        type: SaleDepartmentsActionTypes.FETCH_PRODUCTS_ERROR,
        payload: { message: 'Поиск завершился ошибкой', img: noResultImage },
      })
    } finally {
      dispatch({ type: SaleDepartmentsActionTypes.LOADING_DISABLE })
    }
  }
}

export const fetchSavedDeps = () => {
  return async (dispatch: Dispatch<SaleDepartmentsAction>) => {
    const { data } = await getSaleDepartmentsItems(undefined, 100, undefined)
    dispatch({
      type: SaleDepartmentsActionTypes.SET_CHECKED_ITEMS,
      payload: data.content.departments.map((item: any) => ({
        departmentId: item.departmentId,
        departmentName: item.departmentName,
      })),
    })
    dispatch({
      type: SaleDepartmentsActionTypes.SET_COPY_CHECKED_ITEMS,
      payload: data.content.departments.map((item: any) => ({
        departmentId: item.departmentId,
        departmentName: item.departmentName,
      })),
    })
  }
}

export function deleteSaleDepartmentsItemAction(
  departmentId: number
): SaleDepartmentsAction {
  return {
    type: SaleDepartmentsActionTypes.DELETE_SALE_DEPARTMENT_ITEM,
    payload: departmentId,
  }
}

export function setSaleDepartmentsPage(page: number): SaleDepartmentsAction {
  if (page === 0) page = 1
  return { type: SaleDepartmentsActionTypes.SET_PRODUCT_PAGE, payload: page }
}

export function setSaleDepartmentsFilterProducts(
  payload: Object
): SaleDepartmentsAction {
  return {
    type: SaleDepartmentsActionTypes.SET_FILTER_PRODUCTS,
    payload,
  }
}

export function resetSaleDepartmentsFilterProducts(): SaleDepartmentsAction {
  return { type: SaleDepartmentsActionTypes.RESET_FILTER_PRODUCTS }
}

export function resetSaleDepartmentsItems(): SaleDepartmentsAction {
  return { type: SaleDepartmentsActionTypes.RESET_SALE_DEPARTMENTS_ITEMS }
}

export function updateSaleDepartmentsAction(
  payload: SaleDepartmentsProducts[]
): SaleDepartmentsAction {
  return {
    type: SaleDepartmentsActionTypes.UPDATE_SALE_DEPARTMENTS_ITEMS,
    payload,
  }
}

export function setSaleDepartmentsSort(
  payload: ISaleDepartmentsPayload
): SaleDepartmentsAction {
  return { type: SaleDepartmentsActionTypes.SET_PRODUCT_SORT, payload }
}

export const fetchDepartmentsAction = (name?: string, page?: number) => {
  return async (dispatch: Dispatch<SaleDepartmentsAction>) => {
    dispatch({ type: SaleDepartmentsActionTypes.LOADING_ENABLE })
    try {
      let {
        data: {
          content: { data },
        },
      } = await getDepartments(name, page)
      dispatch({
        type: SaleDepartmentsActionTypes.SAVE_DEPARTMENTS_LIST,
        payload: data,
      })
    } catch (e) {
      alert('Ошибка получения грузополучателя')
    } finally {
      dispatch({ type: SaleDepartmentsActionTypes.LOADING_DISABLE })
    }
  }
}

export const handleSaleDepartmentCheckBox = (
  departmentId: number,
  departmentName: string
) => {
  return async (
    dispatch: Dispatch<SaleDepartmentsAction>,
    getState: () => AppStateType
  ) => {
    const state = getState()
    const checkedSaleDepartments = state.saleDepartments.checkedSaleDepartments
    const currentIndex = checkedSaleDepartments.findIndex(
      (item) => item.departmentId === departmentId
    )
    if (currentIndex === -1) {
      dispatch({
        type: SaleDepartmentsActionTypes.ADD_CHECKED_ITEMS,
        payload: [{ departmentId, departmentName }],
      })
    } else {
      dispatch({
        type: SaleDepartmentsActionTypes.SET_CHECKED_ITEMS,
        payload: checkedSaleDepartments.filter(
          (i: any) => i.departmentId !== departmentId
        ),
      })
    }
  }
}

export const submitDepartmentsAction = () => {
  return async (
    dispatch: Dispatch<SaleDepartmentsAction | ApplicationAction>,
    getState: () => AppStateType
  ) => {
    try {
      const state = getState()
      const checkedSaleDepartments =
        state.saleDepartments.checkedSaleDepartments
      const checkedSaleDepartmentsCopy =
        state.saleDepartments.checkedSaleDepartmentsCopy

      const delDifferArray = checkedSaleDepartmentsCopy.filter(
        (item) =>
          !checkedSaleDepartments.some(
            (item2) => item2.departmentId === item.departmentId
          )
      )

      const addDifferArray = checkedSaleDepartments.filter(
        (item) =>
          !checkedSaleDepartmentsCopy.some(
            (item2) => item2.departmentId === item.departmentId
          )
      )
      if (addDifferArray.length > 0) {
        await postSaleDepartmentsItems(addDifferArray)
      }
      if (delDifferArray.length > 0) {
        await deleteSaleDepartmentsItems(
          delDifferArray.map((i) => i.departmentId)
        )
      }
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Ошибка получения параметров',
      //   })
      // )
    } finally {
      dispatch({ type: SaleDepartmentsActionTypes.UPDATE_COPY_DEPARTMENTS })
    }
  }
}

export const enableConsigneeDepartmentAction = (
  checkedDepartment: IConsigneeDepartment
) => {
  return async (dispatch: Dispatch<SaleComplexAction | ApplicationAction>) => {
    try {
      await postConsigneeDepartment(checkedDepartment)
      dispatch(enableSaleComplexBlockAction())
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Ошибка получения параметров',
      //   })
      // )
    }
  }
}

export const selectStopListDepartment = (departmentId: string | number) => ({
  type: SaleDepartmentsActionTypes.SAVE_STOP_LIST_DEPARTMENT,
  payload: departmentId,
})

export const showComplexTable = () => ({
  type: SaleDepartmentsActionTypes.SHOW_SALE_COMPLEX_TABLE,
})

export const resetStopListSelectedDepartment = () => ({
  type: SaleDepartmentsActionTypes.RESET_STOP_LIST_DEPARTMENT,
})

export const resetDepartmentsCopyAction = () => ({
  type: SaleDepartmentsActionTypes.RESET_COPY_DEPARTMENTS,
})
