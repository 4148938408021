import React from 'react'

// mui imports
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

// hooks
import { useActions } from '../../../hooks/useActions'

// project imports

// api
import { deleteStopListItem } from '../../../../features/Sale/api/sale'
import { IStopListParams } from '../../../types/types'
import { useTypedSelector } from '../../../hooks/useTypedSelector'

interface IStopListContentProps {
  items: any[]
  header: string
  limit: string
  departmentId: number
}

const StopListContentTable = ({
  items,
  header,
  limit,
  departmentId,
}: IStopListContentProps) => {
  const {
    deleteStopListItemAction,
    deleteSaleComplexStopListItemAction,
    updateCostBonusesAction,
    updateSaleDepartmentsAction,
  } = useActions()

  const isBlockedRowsActive = useTypedSelector(
    (state) => state.saleComplex.isBlockedTable
  )
  const bonusPriceActive = useTypedSelector(
    (state) =>
      state.saleComplex.options.bonusInPrice.applyBonusInPriceToProductsPrice
  )
  const isConsigneeDepartmentActive = useTypedSelector(
    (state) =>
      state.saleComplex.options.consigneeDepartment?.departmentName.length > 0
  )

  const isTprBlocked =
    isBlockedRowsActive || bonusPriceActive || isConsigneeDepartmentActive

  const stopListHandler = async (params: IStopListParams) => {
    let { data } = await deleteStopListItem(params)
    updateCostBonusesAction(data.content.uiChanges.bonuses)
    updateSaleDepartmentsAction(data.content.uiChanges.departments)
    deleteStopListItemAction(params)
    deleteSaleComplexStopListItemAction(params)
  }

  return (
    <>
      <TableContainer>
        <Table size="small" aria-label="a stop-list table">
          <TableHead>
            <TableRow
              sx={{
                zIndex: 1,
                '& th': {
                  borderColor: 'background.paper',
                  backgroundColor: 'neutral.light',
                },
              }}
            >
              <TableCell
                colSpan={3}
                align="left"
                sx={{
                  fontSize: '0.825rem',
                  fontWeight: '600',
                  color: 'primary.main',
                }}
              >
                {header}
              </TableCell>

              <TableCell
                colSpan={4}
                align="left"
                sx={{ whiteSpace: 'nowrap', color: 'primary.main', pl: 3.6 }}
              >
                Лимит: {limit}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                '& th': {
                  fontSize: '0.688rem',
                  lineHeight: '1rem',
                  color: 'primary.main',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  borderBottom: 0,
                  backgroundColor: 'background.paper',
                },
              }}
            >
              <TableCell align="left">КОД УС</TableCell>
              <TableCell align="left">Наименование товара</TableCell>
              <TableCell align="left">Производитель</TableCell>
              <TableCell align="center">Текущий остаток</TableCell>
              <TableCell align="center">продажи за пред. мес., уп</TableCell>
              <TableCell align="center">кол-во для заказа,шт.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row: any) => (
              <TableRow
                sx={{
                  '& th,td': {
                    fontSize: '0.688rem',
                    lineHeight: '1rem',
                    color: '#5F5F5F',
                    fontWeight: '700',
                  },
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row" align="left">
                  {row.productEsCode}
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.supplierName}</TableCell>
                <TableCell align="center">{row.departmentStockQuantity}</TableCell>
                <TableCell align="center">
                  {row.departmentSoldIn30Days}
                </TableCell>
                <TableCell align="center">{row.initAmount}</TableCell>

                <TableCell align="center">
                  <Tooltip title="Удалить из стоп-листа">
                    <span>
                      <IconButton
                        disabled={isTprBlocked}
                        onClick={() =>
                          stopListHandler({
                            depId: departmentId,
                            productEscode: row.productEsCode,
                          })
                        }
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default StopListContentTable
