import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'

// third lib
import { debounce } from 'lodash'

// mui
import { Box, Divider, SxProps, Theme } from '@mui/material'

// project
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import { useActions } from '../../../../../shared/hooks/useActions'
import { ISupplierListItem } from '../types/supplierListTypes'
import SupplierListSerchPanel from './SupplierListSerchPanel'
import SupplierList from './SupplierList'

const containerStyle: SxProps<Theme> = {
  border: '1px solid',
  borderColor: 'action.disabled',
  boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
  borderRadius: '8px',
  pb: 2,
  overflowX: 'initial',
  height: 'calc(100vh - 600px)',
  overflowY: 'auto',
  minHeight: '400px',
}

function SupplierListBlock() {
  const items = useTypedSelector((state) => state.supplierList.items)
  const isLoading = useTypedSelector((state) => state.supplierList.loading)

  const [searchValue, setSearchValue] = useState('')
  const [filteredItems, setFilteredItems] = useState(items)

  const {
    fetchSupplierList,
    setSelectedSupplier,
    showSupplierListModal,
    handleSupplierListCheckBox,
  } = useActions()

  useEffect(() => {
    fetchSupplierList()
  }, [])

  useEffect(() => {
    if (!isLoading) {
      setFilteredItems(items)
    }
  }, [isLoading, items])

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setSearchValue(val)
    debounceFilter(val.trim())
  }

  const debounceFilter = useMemo(
    () =>
      debounce((val: string) => {
        if (val) {
          const filtered = items.filter(
            (item) =>
              item.supplierName &&
              item.supplierName.toLowerCase().includes(val.toLowerCase())
          )
          setFilteredItems(filtered)
        } else {
          setFilteredItems(items)
        }
      }, 1000),
    [items]
  )

  const handleOpenModal = (item: ISupplierListItem) => {
    setSelectedSupplier(item)
    showSupplierListModal()
  }

  return (
    <Box sx={containerStyle}>
      <SupplierListSerchPanel handleSearchInput={handleSearchInput} />
      <Divider />
      <SupplierList
        isLoading={isLoading}
        filteredItems={filteredItems}
        handleOpenModal={handleOpenModal}
        handleSupplierListCheckBox={handleSupplierListCheckBox}
      />
    </Box>
  )
}

export default SupplierListBlock
