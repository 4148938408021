import { Dispatch } from 'redux'
import {
  SaleCostBonusesAction,
  SaleCostBonusesActionTypes,
  SaleCostBonusesState,
} from './saleCostBonusesReduxTypes'
import { getCostBonuses } from '../../../../api/sale'
import { getDepartments } from '../../../../../../shared/api/department'

export const fetchCostBonusesAction = () => {
  return async (dispatch: Dispatch<SaleCostBonusesAction>) => {
    const { data } = await getCostBonuses()
    dispatch({
      type: SaleCostBonusesActionTypes.FETCH_SALE_COST_BONUSES_SUCCESS,
      payload: data.content,
    })
  }
}

export const updateCostBonusesAction = (
  bonuses: SaleCostBonusesState
): SaleCostBonusesAction => {
  return {
    type: SaleCostBonusesActionTypes.FETCH_SALE_COST_BONUSES_SUCCESS,
    payload: bonuses,
  }
}

export const resetCostBonusesStateAction = (): SaleCostBonusesAction => ({
  type: SaleCostBonusesActionTypes.RESET_SALE_COST_BONUSES,
})

export const fetchStandardDepartments = (name?: string, page?: number) => {
  return async (dispatch: Dispatch<SaleCostBonusesAction>) => {
    try {
      dispatch({ type: SaleCostBonusesActionTypes.LOADING_ENABLE })
      let {
        data: {
          content: { data },
        },
      } = await getDepartments(name, page)
      dispatch({
        type: SaleCostBonusesActionTypes.SAVE_DEPARTMENTS_LIST,
        payload: data,
      })
    } catch (e) {
      alert('Ошибка получения грузополучателя')
    } finally {
      dispatch({ type: SaleCostBonusesActionTypes.LOADING_DISABLE })
    }
  }
}
