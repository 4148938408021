import React from 'react'

// hooks
import { useActions } from '../../../shared/hooks/useActions'

// project imports
import BackToLastPage from '../../../shared/components/BackToLastPage'
import SaleDepartmentAutoComplete from '../../../shared/components/autoCompletes/SaleDepartmentAutoComplete'

// mui
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { Box } from '@mui/material'

const SaleDetailsAppBar = () => {
  const {
    handleSaleDetailsAutoComplete,
    startSaleDetailsSearching,
    resetSaleDetailsFilter,
  } = useActions()

  const filter = useTypedSelector((state) => state.saleDetails.filter)

  return (
    <Box
      sx={{
        zIndex: 2,
        backgroundColor: 'background.paper',
        pb: 2,
      }}
    >
      <BackToLastPage text="Детализация" />
      <Stack
        direction="row"
        justifyContent="start"
        columnGap={2}
        marginBottom={1}
      >
        <SaleDepartmentAutoComplete
          width={'320px'}
          value={filter['departmentIdRange']}
          customId={'departmentId'}
          customName={'departmentName'}
          isCheckBoxType={true}
          variant={'multiple'}
          handleAutoCompleteFields={(val: any) =>
            handleSaleDetailsAutoComplete(val, 'departmentIdRange')
          }
        />

        <Button
          variant="contained"
          sx={{ flexShrink: 0, fontSize: '0.775rem', maxHeight: 40 }}
          onClick={() => startSaleDetailsSearching()}
        >
          Поиск
        </Button>
        <Button
          sx={{ flexShrink: 0, fontSize: '0.775rem', maxHeight: 40 }}
          variant="outlined"
          onClick={() => resetSaleDetailsFilter()}
        >
          Сбросить
        </Button>
      </Stack>
    </Box>
  )
}

export default SaleDetailsAppBar
