import { useRef } from 'react'

// MUI
import { TableCell, TableRow, Checkbox, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

/// project imports
import { IAutoOrderProduct } from '../types/autoOrderProductsTypes'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import QuantityToOrderField from './QuantityToOrderFIeld'
import FormattedDate1 from '../../../../../shared/components/FormattedDate1'
import { useActions } from '../../../../../shared/hooks/useActions'

export function AutoOrderProductRow({
  row,
  onChangeQuantity,
  onChangeSelect,
  selectedRow,
}: {
  row: IAutoOrderProduct
  onChangeQuantity: (val: number, val2: string) => void
  onChangeSelect: (id: string) => void
  selectedRow: string
}) {
  const { deletedPosition, showDeletedPositionModal } = useActions()

  const inputRef = useRef<HTMLInputElement | null>(null)

  const { activeFields } = useTypedSelector((state) => state.autoOrderProducts)

  const handleClickProduct = () => {
    inputRef.current?.focus()
    onChangeSelect(row.id) // todo refactor
  }

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation()
    deletedPosition(row.autoOrderBidSimilarProductsGroupId)
    showDeletedPositionModal()
  }
  return (
    <TableRow
      onClick={handleClickProduct}
      sx={{
        backgroundColor: selectedRow === row.id ? 'ButtonShadow' : '',
      }}
    >
      {activeFields['esCode'] && (
        <TableCell align="left" sx={{ minWidth: 50 }}>
          {row.esCode}
        </TableCell>
      )}
      {activeFields['bitProductName'] && (
        <TableCell align="left" sx={{ minWidth: 200 }}>
          {row.bitProductName}
        </TableCell>
      )}
      {activeFields['productName'] && (
        <TableCell align="left" sx={{ minWidth: 200 }}>
          {row.productName}
        </TableCell>
      )}
      {activeFields['producerName'] && (
        <TableCell align="left" sx={{ minWidth: 100 }}>
          {row.producerName}
        </TableCell>
      )}
      {activeFields['quantityToOrder'] && (
        <TableCell align="left" sx={{ minWidth: 50 }}>
          <QuantityToOrderField
            inputRef={inputRef}
            row={row}
            defaultValue={row.quantityToOrder}
            onChangeQuantity={onChangeQuantity}
          />
        </TableCell>
      )}
      {activeFields['multiplicityOfBoxQuantity'] && (
        <TableCell align="center" sx={{ minWidth: 80 }}>
          {row.multiplicityOfBoxQuantity}
        </TableCell>
      )}
      {activeFields['price'] && (
        <TableCell align="left" sx={{ minWidth: 100 }}>
          {row.price}
        </TableCell>
      )}
      {activeFields['cost'] && (
        <TableCell align="left" sx={{ minWidth: 50 }}>
          {row.cost}
        </TableCell>
      )}
      {activeFields['expirationDate'] && (
        <TableCell align="left" sx={{ minWidth: 80 }}>
          {<FormattedDate1 date={row.expirationDate} />}
        </TableCell>
      )}
      {activeFields['valueAddedTaxPercentage'] && (
        <TableCell align="center" sx={{ minWidth: 80 }}>
          {row.valueAddedTaxPercentage}
        </TableCell>
      )}
      {activeFields['registeredPrice'] && (
        <TableCell align="center" sx={{ minWidth: 80 }}>
          {row.registeredPrice}
        </TableCell>
      )}
      {activeFields['isVitalMedicinalProduct'] && (
        <TableCell align="left" sx={{ minWidth: 80 }}>
          <Checkbox checked={row.isVitalMedicinalProduct} />
        </TableCell>
      )}
      {activeFields['minimalQuantity'] && (
        <TableCell align="center" sx={{ minWidth: 80 }}>
          {row.minimalQuantity}
        </TableCell>
      )}
      {activeFields['quantityInPriceList'] && (
        <TableCell align="center" sx={{ minWidth: 80 }}>
          {row.quantityInPriceList}
        </TableCell>
      )}
      {activeFields['remainingQuantity'] && (
        <TableCell align="center" sx={{ minWidth: 100 }}>
          {row.remainingQuantity}
        </TableCell>
      )}
      {activeFields['quantitySold'] && (
        <TableCell align="center" sx={{ minWidth: 100 }}>
          {row.quantitySold}
        </TableCell>
      )}
      <TableCell>
        <IconButton onClick={handleDelete} size="small">
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
