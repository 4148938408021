import React from 'react'

//mui
import TableContainer from '@mui/material/TableContainer'
import TableSortLabel from '@mui/material/TableSortLabel'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import { SxProps, Theme } from '@mui/material'

//const
import { headCellsArray } from '../consts/processingAutoOrder'

import {
  IProcessingAutoOrderItem,
  ProcessingAutoOrderFieldType,
  ProcessingAutoOrderFieldTypeMap,
} from '../types/processingAutoOrderTypes'
import ElementWatch from '../../../../Product/components/ElementWatch'
import { useActions } from '../../../../../shared/hooks/useActions'
import { ProcessingAutoOrderRow } from './ProcessingAutoOrderRow'
import { useProcessingAutoOrderController } from '../controllers/useProcessingAutoOrderController'

const tableHeadStyle: SxProps<Theme> = {
  '& th': {
    whiteSpace: 'nowrap',
    fontSize: '0.73rem',
    color: 'primary.main',
    fontWeight: '770',
    textTransform: 'uppercase',
    borderBottom: 0,
    backgroundColor: 'background.paper',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
}

const tableContainerStyle: SxProps<Theme> = {
  border: '1px solid',
  borderColor: 'action.disabled',
  boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
  borderRadius: '8px',
  pb: 1,
  overflowX: 'initial',
  height: 'calc(100vh - 600px)',
  overflowY: 'auto',
  minHeight: '300px',
}

const ProcessingAutoOrderTable = () => {
  const {
    firstElement,
    lastElement,
    inputRef,
    loading,
    items,
    order,
    listOrientation,
  } = useProcessingAutoOrderController()

  const { setProcessingAutoOrderSort } = useActions()

  const sortHandler = (field: string) => {
    const sortDataMap =
      ProcessingAutoOrderFieldTypeMap[field as ProcessingAutoOrderFieldType]

    if (order === 'asc') {
      setProcessingAutoOrderSort({
        orientation: sortDataMap.desc,
        order: 'desc',
      })
    } else {
      setProcessingAutoOrderSort({
        orientation: sortDataMap.asc,
        order: 'asc',
      })
    }
  }

  const activeSort = (field: string) => {
    const sortDataMap =
      ProcessingAutoOrderFieldTypeMap[field as ProcessingAutoOrderFieldType]

    return (
      sortDataMap.asc === listOrientation ||
      sortDataMap.desc === listOrientation
    )
  }

  return (
    <TableContainer sx={tableContainerStyle}>
      <Table size="small" aria-label="a auto-order-settings table">
        <TableHead>
          <TableRow sx={tableHeadStyle}>
            {headCellsArray.map((item) => (
              <TableCell
                key={item.name}
                align={item.align}
                sx={{
                  padding: item.enablePaddings
                    ? '0.5rem 1rem 0 1rem'
                    : '0.5rem 0 0 0',
                }}
              >
                <TableSortLabel
                  active={activeSort(item.field)}
                  direction={order}
                  onClick={() => sortHandler(item.field)}
                >
                  <Box sx={{ pl: item.enableLeftPadding ? '18px' : 'unset' }}>
                    {item.name}
                  </Box>
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <ElementWatch
            componentNode={'tr'}
            element={firstElement}
            isLoading={loading}
          />
          {items.map((row: IProcessingAutoOrderItem, index) => (
            <React.Fragment key={row.id + index}>
              <ProcessingAutoOrderRow row={row} />
              <tr
                // @ts-ignore
                ref={(el) => (inputRef.current[row.id] = el)}
              />
            </React.Fragment>
          ))}
          <ElementWatch
            componentNode={'tr'}
            element={lastElement}
            isLoading={loading}
          />
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ProcessingAutoOrderTable
