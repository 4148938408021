import React, { ChangeEvent } from 'react'

// mui
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  SxProps,
  Theme,
} from '@mui/material'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'

// project
import ItemsLoader from '../../../../../shared/components/ItemsLoader'
import { ISupplierListItem } from '../types/supplierListTypes'

const listStyle: SxProps<Theme> = {
  minWidth: '260px',
  overflow: 'auto',
  overflowX: 'hidden',
}

const supplierNameStyle: SxProps<Theme> = {
  width: '100%',
  pr: 1,
  paddingY: '2px',
  fontWeight: 600,
  fontSize: 12,
  color: 'primary.main',
  textDecoration: 'unset',
  wordBreak: 'break-word',
}

interface ISupplierListProps {
  isLoading: boolean
  filteredItems: ISupplierListItem[]
  handleOpenModal: (item: ISupplierListItem) => void
  handleSupplierListCheckBox: (
    event: ChangeEvent<HTMLInputElement>,
    item: ISupplierListItem
  ) => void
}

const SupplierList = ({
  isLoading,
  filteredItems,
  handleOpenModal,
  handleSupplierListCheckBox,
}: ISupplierListProps) => {
  return (
    <Box sx={{ overflow: 'auto' }}>
      <ItemsLoader loading={isLoading} isEmpty={filteredItems.length === 0}>
        <List sx={listStyle} aria-labelledby="supplier list">
          {filteredItems.map((item) => {
            return (
              <ListItem
                key={item.supplierId}
                sx={{
                  '&:hover': {
                    backgroundColor: 'action.selected',
                  },
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => handleOpenModal(item)}
                    disabled={!item.isAvailableForUseInAutoOrder}
                  >
                    <ListAltOutlinedIcon />
                  </IconButton>
                }
                disablePadding
              >
                <ListItemIcon
                  sx={{
                    ml: 1,
                    minWidth: '31px',
                  }}
                >
                  <Checkbox
                    edge="start"
                    id={String(item.supplierId)}
                    onChange={(event) =>
                      handleSupplierListCheckBox(event, item)
                    }
                    checked={item.isActiveInAutoOrder}
                    disabled={!item.isAvailableForUseInAutoOrder}
                  />
                </ListItemIcon>
                <Box sx={supplierNameStyle}>{item.supplierName}</Box>
              </ListItem>
            )
          })}
        </List>
      </ItemsLoader>
    </Box>
  )
}

export default SupplierList
