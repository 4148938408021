import {
  SaleStopListsAction,
  SaleStopListsActionTypes,
  SaleStopListsState,
} from './saleStopListsReduxTypes'
import cloneDeep from 'lodash/cloneDeep'

const initialState: SaleStopListsState = {
  loading: false,
  error: '',
  page: 1,
  products: [],
}

export const saleStopListsReducer = (
  state = initialState,
  action: SaleStopListsAction
): SaleStopListsState => {
  switch (action.type) {
    case SaleStopListsActionTypes.FETCH_PRODUCTS_SUCCESS:
      const content = action.payload
      return {
        ...state,
        products: content,
        error: '',
      }

    case SaleStopListsActionTypes.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case SaleStopListsActionTypes.SET_PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case SaleStopListsActionTypes.DELETE_STOP_LIST_ITEM:
      const { depId, productEscode } = action.payload
      let tempArr = cloneDeep(state.products)
      const itemDepartment = tempArr.find(
        (item: any) => item.departmentId === depId
      )
      const itemProducts = itemDepartment.products.filter(
        (item: any) => item.productEsCode !== productEscode
      )
      itemDepartment.products = itemProducts.slice()

      return {
        ...state,
        products: tempArr,
      }

    default:
      return state
  }
}
