import React from 'react'

const PreLoader = () => (
  <div className="pulse-loader">
    <svg
      data-v-daf6461d=""
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="62"
      viewBox="0 0 40 39"
      className="text-primary"
      style={{ width: '64px', height: '64px', fill: '#57A5F1' }}
    >
      <g clipPath="url(#a)">
        <path d="M10.655 2.01c-5.206 2.708-7.672 9.208-7.672 9.208C9.937 4.544 14.61 2.213 24.08.114c0 0-8.219-.812-13.425 1.896ZM14.216 7.698C3.257 12.03.408 18.688.408 18.688s-.14 1.083.408 1.625c.548.541 7.646.655 17.784-3.407 10.137-4.063 16.026-9.208 15.068-11.104-.96-1.896-8.493-2.438-19.452 1.896ZM5.175 30.176c-2.192-1.083-4.136-4.997-3.589-6.351.548-1.354 12.934.82 20.058-1.617 7.548-2.583 11.75-6.386 14.216-9.094 2.465-2.708 4.806 1.355 3.014 6.229-1.793 4.875-6.028 7.583-8.494 9.208-2.465 1.625-5.989 2.617-9.589 3.25-4.11.724-7.123.271-7.945.271-.822 0-5.48-.812-7.671-1.896ZM20.517 38.843c-6.849 0-13.424-5.146-13.424-5.146 10.726 3.719 16.85 3.264 27.945-1.083 0 0-7.671 6.229-14.52 6.229Z"></path>
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h40v40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  </div>
)

export default PreLoader
