import React from "react"
import { Typography } from "@mui/material"

const RouteWarning = ({ text }: { text: string }) => {
  return (
    <>
      <Typography
        textAlign="left"
        variant="h5"
        component="h2"
        marginTop={1}
        fontWeight={"600"}
        color={"#909DB5"}
      >
         {text}
      </Typography>
    </>
  )
}

export default RouteWarning