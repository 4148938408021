//actions
import {
  AutoOrderAction,
  AutoOrderActionTypes,
  AutoOrderOrientation,
  AutoOrderState,
} from './autoOrderReduxTypes'

//const
import { AUTO_ORDER_STATUS, AUTO_ORDER } from '../../consts/autoOrder'

//project
import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'

const initialState: AutoOrderState = {
  enough: false,
  direction: LoadDirections.DOWN,
  loading: false,
  error: {} as ErrorInfo,
  page: 1,
  limit: 30,
  total: 30,
  filter: [],
  id: null,
  startSearch: false,
  checkedItems: [],
  isCheckedAll: false,
  orderBy: 'creationDate',
  order: 'asc',
  listOrientation: AutoOrderOrientation.StartDate,
  items: [],
  conditionStatus: {
    calculationStatus: null,
    editAvailabilityStatus: null,
    userBidsStatus: null,
  },
}

export const autoOrderReducer = (
  state = initialState,
  action: AutoOrderAction
): AutoOrderState => {
  switch (action.type) {
    case AutoOrderActionTypes.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
        enough: false,
        direction: action.payload.loadFlag
          ? LoadDirections.DOWN
          : LoadDirections.UP,
      }

    case AutoOrderActionTypes.START_AUTO_ORDER_SEARCH:
      return {
        ...state,
        items: [],
        enough: false,
        startSearch: !state.startSearch,
      }

    case AutoOrderActionTypes.FETCH_PRODUCTS_SUCCESS:
      const { content, loading } = action.payload
      const {
        MIN_ITEMS_AFTER_LOADING,
        START_DOWN_INDEX,
        END_DOWN_INDEX,
        START_UP_INDEX,
        END_UP_INDEX,
      } = AUTO_ORDER

      if (state.items.length > MIN_ITEMS_AFTER_LOADING) {
        if (state.direction === LoadDirections.DOWN) {
          const itemsCopy = state.items.slice(START_DOWN_INDEX, END_DOWN_INDEX)

          return {
            ...state,
            items: [...itemsCopy, ...content.items],
            total: content.pagination.total,
            loading,
          }
        } else if (state.direction === LoadDirections.UP) {
          const itemsCopy = state.items.slice(START_UP_INDEX, END_UP_INDEX)

          return {
            ...state,
            items: [...content.items, ...itemsCopy],
            total: content.pagination.total,
            loading,
          }
        }
      }

      return {
        ...state,
        items: [...state.items, ...content.items],
        total: content.pagination.total,
        loading,
      }

    case AutoOrderActionTypes.FETCH_PRODUCTS_ERROR:
      return { ...state, loading: false, error: action.payload }

    case AutoOrderActionTypes.SET_PRODUCT_SORT:
      return {
        ...state,
        items: [],
        enough: false,
        listOrientation: action.payload.orientation,
        order: action.payload.order,
      }

    case AutoOrderActionTypes.FETCH_PRODUCTS_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }

    case AutoOrderActionTypes.SET_AUTO_ORDER_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case AutoOrderActionTypes.ADD_CHECKED_ITEMS:
      const updatedCheckedItems = state.checkedItems.concat([action.payload])
      const currentItemIndex = state.items.findIndex(
        (el) => el.id === action.payload
      )

      if (currentItemIndex !== -1) {
        const newArray = [...state.items]

        newArray[currentItemIndex] = {
          ...newArray[currentItemIndex],
          status: AUTO_ORDER_STATUS.IN_PROCESSING,
          enableToDeleteUserAutoOrderSummaryBid: true,
          enableToDeleteSummaryBid: false,
        }

        // Обновляем состояние, только если элемент найден
        return {
          ...state,
          checkedItems: [...updatedCheckedItems],
          items: newArray,
        }
      }

      return state

    case AutoOrderActionTypes.SET_CHECKED_ITEMS:
      const filteredCheckedItems = state.checkedItems.filter(
        (id: string) => id !== action.payload.id
      )
      const deletedItemIndex = state.items.findIndex(
        (el) => el.id === action.payload.id
      )

      if (deletedItemIndex !== -1) {
        const newArray = [...state.items]

        newArray[deletedItemIndex] = {
          ...newArray[deletedItemIndex],
          status:
            action.payload.status === AUTO_ORDER_STATUS.NEW
              ? AUTO_ORDER_STATUS.NEW
              : AUTO_ORDER_STATUS.PARTIALLY_PROCESSED,
          enableToDeleteUserAutoOrderSummaryBid: false,
          enableToDeleteSummaryBid:
            action.payload.status === AUTO_ORDER_STATUS.NEW,
        }

        // Обновляем состояние, только если элемент найден
        return {
          ...state,
          checkedItems: [...filteredCheckedItems],
          items: newArray,
        }
      }

      return state

    case AutoOrderActionTypes.REMOVE_FILTER_WITH_EMPTY_VALUE:
      return {
        ...state,
        filter: action.payload,
      }

    case AutoOrderActionTypes.SET_FILTER_PRODUCTS:
      // Если массив
      if (Array.isArray(action.payload)) {
        const firstDateItem = action.payload[0]
        const filtersCopy = [...state.filter]

        const filteredCopy = filtersCopy.filter(
          (item) => item.propertyName !== firstDateItem.propertyName
        )

        const resultFiltersCopy = filteredCopy.concat(action.payload)

        return {
          ...state,
          filter: resultFiltersCopy,
        }
        // Если объект
      } else {
        const updatedItem = action.payload
        const index = state.filter.findIndex(
          (item) => item.propertyName === updatedItem.propertyName
        )

        if (index !== -1) {
          const filterCopy = [...state.filter]
          filterCopy[index] = updatedItem

          return {
            ...state,
            filter: filterCopy,
          }
        } else {
          return {
            ...state,
            filter: [...state.filter, updatedItem],
          }
        }
      }

    case AutoOrderActionTypes.RESET_FILTER_AUTO_ORDER:
      return {
        ...state,
        filter: [],
        // items: [],
        page: 1,
        // startSearch: !state.startSearch,
        // error: {},
      }

    case AutoOrderActionTypes.RESET_AUTO_ORDER_ITEMS:
      return {
        ...state,
        items: [],
        page: 1,
      }

    case AutoOrderActionTypes.DELETE_PRODUCT_BY_ID:
      console.log(action.payload)
      const filteredItems = state.items.filter((i) => i.id !== action.payload)
      return {
        ...state,
        items: filteredItems,
      }

    case AutoOrderActionTypes.SAVE_AUTO_ORDER_CONDITION:
      return {
        ...state,
        conditionStatus: action.payload,
      }
    default:
      return state
  }
}
