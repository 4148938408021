import { ChangeEvent } from "react";

import { Dispatch } from "redux";

import { SupplierSettingsAction, SupplierSettingsActionTypes, SupplierSettingsState } from "./supplierSettingsReduxTypes";
import { FactorItem, PriorityItem } from "../../types/supplierSettingsTypes";

import { AppStateType } from "../../../../../../shared/store";
import { deleteSupplierSettingsOptions, getSupplierSettingsOptions, putSupplierSettingsOptions } from "../../api/supplierSettings";
import { ApplicationAction, ApplicationActionTypes, SnackTypes } from "../../../../../../shared/store/application/applicationReduxTypes";


// TODO: настроить обработку разных ошибок
export const fetchSupplierSettings = (departmentId: number | null) => {
  return async (
    dispatch: Dispatch<SupplierSettingsAction | ApplicationAction>,
    getState: () => AppStateType
  ) => {
    try {

      // закоментировать, чтобы воссоздать ошибку сервера
      if(departmentId === null) {
        return
      }

      const response = await getSupplierSettingsOptions(departmentId);
      const content = response?.data?.content || null;

      // симулирую ошибку при ответе бекенда
      // response.data.isError = true 
      response.data.description = 'Ошибка получения подразделения'

      if (response.data.isError) {
        throw new Error(response.data.description)

      } else {
        dispatch({
          type: SupplierSettingsActionTypes.FETCH_SUPPLIER_SETTINGS_SUCCESS,
          payload: content,
        });
      }
    } catch (e) {
      // dispatch({
      //   type: ApplicationActionTypes.SHOW_SNACK,
      //   payload: {
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: `${e.message}`,
      //   }
      // });
    }
  };
};


export const resetSupplierSettings = (departmentId: number) => {
  return async (
    dispatch: Dispatch<SupplierSettingsAction | ApplicationAction>,
    getState: () => AppStateType
    ) => {
    try {
      let {
        data: { content },
      } = await deleteSupplierSettingsOptions(departmentId)

      dispatch({ 
        type: SupplierSettingsActionTypes.FETCH_SUPPLIER_SETTINGS_SUCCESS, 
        payload: content,
      });
    } catch (error) {
      // dispatch({
      //   type: ApplicationActionTypes.SHOW_SNACK,
      //   payload: { 
      //     typeSnack: SnackTypes.ERROR, messageSnack: 'Ошибка удаления настроек' 
      //   },
      // });
    }
  }
}

export const saveSupplierSettings = (supplierSettings: SupplierSettingsState) =>  {
  return async (
    dispatch: Dispatch<SupplierSettingsAction | ApplicationAction>,
    ) => {
      try {

        await putSupplierSettingsOptions(supplierSettings)

      } catch (error) {
        // console.log(error)
        // dispatch({
        //   type: ApplicationActionTypes.SHOW_SNACK,
        //   payload: { 
        //     typeSnack: SnackTypes.ERROR, messageSnack: 'Ошибка сохранения настроек'
        //   },
        // });
      }
    }
}

export const setShelfLifePercentSettingsAction = (val: number) => {
  return {
    type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_TEXT_SHELF_LIFE_PERCENT,
    payload: val,
  }
}

export const setShelfLifeSettingsAction = (val: string) => ({
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_TEXT_SHELF_LIFE,
  payload: val,
});

export const setSupplierOrderMinimumCostPercentSettingsAction = (val: string) => ({
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_TEXT_MIN_COST_PERCENT,
  payload: val,
});

export const updateSupplierSettingsPrioritiesOrder = (newPriorities: PriorityItem[]) => {
    return {
      type: SupplierSettingsActionTypes.UPDATE_SUPPLIER_SETTINGS_PRIORITY_ORDER,
      payload: newPriorities,
    };
  }
  
export const setSupplierSettingsOptionFactorsList = (newFactorsList: FactorItem[]) => ({
    type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_FACTORS_LIST,
    payload: newFactorsList,
  });

  const actionMap: Record<string, SupplierSettingsActionTypes> = {
    remainingShelfLifeInclude: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_SHELF_LIFE,
    remainingShelfLifePercentInclude: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_SHELF_LIFE_PERCENT,
    onlyProductsWithBestRating: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_BEST_RATING,
    considerMultiplicityOfBoxQuantity: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_MULTIPLICITY,
    considerMinimumQuantity: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_MIN_QUANTITY,
    distributeSupplierOrderIfCostLessThanMinimumCost: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_DISTRIBUTE_SUPPLIER_ORDER,
    considerQuantityAvailabilityFactor: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_FACTOR,
  };
  
  export const changeSupplierSettingsCheckBoxsAction = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const payload = e.target.checked;
    const name = e.target.name;
  
    if (name in actionMap) {
      const actionType = actionMap[name];
      return {
        type: actionType,
        payload,
      };
    }
  
    return null;
  }