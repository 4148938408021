import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as HospitalIcon } from '../../../assets/images/Hospital 3.svg'
import { Tooltip } from '@mui/material'

const SvgHospital = ({
  renderCondition = false,
  initialSizes = {
    width: '20px',
    height: '20px'
  }
}: {
  renderCondition?: boolean,
  initialSizes?: {
    [key: string]: string
  }
}) => {
  if (!renderCondition) return null
  return (
    <Tooltip title="ЖНВЛП">
      <SvgIcon
        viewBox="0 0 20 20"
        style={{
          ...initialSizes
        }}
      >
        <HospitalIcon />
      </SvgIcon>
    </Tooltip>
  )
}

export default SvgHospital
