// assets
import InterRegularTTF from '../assets/fonts/Inter-Regular.ttf'
import { Theme } from '@mui/material/styles'

export const getAdditionalOptions = (theme: Theme) => {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': {
          fontFamily: 'Inter',
          src: `local('Inter'), local('Inter-Regular'), url(${InterRegularTTF}) format("truetype")`,
        },

        '*::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },

        '*::-webkit-scrollbar-track': {
          background: theme.palette.background.default,
        },

        '*::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.primary.main,
          borderRadius: '5px',
          border: '2px solid #F4F7FE',
        },
      },
    },
  }
}
