import React from 'react'
import { ListSubheader } from '@mui/material'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { postUserSupplierALlPList } from '../api/supplier'
import { IOptionsProps } from '../../../shared/types/types'
import { SnackTypes } from '../../../shared/store/application/applicationReduxTypes'
import { useActions } from '../../../shared/hooks/useActions'

interface ISupHeaderProps {
  changeItems: (val: IOptionsProps[]) => void
  fetchSuppliersItems: (...args: any) => Promise<void>
}

const SupHeader = ({ changeItems, fetchSuppliersItems }: ISupHeaderProps) => {
  const { showSnack } = useActions()

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    postUserSupplierALlPList(e.target.checked)
      .then(() => {
        changeItems([])
        fetchSuppliersItems()
      })
      .catch(
        () => ({})
        // showSnack({
        //   typeSnack: SnackTypes.ERROR,
        //   messageSnack: 'Ошибка запроса',
        // })
      )
  }

  return (
    <>
      <ListSubheader
        sx={{
          padding: 0,
          borderRadius: '8px 0 0 0',
          display: 'flex',
          columnGap: '15px',
        }}
      >
        <Box sx={{ ml: 1 }}>
          <Checkbox edge="start" disableRipple onChange={handleSelectAll} />
          Выбрать всех
        </Box>
      </ListSubheader>
    </>
  )
}

export default SupHeader
