import * as React from 'react'
import { useMemo } from 'react'

// mui imports
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'

//redux
import { useActions } from '../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'

// project imports
import ElementWatch from '../../../../Product/components/ElementWatch'
import ErrorPage from '../../../../Product/components/ErrorPage'
import SaleDepartmentsRow from './SaleDepartmentsRow'

//controller
import { useSaleDepartmentsController } from '../controllers/useSaleDepartmentsController'

// types
import { SaleDetailsFieldType } from '../../../../SaleDetails/types/saleDetailsTypes'
import { ISaleDepartmentsHeadCells } from '../types/saleDepartmentsTypes'

// libs
import debounce from 'lodash/debounce'

// api
import { putProductsInWay } from '../../../api/sale'

const SaleDepartmentsTable = () => {
  const isComplexTableShowed = useTypedSelector(
    (state) => state.saleDepartments.isComplexTableShowed
  )
  const { setSaleDepartmentsSort } = useActions()

  const headCellsSaleDepartments: ISaleDepartmentsHeadCells[] = useMemo(
    () => [
      {
        name: 'Подразделения',
        align: 'left',
        field: 'departmentName',
        enableLeftPadding: false,
        enablePaddings: true,
        disableField: false,
      },
      {
        name: 'Сумма заказа',
        align: 'center',
        field: 'departmentOrderCost',
        enableLeftPadding: true,
        enablePaddings: false,
        disableField: false,
      },
      {
        name: 'бонус, ₽',
        align: 'center',
        field: 'departmentBonusCost',
        enableLeftPadding: true,
        enablePaddings: false,
        disableField: false,
      },
      {
        name: 'бонус, %',
        align: 'center',
        field: 'departmentBonusPercentage',
        enableLeftPadding: true,
        enablePaddings: false,
        disableField: false,
      },

      {
        name: 'В пути',
        align: 'center',
        field: 'productOnWay',
        enableLeftPadding: true,
        enablePaddings: false,
        disableField: isComplexTableShowed,
        Icon: LocalShippingIcon,
      },
    ],
    [isComplexTableShowed]
  )

  const {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    products,
    order,
    orderBy,
  } = useSaleDepartmentsController()

  const sortHandler = (field: SaleDetailsFieldType) => {
    const isAsc = orderBy === field && order === 'asc'
    setSaleDepartmentsSort({ orderBy: field, order: isAsc ? 'desc' : 'asc' })
  }

  const debouncedRequest = useMemo(
    () =>
      debounce(
        (amount: number, departmentId: number) =>
          putProductsInWay(amount, departmentId),
        800
      ),
    []
  )

  const changeItemAmount = (amount: number, departmentId: number) => {
    debouncedRequest(amount, departmentId)
  }

  if (Object.keys(error).length) {
    return (
      <ErrorPage
        {...{
          error,
          firstElement,
          lastElement,
        }}
      />
    )
  }

  return (
    <Box>
      <TableContainer
        sx={{
          border: '1px solid #E1E3E6',
          boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
          borderRadius: '8px',
          height: 'calc(100vh - 340px)',
          overflowY: 'auto',
          overflowX: 'initial',
        }}
      >
        <Table
          size="small"
          aria-label="a departments table"
          sx={{ tableLayout: 'auto' }}
        >
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  fontSize: '0.6rem',
                  lineHeight: '1rem',
                  color: 'primary.main',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  borderBottom: 0,
                  backgroundColor: 'background.paper',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                },
              }}
            >
              {headCellsSaleDepartments.map((item) => (
                <TableCell
                  key={item.name}
                  align={item.align}
                  sx={{
                    padding: item.enablePaddings
                      ? '0.5rem 1rem 0 1rem'
                      : '0.5rem 0 0 0',
                  }}
                >
                  {!item.disableField && (
                    <TableSortLabel
                      active={orderBy === item.field}
                      direction={orderBy === item.field ? order : 'asc'}
                      onClick={() => sortHandler(item.field)}
                    >
                      <Box
                        sx={{ pl: item.enableLeftPadding ? '18px' : 'unset' }}
                      >
                        {item.Icon ? <item.Icon /> : item.name}
                      </Box>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <ElementWatch
              componentNode={'tr'}
              element={firstElement}
              isLoading={loading}
            />
            {products.map((row: any) => (
              // @ts-ignore
              <React.Fragment key={row.departmentId}>
                <SaleDepartmentsRow
                  row={row}
                  changeItemAmount={changeItemAmount}
                />
                <tr
                  // @ts-ignore
                  ref={(el) => (inputRef.current[row.departmentId] = el)}
                />
              </React.Fragment>
            ))}
            <ElementWatch
              componentNode={'tr'}
              element={lastElement}
              isLoading={loading}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default SaleDepartmentsTable
