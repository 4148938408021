import React, { useEffect, useState } from 'react'

// import MUI
import {
  Checkbox,
  Typography,
  FormControlLabel,
  Button,
  Grid,
  Box,
} from '@mui/material'

// import consts
import { headCellsArray } from '../consts/autoOrderConsts'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import { useActions } from '../../../../../shared/hooks/useActions'

// import laodash
import _ from 'lodash'

const AutoOrderFieldsContent = ({ onClose }: { onClose: () => void }) => {
  const { saveAutoOrderAcitveFields, setAutoOrderFields } = useActions()

  const activeFiltes = useTypedSelector(
    (state) => state.autoOrderProducts.activeFields
  )

  const [filters, setFilters] = useState(activeFiltes)

  const handleChangeFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    const isCheked =
      Object.values(filters).filter((el) => el === true).length < 2 && !checked
    if (isCheked) return
    setFilters({ ...filters, [name]: checked })
  }
  const handleSubmit = () => {
    const isChangeDFields = !_.isEqual(activeFiltes, filters)
    if (isChangeDFields) {
      saveAutoOrderAcitveFields(filters)
      setAutoOrderFields(filters)
    }
    onClose()
  }

  console.log(activeFiltes, 'active ')

  return (
    <React.Fragment>
      <Box
        sx={{
          height: 260,
          overflow: 'auto',
          paddingLeft: '15px',
        }}
      >
        <Grid container>
          {headCellsArray.map(({ field, name }) => (
            <Grid item xs={4} key={field}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters[field as keyof typeof filters]}
                    onChange={handleChangeFilters}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#5F5F5F',
                    }}
                  >
                    {' '}
                    {name}
                  </Typography>
                }
                name={field}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Button
        sx={{ mt: 3 }}
        fullWidth
        type="button"
        onClick={handleSubmit}
        variant="contained"
      >
        Сохранить
      </Button>
    </React.Fragment>
  )
}

export default AutoOrderFieldsContent
