import { createTheme } from '@mui/material/styles'
import { getAdditionalOptions } from './additionalOptions'

export const mainTheme = createTheme({
  textTooltip: 'Главная тема',
  palette: {
    primary: {
      main: '#5868B4',
    },
    secondary: {
      main: '#606F89',
    },
    background: {
      default: '#f2f8f9',
      paper: '#fff',
    },
    neutral: {
      main: '#7D90B2',
      light: '#F9F8F8',
      contrastText: '#FFF',
    },
    text: {
      primary: '#000',
      secondary: '#606F89',
    },
  },
})

mainTheme.components = getAdditionalOptions(mainTheme)
