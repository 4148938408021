import { ISubAutoOrderHeadCells, SubAutoOrderFieldTypeMap } from '../types/subAutoOrderTypes'

export const SUB_AUTO_ORDER = {
  MIN_ITEMS_AFTER_LOADING: 31,
  START_DOWN_INDEX: 30,
  END_DOWN_INDEX: 90,
  START_UP_INDEX: 0,
  END_UP_INDEX: 59,
}

export const headCellsArray: ISubAutoOrderHeadCells[] = [
  {
    name: '',
    align: 'left',
    field: '',
    enableLeftPadding: false,
    disableField: false,
    disableSort: true,
    enablePadding: false,
  },
  {
    name: 'Наименование товара',
    align: 'left',
    field: SubAutoOrderFieldTypeMap.productName.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePadding: false,
  },
  {
    name: 'Кол-во',
    align: 'center',
    field: SubAutoOrderFieldTypeMap.quantityToOrder.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePadding: false,
  },
  {
    name: 'Остаток',
    align: 'center',
    field: SubAutoOrderFieldTypeMap.remainingQuantity.value,
    enableLeftPadding: true,
    disableField: false,
    enablePadding: false,
    disableSort: false,
  },
  {
    name: 'Продано',
    align: 'center',
    field: SubAutoOrderFieldTypeMap.quantitySold.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePadding: false,
  },
  {
    name: 'Заказано',
    align: 'center',
    field: SubAutoOrderFieldTypeMap.orderedQuantity.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePadding: false,
  },
  {
    name: 'Осталось',
    align: 'center',
    field: SubAutoOrderFieldTypeMap.remainingQuantityToOrder.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePadding: false,
  },
  {
    name: 'Отказано',
    align: 'center',
    field: SubAutoOrderFieldTypeMap.isRefusalToOrder.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePadding: false,
  },
  {
    name: 'Поставщик',
    align: 'center',
    field: SubAutoOrderFieldTypeMap.supplierName.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePadding: false,
  },
  {
    name: 'Номер заказа',
    align: 'center',
    field: SubAutoOrderFieldTypeMap.proMarketOrderId.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePadding: false,
  },
  {
    name: 'Дата заказа',
    align: 'center',
    field: SubAutoOrderFieldTypeMap.proMarketOrderDate.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePadding: false,
  },
  {
    name: 'Сумма заказа',
    align: 'center',
    field: SubAutoOrderFieldTypeMap.productOrderCost.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePadding: false,
  },
]