import { LoadDirections } from "../../../../../shared/types/types";
import { IPreOrder, IPreOrderSorts, IPreOrders } from "../types/departmentsPreOrderTypes";


export enum DepartmentsPreOrdersActionTypes {
    SAVE_DEPARTMENT_PRE_ORDERS = 'SAVE_DEPARTMENT_PRE_ORDERS',
    SAVE_PREORDER_SORT = 'SAVE_PREORDER_SORT',
    SELECT_PRE_ORDER = 'SELECT_PRE_ORDER',
    LOADING_DEPARMENTS_PRE_ORDER_ENB = 'LOADING_DEPARMENTS_PRE_ORDER_ENB',
    LOADING_DEPARMENTS_PRE_ORDER_DSB = 'LOADING_DEPARMENTS_PRE_ORDER_DSB',
    SET_ENOUGH = 'SET_ENOUGH',
    SET_DEPARTMENT_PAGE = 'SET_DEPARTMENT_PAGE',
    CLEAR_DEPARTMENT = 'CLEAR_DEPARTMENT',
    DEPARTEMNT_REQ = 'DEPARTEMNT_REQ',
    SET_NEW_PRE_ORDER = 'SET_NEW_PRE_ORDER',
    SHOW_PRE_ORDERS = 'SHOW_PRE_ORDERS',
    UPDATE_REQUEST = 'UPDATE_REQUEST',
    TEMP_SHOW = 'TEMP_SHOW'
}

export interface DepartmentsPreOrderState {
    selectPreOrder: IPreOrderSorts | null,
    preOrders: IPreOrder[],
    page: number,
    limit: number,
    enough: boolean,
    direction: LoadDirections,
    total: number,
    preOrderSort: string,
    loading: boolean,
    showPreOrder: boolean,
    updateSelect: boolean,
    tempShow: boolean,
}


export interface PreOrderSaveFilters {
    type: DepartmentsPreOrdersActionTypes.SAVE_PREORDER_SORT,
    payload: string,
}


export interface DepartmentSaveAction {
    type: DepartmentsPreOrdersActionTypes.SAVE_DEPARTMENT_PRE_ORDERS,
    payload: {
        content: IPreOrders,
        loadFlag: boolean,
        loading: boolean,
        updateSelect?: boolean
    }
}

export interface SelectPreOrder {
    type: DepartmentsPreOrdersActionTypes.SELECT_PRE_ORDER,
    payload: IPreOrder,
}

export interface DepartmentsLoadingEnable {
    type: DepartmentsPreOrdersActionTypes.LOADING_DEPARMENTS_PRE_ORDER_ENB,
}
export interface DepartmentsLoadingDisable {
    type: DepartmentsPreOrdersActionTypes.LOADING_DEPARMENTS_PRE_ORDER_DSB,
}
export interface DepartmentsIsEnough {
    type: DepartmentsPreOrdersActionTypes.SET_ENOUGH,
    payload: boolean,
}
export interface DeaprtmentsSetPage {
    type: DepartmentsPreOrdersActionTypes.SET_DEPARTMENT_PAGE,
    payload: number
}

export interface DeaprtmentClear {
    type: DepartmentsPreOrdersActionTypes.CLEAR_DEPARTMENT
}
interface FetchPreOrdersAction {
    type: DepartmentsPreOrdersActionTypes.DEPARTEMNT_REQ
    payload: { loadFlag: boolean; loading: boolean }
}

export interface NewPreOrder {
    type: DepartmentsPreOrdersActionTypes.SET_NEW_PRE_ORDER,
    payload: IPreOrder,
}


interface ShowPreOrder {
    type: DepartmentsPreOrdersActionTypes.SHOW_PRE_ORDERS,
    payload: boolean,
}

interface UpdatePreOrder {
    type: DepartmentsPreOrdersActionTypes.UPDATE_REQUEST,
    payload: boolean,   
}

interface TempShow {
    type: DepartmentsPreOrdersActionTypes.TEMP_SHOW,
    payload: boolean
}

export type DepartmentPreOrdersActions = 
    | DepartmentSaveAction
    | PreOrderSaveFilters
    | SelectPreOrder
    | DepartmentsLoadingEnable
    | DepartmentsLoadingDisable
    | DepartmentsIsEnough
    | DeaprtmentsSetPage
    | DeaprtmentClear
    | FetchPreOrdersAction
    | NewPreOrder
    | ShowPreOrder
    | UpdatePreOrder
    | TempShow