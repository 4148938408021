import { useActions } from '../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { useEffect, useRef } from 'react'
import { useObserver } from '../../../shared/hooks/useObserver'
import { SIMILAR } from '../consts/similar'
import { LoadDirections } from '../../../shared/types/types'
import qs from 'qs'

type argsType = [number, number, number, number, any, boolean]

export const useSimilarController = () => {
  const { fetchSimilarProducts, setSimilarPage, resetSimilarFilterProducts } =
    useActions()

  const {
    products,
    error,
    page,
    limit,
    filter,
    direction,
    enough,
    order,
    orderBy,
  } = useTypedSelector((state) => state.similar)

  const { loading } = useTypedSelector((state) => state.app)
  const inputRef = useRef<null | HTMLSpanElement[]>([])
  const savePageNum = useRef(page)
  const firstElement = useRef<HTMLDivElement>(null)
  const lastElement = useRef<HTMLDivElement>(null)

  const isFullLength = products.length >= SIMILAR.MAX_PRODUCT_ITEMS

  useObserver(firstElement, enough && page > 1 && isFullLength, loading, () => {
    const isNotUpDirection = direction !== LoadDirections.UP && isFullLength
    setSimilarPage(
      isNotUpDirection
        ? page - Math.ceil(SIMILAR.MAX_PRODUCT_ITEMS / limit)
        : page - 1
    )
  })

  useObserver(lastElement, enough, loading, () => {
    const isNotDownDirection = direction !== LoadDirections.DOWN && isFullLength
    setSimilarPage(
      isNotDownDirection
        ? page + Math.ceil(SIMILAR.MAX_PRODUCT_ITEMS / limit)
        : page + 1
    )
  })

  useEffect(() => {
    if (!window.location.search) return
    let parsedParams: any = qs.parse(window.location.search.substring(1))

    let args: argsType = [
      parsedParams.supplierGoodId,
      parsedParams.priceListTypeId,
      page,
      limit,
      filter,
      savePageNum.current < page,
    ]

    const fetch = async (...args: argsType) => fetchSimilarProducts(...args)

    fetch(...args).then(() => {
      if (!(isFullLength && inputRef.current !== null)) return
      if (savePageNum.current < page)
        inputRef.current[
          products[products.length - 1].productWithInfo.product.priceListItemId
        ]?.scrollIntoView() // скролл вниз
      if (savePageNum.current > page)
        inputRef.current[products[0].productWithInfo.product.priceListItemId]?.scrollIntoView() // скролл вверх
      savePageNum.current = page
    })
  }, [page])

  useEffect(() => {
    return () => {
      resetSimilarFilterProducts()
    }
  }, [])

  return {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    products,
    order,
    orderBy,
  }
}
