import React from 'react'
import { Box, Typography } from '@mui/material'
import ModalCloseButton from '../../../../shared/components/UI/ModalCloseButton'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { CloseModal } from '../../../../shared/types/types'
import { useActions } from '../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'

const OrderErrorContent = ({ handleClose }: CloseModal) => {
  const { checkedItems } = useTypedSelector((state) => state.newHistory)
  const isMultiRequest = checkedItems.length === 1
  const modalText = !isMultiRequest ? 'Ошибки' : 'Отменить'
  const { showNewHistoryNoted, updateNewHistoryRequest, showNewHistoryErrors } =
    useActions()

  const submit = () => {
    updateNewHistoryRequest()
    handleClose()
  }

  const invokeCallback = !isMultiRequest
    ? function () {
        showNewHistoryErrors(true)
        showNewHistoryNoted(true)
        handleClose()
      }
    : handleClose

  return (
    <>
      <Box display="flex" justifyContent="center" marginTop={3}>
        <ErrorOutlineIcon color="error" sx={{ fontSize: '60px' }} />
      </Box>

      <Typography
        component="h3"
        color={'error'}
        sx={{
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: '600',
          fontSize: '1.563rem',
        }}
      >
        Ошибка в заказе
      </Typography>

      <Typography
        component="h4"
        sx={{
          color: 'secondary.main',
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: '600',
          fontSize: '1.25rem',
          my: 3,
        }}
      >
        Вы уверены,что хотите оформить заказ?
      </Typography>

      <Stack flexDirection="row" alignItems="center" columnGap={'1rem'}>
        <Button onClick={submit} variant="contained" fullWidth>
          Подтвердить
        </Button>

        <Button onClick={invokeCallback} variant="outlined" fullWidth>
          {modalText}
        </Button>
      </Stack>
      <ModalCloseButton handleClose={handleClose} />
    </>
  )
}

export default OrderErrorContent
