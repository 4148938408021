import React, { useEffect, useState } from 'react'

// TODO update date-range-picker
// third lib
import { useHistory } from 'react-router-dom'

// mui
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import SendIcon from '@mui/icons-material/Send'
import { Tooltip } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

// styled

//types
import { IOptionsProps } from '../../../shared/types/types'
import { IAutoOrderStatusProps } from '../types/autoOrderTypes'
import { IDateRange } from '../../../shared/types/types'

// project
import BackToLastPage from '../../../shared/components/BackToLastPage'
import {
  getAutoOrderFinalRoute,
  getAutoOrderSettingsRoute,
} from '../../../shared/services/routeService'
import DepartmentAutoComplete from '../../../shared/components/autoCompletes/DepartmentAutoComplete'
import AutoOrderStatusAutoComplete from '../../../shared/components/autoCompletes/AutoOrderStatusAutoComplete'

// actions
import { useActions } from '../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import CustomDateRangePicker from '../../../shared/components/UI/CustomRangePicker/CustomRangePicker'

const AutoOrderBar = () => {
  const history = useHistory()

  const [departmentValue, setDepartmentValue] = useState<IOptionsProps[]>([])
  const [hasProcessingOrders, setProcessingOrders] = useState(false)

  const checkedItems = useTypedSelector((state) => state.autoOrder.checkedItems)

  const [statusValue, setStatusValue] = useState<IAutoOrderStatusProps | null>(
    null
  )
  const { calculationStatus, userBidsStatus } = useTypedSelector(
    (state) => state.autoOrder.conditionStatus
  )
  const [dateRangeValue, setDateRangeValue] = useState<IDateRange>({
    startDate: null,
    endDate: null,
  })

  // Подсказка о необходимости заполнить поле, если одно из них пустое

  const {
    resetAutoOrderFilter,
    resetAutoOrderItems,
    handleAutoOrderDepartment,
    handleAutoOrdersComboBox,
    handleAutoOrderDate,
    fetchAutoOrder,
  } = useActions()

  const resetPage = async () => {
    resetAutoOrderFilter()
    setDateRangeValue({
      startDate: null,
      endDate: null,
    })
    setStatusValue(null)
    setDepartmentValue([])
    fetchAutoOrder()
  }

  const searchPage = async () => {
    await resetAutoOrderItems()
    await fetchAutoOrder()
  }

  const handleDateRangeChange = (newValue: IDateRange) => {
    setDateRangeValue(newValue)
    const dateRange: [Date | null, Date | null] = [
      newValue.startDate,
      newValue.endDate,
    ]
    handleAutoOrderDate(dateRange, 'CreationDate')
  }

  useEffect(() => {
    if (userBidsStatus === 1) {
      setProcessingOrders(true)
    } else {
      setProcessingOrders(false)
    }
    if (calculationStatus === 1) {
      history.push(getAutoOrderFinalRoute())
    }
  }, [checkedItems, calculationStatus, userBidsStatus])

  const handleView = () => {
    if (hasProcessingOrders) {
      history.push(getAutoOrderSettingsRoute())
    }
  }

  return (
    <Box sx={{ backgroundColor: 'background.paper', mb: 2 }}>
      <BackToLastPage text={'Заявки'} />
      <Stack
        direction="row"
        justifyContent="start"
        columnGap={2}
        marginBottom={1}
      >
        <CustomDateRangePicker
          rangePickerValue={dateRangeValue}
          onDateRangeChange={handleDateRangeChange}
        />
        <React.Fragment>
          <Box sx={{ width: 230, flexShrink: 0 }}>
            <DepartmentAutoComplete
              value={departmentValue}
              handleDepartmentValue={setDepartmentValue}
              isCheckBoxType={true}
              variant={'multiple'}
              handleAutoCompleteFields={(val: IOptionsProps[] | null) =>
                handleAutoOrderDepartment(val, 'DepartmentId')
              }
            />
          </Box>
        </React.Fragment>

        <React.Fragment>
          <AutoOrderStatusAutoComplete
            value={statusValue}
            handleStatusValue={setStatusValue}
            handleAutoCompleteFields={(val: IAutoOrderStatusProps | null) =>
              handleAutoOrdersComboBox(val, 'Status')
            }
          />
        </React.Fragment>

        <Button
          variant="contained"
          sx={{ flexShrink: 0, fontSize: '0.775rem', maxHeight: 40 }}
          onClick={searchPage}
        >
          Поиск
        </Button>
        <Button
          onClick={resetPage}
          variant="outlined"
          sx={{
            flexShrink: 0,
            fontSize: '0.775rem',
            maxHeight: 40,
          }}
        >
          Сбросить
        </Button>
        <Tooltip
          title={
            hasProcessingOrders ? 'Перейти в настройки' : 'Выберите заявки'
          }
        >
          <span>
            <Button
              variant="contained"
              sx={{ flexShrink: 0, fontSize: '0.775rem', minHeight: 40 }}
              endIcon={<SendIcon />}
              onClick={handleView}
              disabled={!hasProcessingOrders}
            >
              Авторасторговка
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </Box>
  )
}

export default AutoOrderBar
