import { onlyNotEmpty } from './commonService'
import { ISearchFilter } from '../types/filterTypes'

const isObjectType = (v: unknown) => {
  return typeof v === 'object' && v !== null && !Array.isArray(v)
}

export const transformRequest = (v: unknown) => {
  if (Array.isArray(v)) {
    return v.map((i) => i?.id).filter((i) => i !== -1)
  }
  return v
}

export const filterToArray = (filter: ISearchFilter) => {
  return Object.keys(filter).filter((key) => filter[key as keyof typeof filter])
}

export const convertFilterData = (): Object => {
  // достаем фильтры из сессии
  const sideFilter = JSON.parse(String(sessionStorage.getItem('filterCash1')))
  const headFilter = JSON.parse(String(sessionStorage.getItem('filterCash2')))

  // Трансоформируем значение-если в параметрах массив объектов {id,name} то превращаем в одномерный массив id
  const requestTransformed = Object.fromEntries(
    Object.entries(sideFilter || {}).map(([k, v]) => [k, transformRequest(v)])
  )
  // Выбираем из объекта проперти с объектом
  const nestedObjects = Object.values(requestTransformed || {}).filter((v) =>
    isObjectType(v)
  )
  // удаляем из объекта проперти с обьектом
  const requestFiltered = Object.fromEntries(
    Object.entries(requestTransformed || {}).filter(([_, v]) => !isObjectType(v))
  )
  // соединяем в один объект
  const resultSideFilter = Object.assign(requestFiltered, ...nestedObjects)

  // фильтруем фильтры хедера
  let filtered = onlyNotEmpty(headFilter || {})
  // возвращаем результат
  return { ...resultSideFilter, ...filtered }
}
