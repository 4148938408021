import React from 'react'
import Box from '@mui/material/Box'
import { IProduct } from '../../types/productTypes'
import { useActions } from '../../../../shared/hooks/useActions'
import Tooltip from '@mui/material/Tooltip'

const NameField = ({ card }: { card: IProduct }) => {
  const { selectProductModal } = useActions()
  return (
    <Box sx={{ flexBasis: 0 }}>
      <Box
        sx={{
          overflowWrap: { xs: 'break-word', xl: 'unset' },
          textTransform: 'uppercase',
          color: 'primary.main',
          fontWeight: 'bold',
          fontSize: { xs: '9px', xl: '10px' },
          mb: 1,
        }}
      >
        Наименование товара
      </Box>
      <Tooltip title={card.name}>
        <Box
          onClick={() => selectProductModal(card)}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            ///////////////////////////
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: { xs: '9px', xl: '10px' },
            '&:hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          }}
        >
          {card.name}
        </Box>
      </Tooltip>
      <Box
        sx={{
          lineHeight: '1.5',
          fontSize: '10px',
          fontWeight: '400',
          fontStyle: 'italic',
        }}
      >
        {card.countryName}
      </Box>
    </Box>
  )
}

export default NameField
