import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

export const LinkCustom = styled(Link)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: '0.625rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '0.7rem',
    whiteSpace: 'nowrap',
  },
  fontFamily: 'Inter',
  textDecoration: 'none',
  textTransform: 'uppercase',
  fontWeight: '600',
  color: theme.palette.common.white,
  '&:hover': {
    textDecoration: 'underline',
  },
}))
