import React from 'react'

// mui
import Box from '@mui/material/Box'

// hooks
import { useActions } from '../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'

// styled
import { ButtonStyled } from '../../../styled/saleStyled'

const style = {
  pointerEvents: 'none' as 'none',
  opacity: '0.5',
}

const SaleDepartmentsHeader = () => {
  const { showSaleDepartmentsModal } = useActions()

  const isComplexTableShowed = useTypedSelector(
    (state) => state.saleDepartments.isComplexTableShowed
  )

  return (
    <Box
      display="flex"
      marginTop="2.25rem"
      marginBottom={3}
      justifyContent="space-between"
    >
      <ButtonStyled
        sx={{ ...(isComplexTableShowed ? style : {}) }}
        variant="contained"
        onClick={() => showSaleDepartmentsModal()}
      >
        Грузополучатели
      </ButtonStyled>
    </Box>
  )
}

export default SaleDepartmentsHeader
