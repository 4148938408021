import {
  NewHistoryFieldType,
  IOrderHistory,
  IOrderFiltered1,
} from '../../types/newHistoryTypes'
import { Order } from '../../../../shared/services/commonService'
import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'

export interface NewHistoryState {
  enough: boolean
  direction: LoadDirections.DOWN | LoadDirections.UP
  error: ErrorInfo | {}
  loading: boolean
  page: number
  limit: number
  total: number
  filter: any
  products: IOrderFiltered1[]
  orderId: number | null
  startSearch: boolean
  checkedItems: number[]
  deletedItem: number | null
  isCheckedAll: boolean
  isShowNoted: boolean
  isShowErrors: boolean
  orderBy: NewHistoryFieldType
  order: Order
}

export enum NewHistoryActionTypes {
  SET_FILTER_PRODUCTS = 'SET_FILTER_NEW_HISTORY',
  RESET_FILTER_PRODUCTS = 'RESET_FILTER_NEW_HISTORY',
  FETCH_PRODUCTS = 'FETCH_NEW_HISTORY',
  FETCH_PRODUCTS_SUCCESS = 'FETCH_NEW_HISTORY_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_NEW_HISTORY_ERROR',
  DELETE_PRODUCT_BY_ID = 'DELETE_PRODUCT_BY_ID',
  UPDATE_PRODUCT_BY_ID = 'UPDATE_PRODUCT_BY_ID',
  SET_PRODUCT_PAGE = 'SET_NEW_HISTORY_PAGE',
  FETCH_PRODUCTS_ENOUGH = 'FETCH_NEW_HISTORY_ENOUGH',
  START_NEW_HISTORY_SEARCH = 'START_NEW_HISTORY_SEARCH',
  SET_IS_CHECKED_ALL = 'SET_NH_IS_CHECKED_ALL',
  SET_CHECKED_ITEMS = 'SET_NH_CHECKED_ITEMS',
  ADD_CHECKED_ITEMS = 'ADD_NH_CHECKED_ITEMS',
  SHOW_IS_NOTED_ITEMS = 'SHOW_IS_NOTED_ITEMS',
  SHOW_IS_ERRORS_IN_ITEMS = 'SHOW_IS_ERRORS_IN_ITEMS',
  SAVE_ORDER_ID = 'SAVE_ORDER_ID',
  SET_PRODUCT_SORT = 'SET_NEW_HISTORY_SORT',
  SET_DELETED_ITEM = 'SET_DELETED_ITEM',
}

export interface SaveOrderIdAction {
  type: NewHistoryActionTypes.SAVE_ORDER_ID
  payload: number
}

interface DeleteIdsNewHistoryAction {
  type: NewHistoryActionTypes.DELETE_PRODUCT_BY_ID
}

interface UpdateIdsNewHistoryAction {
  type: NewHistoryActionTypes.UPDATE_PRODUCT_BY_ID
}

interface SearchingNewHistoryAction {
  type: NewHistoryActionTypes.START_NEW_HISTORY_SEARCH
}

interface ShowNewHistoryNotedAction {
  type: NewHistoryActionTypes.SHOW_IS_NOTED_ITEMS
  payload: boolean
}

interface ShowNewHistoryErrorsAction {
  type: NewHistoryActionTypes.SHOW_IS_ERRORS_IN_ITEMS
  payload: boolean
}

interface FetchNewHistoryEnoughAction {
  type: NewHistoryActionTypes.FETCH_PRODUCTS_ENOUGH
  payload: boolean
}

interface SetFilterNewHistoryAction {
  type: NewHistoryActionTypes.SET_FILTER_PRODUCTS
  payload: Object
}

interface SetCheckedAllNewHistoryAction {
  type: NewHistoryActionTypes.SET_IS_CHECKED_ALL
}

interface SetCheckedItemsNewHistoryAction {
  type: NewHistoryActionTypes.SET_CHECKED_ITEMS
  payload: number[]
}

interface AddCheckedItemsNewHistoryAction {
  type: NewHistoryActionTypes.ADD_CHECKED_ITEMS
  payload: number[]
}

interface ResetFilterNewHistoryAction {
  type: NewHistoryActionTypes.RESET_FILTER_PRODUCTS
}

interface FetchNewHistoryAction {
  type: NewHistoryActionTypes.FETCH_PRODUCTS
}

export interface FetchNewHistorySuccessAction {
  type: NewHistoryActionTypes.FETCH_PRODUCTS_SUCCESS
  payload: { content: IOrderHistory; loadFlag: boolean }
}

export interface FetchNewHistoryErrorAction {
  type: NewHistoryActionTypes.FETCH_PRODUCTS_ERROR
  payload: ErrorInfo | {}
}

interface SetNewHistoryPage {
  type: NewHistoryActionTypes.SET_PRODUCT_PAGE
  payload: number
}

export interface INewHistoryPayload {
  orderBy: NewHistoryFieldType
  order: Order
}
interface SetNewHistorySort {
  type: NewHistoryActionTypes.SET_PRODUCT_SORT
  payload: INewHistoryPayload
}
interface SetDeletedItem {
  type: NewHistoryActionTypes.SET_DELETED_ITEM,
  payload: number
}
export type NewHistoryAction =
  | FetchNewHistoryEnoughAction
  | SetFilterNewHistoryAction
  | ResetFilterNewHistoryAction
  | FetchNewHistoryAction
  | FetchNewHistorySuccessAction
  | FetchNewHistoryErrorAction
  | SetNewHistoryPage
  | SearchingNewHistoryAction
  | SetCheckedAllNewHistoryAction
  | SetCheckedItemsNewHistoryAction
  | AddCheckedItemsNewHistoryAction
  | ShowNewHistoryNotedAction
  | ShowNewHistoryErrorsAction
  | DeleteIdsNewHistoryAction
  | UpdateIdsNewHistoryAction
  | SaveOrderIdAction
  | SetNewHistorySort
  | SetDeletedItem