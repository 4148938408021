import {
  BasketArray,
  IBasketItems,
  IBasketVal,
  IInitialProduct,
} from '../../features/Basket/types/basketTypes'
import { IProduct } from '../../features/Product/types/productTypes'

export const orderSum = (arr: BasketArray) => {
  let params: number[] = []
  arr.forEach(({ items }) => {
    params.push(...items.map((i) => i.amount * (i.price.match as number)))
  })
  return params.reduce((partialSum, a) => partialSum + a, 0)
}

export const orderItemSum = (arr: IBasketVal[]) => {
  return arr
    .map((i) => i.amount * (i.price.match as number))
    .reduce((partialSum, a) => partialSum + a, 0)
}

export const orderItemCount = (arr: IBasketVal[]) => {
  return arr.map((i) => i.amount).reduce((partialSum, a) => partialSum + a, 0)
}

export const getPageCount = (totalCount: number, limit: number) => {
  return Math.ceil(totalCount / limit)
}

export const getInitialProduct = (card: IProduct): IInitialProduct => ({
  id: +card.id,
  esCode: card.esCode,
  code: card.code,
  name: card.name,
  supplierId: card.supplierId,
  supplierGoodId: card.supplierGoodId,
  supplierName: card.supplierName,
  countryName: card.countryName,
  producerName: card.producerName,
  internationalName: card.internationalName,
  expirationDate: card.expirationDate,
  storageLife: card.storageLife,
  quantity: card.quantity,
  boxQuantity: card.boxQuantity,
  packQuantity: card.packQuantity,
  price: card.price,
  priceListId: card.priceListId,
  priceListTypeId: card.priceListTypeId,
  priceListName: card.priceListName,
  priceListExtendedName: card.priceListExtendedName,
  priceListDate: card.priceListDate,
  minimalQuantity: card.minimalQuantity,
  registerPrice: card.registerPrice,
  vatRate: card.vatRate,
  priceListItemId: card.priceListItemId,
  storeCode: card.storeCode,
  payerCode: card.payerCode,
  esName: card.esName,
  externalId: card.externalId,
  gtin: card.gtin,
  isBad: card.isBad,
  isBenefit: card.isBenefit,
  isImmunoBiological: card.isImmunoBiological,
  isJvnls: card.isJvnls,
  isKiz: card.isKiz,
  isOa: card.isOa,
  isPrPkkn: card.isPrPkkn,
  isReceipt: card.isReceipt,
  producerPrice: card.producerPrice,
  remainExpirationDays: card.remainExpirationDays,
  remainExpirationPercent: card.remainExpirationPercent,
  seria: card.seria,
  wholesaleMarkup: card.wholesaleMarkup,
})

export const transformOrders = (arr: IBasketItems[]) => {
  return arr.map((i) => ({
    basketItemId: i.id,
    productId: i.product.id.match,
    amount: i.amount,
    price: i.product.price, //
    priceAmountMatch: Number(i.product.price.match) * i.amount, //
    priceAmountInit: Number(i.product.price.initial) * i.amount, //
    supplier: i.supplier.name,
    supplierId: i.supplier.id,
    department: i.department.name,
    priceListName:
      i.matchProductCount > 0
        ? i.product.priceListName.match
        : i.product.priceListName.initial,
    supplierAndDepartment: `${i.supplier.name}—${i.department.name}`,
    supplierAndPriceList: `${i.supplier.name}—${i.product.priceListName.match}`,
    departmentAndPriceList: `${i.department.name}—${i.product.priceListName.match}`,
    supplierAndDepartmentAndPL: `${i.supplier.name}—${i.department.name}—${i.product.priceListName.match}`,
    name:
      i.matchProductCount > 0 ? i.product.name.match : i.product.name.initial,
    esName:
      i.matchProductCount > 0
        ? i.product.esName.match
        : i.product.esName.initial,
    boxQuantity: i.product.boxQuantity,
    quantity: i.product.quantity,
    minimalQuantity: i.product.minimalQuantity,
    expirationDate: i.product.expirationDate,
    esCode: i.product.esCode.match,
    internationalName: i.product.internationalName.match,
    matchProductCount: i.matchProductCount > 0,
    marketingEventStatuses: i.product.marketingEventStatuses,
    supplierGoodId: i.supplierGoodId,
    priceListTypeId: i.priceListTypeId,
    storeCode: i.storeCode,
    payerCode: i.payerCode,
  }))
}

export const sumOfSupplerId = (id: number, array: any[]): number =>
  array
    .filter((i) => i.supplierId === id)
    .reduce((a, b) => a + b.amount * b.price, 0)
