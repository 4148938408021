import http from '../../../shared/api/config/instance'
import { IAutoOrderStatusProps } from '../types/autoOrderTypes'

export const postAutoOrder = (
  pageNumber: number,
  pageSize: number,
  filter: any[] = [],
  order: string = '',
) => {
  return http.httpCore.post(
    'auto-order/summary-bids/filter',
    {
        pagination: {
          pageNumber,
          pageSize
        },
        filter: filter,
        order: order,
      }
    )
}

export const getAutoOrderStatus = () =>
  http.httpCore.get<{ content: { items: IAutoOrderStatusProps[] } }>(
    'auto-order/dictionary/bid-statuses'
  )

export const deleteAutoOrder = (id: string) =>
  http.httpCore.delete(`auto-order/summary-bids?summaryBidIds=${id}`, {
  })

export const postAutoOrderUserSettings = (
  id: string = '',
) => {
  return http.httpCore.post(
    'auto-order/user-settings/bids',
    {
      summaryBidIds: [id]
    }
  )
}

export const deleteAutoOrderUserSettings = (
  id: string = '',
  deleteAll: boolean = false
) => {
  return http.httpCore.delete('auto-order/user-settings/bids', {
    data: {
      summaryBidIds: [id],
      deleteAll,
    },
  });
};

export const getAutoOrderCondition = () =>
  http.httpCore.get('auto-order/condition')


