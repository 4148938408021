import React from 'react'
import { Box, Button, Grid, LinearProgress, Stack } from '@mui/material'
import CardBaseInfo from '../../components/CardBaseInfo'
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'
import { useProductItemController } from '../../../../shared/controllers/useProductItemController'
import { PRODUCT_ITEM_MODAL } from '../../../../shared/consts/productItemModal'
import ClearIcon from '@mui/icons-material/Clear'
import SvgHospital from '../../../../shared/components/UI/svgIcons/SvgHospital'
import SvgMolecula from '../../../../shared/components/UI/svgIcons/SvgMolecule'
import SvgBad from '../../../../shared/components/UI/svgIcons/SvgBad'
import SvgAccounting from '../../../../shared/components/UI/svgIcons/SvgAccounting'
import SvgDocument from '../../../../shared/components/UI/svgIcons/SvgDocument'
import SvgCheck from '../../../../shared/components/UI/svgIcons/SvgCheck'
import SvgBlueLogo from '../../../../shared/components/UI/svgIcons/SvgBlueLogo'
import SvgOrangeLogo from '../../../../shared/components/UI/svgIcons/SvgOrangeLogo'
import SvgRedLogo from '../../../../shared/components/UI/svgIcons/SvgRedLogo'
import SvgGreenLogo from '../../../../shared/components/UI/svgIcons/SvgGreenLogo'
import SvgHealthIcon from '../../../../shared/components/UI/svgIcons/SvgHealth'
import ProudctDetailedTable from '../../components/ProductWideCard/ProductDetailedTable'
import SvgClock from '../../../../shared/components/UI/svgIcons/SvgClock'

const { PRODUCT_FIELDS } = PRODUCT_ITEM_MODAL

const ProductItemContent = () => {
  const { selectedProduct } = useTypedSelector((state) => state.product)
  const defaultDepartment = useTypedSelector(
    (state) => state.app.defaultDepartment?.id
  )

  const { product, loading, info } = useProductItemController(
    selectedProduct.id,
    defaultDepartment
  )

  const {
    isBad,
    isBenefit,
    isImmunoBiological,
    isJvnls,
    isOa,
    isPrPkkn,
    isReceipt,
    remainExpirationDays,
  } = product || {}

  const initialIconSizes = {
    width: '30px',
    height: '30px',
  }
  const {
    proAptMarketingEvents = [],
    isInProAptMm = false,
    isInMatrixMm = false,
    matrixMarketingEvents = [],
    highMarginProductMarketingEvents = [],
    isInHighMarginProductMm = false,
    isInFirstTableBonusMm = false,
    firstTableBonusMarketingEvents = [],
  } = info?.marketingEvents || {}

  const healthPm = info?.compensatedDiscounts || null

  const isExpiredDay = remainExpirationDays < 181

  if (loading) return <LinearProgress />
  return (
    <>
      <Box
        sx={{
          textAlign: 'left',
          display: '-webkit-box',
          mb: 1,
          color: 'primary.main',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          fontWeight: '600',
          fontSize: {
            lg: 18,
            md: 16,
            xs: 14,
          },
        }}
      >
        {product.name}
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <SvgHospital
          initialSizes={initialIconSizes}
          renderCondition={isJvnls}
        />
        <SvgMolecula
          initialSizes={initialIconSizes}
          renderCondition={isImmunoBiological}
        />
        <SvgBad initialSizes={initialIconSizes} renderCondition={isBad} />
        <SvgAccounting
          initialSizes={initialIconSizes}
          renderCondition={isPrPkkn}
        />
        <SvgDocument
          initialSizes={initialIconSizes}
          renderCondition={isReceipt}
        />
        <SvgCheck initialSizes={initialIconSizes} renderCondition={isOa} />

        <SvgBlueLogo
          initialSizes={initialIconSizes}
          events={proAptMarketingEvents}
          renderCondition={isInProAptMm}
        />
        <SvgOrangeLogo
          initialSizes={initialIconSizes}
          events={firstTableBonusMarketingEvents}
          renderCondition={isInFirstTableBonusMm}
        />
        <SvgRedLogo
          initialSizes={initialIconSizes}
          events={highMarginProductMarketingEvents}
          renderCondition={isInHighMarginProductMm}
        />
        <SvgGreenLogo
          initialSizes={initialIconSizes}
          events={matrixMarketingEvents}
          renderCondition={isInMatrixMm}
        />
        <SvgHealthIcon
          initialSizes={{
            width: '12px',
            heigth: '12px',
          }}
          health={healthPm}
          renderCondition={!!healthPm?.length}
        />
        <SvgClock 
          initialSizes={initialIconSizes}
         days={remainExpirationDays} 
          renderCondition={isExpiredDay} /> 
      </Box>
      <Stack alignItems={'center'} justifyContent={'center'}>
        <ProudctDetailedTable tableValues={PRODUCT_FIELDS} product={product} />
        {/* Отключаем кнопку временно */}
        {/* <Button
          startIcon={<ClearIcon fontSize="small" />}
          variant="contained"
          color="error"
          sx={{
            mt: 2,
            fontSize: '14px',
            textTransform: 'uppercase',
          }}
        >
          Неверная привязка
        </Button> */}
      </Stack>
    </>
  )
}

export default ProductItemContent
