import { useActions } from '../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { useEffect, useRef } from 'react'
import { useObserver } from '../../../shared/hooks/useObserver'
import { SALE_DETAILS } from '../consts/saleDetails'
import { LoadDirections } from '../../../shared/types/types'
import { fetchSaleDetailsProductsAction } from '../model/saleDetails/saleDetailsAction'

type argsType = [number, number, any, boolean]

export const useSaleDetailsController = () => {
  const { fetchSaleDetailsProductsAction, setSaleDetailsPage } = useActions()

  const {
    products,
    error,
    page,
    limit,
    filter,
    direction,
    enough,
    order,
    orderBy,
    startSearch,
  } = useTypedSelector((state) => state.saleDetails)

  const loading = useTypedSelector((state) => state.saleDetails.loading)
  const inputRef = useRef<null | HTMLSpanElement[]>([])
  const savePageNum = useRef(page)
  const firstElement = useRef<HTMLTableRowElement>(null)
  const lastElement = useRef<HTMLTableRowElement>(null)

  const isFullLength = products.length >= SALE_DETAILS.MAX_PRODUCT_ITEMS

  useObserver(firstElement, page > 1 && isFullLength, loading, () => {
    const isNotUpDirection = direction !== LoadDirections.UP && isFullLength
    setSaleDetailsPage(
      isNotUpDirection
        ? page - Math.ceil(SALE_DETAILS.MAX_PRODUCT_ITEMS / limit)
        : page - 1
    )
  })

  useObserver(lastElement, enough, loading, () => {
    const isNotDownDirection = direction !== LoadDirections.DOWN && isFullLength
    setSaleDetailsPage(
      isNotDownDirection
        ? page + Math.ceil(SALE_DETAILS.MAX_PRODUCT_ITEMS / limit)
        : page + 1
    )
  })

  useEffect(() => {
    let args: argsType = [page, limit, filter, savePageNum.current < page]
    const fetch = async (...args: argsType) =>
      fetchSaleDetailsProductsAction(...args)

    fetch(...args).then(() => {
      if (!(isFullLength && inputRef.current !== null)) {
        savePageNum.current = page
        return
      }
      if (savePageNum.current < page) {
        inputRef.current[
          products[products.length - 5].productEsCode
        ]?.scrollIntoView() // скролл вниз
      }
      if (savePageNum.current > page) {
        inputRef.current[products[0].productEsCode]?.scrollIntoView() // скролл вверх
      }
      savePageNum.current = page
    })
  }, [page, startSearch])

  return {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    products,
    order,
    orderBy,
  }
}
