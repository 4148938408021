import http from '../../../../../shared/api/config/instance'
import { IConsigneeDepartment } from '../modals/UnionDepartmentModal/UnionDepartmentModalContent'

export const postConsigneeDepartment = (params: IConsigneeDepartment) => {
  return http.httpCore.post(
    'complex-order/settings/consignee-department',
    params
  )
}
export const postSendSubmit = (idsArray: number[]) =>
  http.httpCore.post('order-approve', {
    orderIdRange: [...idsArray],
  })
export const postSaleSubmit = () => http.httpCore.post('complex-order/save', {})
