import { AuthAction, AuthActionTypes, AuthState } from './authReduxTypes'

const initialState: AuthState = {
  token: null,
  claims: {},
}

export const authReducer = (
  state = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_USER:
      return { ...state, token: action.payload }
    case AuthActionTypes.LOGOUT_USER:
      return { ...state, token: null }
    case AuthActionTypes.CHECK_USER_CLAIMS:
      return { ...state, claims: action.payload }
    default:
      return state
  }
}
