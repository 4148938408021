import { useEffect, useState } from 'react'
import { getCardById } from '../../features/Product/api/product'
import { format } from 'date-fns'
import { IProduct, ProductInfo } from '../../features/Product/types/productTypes'

type ProductControllerOmit = Omit<IProduct, 'price'>

export type  ProductControllerType = { 
  price: string
} & ProductControllerOmit
export const useProductItemController = (id: string, departmentId: number | undefined) => {
  
  const [product, setProduct] = useState<ProductControllerType>({} as ProductControllerType)
  const [loading, setLoading] = useState(false)
  const [info,setInfo] = useState({} as ProductInfo);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      let { data } = await getCardById(id, departmentId)            
      const {
        content: { 
          product: { expirationDate, priceListDate, price, registerPrice, remainExpirationPercent, remainExpirationDays  }, 
        },
        content,
      } = data      
      const temp = {
        ...content.product,
        expirationDate: format(new Date(expirationDate), 'dd.MM.yyyy'),
        remainExpiration: `${remainExpirationPercent}% / ${remainExpirationDays} дн.`,
        remainExpirationDays: remainExpirationDays,
        priceListDate: format(new Date(priceListDate), 'dd.MM.yyyy'),
        price: window.numeral(price).format('0,0.00').replace(',', ' '),
        registerPrice: +window
          .numeral(registerPrice)
          .format('0,0.00')
          .replace(',', ' '),
      }
      setProduct(temp)
      setLoading(false)
      setInfo(content.info)
    }
    fetchData()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return {
    product,
    loading,
    info,
  }
}
