import React, { ReactNode, useMemo, useState } from 'react'

// mui imports
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

// utils
import { onlyNumbers } from '../../../shared/services/commonService'

// project imports
import FormattedDate1 from '../../../shared/components/FormattedDate1'
import FormattedPrice1 from '../../../shared/components/FormattedPrice1'

import {
  BoxQuantityField,
  MinQuantityField,
  QuantityField,
} from './SubHistoryFields'
import { IOrderItemsFiltered } from '../../NewHistory/types/newHistoryTypes'
import BadgeCheck from '../../../shared/components/UI/BadgeCheck'
import BadgeRejected from '../../../shared/components/UI/BadgeRejected'

interface IEditHistoryRowProps {
  row: IOrderItemsFiltered
  changeItemAmount: (amount: number, id: number) => void
  deleteItem: (id: number) => void
  isCanEdit: boolean
}

export function SubHistoryRow({
  row,
  changeItemAmount,
  deleteItem,
  isCanEdit,
}: IEditHistoryRowProps) {
  const [amount, setAmount] = useState<any>(row.amount)

  const { confirmedCount, orderCount } = row

  const cancelledStatus =
    row.completed === false ? 'rgba(250, 33, 3, 0.2)' : 'unset'

  const status = useMemo(() => {
    let badge: ReactNode
    if (row.completed === null) {
      badge = null
    } else if (!row.completed) {
      badge = (
        <BadgeRejected totalCount={orderCount} confirmed={confirmedCount} />
      )
    } else if (row.completed) {
      badge = <BadgeCheck />
    }
    return badge
  }, [])

  const amountHandler = (rowId: number) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      let amount = event.target.value
      if (!/^[0-9]+$/.test(String(amount)) || Number(amount) < 1) {
        return
      }
      setAmount(amount)
      changeItemAmount(Number(event.target.value), rowId)
    }
  }

  return (
    <TableRow
      sx={{
        backgroundColor: cancelledStatus,
        '& td': {
          fontSize: '0.875rem',
        },
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell align="center" sx={{ width: 0 }}>
        {status}
      </TableCell>
      <TableCell align="left">{row.esCode}</TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">{row.producerName}</TableCell>
      <TableCell>
        <BoxQuantityField boxQuantity={row.boxQuantity} amount={amount} />
      </TableCell>
      <TableCell align="center">
        <MinQuantityField minQuantity={row.minimalQuantity} amount={amount} />
      </TableCell>

      <TableCell>
        <QuantityField quantity={row.quantity} amount={amount} />
      </TableCell>

      <TableCell align="center">
        <FormattedDate1 date={row.expirationDate} />
      </TableCell>
      <TableCell align="right">
        <FormattedPrice1 price={row.price} />
      </TableCell>

      <TableCell align="center">
        {isCanEdit ? (
          <input
            type="number"
            min={1}
            value={amount}
            onInput={onlyNumbers}
            onChange={amountHandler(row.id)}
            style={{
              backgroundColor: 'inherit',
              color: 'inherit',
              lineHeight: '24px',
              textAlign: 'center',
              width: '65px',
              marginLeft: '8px',
              border: 0,
              outline: 0,
            }}
          />
        ) : (
          row.amount
        )}
      </TableCell>

      <TableCell align="center">{confirmedCount}</TableCell>

      {isCanEdit && (
        <>
          <TableCell align="right">
            <Tooltip title="Удалить позицию">
              <IconButton onClick={() => deleteItem(row.id)} size="small">
                <DeleteIcon fontSize="small" sx={{ color: 'neutral.main' }} />
              </IconButton>
            </Tooltip>
          </TableCell>
        </>
      )}
    </TableRow>
  )
}
