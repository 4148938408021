import {
  BasketAction,
  BasketActionTypes,
  BasketState,
} from './basketReduxTypes'

const initialState: BasketState = {
  itemsCount: 0,
  itemsSum: 0,
  supplierDetails: [],
  basketRecords: {},
}

export const basketReducer = (
  state = initialState,
  action: BasketAction
): BasketState => {
  switch (action.type) {
    case BasketActionTypes.SAVE_BASKET_RECORDS_META:
      return {
        ...state,
        itemsCount: action.payload.itemsCount,
        itemsSum: action.payload.itemsSum,
        supplierDetails: action.payload.supplierDetails,
      }
    case BasketActionTypes.ADD_BASKET_RECORDS:
      return {
        ...state,
        basketRecords: { ...state.basketRecords, ...action.payload },
      }
    case BasketActionTypes.DELETE_BASKET_RECORD:
      const newBasketRecords = { ...state.basketRecords }
      delete newBasketRecords[action.payload]

      return {
        ...state,
        basketRecords: newBasketRecords,
      }

    case BasketActionTypes.DELETE_BASKET_RECORDS:
      const newBasketRecords1 = { ...state.basketRecords }
      action.payload.forEach((property) => {
        delete newBasketRecords1[property]
      })
      return {
        ...state,
        basketRecords: newBasketRecords1,
      }

    case BasketActionTypes.DELETE_ALL_BASKET_RECORDS:
      return {
        ...state,
        basketRecords: {},
      }

    default:
      return state
  }
}
