import { useActions } from '../../../../../shared/hooks/useActions'
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Fade,
  Modal,
  Typography,
} from '@mui/material'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import { useEffect } from 'react'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  bgcolor: 'background.paper',
  border: '2px solid',
  borderColor: 'action.disabled',
  boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: '2rem 2rem',
}

const AutoOrderDeleteModal = () => {
  const active = useTypedSelector((state) => state.modal.active)
  const { modalDisable, deleteAutoOrderProducts } = useActions()

  const handleClose = () => {
    modalDisable()
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      deleteAutoOrderProducts()
    } else if (event.key === 'Escape') {
      handleClose()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  return (
    <>
      <Modal
        open={active}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={active}>
          <Box sx={style}>
            <Typography
              id="filters-modal"
              align="center"
              variant="h6"
              component="h2"
            >
              Вы действительно хотите отменить заказ?
            </Typography>
            <Divider />
            <Button
              color="error"
              sx={{ mt: 3 }}
              fullWidth
              type="button"
              onClick={deleteAutoOrderProducts}
              variant="contained"
            >
              Да, отменить заказ
            </Button>
            <Button
              sx={{ mt: 3 }}
              fullWidth
              type="button"
              onClick={handleClose}
              variant="outlined"
            >
              Закрыть
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default AutoOrderDeleteModal
