import { ISubHistoryHeadCells } from '../types/subHistoryTypes'

export const headCellsArray: ISubHistoryHeadCells[] = [
  {
    name: '',
    align: 'center',
    field: 'completed',
    enablePadding: false,
  },
  {
    name: 'Код Ус',
    align: 'left',
    field: 'esCode',
    enablePadding: true,
  },
  {
    name: 'Наименование товара',
    align: 'left',
    field: 'name',
    enablePadding: true,
  },
  {
    name: 'Производитель',
    align: 'left',
    field: 'producerName',
    enablePadding: true,
  },
  {
    name: 'Кратность',
    align: 'center',
    field: 'boxQuantity',
    enablePadding: true,
  },
  {
    name: 'Мин кол-во',
    align: 'center',
    field: 'minimalQuantity',
    enablePadding: true,
  },
  {
    name: 'Доступно',
    align: 'center',
    field: 'quantity',
    enablePadding: true,
  },
  {
    name: 'Срок годности',
    align: 'center',
    field: 'expirationDate',
    enablePadding: true,
  },
  {
    name: 'Цена',
    align: 'center',
    field: 'price',
    enablePadding: true,
  },

  {
    name: 'Кол-во',
    align: 'center',
    field: 'amount',
    enablePadding: true,
  },
  {
    name: 'Одобрено',
    align: 'center',
    field: 'confirmedCount',
    enablePadding: true,
  },
]
