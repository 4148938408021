import React, { useEffect, useState } from 'react'

// controllers
import { useScrollController } from '../../shared/controllers/useScrollController'
import { useProductController } from './controllers/useProductController'

// project imports
import ErrorPage from './components/ErrorPage'
import ElementWatch from './components/ElementWatch'
import ProductWideCard from './components/ProductWideCard/ProductWideCard'

// redux
import { useActions } from '../../shared/hooks/useActions'

const ProductMain = () => {
  useScrollController()

  const { fetchBasketRecords } = useActions()
  const { error, firstElement, lastElement, inputRef, loading, products } = useProductController()
  const [currentCardId, setCurrentCardId] = useState(0);


  useEffect(() => {
    fetchBasketRecords();
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    setCurrentCardId(0);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  if (Object.keys(error).length > 0) {
    return (
      <ErrorPage
        {...{
          error,
          firstElement,
          lastElement,
        }}
      />
    )
  }

  return (
    <>
      {products.length > 0 && (
        <ElementWatch element={firstElement} isLoading={loading} />
      )}

      {products.map((card, index) => (
        <React.Fragment key={card.productWithInfo.product.priceListItemId}>
          <ProductWideCard
            index={index}
            card={card}
            currentCardId={currentCardId}
            setCurrentCardId={setCurrentCardId}
          />
          <span
            // @ts-ignore
            ref={(el) => (inputRef.current[card.priceListItemId] = el)}
          />
        </React.Fragment>
      ))}
      {products.length > 0 && (
        <ElementWatch element={lastElement} isLoading={loading} />
      )}
    </>
  )
}

export default ProductMain
