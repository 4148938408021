import React from "react"
import { format } from "date-fns"

const FormattedTime = ({date}:{date:string}) => {
  const content = format(new Date(date), "HH:mm:ss")
  return (
    <span>
      {content}
    </span>
  )
}

export default FormattedTime