import http from '../../../shared/api/config/instance'
import { onlyNotEmpty } from '../../../shared/services/commonService'
import { IOtherProducts } from '../types/otherTypes'

export const getOtherProducts = (
  filters: any,
  pageNumber?: number,
  pageSize?: number
) => {
  const newFilters = onlyNotEmpty(filters)

  return http.httpCore.get<{ content: IOtherProducts }>('product/alternative', {
    params: {
      sort: filters.order,
      pageSize,
      pageNumber,
      ...newFilters,
    },
  })
}
