import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import SupSearch from './SupSearch'
import SupSelect from './SupSelect'
import debounce from 'lodash/debounce'
import { SelectChangeEvent } from '@mui/material/Select'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { toggleWithPLButtonAction } from '../model/supplier/supplierAction'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'

interface ISupSearchActivity {
  callback: (
    name?: string,
    onlyActive?: string,
    withPl?: boolean
  ) => Promise<void>
  fetchSuppliersItems: (...args: any) => Promise<void>
}

const SupSearchActivity = ({
  callback,
  fetchSuppliersItems,
}: ISupSearchActivity) => {
  const [query, setQuery] = useState('')
  const [activity, setActivity] = useState('')

  const dispatch = useDispatch()
  const withPl = useTypedSelector((state) => state.supplier.withPLButton)

  const request = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value
    setQuery(val)
    debounceRequest(val, activity, withPl)
  }

  const debounceRequest = useMemo(
    () =>
      debounce(
        (val: string, activity: string, withPl: boolean) =>
          callback(val, activity, withPl),
        800
      ),
    []
  )

  const handleChangeActivity = (event: SelectChangeEvent) => {
    setQuery('')
    setActivity(event.target.value)
    fetchSuppliersItems(undefined, event.target.value)
  }

  const handleWithPriceList = (
    event: React.ChangeEvent<HTMLInputElement>,
    localState?: boolean
  ) => {
    const currentValue = event.target.checked
    fetchSuppliersItems(undefined, undefined, currentValue)
    dispatch(toggleWithPLButtonAction(currentValue))
    setQuery('')
  }

  return (
    <>
      <SupSearch request={request} query={query} />
      <Stack direction={'row'} gap={1} justifyContent={'space-between'} my={1}>
        <SupSelect
          activity={activity}
          handleChangeActivity={handleChangeActivity}
        />
        <FormControlLabel
          sx={{
            border: '0.5px solid',
            borderColor: 'secondary.main',
            borderRadius: '4px',
            px: 2,
            margin: 0,
          }}
          control={
            <Checkbox
              edge="start"
              disableRipple
              onChange={handleWithPriceList}
              checked={withPl}
            />
          }
          label={
            <Typography fontWeight={'400'} fontSize={'14px'}>
              Все с ПЛ
            </Typography>
          }
        />
      </Stack>
    </>
  )
}

export default SupSearchActivity
