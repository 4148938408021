import React, { ReactNode } from 'react'

// mui
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import { CustomTab, TabPanel } from './CustomTab'

// hooks
import { useTypedSelector } from '../../hooks/useTypedSelector'

// types
import { DeliveryMode } from '../../../features/Supplier/components/PriceList/types/priceListTypes'

interface ICustomVerticalTabs {
  labels: string[]
  children: ReactNode
  handleChange: (event: React.SyntheticEvent, newValue: number) => void
  tabValue: number
}

const style = {
  pointerEvents: 'none' as 'none',
  opacity: '0.2',
}

const CustomVerticalTabs = ({
  labels,
  children,
  handleChange,
  tabValue,
}: ICustomVerticalTabs) => {
  const mode = useTypedSelector((state) => state.priceList.deliveryMode)
  const checkedItems = useTypedSelector((state) => state.priceList.checkedItems)

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        ...((
          mode === DeliveryMode.withDepartments
            ? !Boolean(checkedItems.length)
            : false
        )
          ? style
          : {}),
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabValue}
        onChange={handleChange}
        aria-label="vertical tabs "
        sx={{
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        {labels.map((label) => (
          <CustomTab label={label} key={label} />
        ))}
      </Tabs>
      {Array.from(Array(7).keys()).map((i: any) => (
        <TabPanel value={tabValue} index={i} key={i}>
          {children}
        </TabPanel>
      ))}
    </Box>
  )
}

export default CustomVerticalTabs
