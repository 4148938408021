import React from 'react'
import { orderItemSum } from '../../../shared/services/basketService'
import numeral from 'numeral'
import Box from '@mui/material/Box'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'

const WarningTemplate = ({ text }: { text: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        columnGap: '5px',
        ml: '0.3rem',
        p: '0.7rem 24px',
        color: 'primary.main',
        fontWeight: '600',
        fontSize: '0.925rem',
      }}
    >
      <ReportGmailerrorredIcon /> {text}
    </Box>
  )
}

const SumPlugin = ({
  supSetting,
  items,
}: {
  supSetting: any[]
  items: any[]
}) => {
  const supId = items[0].supplierId
  const supplier = supSetting.find((item) => item.supplierId === supId)
  const itemSum = orderItemSum(items)
  if (!supplier)
    return <WarningTemplate text={'Нет сохраненных настроек для поставщика'} />
  if (itemSum < supplier.minSum) {
    const content = numeral(supplier.minSum - itemSum)
      .format('0,0.00')
      .replace(',', ' ')
    return <WarningTemplate text={`Нужно заказать еще на ${content} ₽`} />
  }
  return <></>
}

export default SumPlugin
