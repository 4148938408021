import { useHistory } from 'react-router-dom'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { getAutoOrderRoute } from '../services/routeService'
import { BackPageTypography } from './styled/BackPageTypography'

const BackToAutoOrderPage = ({
  text,
  marginDisabled,
}: {
  text?: string
  marginDisabled?: boolean
}) => {
  const history = useHistory()
  return (
    <Box
      sx={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}
      marginBottom={marginDisabled ? 0 : 1}
    >
      <IconButton
        size="large"
        onClick={() => history.push(getAutoOrderRoute())}
      >
        <ArrowBackIcon />
      </IconButton>
      <BackPageTypography>{text}</BackPageTypography>
    </Box>
  )
}

export default BackToAutoOrderPage
