import React, { useEffect, useMemo, useRef, useState } from 'react'

// hooks
import { useActions } from '../../../../../shared/hooks/useActions'

// libs
import { useParams } from 'react-router-dom'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'

// types
import { IPlistItems } from '../types/priceListTypes'
import { SnackTypes } from '../../../../../shared/store/application/applicationReduxTypes'

// api
import {
  getSupplierPListById,
  getSupplierPriority,
  getSupplierSettings,
  postSupplierSettings,
  postUserPList,
  putSupplierPriority
} from '../../../api/supplier'
import _ from 'lodash'
import { setPriceListDeliveryFilterAction } from '../model/priceList/priceListAction'

const initErrorsState = {
  minSumError: false, //todo добавить остальные поля
}

// TODO: Функция для фильтрации ввода
const filterNumbers = (inputValue: string): string => {
  return inputValue.replace(/\D/g, '0'); // Оставить только цифры, убрать все остальное
}

export const usePriceListParamsController = () => {
  const { showSnack, setPriceListDeliveryFilterAction } = useActions()

  const { supplierId }: { supplierId: string } = useParams()
  const [plist, setPlist] = useState<IPlistItems[]>([])
  const [orderParameters, setOrderParameters] = useState({ minSum: '0' , prority: '0', isActive: false})
  const [validationsErrors, setValidationErrors] = useState(initErrorsState)

  const listContainer = useRef<IPlistItems[]>([])

  const resetLists = () => {
    const result = plist.map((item) => ({ ...item, isActive: false }))
    setPlist(result)
  }

  const debounceRequest = useMemo(
    () =>
      debounce(
        (event: React.ChangeEvent<HTMLInputElement>) =>
          fetch(event.target.value),
        800
      ),
    []
  )

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const typeId1 = Number(event.target.id)
    const checked = event.target.checked
    let tempArr = cloneDeep(plist)
    let changeItem = tempArr.find((item) => item.typeId === typeId1)
    if (changeItem) {
      changeItem.isActive = checked
    }
    setPlist(tempArr)
  }

  const handleTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const value = filterNumbers(event.target.value.trim());
    const value = event.target.value.trim(); // Убираем начальные и конечные пробелы
    const minSum = value === '' ? '0' : value.replace(/^0+/,''); // Если значение пустое, заменяем на '0'
    setOrderParameters({ ...orderParameters, minSum });
    // setOrderParameters({ ...orderParameters, minSum: event.target.value }) // old version pm-547
  }

  const handleChangePriority = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(+event.target.value < 1) return 
    setOrderParameters({ ...orderParameters, prority: event.target.value })
  }
  const handleCheckbox = (_: React.SyntheticEvent, checked: boolean) => {
    setOrderParameters({ ...orderParameters, isActive: checked})

  }
  const onSubmit = () => {
    setValidationErrors(initErrorsState)
    const startList = [...listContainer.current]
    let filteredArray = plist.filter(
      (i, index) => i.isActive !== startList[index].isActive
    )
    let result = filteredArray.map((i) => ({
      supplierId: Number(supplierId),
      PriceListTypeId: i.typeId,
      active: i.isActive,
    }))
    if (Number(orderParameters.minSum) <= -1) {
      setValidationErrors({ ...validationsErrors, minSumError: true })
      return
    }
    Promise.all([
      postUserPList(result),
      postSupplierSettings(Number(supplierId), orderParameters.minSum),
      putSupplierPriority({ supplierPriority: +orderParameters.prority!,
          isActive: orderParameters.isActive,
          supplierId: +supplierId,
          supplierName: ''
        })
    ])
      .then(() =>
        showSnack({
          typeSnack: SnackTypes.SUCCESS,
          messageSnack: 'Настройки сохранены',
        })
      )
      .catch((e) => {}
        // showSnack({
        //   typeSnack: SnackTypes.ERROR,
        //   messageSnack: 'Настройки не сохранены',
        // })
      )
  }

  const fetch = async (priceListName?: string) => {
    try {
      const [priceLists, settings, priroity] = await Promise.all([
        getSupplierPListById(Number(supplierId), priceListName),
        getSupplierSettings(Number(supplierId)),
        getSupplierPriority(Number(supplierId))
      ])
      const newPriceLists = priceLists.data.content.data
      const newSettings = settings.data.content.settings
      const prioritySettings = priroity.data.content
      const copy = cloneDeep(newPriceLists)
      setPlist(newPriceLists)
  
      if (newSettings.length === 0) {
        setOrderParameters({ 
          ...orderParameters, 
          minSum: '0',
          isActive: prioritySettings.isActive,
          prority: prioritySettings.supplierPriority
        })
      } else {
        setOrderParameters({
          ...orderParameters,
          minSum: newSettings[0].minSum,
          isActive: prioritySettings.isActive, 
          prority: prioritySettings.supplierPriority
        })
      }
      listContainer.current = [...copy]
    } catch (e) {
      // showSnack({
      //   typeSnack: SnackTypes.ERROR,
      //   messageSnack: 'Настройки не загружены',
      // })
    }
  }

  useEffect(() => {
    fetch()
    setPriceListDeliveryFilterAction({ supplierId })
  }, [supplierId])

  const isEmptyList = plist.length === 0

  return {
    validationsErrors,
    handleTextField,
    onSubmit,
    debounceRequest,
    isEmptyList,
    plist,
    handleOnChange,
    resetLists,
    handleChangePriority,
    orderParameters,
    handleCheckbox,
    setOrderParameters,
  }
}
