import { RefObject, useEffect, useRef } from 'react'

export const useObserver = (
  ref: RefObject<HTMLElement>,
  canLoad: boolean,
  isLoading: boolean,
  callback: () => void
) => {
  const observer = useRef<IntersectionObserver>()

  useEffect(() => {    
    if (isLoading) return
    
    if (observer.current) observer.current.disconnect()
    const cb = function (entries: { isIntersecting: boolean }[]) {
      if (entries[0].isIntersecting && canLoad) {   
        callback()
      }
    }
    observer.current = new IntersectionObserver(cb)
    if (ref.current) {
      observer.current.observe(ref.current as Element)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])
}
