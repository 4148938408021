import React from 'react'
import IconButton from '@mui/material/IconButton'
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore'
import { useHistory } from 'react-router-dom'
import { Badge, Box, Typography } from '@mui/material'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import FormattedPrice1 from '../../../shared/components/FormattedPrice1'
import { getBasketRoute } from '../../../shared/services/routeService'
import HoverPopover from '../../../shared/components/HoverPopover'

const BarCustom = () => {
  const history = useHistory()
  const { itemsCount, itemsSum } = useTypedSelector((state) => state.basket)

  const claims = useTypedSelector((state) => state.auth.claims);

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        disabled={!claims[ 'promarket.basket_item.get' ]}
        sx={{ color: 'common.white' }}
        onClick={() => history.push(getBasketRoute())}
      >
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          badgeContent={itemsCount}
          color="warning"
        >
          <LocalGroceryStoreIcon />
        </Badge>
      </IconButton>

      <Box display="flex" flexDirection="column" marginTop={1.9}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: '500',
            fontSize: '0.75rem',
          }}
        >
          <FormattedPrice1 price={itemsSum} />
        </Typography>
        <HoverPopover />
      </Box>
    </Box>
  )
}

export default BarCustom
