import { useEffect } from 'react'
import { useActions } from '../../../shared/hooks/useActions'

export const useFilterFetchController = () => {
  const { fetchAllFilters } = useActions()

  useEffect(() => {
    fetchAllFilters()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
