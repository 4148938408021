import React, { useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { getThemeByName } from './base'

interface ThemeProps1 {
  children?: React.ReactChild | React.ReactNode
}

export const ThemeContext = React.createContext((themeName: string): void => {})

const ThemeInjector = ({ children }: ThemeProps1) => {
  const curThemeName = localStorage.getItem('appTheme') || 'mainTheme'
  const [themeName, _setThemeName] = useState(curThemeName)
  const theme = getThemeByName(themeName)

  const setThemeName = (themeName: string): void => {
    localStorage.setItem('appTheme', themeName)
    _setThemeName(themeName)
  }

  return (
    <ThemeContext.Provider value={setThemeName}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}
export default ThemeInjector
