import React from 'react'
import { Typography } from '@mui/material'

const StatusInfo = ({ status }: { status: number | undefined }) => {
  let content
  switch (status) {
    case 0:
      content = (
        <Typography fontSize={12} fontWeight={600} color="#BCBCBC">
          Неизвестный
        </Typography>
      )
      break
    case 1:
      content = (
        <Typography fontSize={12} fontWeight={600} color="#FA8803">
          Новый заказ
        </Typography>
      )
      break

    case 2:
      content = (
        <Typography fontSize={12} fontWeight={600} color="#40AC58">
          В очереди на отправку
        </Typography>
      )
      break

    case 3:
      content = (
        <Typography fontSize={12} fontWeight={600} color="#FA8803">
          Отправлен поставщику
        </Typography>
      )
      break

    case 4:
      content = (
        <Typography fontSize={12} fontWeight={600} color="#5868B4">
          Частично одобрен
        </Typography>
      )
      break

    case 5:
      content = (
        <Typography fontSize={12} fontWeight={600} color="#40AC58">
          Одобрен
        </Typography>
      )
      break

    case 6:
      content = (
        <Typography fontSize={12} fontWeight={600} color="#5868B4">
          Отклонен
        </Typography>
      )
      break

    case 7:
      content = (
        <Typography fontSize={12} fontWeight={600} color="#FA8803">
          Новый КЗ
        </Typography>
      )
      break

    default:
      content = ''
  }

  return <span>{content}</span>
}

export default StatusInfo
