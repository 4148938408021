import React from 'react'

// mui imports
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

// third party libs
import { Link as RouterLink } from 'react-router-dom'

// routes
import {
  getBindsRoute,
  getSuppliersRoute,
} from '../../../../shared/services/routeService'

const ReferenceMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        component="a"
        sx={{
          textTransform: 'uppercase',
          // whiteSpace: 'nowrap',
          fontWeight: '600',
          fontFamily: 'Inter',
          fontSize: { xs: '0.625rem', xl: '0.7rem' },
          color: 'common.white',
          '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        }}
        aria-controls={open ? 'reference-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Справочники
      </Box>

      <Menu
        id="reference-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component={RouterLink}
          to={getSuppliersRoute()}
          sx={{ fontSize: 14 }}
          onClick={handleClose}
        >
          Поставщики
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to={getBindsRoute()}
          sx={{ fontSize: 14 }}
          onClick={handleClose}
        >
          Привязки
        </MenuItem>
      </Menu>
    </>
  )
}

export default ReferenceMenu
