export enum AuthActionTypes {
  LOGIN_USER = "LOGIN_USER",
  LOGOUT_USER = "LOGOUT_USER",
  CHECK_USER_CLAIMS = "CHECK_USER_CLAIMS",
}

export interface AuthState {
  token:string | null
  claims: Record<string, boolean>
}

export interface AuthLoginUserAction {
  type: AuthActionTypes.LOGIN_USER;
  payload: string
}

export interface CheckUserClaims {
  type: AuthActionTypes.CHECK_USER_CLAIMS;
  payload: Record<string, boolean>
}

export interface  AuthLogoutUserAction {
  type: AuthActionTypes.LOGOUT_USER;
}

export type AuthAction = AuthLoginUserAction | AuthLogoutUserAction | CheckUserClaims

export interface IMyToken {
  exp: number;
}
