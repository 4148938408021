import React from 'react'
import { IAutoCompleteProps } from '../../types/types'
import { useAutoCompleteController } from '../../controllers/useAutoCompleteController'
import { getDepartments } from '../../api/department'
import AutoCompleteTemplate from './AutoCompleteTemplate'

const DepartmentAutoComplete = ({
  customPopover,
  limitTags = 2,
  variant,
  value, handleDepartmentValue,
  handleAutoCompleteFields,
  isCheckBoxType,
}: IAutoCompleteProps) => {
  const { options, debouncedRequest, loading, handleOpen, handleClose } =
    useAutoCompleteController(getDepartments, 'content.data', isCheckBoxType)

  return (
    <AutoCompleteTemplate
      customPopover={customPopover}
      variant={variant}
      label={'Грузополучатель'}
      value={value}
      handleDepartmentValue={handleDepartmentValue}
      limitTags={limitTags}
      options={options}
      debouncedRequest={debouncedRequest}
      loading={loading}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleAutoCompleteFields={handleAutoCompleteFields}
    />
  )
}

export default DepartmentAutoComplete
