import { styled } from "@mui/material/styles"

export const Listbox = styled("ul")(({ theme }) => ({
  width: "100%",
  top: "38px",
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: "absolute",
  listStyle: "none",
  backgroundColor: theme.palette.background.paper,
  overflow: "auto",
  maxHeight: 200,
  border: "1px solid rgba(0,0,0,.25)",
  borderBottomLeftRadius: "3px",
  borderBottomRightRadius: "3px",
  "& li:hover": {
    backgroundColor: "#4a8df6",
    color: "white",
    cursor: "pointer"
  },
  "& li:active": {
    backgroundColor: "#2977f5",
    color: "white"
  }
}))