import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import { ISearchFilter } from '../../../shared/types/filterTypes'

interface IHeaderMenuProps {
  filter: ISearchFilter
  setFilter: (payload: any) => void
}

export default function HeaderMenu({ filter, setFilter }: IHeaderMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.name
    setFilter({ ...filter, [name]: event.target.checked })
  }

  return (
    <div>
      <IconButton sx={{ color: 'common.white' }} onClick={handleClick}>
        <FilterAltIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                name="name"
                checked={filter['name']}
                onChange={handleChangeFilter}
              />
            }
            label={
              <Typography
                color={'text.primary'}
                fontSize="0.875rem"
                fontWeight="400"
              >
                По наименованию
              </Typography>
            }
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                name="producer"
                checked={filter['producer']}
                onChange={handleChangeFilter}
              />
            }
            label={
              <Typography
                color={'text.primary'}
                fontSize="0.875rem"
                fontWeight="400"
              >
                По производителю
              </Typography>
            }
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                name="supplier"
                checked={filter['supplier']}
                onChange={handleChangeFilter}
              />
            }
            label={
              <Typography
                color={'text.primary'}
                fontSize="0.875rem"
                fontWeight="400"
              >
                По поставщику
              </Typography>
            }
          />
        </MenuItem>

        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                name="country"
                checked={filter['country']}
                onChange={handleChangeFilter}
              />
            }
            label={
              <Typography
                color={'text.primary'}
                fontSize="0.875rem"
                fontWeight="400"
              >
                По стране
              </Typography>
            }
          />
        </MenuItem>
      </Menu>
    </div>
  )
}
