import React from 'react'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const SupSelect = ({
  activity,
  handleChangeActivity,
}: {
  activity: string
  handleChangeActivity: (event: SelectChangeEvent) => void
}) => {
  return (
    <>
      <FormControl sx={{ minWidth: 120 }}>
        <Select
          sx={{
            '& .MuiSelect-select': {
              fontSize: '0.875rem',
            },
          }}
          size="small"
          value={activity}
          onChange={handleChangeActivity}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">Все</MenuItem>
          <MenuItem value={'true'}>Активные</MenuItem>
          <MenuItem value={'false'}>Неактивные</MenuItem>
        </Select>
      </FormControl>
    </>
  )
}

export default SupSelect
