import React, { useState } from 'react'
import FromToAutoComplete, {
  DIRECTIONS,
} from '../../../../shared/components/autoCompletes/FromToAutoComplete'
import { TextFieldCustom } from '../../../../shared/components/styled/TextFieldCustom'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import RenderIcons from '../RenderIcons'
import { IFilter } from '../../../../shared/types/filterTypes'

interface ISpecialTextField {
  filter: IFilter
  setFilter: (payload: IFilter) => void
  handleTextFields: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => void
  handleOrderChange: (key: string) => void
}

function directionCompare(val: string): string {
  return val === DIRECTIONS.FROM
    ? 'remainExpirationPercentFilterMin'
    : 'remainExpirationPercentFilterMax'
}

const ExpirationPercentTextField = ({
  filter,
  setFilter,
  handleTextFields,
  handleKeyPress,
  handleOrderChange,
}: ISpecialTextField) => {
  const [direction, setDirection] = useState('')

  const handleDirection = (newValue: any) => {
    if (!newValue) return
    if (newValue.val === DIRECTIONS.FROM) {
      setDirection(DIRECTIONS.FROM)
      setFilter({
        ...filter,
        remainExpirationPercentFilter: {
          ...filter.remainExpirationPercentFilter,
          remainExpirationPercentFilterMax: '',
        },
      })
      return
    }
    setDirection(DIRECTIONS.TO)
    setFilter({
      ...filter,
      remainExpirationPercentFilter: {
        ...filter.remainExpirationPercentFilter,
        remainExpirationPercentFilterMin: '',
      },
    })
  }

  return (
    <>
      <FromToAutoComplete handleDirection={handleDirection} />
      <TextFieldCustom
        InputProps={{
          endAdornment: (
            <>
              {
                //@ts-ignore
                filter.remainExpirationPercentFilter[
                  directionCompare(direction)
                ] && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      let keyName = directionCompare(direction)
                      setFilter({
                        ...filter,
                        remainExpirationPercentFilter: {
                          ...filter.remainExpirationPercentFilter,
                          [keyName]: '',
                        },
                      })
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )
              }
            </>
          ),
        }}
        name={directionCompare(direction)}
        label={'Процент остаточного срока годности'}
        value={
          //@ts-ignore
          filter.remainExpirationPercentFilter[directionCompare(direction)]
        }
        onChange={handleTextFields}
        variant="outlined"
        size="small"
        fullWidth
        onKeyPress={handleKeyPress}
      />
      <IconButton
        sx={{ ml: '3px' }}
        onClick={() => handleOrderChange('remainExpirationOrderRule')}
      >
        <RenderIcons condition={filter['remainExpirationOrderRule']} />
      </IconButton>
    </>
  )
}

export default ExpirationPercentTextField
