import http from '../../../../../shared/api/config/instance'

export const postProcessingAutoOrder = (
  pageNumber: number,
  pageSize: number,
  order: string = ''
) => {
  return http.httpCore.post('auto-order/user-settings/bids/filter', {
    pagination: {
        pageNumber,
        pageSize
      },
    order
  })
}

export const deleteProcessingAutoOrder = (
  id: string[],
  deleteAll: boolean = false
) =>
  http.httpCore.delete(`auto-order/user-settings/bids`, {
    data: {
      summaryBidIds: id,
      deleteAll,
    }
  });






