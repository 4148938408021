import http from '../../../shared/api/config/instance'
import {
  IBasketContent,
  IBasketCount,
  IBasketOptions,
} from '../types/basketTypes'
import { BASKET_PAGINATION } from '../consts/basket'
import { BASKET_STORAGE_KEY } from '../../../shared/consts/basket'

export const postBasket = (params: IBasketOptions[]) =>
  http.httpCore.post('basket-items', params)

export const postOrder = (params: number[]) =>
  http.httpCore.post('order', {
    basketItemsIdList: params,
  })

export const putBasketItem = (
  basketItemId: string | number,
  amount?: number,
  basketItem?: IBasketOptions
) =>
  http.httpCore.put<any>('basket-item', {
    //todo ts
    basketItemId,
    amount,
    basketItem,
  })

export const getBasket = (
  showUserSupplierSettings = true,
  pageNumber: number
) =>
  http.httpCore.get<IBasketContent>('basket-items', {
    params: {
      pageSize: BASKET_PAGINATION.MAX_PRODUCT_ITEMS,
      showUserSupplierSettings,
      pageNumber,
    },
  })

export const getBasketCount = () =>
  http.httpCore.get<IBasketCount>('basket-count')

export const postBasketStorage = (params: string) =>
  http.httpCore.post('frontend/settings/key-value/upsert', {
    keyValueSettings: [
      {
        key: BASKET_STORAGE_KEY,
        value: params,
      },
    ],
  })

export const getBasketStorage = () =>
  // todo ref
  http.httpCore.get<any>('frontend/settings/key-value')

export const deleteBasketStorage = () =>
  http.httpCore.delete('frontend/settings/key-value/items', {
    data: {
      keys: [BASKET_STORAGE_KEY],
    },
  })

export const deleteBasketArray = (params: number[]) =>
  http.httpCore.delete('basket-item', {
    data: {
      basketItemIdRange: [...params],
    },
  })

export const deleteBasket = () =>
  http.httpCore.delete('basket', {
    data: {},
  })
