import React, { useMemo, useState } from 'react'

// types
import { IBasketTableProps, IBasketVal } from '../types/basketTypes'

// service
import {
  orderItemCount,
  orderItemSum,
} from '../../../shared/services/basketService'
import {
  getComparator,
  onlyNumbers,
  Order,
  qsStringify,
} from '../../../shared/services/commonService'
import {
  getOtherRouteById,
  getSimilarRoute,
} from '../../../shared/services/routeService'

// assets
import DeleteIcon from '@mui/icons-material/Delete'
import ClearIcon from '@mui/icons-material/Clear'
import SendIcon from '@mui/icons-material/Send'
import SvgHospital from '../../../shared/components/UI/svgIcons/SvgHospital'
import SvgMolecule from '../../../shared/components/UI/svgIcons/SvgMolecule'
import SvgBad from '../../../shared/components/UI/svgIcons/SvgBad'
import SvgAccounting from '../../../shared/components/UI/svgIcons/SvgAccounting'
import SvgDocument from '../../../shared/components/UI/svgIcons/SvgDocument'
import SvgCheck from '../../../shared/components/UI/svgIcons/SvgCheck'
import SvgBlueLogo from '../../../shared/components/UI/svgIcons/SvgBlueLogo'
import SvgOrangeLogo from '../../../shared/components/UI/svgIcons/SvgOrangeLogo'
import SvgGreenLogo from '../../../shared/components/UI/svgIcons/SvgGreenLogo'
import SvgPlus from '../../../shared/components/UI/svgIcons/SvgPlus'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import qs from 'qs'

//router
import { useHistory } from 'react-router-dom'

// styled
import { TextCircle } from '../../../shared/components/styled/TextCircle'

// mui imports
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import Button from '@mui/material/Button'
import TableBody from '@mui/material/TableBody'

// project import
import BasketComparisonCell from './BasketComparisonCell'
import BasketComparisonPriceCell from './BasketComparisonPriceCell'
import BasketComparisonDateCell from './BasketComparisonDateCell'
import { EnhancedCellsBlock } from './EnhancedCellsBlock'
import FormattedPrice1 from '../../../shared/components/FormattedPrice1'
import { styled } from '@mui/material/styles'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'

const TableCellStyled = styled(TableCell)({
  minWidth: '100px',
  maxWidth: '100px',
})

interface IBasketTableRowProps {
  row: IBasketVal
  deleteItem: (parentKey: number, childId: number) => void
  changeItemAmount: (value: string, parentKey: number, childId: number) => void
  firstColumnValue: string
  parentKey: number
}

const BasketTableRow = ({
  row,
  deleteItem,
  changeItemAmount,
  firstColumnValue,
  parentKey,
}: IBasketTableRowProps) => {
  const history = useHistory()

  const claims = useTypedSelector((state) => state.auth.claims)

  const saveBasketItemAndPush = (row: IBasketVal) => {
    const {
      basketItemId,
      esCode,
      esName,
      name,
      internationalName,
      priceListTypeId,
      supplierGoodId,
      payerCode,
      storeCode,
    } = row

    const requestString = qsStringify({
      basketItemId,
      esCode,
      name,
      esName,
      internationalName,
      priceListTypeId,
      supplierGoodId,
      payerCode,
      storeCode,
      productTitle: esName || name,
    })
    history.push(getOtherRouteById(requestString))
  }

  const handlePush = (row: IBasketVal) => {
    let requestString = qs.stringify({
      supplierGoodId: row.supplierGoodId,
      priceListTypeId: row.priceListTypeId,
    })
    history.push(getSimilarRoute(requestString))
  }

  const {
    isBad,
    isBenefit,
    isFirstHundredBonus,
    isImmunoBiological,
    isJvnls,
    isMyHealth,
    isOa,
    isPrPkkn,
    isProApt,
    isReceipt,
  } = row.marketingEventStatuses || {}

  const initialIconSizes = {
    width: '20px',
    heigth: '20px',
  }

  return (
    <TableRow
      sx={{
        '& td,& td div': {
          fontSize: '0.688rem',
          overflowWrap: 'break-word',
          ...(!row.matchProductCount ? { color: 'action.disabled' } : {}),
        },
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      {!!firstColumnValue && (
        <TableCellStyled align="left" sx={{ paddingLeft: '8px' }}>
          {row[firstColumnValue as keyof IBasketVal] instanceof Object &&
            Object.entries(row[firstColumnValue as keyof IBasketVal]).map(
              ([key, value]) => (
                <div key={key}>
                  {key}: {value ? 'Да' : 'Нет'}
                </div>
              )
            )}
        </TableCellStyled>
      )}
      <TableCell>
        <Box>
          <Box display="flex">
            <SvgHospital
              initialSizes={initialIconSizes}
              renderCondition={isJvnls}
            />
            <SvgMolecule
              initialSizes={initialIconSizes}
              renderCondition={isImmunoBiological}
            />
            <SvgBad initialSizes={initialIconSizes} renderCondition={isBad} />
          </Box>
          <Box display="flex">
            <SvgAccounting
              initialSizes={initialIconSizes}
              renderCondition={isPrPkkn}
            />
            <SvgDocument
              initialSizes={initialIconSizes}
              renderCondition={isReceipt}
            />
            <SvgCheck initialSizes={initialIconSizes} renderCondition={isOa} />
          </Box>
        </Box>
      </TableCell>
      <TableCellStyled align="left" sx={{ paddingLeft: '8px' }}>
        {row.name}
      </TableCellStyled>
      <TableCell>
        <Box>
          <Box display="flex">
            <SvgBlueLogo
              initialSizes={initialIconSizes}
              renderCondition={isProApt}
            />
            <SvgOrangeLogo
              initialSizes={initialIconSizes}
              renderCondition={isFirstHundredBonus}
            />
          </Box>
          <Box display="flex">
            <SvgGreenLogo
              initialSizes={initialIconSizes}
              renderCondition={false}
            />
            <SvgPlus renderCondition={isMyHealth} />
          </Box>
        </Box>
      </TableCell>
      <TableCell align="center">
        <BasketComparisonCell field={row.minimalQuantity} />
      </TableCell>
      <TableCell align="center">
        <BasketComparisonCell field={row.boxQuantity} />
      </TableCell>
      <TableCell align="center">
        <BasketComparisonCell field={row.quantity} />
      </TableCell>
      <TableCellStyled align="center">{row.priceListName}</TableCellStyled>
      <TableCell align="center">
        <BasketComparisonDateCell row={row} />
      </TableCell>
      <TableCellStyled align="center">
        <FormattedPrice1 price={row.price.match} />
      </TableCellStyled>
      <TableCell align="center">
        <InputBase
          disabled={!row.matchProductCount}
          defaultValue={row.amount}
          onInput={onlyNumbers}
          inputProps={{ min: 1 }}
          sx={{
            '&.MuiInputBase-root': {
              '& input': {
                textAlign: 'center',
                fontSize: '0.725rem',
                marginLeft: '8px',
                width: '70px',
              },
            },
          }}
          type="number"
          onChange={(event) =>
            changeItemAmount(event.target.value, parentKey, row.basketItemId)
          }
        />
      </TableCell>
      <TableCellStyled align="center" sx={{ whiteSpace: 'nowrap' }}>
        <BasketComparisonPriceCell row={row} />
      </TableCellStyled>

      <TableCell align="center">
        <Tooltip title="Удалить">
          <IconButton
            disabled={!claims['promarket.basket_item.delete']}
            onClick={() => deleteItem(parentKey, row.basketItemId)}
          >
            <DeleteIcon sx={{ color: 'primary.main' }} />
          </IconButton>
        </Tooltip>
      </TableCell>

      <TableCell align="center" sx={{ padding: 'unset' }}>
        <Box display={'flex'}>
          <Tooltip title="Альтернативные товары">
            <IconButton onClick={() => saveBasketItemAndPush(row)}>
              <TextCircle>A</TextCircle>
            </IconButton>
          </Tooltip>

          <Tooltip title="Похожие товары">
            <IconButton onClick={() => handlePush(row)}>
              <Inventory2OutlinedIcon sx={{ color: 'primary.main' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  )
}

const BasketTable = ({
  firstColumn,
  header,
  itemsNames,
  partHandleSubmit,
  partHandleDelete,
  deleteItem,
  changeItemAmount,
  parentKey,
}: IBasketTableProps) => {
  const orderItemCountTemplate = useMemo(() => {
    return orderItemCount(itemsNames)
  }, [itemsNames])

  const orderItemSumTemplate = useMemo(() => {
    return window
      .numeral(orderItemSum(itemsNames))
      .format('0,0.00')
      .replace(',', ' ')
  }, [itemsNames])

  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<any>('name')
  const claims = useTypedSelector((state) => state.auth.claims)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  return (
    <>
      <TableContainer
        sx={{
          mb: '4px',
          p: '4px',
          pr: 2,
          overflowX: 'initial',
        }}
      >
        <Table size="small" padding="none" aria-label="a basket table">
          <TableHead>
            <TableRow
              sx={{
                position: 'sticky',
                top: -4,
                zIndex: 1,
                '& th:not(:last-child)': {
                  border: '5px solid',
                  borderColor: 'background.paper',
                  backgroundColor: 'neutral.light',
                },
                '& th:last-child': {
                  border: '5px solid',
                  borderColor: 'background.paper',
                },
              }}
            >
              <TableCell
                colSpan={!!firstColumn.val ? 10 : 9}
                align="left"
                sx={{
                  fontSize: '0.825rem',
                  fontWeight: '600',
                  color: 'primary.main',
                  paddingLeft: '8px',
                }}
              >
                {header}
              </TableCell>

              <TableCell
                align="center"
                sx={{ whiteSpace: 'nowrap', color: 'primary.main' }}
              >
                {orderItemCountTemplate}
              </TableCell>

              <TableCell
                align="center"
                sx={{ whiteSpace: 'nowrap', color: 'primary.main' }}
              >
                {orderItemSumTemplate} &#8381;
              </TableCell>

              <TableCell sx={{ width: 0, padding: 'unset' }} align="center">
                <Tooltip title="Удалить заказы">
                  <IconButton
                    disabled={!claims['promarket.basket_item.delete']}
                    size="small"
                    onClick={() => partHandleDelete(parentKey)}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>

              <TableCell align="center" sx={{ padding: 'unset', width: 0 }}>
                <Tooltip title="Сохранить заказ">
                  <Button
                    disabled={
                      !claims['promarket.order.create'] &&
                      !claims['promarket.order.send_supplier']
                    }
                    onClick={() => partHandleSubmit(parentKey)}
                    variant="contained"
                    startIcon={<SendIcon sx={{ ml: '12px' }} />}
                    sx={{ padding: '10px 0px' }}
                  />
                </Tooltip>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                '& th': {
                  whiteSpace: 'nowrap',
                  fontSize: '0.688rem',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  borderBottom: 0,
                  color: 'primary.main',
                  position: 'sticky',
                  backgroundColor: 'background.paper',
                  top: 36,
                  zIndex: 1,
                },
              }}
            >
              {!!firstColumn.text && (
                <TableCell align="left" sx={{ paddingLeft: '8px' }}>
                  {firstColumn.text}
                </TableCell>
              )}
              <TableCell />
              <EnhancedCellsBlock
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsNames
              .slice()
              .sort(getComparator(order, orderBy))
              .map((row) => (
                <BasketTableRow
                  key={row.basketItemId}
                  row={row}
                  deleteItem={deleteItem}
                  changeItemAmount={changeItemAmount}
                  firstColumnValue={firstColumn.val}
                  parentKey={parentKey}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default BasketTable
