import { TableAlignType } from '../../../../../shared/types/types'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'

export interface IPlistItems {
  extendedName: string
  id: number
  name: string
  typeId: number
  isActive: boolean
}

export interface IUserPlistQueryProps {
  supplierId: number
  PriceListTypeId: number
  active: boolean
}

export interface IPriceListDeliveryHeadCells {
  name: string
  align: TableAlignType
  field: PriceListDeliveryFieldType
  enableLeftPadding?: boolean
  enablePaddings?: boolean
  disableField?: boolean
  disableSort?: boolean
  Icon?: OverridableComponent<SvgIconTypeMap>
}

export type PriceListDeliveryFieldType = any

export enum DayToIndex {
  deliveryDaysNumberFromMonday,
  deliveryDaysNumberFromTuesday,
  deliveryDaysNumberFromWednesday,
  deliveryDaysNumberFromThursday,
  deliveryDaysNumberFromFriday,
  deliveryDaysNumberFromSaturday,
  deliveryDaysNumberFromSunday,
}

export enum DeliveryMode {
  default,
  withDepartments,
}
