import React, { ReactNode } from 'react'

// hooks
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { useBasketController } from './controllers/useBasketController'

// project
import NoItemsBasket from './components/NoItemsBasket'
import BackToLastPage from '../../shared/components/BackToLastPage'
import SumPlugin from './components/SumPlugin'
import BasketTable from './components/BasketTable'
import BasketSumColumn from './components/BasketSumColumn'
import Pagination from '@mui/material/Pagination'
import SupplierSumsList from '../../shared/components/SupplierSumsList'

// mui
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { FormControlLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { TabPanel } from '../../shared/components/styled/CustomTab'

// styled
import { styled } from '@mui/material/styles'

const BoxStyled = styled(Box)({
  overflow: 'scroll',
  maxHeight: 'calc(100vh - 240px)',
})

const BasketMain = () => {
  const loading = useTypedSelector((state) => state.app.loading)
  const supplierDetails = useTypedSelector(
    (state) => state.basket.supplierDetails
  )
  const {
    basketProps,
    basketColumnProps,
    tabValue,
    handleChangeTab,
    handleChangeCheckBox,
    checked,
    orders,
    supSetting,
    handleChange,
    page,
    totalPages,
  } = useBasketController()

  let emptyImage = orders.length === 0 && !loading && <NoItemsBasket />

  let pagination: ReactNode

  if (loading) {
    pagination = null
  } else if (totalPages > 1) {
    pagination = (
      <Box display="flex" justifyContent="center" marginTop={3}>
        <Pagination count={totalPages} page={page} onChange={handleChange} />
      </Box>
    )
  }

  return (
    <>
      <Box marginX={2}>
        <BackToLastPage text={'Корзина'} />

        <Grid container spacing={4}>
          <Grid item xs={10}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between',
              }}
            >
              <Tabs
                sx={{
                  '& .MuiTabs-indicator': {
                    top: '0px',
                  },
                  mb: 1,
                }}
                value={tabValue}
                onChange={handleChangeTab}
              >
                <Tab label="Поставщик" />
                <Tab label="Получатель" />
                <Tab label="Поставщик-получатель" />
              </Tabs>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChangeCheckBox}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Группировать по ПЛ"
              />
            </Box>

            <TabPanel value={tabValue} index={0}>
              {emptyImage}
              <BoxStyled>
                {orders.map(({ uniqKey, items }, index) => (
                  <React.Fragment key={uniqKey}>
                    {supSetting.length !== 0 && (
                      <SumPlugin supSetting={supSetting} items={items} />
                    )}

                    <BasketTable
                      firstColumn={{
                        text: 'Получатель',
                        val: 'department',
                      }}
                      parentKey={index}
                      header={uniqKey}
                      itemsNames={items}
                      {...basketProps}
                    />
                  </React.Fragment>
                ))}
              </BoxStyled>
              {pagination}
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              {emptyImage}
              <BoxStyled>
                {orders.map(({ uniqKey, items }, index) => (
                  <React.Fragment key={uniqKey}>
                    <BasketTable
                      firstColumn={{ text: 'Поставщик', val: 'supplier' }}
                      parentKey={index}
                      header={uniqKey}
                      itemsNames={items}
                      {...basketProps}
                    />
                  </React.Fragment>
                ))}
              </BoxStyled>
              {pagination}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {emptyImage}
              <BoxStyled>
                {orders.map(({ uniqKey, items }, index) => (
                  <React.Fragment key={uniqKey}>
                    <BasketTable
                      firstColumn={{
                        text: '',
                        val: '',
                      }}
                      parentKey={index}
                      header={uniqKey}
                      itemsNames={items}
                      {...basketProps}
                    />
                  </React.Fragment>
                ))}
              </BoxStyled>
              {pagination}
            </TabPanel>
          </Grid>
          <Grid item xs={2}>
            <BasketSumColumn {...basketColumnProps} />
            <Box
              sx={{
                overflow: 'scroll',
                maxHeight: 'calc(100vh - 445px)',
              }}
            >
              <SupplierSumsList items={supplierDetails} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default BasketMain
