import http from './config/instance'
import { IOptionsProps } from '../types/types'

export const getSuppliers = (
  name?: string | null,
  pageSize: number | null = 20
) =>
  http.httpCore.get<{ content: IOptionsProps[] }>('hint/suppliers', {
    params: {
      name,
      pageSize,
    },
  })

export const getSupplier = (supplierIdRange: string) =>
  http.httpCore.get<{ content: { data: IOptionsProps[] } }>('suppliers', {
    params: {
      supplierIdRange,
    },
  })

export const getCountries = (name?: string, pageSize = 20) =>
  http.httpCore.get<{ content: IOptionsProps[] }>('hint/producer-countries', {
    params: {
      name,
      pageSize,
    },
  })

export const getNewSuppliers = (
  name?: string,
  onlyActive?: string,
  withPriceLists?: boolean,
  supplierIdRange?: number[],
  pageSize = 20,
  showActivity = true,
  pageNumber?: number
) => {
  return http.httpCore.get<{
    content: { data: IOptionsProps[]; pageNumber: number }
  }>('suppliers', {
    params: {
      ...(name ? { name } : {}),
      ...(onlyActive !== '' ? { onlyActive } : {}),
      ...(withPriceLists ? { withPriceLists } : {}),
      ...(supplierIdRange?.length !== 0 ? { supplierIdRange } : {}),
      ...(pageNumber ? { pageNumber } : {}),
      pageSize,
      showActivity,
    },
  })
}

export const getProducers = (name?: string, pageSize = 20) =>
  http.httpCore.get<{ content: IOptionsProps[] }>('hint/producers', {
    params: {
      name,
      pageSize,
    },
  })
