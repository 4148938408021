import React from 'react'

// mui
import Checkbox from '@mui/material/Checkbox'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'

// project
import FormattedDate1 from '../../../shared/components/FormattedDate1'
import StatusInfo from '../UI/StatusInfo'

// third libs
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

// service
import { getViewAutoOrderRouteByOrderId } from '../../../shared/services/routeService'

// types
import { IAutoOrderItem } from '../types/autoOrderTypes'
import { AppStateType } from '../../../shared/store'

// const
import { AUTO_ORDER_STATUS } from '../consts/autoOrder'

// actions
import { sendAutoOrderDeleteRequest } from '../model/autoOrder/autoOrderAction'
import { useActions } from '../../../shared/hooks/useActions'

// selectors
import selectAutoOrderRowData from '../helpers/selectorAutoOrderRowData'

export function AutoOrderRow({ row }: { row: IAutoOrderItem }) {
  const history = useHistory()
  const { isCheckboxDisabled, isCheckboxChecked } = useSelector(
    (state: AppStateType) => selectAutoOrderRowData(state, row.id)
  )
  const isEnableToDelete = row.enableToDeleteSummaryBid && AUTO_ORDER_STATUS.NEW
  const { handleAutoOrderCheckBox, fetchAutoOrder, resetAutoOrderItems } =
    useActions()

  const handleDeleteOrderById = async (id: string) => {
    await sendAutoOrderDeleteRequest(id)
    resetAutoOrderItems()
    fetchAutoOrder()
  }

  const handleEdit = () => {
    history.push(getViewAutoOrderRouteByOrderId(row.id))
  }

  const mouseDownHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event.button === 1) {
      window.open(getViewAutoOrderRouteByOrderId(row.id), '_blank')
    }
  }

  return (
    <TableRow
      sx={{
        backgroundColor: 'unset',
        '& td': {
          fontSize: '0.875rem',
        },
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell align="left">
        <Checkbox
          disabled={isCheckboxDisabled}
          id={row.id}
          onChange={handleAutoOrderCheckBox}
          checked={isCheckboxChecked}
        />
      </TableCell>

      <TableCell align="left">
        <FormattedDate1 date={row.creationDate} />
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: 'unset',
          fontWeight: '600',
        }}
      >
        <span>{row.code}</span>
      </TableCell>
      <TableCell align="left" sx={{ minWidth: '160px' }}>
        {row.departmentName}
      </TableCell>
      <TableCell align="left">
        <StatusInfo status={row.status} />
      </TableCell>
      <TableCell align="left">
        <FormattedDate1 date={row.processDate} />
      </TableCell>
      <TableCell align="left" sx={{ minWidth: '160px' }}>
        {row.description}
      </TableCell>
      <TableCell>
        <Stack direction="row">
          <Tooltip title={'Просмотреть заявку'}>
            <span>
              <IconButton
                size="small"
                onClick={handleEdit}
                onMouseDown={mouseDownHandler}
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={isEnableToDelete ? 'Удалить заявку' : 'Недоступное действие'}
          >
            <span>
              <IconButton
                disabled={!isEnableToDelete}
                onClick={() => handleDeleteOrderById(row.id)}
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  )
}
