import React from 'react'

// project imports
import ElementWatch from '../Product/components/ElementWatch'
import { useSimilarController } from './controllers/useSimilarController'
import ErrorPage from '../Product/components/ErrorPage'
import BackToLastPage from '../../shared/components/BackToLastPage'
import SimilarTableRow from './SimilarTableRow'
import { headCellsSimilar } from './consts/similar'

//mui imports
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import { SimilarFieldType } from './types/similarTypes'
import { useActions } from '../../shared/hooks/useActions'

const SimilarMain = () => {
  const {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    products,
    order,
    orderBy,
  } = useSimilarController()

  const { setSimilarSort } = useActions()

  const sortHandler = (field: SimilarFieldType) => {
    const isAsc = orderBy === field && order === 'asc'
    setSimilarSort({ orderBy: field, order: isAsc ? 'desc' : 'asc' })
  }

  if (Object.keys(error).length > 0) {
    return (
      <ErrorPage
        {...{
          error,
          firstElement,
          lastElement,
        }}
      />
    )
  }
  return (
    <>
      <BackToLastPage text="Похожие товары" />
      <ElementWatch element={firstElement} isLoading={loading} />
      <TableContainer
        sx={{
          border: '1px solid',
          borderColor: 'action.disabled',
          boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
          borderRadius: '8px',
          pb: 2,
          overflowX: 'initial',
        }}
      >
        <Table size="small" aria-label="a basket table">
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  whiteSpace: 'nowrap',
                  fontSize: '0.75rem',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  borderBottom: 0,
                  color: 'primary.main',
                },
              }}
            >
              <TableCell />
              {headCellsSimilar.map((item) => (
                <TableCell key={item.name} align={item.align}>
                  {!item.disableField && (
                    <TableSortLabel
                      active={orderBy === item.field}
                      direction={orderBy === item.field ? order : 'asc'}
                      onClick={() => sortHandler(item.field)}
                    >
                      <Box sx={{ pl: item.enablePadding ? '18px' : 'unset' }}>
                        {item.name}
                      </Box>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((row) => (
              <React.Fragment key={row.productWithInfo.product.id}>
              <SimilarTableRow row={row} />
                <tr
                  // @ts-ignore
                  ref={(el) => (inputRef.current[row.priceListItemId] = el)}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ElementWatch element={lastElement} isLoading={loading} />
    </>
  )
}

export default SimilarMain
