export const TRANSLATIONS_RU = {
  dialog: {
    none: 'нет опций',
    new: 'новый',
    completed: 'В очереди на отправку',
    sended: 'отправлен',
    parted_approved: 'частично одобрен',
    approved: 'одобрен',
    refused: 'отменен',
  },
}
