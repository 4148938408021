import { Order } from '../../../../../../shared/services/commonService'
import {
  ISaleComplexOptions,
  SaleComplexFieldType,
} from '../../types/saleComplexTypes'
import {
  ErrorInfo,
  IDepartmentsNeedsParams,
  IStopListParams,
  LoadDirections,
} from '../../../../../../shared/types/types'

export interface SaleComplexState {
  isBlockedTable: boolean
  enough: boolean
  direction: LoadDirections.DOWN | LoadDirections.UP
  error: ErrorInfo | {}
  loading: boolean
  page: number
  limit: number
  total: number
  filter: any
  products: any
  orderBy: SaleComplexFieldType
  order: Order
  startSearch: boolean
  options: ISaleComplexOptions
}

export enum SaleComplexActionTypes {
  RESET_SALE_COMPLEX_STATE = 'RESET_SALE_COMPLEX_STATE',
  LOADING_ENABLE = 'LOADING_SALE_COMPLEX_ENABLE',
  LOADING_DISABLE = 'LOADING_SALE_COMPLEX_DISABLE',
  SET_FILTER_PRODUCTS = 'SET_FILTER_SALE_COMPLEX',
  RESET_FILTER_PRODUCTS = 'RESET_FILTER_SALE_COMPLEX',
  FETCH_PRODUCTS = 'FETCH_SALE_COMPLEX',
  FETCH_PRODUCTS_SUCCESS = 'FETCH_SALE_COMPLEX_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_SALE_COMPLEX_ERROR',
  SET_PRODUCT_PAGE = 'SET_SALE_COMPLEX_PAGE',
  FETCH_PRODUCTS_ENOUGH = 'FETCH_SALE_COMPLEX_ENOUGH',
  SET_PRODUCT_SORT = 'SET_SALE_COMPLEX_SORT',
  ADD_SALE_COMPLEX_STOP_LIST_ITEM = 'ADD_SALE_COMPLEX_STOP_LIST_ITEM',
  DELETE_SALE_COMPLEX_STOP_LIST_ITEM = 'DELETE_SALE_COMPLEX_STOP_LIST_ITEM',
  DELETE_ALL_SALE_COMPLEX_STOP_LIST_ITEMS = 'DELETE_ALL_SALE_COMPLEX_STOP_LIST_ITEMS',
  SAVE_SALE_COMPLEX_OPTIONS = 'SAVE_SALE_COMPLEX_OPTIONS',
  SAVE_SALE_COMPLEX_OPTION_PERIOD = 'SAVE_SALE_COMPLEX_OPTION_PERIOD',
  SAVE_SALE_COMPLEX_OPTION_SHELF_LIFE_PERCENT = 'SAVE_SALE_COMPLEX_OPTION_SHELF_LIFE_PERCENT',
  SAVE_SALE_COMPLEX_OPTION_SHELF_LIFE = 'SAVE_SALE_COMPLEX_OPTION_SHELF_LIFE',
  SAVE_SALE_COMPLEX_OPTION_PRICE_GAIN = 'SAVE_SALE_COMPLEX_OPTION_PRICE_GAIN',
  SAVE_SALE_COMPLEX_OPTION_AVAILABILITY = 'SAVE_SALE_COMPLEX_OPTION_AVAILABILITY',
  SAVE_SALE_COMPLEX_TEXT_SHELF_LIFE_PERCENT = 'SAVE_SALE_COMPLEX_TEXT_SHELF_LIFE_PERCENT',
  SAVE_SALE_COMPLEX_TEXT_SHELF_LIFE = 'SAVE_SALE_COMPLEX_TEXT_SHELF_LIFE',
  SAVE_SALE_COMPLEX_QUANTITY_FOR_ORDER = 'SAVE_SALE_COMPLEX_QUANTITY_FOR_ORDER',
  ENABLE_SALE_COMPLEX_BLOCK_TABLE = 'ENABLE_SALE_COMPLEX_BLOCK_TABLE',
  FETCH_HIGH_MARGIN = 'FETCH_HIGH_MARGIN',
}

interface SaveSaleComplexQuantityForOrderAction {
  type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_QUANTITY_FOR_ORDER
  payload: IDepartmentsNeedsParams
}

interface EnableSaleComplexBlockTableAction {
  type: SaleComplexActionTypes.ENABLE_SALE_COMPLEX_BLOCK_TABLE
}

interface ResetSaleComplexStateAction {
  type: SaleComplexActionTypes.RESET_SALE_COMPLEX_STATE
}

interface SaveSaleComplexTextShelfLifePercentAction {
  type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_TEXT_SHELF_LIFE_PERCENT
  payload: string
}

interface SaveSaleComplexTextShelfLifeAction {
  type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_TEXT_SHELF_LIFE
  payload: string
}

interface SaveSaleComplexOptionAvailabilityByQuantityAction {
  type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_AVAILABILITY
  payload: boolean
}

interface SaveSaleComplexOptionPriceGainAction {
  type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_PRICE_GAIN
  payload: boolean
}

interface SaveSaleComplexOptionShelfLifePercentAction {
  type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_SHELF_LIFE_PERCENT
  payload: boolean
}

interface SaveSaleComplexOptionShelfLifeAction {
  type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_SHELF_LIFE
  payload: boolean
}

interface SaveSaleComplexOptionPeriodAction {
  type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_PERIOD
  payload: string
}

interface SaveSaleComplexOptionsAction {
  type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTIONS
  payload: ISaleComplexOptions
}

interface DeleteAllStopListsItemAction {
  type: SaleComplexActionTypes.DELETE_ALL_SALE_COMPLEX_STOP_LIST_ITEMS
}

interface DeleteStopListsItemAction {
  type: SaleComplexActionTypes.DELETE_SALE_COMPLEX_STOP_LIST_ITEM
  payload: IStopListParams
}

interface AddStopListsItemAction {
  type: SaleComplexActionTypes.ADD_SALE_COMPLEX_STOP_LIST_ITEM
  payload: IStopListParams
}

interface FetchSaleComplexEnoughAction {
  type: SaleComplexActionTypes.FETCH_PRODUCTS_ENOUGH
  payload: boolean
}

interface SetFilterSaleComplexAction {
  type: SaleComplexActionTypes.SET_FILTER_PRODUCTS
  payload: Object
}

interface ResetFilterSaleComplexAction {
  type: SaleComplexActionTypes.RESET_FILTER_PRODUCTS
}

interface FetchSaleComplexAction {
  type: SaleComplexActionTypes.FETCH_PRODUCTS
}

export interface FetchSaleComplexSuccessAction {
  type: SaleComplexActionTypes.FETCH_PRODUCTS_SUCCESS
  payload: { content: any; loadFlag: boolean }
}

export interface FetchSaleComplexErrorAction {
  type: SaleComplexActionTypes.FETCH_PRODUCTS_ERROR
  payload: ErrorInfo | {}
}

interface SetSaleComplexPage {
  type: SaleComplexActionTypes.SET_PRODUCT_PAGE
  payload: number
}

export interface ISaleComplexPayload {
  orderBy: SaleComplexFieldType
  order: Order
}

interface SetSaleComplexSort {
  type: SaleComplexActionTypes.SET_PRODUCT_SORT
  payload: ISaleComplexPayload
}

export interface LoadingSaleComplexEnableAction {
  type: SaleComplexActionTypes.LOADING_ENABLE
}

export interface LoadingSaleComplexDisableAction {
  type: SaleComplexActionTypes.LOADING_DISABLE
}
export interface FetchHighMarginProducts {
  type: SaleComplexActionTypes.FETCH_HIGH_MARGIN
  payload: boolean
}

export type SaleComplexAction =
  | FetchSaleComplexEnoughAction
  | SetFilterSaleComplexAction
  | ResetFilterSaleComplexAction
  | FetchSaleComplexAction
  | FetchSaleComplexSuccessAction
  | FetchSaleComplexErrorAction
  | SetSaleComplexPage
  | SetSaleComplexSort
  | DeleteStopListsItemAction
  | AddStopListsItemAction
  | DeleteAllStopListsItemAction
  | SaveSaleComplexOptionsAction
  | SaveSaleComplexOptionPeriodAction
  | LoadingSaleComplexEnableAction
  | LoadingSaleComplexDisableAction
  | SaveSaleComplexOptionShelfLifePercentAction
  | SaveSaleComplexOptionShelfLifeAction
  | SaveSaleComplexOptionPriceGainAction
  | SaveSaleComplexOptionAvailabilityByQuantityAction
  | SaveSaleComplexTextShelfLifePercentAction
  | SaveSaleComplexTextShelfLifeAction
  | ResetSaleComplexStateAction
  | SaveSaleComplexQuantityForOrderAction
  | EnableSaleComplexBlockTableAction
  | FetchHighMarginProducts
