import * as React from 'react'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import SupplierSumsList from './SupplierSumsList'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { Box } from '@mui/material'

export default function HoverPopover() {
  const supplierDetails = useTypedSelector(
    (state) => state.basket.supplierDetails
  )
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Typography
        onClick={handleClick}
        sx={{
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: '10px',
          cursor: 'pointer',
        }}
      >
        Подробнее
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: 'calc(100vh - 70px)',
            width: '320px',
          }}
        >
          <SupplierSumsList items={supplierDetails} border={false} />
        </Box>
      </Popover>
    </>
  )
}
