import { Dispatch } from 'redux'

import { getSaleStopListItems } from '../../../features/Sale/api/sale'
import {
  SaleStopListsAction,
  SaleStopListsActionTypes,
} from './saleStopListsReduxTypes'
import { IStopListParams } from '../../types/types'

export const fetchStopListData = (departmentId?: string | number) => {
  return async (dispatch: Dispatch<SaleStopListsAction>) => {
    try {
      const { data } = await getSaleStopListItems(
        undefined,
        undefined,
        departmentId
      )
      const content = data.content.stopLists
      const isEmpty = content.length === 0
      if (isEmpty) {
        dispatch({
          type: SaleStopListsActionTypes.FETCH_PRODUCTS_ERROR,
          payload: 'Поиск не дал результатов',
        })
        return
      }
      dispatch({
        type: SaleStopListsActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: content,
      })
    } catch (e) {
      dispatch({
        type: SaleStopListsActionTypes.FETCH_PRODUCTS_ERROR,
        payload: 'Поиск завершился ошибкой',
      })
    }
  }
}

export const deleteStopListItemAction = (params: IStopListParams) => ({
  type: SaleStopListsActionTypes.DELETE_STOP_LIST_ITEM,
  payload: params,
})
