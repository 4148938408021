import {
  AutoOrderFieldType,
  IAutoOrder,
  IAutoOrderFilterItem,
  IAutoOrderItem,
  IAutoOrderCondition
} from '../../types/autoOrderTypes'
import { Order } from '../../../../shared/services/commonService'
import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'

export interface AutoOrderState {
  enough: boolean
  direction: LoadDirections.DOWN | LoadDirections.UP
  error: ErrorInfo | {}
  loading: boolean
  page: number
  limit: number
  total: number
  filter: IAutoOrderFilterItem[]
  id: string | null
  startSearch: boolean
  checkedItems: string[] | any
  isCheckedAll: boolean
  orderBy: AutoOrderFieldType
  order: Order,
  listOrientation: AutoOrderOrientation,
  items: IAutoOrderItem[],
  conditionStatus: IAutoOrderCondition
}

export enum AutoOrderOrientation {
  StartDate = 'CreationDate',
  EndDate = '-CreationDate',
  StatusAsc = 'Status',
  StatusDesc = '-Status',
  CodesAsc = 'Codes',
  CodesDesc = '-Codes',
  DepartmentAsc = 'DepartmentId',
  DepartmentDesc = '-DepartmentId',
  DepartmentNameAsc = 'DepartmentName',
  DepartmentNameDesc = '-DepartmentName',
  ProcessDateAsc = 'ProcessDate',
  ProcessDateDesc = '-ProcessDate',
  DescriptionsAsc = 'Descriptions',
  DescriptionsDesc = '-Descriptions'
}


// TODO: fix naming because 'FETCH_AUTO_ORDER' used twice
export enum AutoOrderActionTypes {
  SET_FILTER_PRODUCTS = 'SET_FILTER_AUTO_ORDER',
  RESET_FILTER_AUTO_ORDER = 'RESET_FILTER_AUTO_ORDER',
  RESET_AUTO_ORDER_ITEMS = 'RESET_AUTO_ORDER_ITEMS',

  LOADING_ENABLE = 'LOADING_AUTO_ORDER_ENABLE',
  LOADING_DISABLE = 'LOADING_AUTO_ORDER_DISABLE',

  FETCH_PRODUCTS_REQUEST = 'FETCH_AUTO_ORDER',
  FETCH_PRODUCTS_SUCCESS = 'FETCH_AUTO_ORDER_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_AUTO_ORDER_ERROR',
  FETCH_PRODUCTS_ENOUGH = 'FETCH_AUTO_ORDER_ENOUGH',

  DELETE_PRODUCT_BY_ID = 'DELETE_AUTO_ORDER_BY_ID',
  SET_AUTO_ORDER_PAGE = 'SET_AUTO_ORDER_PAGE',

  START_AUTO_ORDER_SEARCH = 'START_AUTO_ORDER_SEARCH',
  SET_CHECKED_ITEMS = 'SET_AUTO_ORDER_CHECKED_ITEMS',
  ADD_CHECKED_ITEMS = 'ADD_AUTO_ORDER_CHECKED_ITEMS',
  SET_PRODUCT_SORT = 'SET_AUTO_ORDER_SORT',

  SET_AUTO_ORDER_DIRECTION = 'SET_AUTO_ORDER_DIRECTION',

  REMOVE_FILTER_WITH_EMPTY_VALUE = 'REMOVE_FILTER_WITH_EMPTY_VALUE',
  SAVE_AUTO_ORDER_CONDITION = 'SAVE_AUTO_ORDER_CONDITION'
}
interface RemoveFilterEmptyValueAutoOrderAction {
  type: AutoOrderActionTypes.REMOVE_FILTER_WITH_EMPTY_VALUE
  payload: IAutoOrderFilterItem[]
}

interface DeleteIdsAutoOrderAction {
  type: AutoOrderActionTypes.DELETE_PRODUCT_BY_ID
  payload: string
}

interface SearchingAutoOrderAction {
  type: AutoOrderActionTypes.START_AUTO_ORDER_SEARCH
}

interface FetchAutoOrderEnoughAction {
  type: AutoOrderActionTypes.FETCH_PRODUCTS_ENOUGH
  payload: boolean
}

interface SetFilterAutoOrderAction {
  type: AutoOrderActionTypes.SET_FILTER_PRODUCTS
  payload: IAutoOrderFilterItem | IAutoOrderFilterItem[]
}

interface SetCheckedItemsAutoOrderAction {
  type: AutoOrderActionTypes.SET_CHECKED_ITEMS
  payload: { id: string; status: number }
}

interface AddCheckedItemsAutoOrderAction {
  type: AutoOrderActionTypes.ADD_CHECKED_ITEMS
  payload: string
}

interface ResetFilterAutoOrderAction {
  type: AutoOrderActionTypes.RESET_FILTER_AUTO_ORDER
}

interface ResetItemsAutoOrderAction {
  type: AutoOrderActionTypes.RESET_AUTO_ORDER_ITEMS
}

interface FetchAutoOrderAction {
  type: AutoOrderActionTypes.FETCH_PRODUCTS_REQUEST
  payload: { loadFlag: boolean; loading: boolean }
}

export interface FetchAutoOrderSuccessAction {
  type: AutoOrderActionTypes.FETCH_PRODUCTS_SUCCESS
  payload: { content: IAutoOrder; loadFlag: boolean; loading: boolean }
}

export interface FetchAutoOrderDisableAction {
  type: AutoOrderActionTypes.LOADING_DISABLE
}

export interface FetchAutoOrderErrorAction {
  type: AutoOrderActionTypes.FETCH_PRODUCTS_ERROR
  payload: ErrorInfo | {}
}

interface SetAutoOrderPage {
  type: AutoOrderActionTypes.SET_AUTO_ORDER_PAGE
  payload: number
}

export interface IAutoOrderPayload {
  orientation: AutoOrderOrientation
  order: Order
  orderBy?: AutoOrderFieldType
}
export interface SetAutoOrderSort {
  type: AutoOrderActionTypes.SET_PRODUCT_SORT
  payload: IAutoOrderPayload
}

export interface LoadingAutoOrderEnableAction {
  type: AutoOrderActionTypes.LOADING_ENABLE
}

export interface LoadingAutoOrderDisableAction {
  type: AutoOrderActionTypes.LOADING_DISABLE
}

export interface SaveAutoOrderCondition {
    type: AutoOrderActionTypes.SAVE_AUTO_ORDER_CONDITION,
    payload: IAutoOrderCondition
}

export type AutoOrderAction =
  | FetchAutoOrderEnoughAction
  | SetFilterAutoOrderAction
  | ResetFilterAutoOrderAction
  | FetchAutoOrderAction
  | FetchAutoOrderSuccessAction
  | FetchAutoOrderErrorAction
  | SetAutoOrderPage
  | SearchingAutoOrderAction
  | SetCheckedItemsAutoOrderAction
  | AddCheckedItemsAutoOrderAction
  | DeleteIdsAutoOrderAction
  | SetAutoOrderSort
  | LoadingAutoOrderEnableAction
  | LoadingAutoOrderDisableAction
  | ResetItemsAutoOrderAction
  | RemoveFilterEmptyValueAutoOrderAction
  | SaveAutoOrderCondition
