import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { CloseModal } from '../../../types/types'
import { useHistory } from 'react-router-dom'
import { putBasketItem } from '../../../../features/Basket/api/basket'
import { getBasketRoute } from '../../../services/routeService'
import qs from 'qs'

const OtherProductContent = ({ handleClose }: CloseModal) => {
  const history = useHistory()

  const basketItemId = qs.parse(
    window.location.search.substring(1)
  ).basketItemId

  const selectedProduct = useTypedSelector(
    (state) => state.product.selectedProduct
  )
  const defaultDepartment = useTypedSelector(
    (state) => state.app.defaultDepartment
  )

  const handleSubmit = () => {
    if (defaultDepartment === null) return
    const departmentId = Number(defaultDepartment.id)
    const params = {
      supplierId: selectedProduct.supplierId,
      departmentId,
      priceListTypeId: selectedProduct.priceListTypeId,
      supplierGoodId: selectedProduct.supplierGoodId,
      storeCode: '',
      payerCode: '',
      amount: 1,
      initialProduct: {
        id: +selectedProduct.id,
        esCode: selectedProduct.esCode,
        code: selectedProduct.code,
        name: selectedProduct.name,
        supplierId: selectedProduct.supplierId,
        supplierGoodId: selectedProduct.supplierGoodId,
        supplierName: selectedProduct.supplierName,
        countryName: selectedProduct.countryName,
        producerName: selectedProduct.producerName,
        internationalName: selectedProduct.internationalName,
        expirationDate: selectedProduct.expirationDate,
        storageLife: selectedProduct.storageLife,
        quantity: selectedProduct.quantity,
        boxQuantity: selectedProduct.boxQuantity,
        packQuantity: selectedProduct.packQuantity,
        price: selectedProduct.price,
        priceListId: selectedProduct.priceListId,
        priceListTypeId: selectedProduct.priceListTypeId,
        priceListName: selectedProduct.priceListName,
        priceListExtendedName: selectedProduct.priceListExtendedName,
        priceListDate: selectedProduct.priceListDate,
        minimalQuantity: selectedProduct.minimalQuantity,
        registerPrice: selectedProduct.registerPrice,
        vatRate: selectedProduct.vatRate,
        priceListItemId: selectedProduct.priceListItemId,
        storeCode: '',
        payerCode: '',
        esName: selectedProduct.esName,
      },
    }

    if (basketItemId) {
      // @ts-ignore
      putBasketItem(Number(basketItemId), undefined, params).then(() => {
        handleClose()
        history.push(getBasketRoute())
      })
    }
  }

  return (
    <Box>
      <Typography
        variant="h6"
        align="center"
        sx={{
          color: 'secondary.main',
          textTransform: 'uppercase',
          mb: '2rem',
        }}
      >
        Заменить на товар?
      </Typography>

      <Grid container spacing={'1.25rem'}>
        <Grid item xs={12}>
          <Box>
            <Typography
              component="div"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '12px',
              }}
            >
              {'Наименование:'}
            </Typography>
            <Typography
              component="div"
              sx={{
                lineHeight: '1.5',
                color: 'text.secondary',
                textTransform: 'uppercase',
                overflowWrap: 'break-word',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              {selectedProduct.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography
              component="div"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '12px',
              }}
            >
              {'Производитель:'}
            </Typography>
            <Typography
              component="div"
              sx={{
                lineHeight: '1.5',
                color: 'text.secondary',
                textTransform: 'uppercase',
                overflowWrap: 'break-word',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              {selectedProduct.producerName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="caption"
              component="div"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '12px',
              }}
            >
              {'Поставщик:'}
            </Typography>
            <Typography
              component="div"
              sx={{
                lineHeight: '1.5',
                color: 'text.secondary',
                textTransform: 'uppercase',
                overflowWrap: 'break-word',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              {selectedProduct.supplierName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Stack flexDirection="row" alignItems="center" columnGap={'1rem'}>
            <Button variant="contained" fullWidth onClick={handleSubmit}>
              Подтвердить
            </Button>

            <Button onClick={handleClose} variant="outlined" fullWidth>
              Отменить
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default OtherProductContent
