import {
  SaleCostBonusesAction,
  SaleCostBonusesActionTypes,
  SaleCostBonusesState,
} from './saleCostBonusesReduxTypes'

const initialState: SaleCostBonusesState = {
  loading: false,
  bonuses: {
    initialOrderCost: 0,
    initialBonusCost: 0,
    lostProfitCost: 0,
    orderCost: 0,
    totalBonusCost: 0,
    totalBonusPlanPercentage: 0,
    bonusForImplementationCost: 0,
    bonusPlanForImplementationPercentage: 0,
    bonusForMarketingEventCost: 0,
    bonusPlanForMarketingEventPercentage: 0,
    extraBonusCost: 0,
    extraBonusPlanPercentage: 0,
    bonusInPriceCost: 0,
    bonusInPricePercentage: 0,
    minimalCompetitorPriceBonusCost: 0,
    minimalCompetitorPriceOrderCost: 0,
  },
  departments: [],
}

export const saleCostBonusesReducer = (
  state = initialState,
  action: SaleCostBonusesAction
): SaleCostBonusesState => {
  switch (action.type) {
    case SaleCostBonusesActionTypes.FETCH_SALE_COST_BONUSES_SUCCESS:
      return {
        ...state,
        bonuses: { ...state.bonuses, ...action.payload },
      }

    case SaleCostBonusesActionTypes.RESET_SALE_COST_BONUSES:
      return {
        ...state,
        bonuses: { ...state.bonuses, ...initialState.bonuses },
      }

    case SaleCostBonusesActionTypes.SAVE_DEPARTMENTS_LIST:
      return { ...state, departments: action.payload }

    case SaleCostBonusesActionTypes.LOADING_ENABLE:
      return { ...state, loading: true }
    case SaleCostBonusesActionTypes.LOADING_DISABLE:
      return { ...state, loading: false }

    default:
      return state
  }
}
