import {
  SupplierSettingsAction,
  SupplierSettingsActionTypes,
  SupplierSettingsState,
} from './supplierSettingsReduxTypes'

const initialState: SupplierSettingsState = {
  considerMinimumQuantity: false,
  considerQuantityAvailabilityFactor: false,
  factors: [],
  departmentId: null,
  considerMultiplicityOfBoxQuantity: false,
  prioritiesOrder: [
    {
      priority: 0,
      order: 0,
    },
    {
      priority: 1,
      order: 1,
    },
    {
      priority: 2,
      order: 2,
    },
    {
      priority: 3,
      order: 3,
    },
    {
      priority: 4,
      order: 4,
    },
  ],
  onlyProductsWithBestRating: false,
  remainingShelfLifeInDays: 0,
  remainingShelfLifeInclude: false,
  remainingShelfLifePercent: 0,
  remainingShelfLifePercentInclude: false,
  distributeSupplierOrderIfCostLessThanMinimumCost: false,
  supplierOrderMinimumCostPercentage: 0,
  updateByUserName: null,
  updatedAt: '',
}

export const supplierSettingsReducer = (
  state = initialState,
  action: SupplierSettingsAction
): SupplierSettingsState => {
  switch (action.type) {
    case SupplierSettingsActionTypes.FETCH_SUPPLIER_SETTINGS_SUCCESS:
      const {
        availabilityByQuantity,
        departmentId,
        multiplicityOfBoxQuantity,
        prioritiesOrder,
        productRating,
        remainingShelfLifeDuration,
        remainingShelfLifePercent,
        supplierOrderCost,
        updateByUserName,
        updatedAt,
      } = action.payload

      return {
        ...state,
        considerMinimumQuantity: availabilityByQuantity.considerMinimumQuantity,
        considerQuantityAvailabilityFactor:
          availabilityByQuantity.considerQuantityAvailabilityFactor,
        factors:
          availabilityByQuantity.quantityAvailabilityFactorDictionary.factors,
        departmentId: departmentId,
        considerMultiplicityOfBoxQuantity:
          multiplicityOfBoxQuantity.considerMultiplicityOfBoxQuantity,
        prioritiesOrder: prioritiesOrder.prioritiesOrder,
        onlyProductsWithBestRating: productRating.onlyProductsWithBestRating,
        remainingShelfLifeInDays:
          remainingShelfLifeDuration.remainingShelfLifeInDays,
        remainingShelfLifeInclude:
          remainingShelfLifeDuration.remainingShelfLifeInclude,
        remainingShelfLifePercent:
          remainingShelfLifePercent.remainingShelfLifePercent,
        remainingShelfLifePercentInclude:
          remainingShelfLifePercent.remainingShelfLifePercentInclude,
        distributeSupplierOrderIfCostLessThanMinimumCost:
          supplierOrderCost.distributeSupplierOrderIfCostLessThanMinimumCost,
        supplierOrderMinimumCostPercentage:
          supplierOrderCost.supplierOrderMinimumCostPercentage,
        updateByUserName: updateByUserName,
        updatedAt: updatedAt,
      }

    case SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_TEXT_SHELF_LIFE:
      state.remainingShelfLifeInDays = action.payload
      return state

    case SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_SHELF_LIFE:
      return {
        ...state,
        remainingShelfLifeInclude: action.payload,
      }

    case SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_TEXT_SHELF_LIFE_PERCENT:
      state.remainingShelfLifePercent = action.payload
      return state

    case SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_SHELF_LIFE_PERCENT:
      return {
        ...state,
        remainingShelfLifePercentInclude: action.payload,
      }

    case SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_BEST_RATING:
      return {
        ...state,
        onlyProductsWithBestRating: action.payload,
      }

    case SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_MULTIPLICITY:
      return {
        ...state,
        considerMultiplicityOfBoxQuantity: action.payload,
      }

    case SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_MIN_QUANTITY:
      return {
        ...state,
        considerMinimumQuantity: action.payload,
      }

    case SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_DISTRIBUTE_SUPPLIER_ORDER:
      return {
        ...state,
        distributeSupplierOrderIfCostLessThanMinimumCost: action.payload,
      }

    case SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_TEXT_MIN_COST_PERCENT:
      state.supplierOrderMinimumCostPercentage = action.payload
      return state

    case SupplierSettingsActionTypes.UPDATE_SUPPLIER_SETTINGS_PRIORITY_ORDER:
      return {
        ...state,
        prioritiesOrder: action.payload,
      }

    case SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_FACTOR:
      return {
        ...state,
        considerQuantityAvailabilityFactor: action.payload,
      }

    case SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_FACTORS_LIST:
      return {
        ...state,
        factors: action.payload,
      }

    default:
      return state
  }
}
