import React from 'react'
import { useActions } from '../../../shared/hooks/useActions'
import { Box, Fab, List, ListItem, ListItemText } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import FilterSideBar from './FiltersSideBar/FilterSideBar'

const DrawerCustomContent = () => {
  const { showSideFilters } = useActions()
  return (
    <>
      <Box sx={{ overflowX: 'hidden', height: 'calc(100vh - 65px)' }}>
        <List
          sx={{
            width: '100%',
            height: '100%',
            bgcolor: 'background.paper',
            padding: '1.5rem 0.5rem 1.5rem 1rem',
          }}
        >
          <ListItem disableGutters dense sx={{ mb: 2 }}>
            <Fab
              color="primary"
              aria-label="add"
              sx={{
                mr: '10px',
                color: 'common.white',
                width: '26px',
                height: '26px',
                minHeight: 'auto',
              }}
              onClick={() => showSideFilters()}
            >
              <AddIcon />
            </Fab>
            <ListItemText
              primaryTypographyProps={{
                fontSize: '0.75rem',
                fontWeight: 'bold',
              }}
              primary="ДОБАВИТЬ ФИЛЬТРЫ"
            />
          </ListItem>
          <FilterSideBar />
        </List>
      </Box>
    </>
  )
}

export default DrawerCustomContent
