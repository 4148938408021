import { PRICE_LIST_RANGE } from './priceListRange'

const { MIN_RANGE, MAX_RANGE } = PRICE_LIST_RANGE

export const DEFAULT_DEPARTMENT_STORAGE_KEY = 'defaultDepartmentStorage'

export const FILTER_OPTIONS: IMergedOptions[] = [
  {
    label: 'Код УС',
    name: 'esCodeFilter',
    state: '',
    nameOrder: 'esCodeOrderRule',
    stateOrder: '',
  },
  {
    label: 'GTIN',
    name: 'gtinFilter',
    state: '',
    nameOrder: 'gtinOrderRule',
    stateOrder: '',
  },
  {
    label: 'Товар',
    name: 'nameFilter',
    state: '',
    nameOrder: 'nameOrderRule',
    stateOrder: '',
  },
  {
    label: 'Крат.',
    name: 'boxQuantityFilterMin',
    state: '',
    nameOrder: 'boxQuantityOrderRule',
    stateOrder: '',
  },
  {
    label: 'Мин',
    name: 'minimalQuantityFilterMin',
    state: '',
    nameOrder: 'minimalQuantityOrderRule',
    stateOrder: '',
  },
  {
    label: 'Уп.',
    name: 'packQuantityFilterMin',
    state: '',
    nameOrder: 'packQuantityOrderRule',
    stateOrder: '',
  },
  {
    label: 'МНН',
    name: 'internationalNameFilter',
    state: '',
    nameOrder: 'internationalNameOrderRule',
    stateOrder: '',
  },
  {
    label: 'Реестровая цена',
    name: 'registerPriceFilterMin',
    state: '',
    nameOrder: 'registerPriceOrderRule',
    stateOrder: '',
  },
  {
    label: 'НДС',
    name: 'vatRateFilter',
    state: { vatRateFilterMin: '', vatRateFilterMax: '' },
    nameOrder: 'vatRateOrderRule',
    stateOrder: '',
  },
  {
    label: 'Цена производителя',
    name: 'producerPriceFilterMin',
    state: '',
    nameOrder: 'producerPriceOrderRule',
    stateOrder: '',
  },
  {
    label: 'Опт. наценка',
    name: 'wholesaleMarkupFilterMin',
    state: '',
    nameOrder: 'wholeSaleOrderRule',
    stateOrder: '',
  },

  {
    label: 'Процент остаточного срока годности',
    name: 'remainExpirationPercentFilter',
    state: {
      remainExpirationPercentFilterMin: '',
      remainExpirationPercentFilterMax: '',
    },
    nameOrder: 'remainExpirationOrderRule',
    stateOrder: '',
  },
  {
    label: 'Поставщик',
    name: 'supplierIdFilter',
    state: [],
    nameOrder: 'supplierOrderRule',
    stateOrder: '',
  },
  {
    label: 'Производитель',
    name: 'producerNameFilter',
    state: [],
    nameOrder: 'producerNameOrderRule',
    stateOrder: '',
  },
  {
    label: 'Страна',
    name: 'producerCountryFilter',
    state: [],
    nameOrder: 'producerCountryOrderRule',
    stateOrder: '',
  },
  {
    label: 'Срок годности',
    name: 'expirationDateFilter',
    state: { expirationDateFilterStart: null, expirationDateFilterEnd: null },
    nameOrder: 'expirationDateOrderRule',
    stateOrder: '',
  },
  {
    label: 'Цена',
    name: 'priceFilter',
    state: { priceFilterMin: MIN_RANGE, priceFilterMax: MAX_RANGE },
    nameOrder: 'priceOrderRule',
    stateOrder: '',
  },
  {
    label: 'Маркетинговые мероприятия',
    name: 'marketingEventTypesFilter',
    state: [],
    nameOrder: 'marketingEventTypesOrderRule',
    stateOrder: '',
  },
]

interface IMergedOptions {
  label: string
  name: string
  state: any
  nameOrder: string
  stateOrder: string | number
}
