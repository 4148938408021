import React from 'react'

import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  SxProps,
  Theme,
} from '@mui/material'

import { ISupplierListPL } from '../../types/supplierListTypes'

const priceListNameStyle: SxProps<Theme> = {
  width: '100%',
  pr: 1,
  paddingY: '2px',
  fontWeight: 600,
  fontSize: 14,
  color: 'primary.main',
  textDecoration: 'unset',
  wordBreak: 'break-word',
}

interface ISupplierPriceListProps {
  priceListTypes: ISupplierListPL[]
  handleCheckboxChange: (priceListId: number, checked: boolean) => void
}

const SupplierPriceList = ({
  priceListTypes,
  handleCheckboxChange,
}: ISupplierPriceListProps) => {
  return (
    <List
      sx={{ maxWidth: '100%', margin: '0 auto 15px auto' }}
      aria-labelledby="supplier price-list"
    >
      {priceListTypes.map((priceList) => {
        return (
          <ListItem
            key={priceList.id}
            sx={{
              '&:hover': {
                backgroundColor: 'action.selected',
              },
            }}
            disablePadding
          >
            <ListItemIcon
              sx={{
                ml: 1,
                minWidth: '31px',
              }}
            >
              <Checkbox
                edge="start"
                id={String(priceList.id)}
                onChange={(event) =>
                  handleCheckboxChange(priceList.id, event.target.checked)
                }
                checked={priceList.isActiveInAutoOrder}
              />
            </ListItemIcon>
            <Box sx={priceListNameStyle}>{priceList.name}</Box>
          </ListItem>
        )
      })}
    </List>
  )
}

export default SupplierPriceList
