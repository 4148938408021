import React, { useRef } from 'react'

// mui imports
import { Box, Checkbox } from '@mui/material'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

// styled
import { TextFieldCustom } from '../../shared/components/styled/TextFieldCustom'

// mui assets
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'

// project imports
import CustomList from './components/CustomList'
import BackToLastPage from '../../shared/components/BackToLastPage'

// redux
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { useActions } from '../../shared/hooks/useActions'

// service
import { onlyNotEmpty } from '../../shared/services/commonService'

// third party
import qs from 'qs'

const OthersAppBar = () => {
  const {
    setOtherFilter,
    setOtherCheckboxs,
    startOtherSearching,
    resetOtherFilter,
  } = useActions()

  const filter = useTypedSelector((state) => state.other.filter)

  const productTitle = qs.parse(
    window.location.search.substring(1)
  ).productTitle

  const esName = qs.parse(window.location.search.substring(1)).esName
  const inputContainer = useRef({})
  const checkboxs = useTypedSelector((state) => state.other.checkboxs)
  const resetField = (key: string) => () => setOtherFilter({ [key]: '' })

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) =>
    event.key === 'Enter' && startOtherSearching()

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name
    if (!event.target.checked) {
      const newFilters = onlyNotEmpty(filter)
      inputContainer.current = { ...inputContainer.current, ...newFilters }
      setOtherFilter({ [key]: '' })
    } else {
      setOtherFilter({
        [key]:
          inputContainer.current[key as keyof typeof inputContainer.current],
      })
    }
    setOtherCheckboxs({ [key]: event.target.checked })
  }

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name
    const value = event.target.value
    setOtherFilter({ [key]: value })
  }

  const handleChangeList = (event: any) => {
    const value = event.target.innerText
    setOtherFilter({ name: value })
  }

  const handleResetFilter = () => {
    resetOtherFilter()
    window.location.reload()
  }

  return (
    <Box
      sx={{
        // position: 'sticky',
        // top: '65px',
        // zIndex: 2,
        backgroundColor: 'background.paper',
        pb: 2,
      }}
    >
      <BackToLastPage
        text={`Другие товары для ${productTitle ? productTitle : 'Без имени'}`}
      />

      <Stack
        direction="row"
        justifyContent="start"
        alignItems="stretch"
        spacing={3}
        marginBottom={3}
      >
        <Box>
          {esName ? (
            <>
              <Checkbox
                size="small"
                name="esName"
                checked={checkboxs.esName}
                onChange={handleChangeCheckBox}
              />
              <CustomList
                label="УC наименование товара"
                name="esName"
                value={filter.esName || ''}
                disabled={!checkboxs.esName}
                handleChangeFilter={handleChangeFilter}
                handleKeyPress={handleKeyPress}
                resetField={resetField}
                handleChangeList={handleChangeList}
              />
            </>
          ) : (
            <>
              <Checkbox
                size="small"
                name="name"
                checked={checkboxs.name}
                onChange={handleChangeCheckBox}
              />
              <CustomList
                label="Наименование товара"
                name="name"
                value={filter.name || ''}
                disabled={!checkboxs.name}
                handleChangeFilter={handleChangeFilter}
                handleKeyPress={handleKeyPress}
                resetField={resetField}
                handleChangeList={handleChangeList}
              />
            </>
          )}
        </Box>

        <Box>
          <Checkbox
            size="small"
            name="esCode"
            checked={checkboxs.esCode}
            onChange={handleChangeCheckBox}
          />
          <TextFieldCustom
            onKeyPress={handleKeyPress}
            label="Код УС"
            name="esCode"
            value={filter.esCode}
            variant="outlined"
            size="small"
            onChange={handleChangeFilter}
            disabled={!checkboxs.esCode}
            sx={{ width: 225 }}
            InputProps={{
              endAdornment: (
                <>
                  {filter.esCode && (
                    <IconButton size="small" onClick={resetField('esCode')}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
        </Box>

        <Box>
          <Checkbox
            size="small"
            name="internationalName"
            checked={checkboxs.internationalName}
            onChange={handleChangeCheckBox}
          />
          <TextFieldCustom
            onKeyPress={handleKeyPress}
            label="МНН"
            name="internationalName"
            value={filter.internationalName}
            variant="outlined"
            size="small"
            onChange={handleChangeFilter}
            disabled={!checkboxs.internationalName}
            sx={{ width: 225 }}
            InputProps={{
              endAdornment: (
                <>
                  {filter.internationalName && (
                    <IconButton
                      size="small"
                      onClick={resetField('internationalName')}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
        </Box>

        <Button
          variant="contained"
          sx={{ padding: '6px 44px' }}
          onClick={startOtherSearching}
        >
          Поиск
        </Button>
        <Button
          onClick={handleResetFilter}
          variant="outlined"
          sx={{ padding: '6px 44px' }}
        >
          {' '}
          Сброс
        </Button>
      </Stack>
    </Box>
  )
}

export default OthersAppBar
