import { OtherAction, OtherActionTypes, OtherState } from './otherReduxTypes'
import { OTHERS } from '../../consts/others'
import { OtherItemSaved } from '../../types/otherTypes'
import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'

const initialFilter = {
  name: '',
  esName: '',
  internationalName: '',
  esCode: '',
  departmentId: '',
}

const initialCheckboxs = {
  name: true,
  esName: true,
  internationalName: true,
  esCode: true,
}

const initialState: OtherState = {
  selectedOtherItem: {} as OtherItemSaved,
  enough: false,
  direction: LoadDirections.DOWN,
  loading: false,
  error: {} as ErrorInfo,
  page: 1,
  limit: 30,
  total: 30,
  filter: initialFilter,
  checkboxs: initialCheckboxs,
  products: [],
  startSearch: false,
  orderBy: 'name',
  order: 'asc',
}

export const otherReducer = (
  state = initialState,
  action: OtherAction
): OtherState => {
  switch (action.type) {
    case OtherActionTypes.START_PRODUCT_SEARCH:
      return {
        ...state,
        products: [],
        page: 1,
        enough: false,
        startSearch: !state.startSearch,
      }
    case OtherActionTypes.SET_FILTER_PRODUCTS:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload },
      }

    case OtherActionTypes.RESET_CHECKBOXS:
      return {
        ...state,
        checkboxs: initialCheckboxs,
      }

    case OtherActionTypes.SET_CHECKBOX_PRODUCTS:
      return {
        ...state,
        checkboxs: { ...state.checkboxs, ...action.payload },
      }

    
    case OtherActionTypes.RESET_FILTER_PRODUCTS:
      return {
        ...state,
        filter: initialFilter,
        products: [],
        page: 1,
        startSearch: !state.startSearch, // TODO check

      }

    case OtherActionTypes.FETCH_PRODUCTS_SUCCESS:
      const { content, loadFlag } = action.payload
      
      const productsArray = loadFlag
      ? [...state.products, ...content.data]
      : [...content.data, ...state.products]
      
      // удаляем дубликаты
      const productFilteredArray = productsArray.filter(
        (v, i, a) => a.findIndex((t) => t.product.id === v.product.id) === i
      )

      const productSlicedArray = loadFlag
        ? productFilteredArray.slice(-OTHERS.MAX_PRODUCT_ITEMS)
        : productFilteredArray.slice(0, OTHERS.MAX_PRODUCT_ITEMS)        
      return {
        ...state,
        products: productSlicedArray,
        error: {},
        total: content.total,
        direction: loadFlag ? LoadDirections.DOWN : LoadDirections.UP,
      }
    case OtherActionTypes.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case OtherActionTypes.SET_PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case OtherActionTypes.SET_PRODUCT_SORT:
      const order = action.payload.order
      const orderBy = action.payload.orderBy
      const newOrder = order === 'asc' ? '+' : '-'
      return {
        ...state,
        filter: { ...state.filter, order: newOrder + orderBy },
        products: [],
        page: 1,
        enough: false,
        startSearch: !state.startSearch,
        orderBy,
        order,
      }

    case OtherActionTypes.FETCH_PRODUCTS_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }

    case OtherActionTypes.SAVE_OTHER_ITEM:
      return {
        ...state,
        selectedOtherItem: action.payload,
      }

    default:
      return state
  }
}
