import React from 'react'

// mui imports
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import SendIcon from '@mui/icons-material/Send'

// styled
import { TextFieldCustom } from '../../../shared/components/styled/TextFieldCustom'

// third lib
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { DateRangePicker } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'

// project imports
import DepartmentAutoComplete from '../../../shared/components/autoCompletes/DepartmentAutoComplete'
import SupplierAutoComplete from '../../../shared/components/autoCompletes/SupplierAutoComplete'
import BackToLastPage from '../../../shared/components/BackToLastPage'
import StatusAutoComplete from '../../../shared/components/autoCompletes/StatusAutoComplete'

// actions
import { useActions } from '../../../shared/hooks/useActions'

// selectors
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { IOptionsProps } from '../../../shared/types/types'

// const
const style = { width: 160, flexShrink: 0 }

const HistoryAppBar = () => {
  const filter = useTypedSelector((state) => state.newHistory.filter)
  const claims = useTypedSelector((state) => state.auth.claims)

  const statusValue = filter['statusIdFilter'][0] || null

  const total = useTypedSelector((state) => state.newHistory.total)
  const isShowNoted = useTypedSelector((state) => state.newHistory.isShowNoted)
  const isShowErrors = useTypedSelector(
    (state) => state.newHistory.isShowErrors
  )
  const products = useTypedSelector((state) => state.newHistory.products)

  const {
    resetNewHistoryFilter,
    handleNewHistoryInputs,
    handleNewHistoryAutoComplete,
    handleNewHistoryComboBox,
    handleNewHistoryTime,
    startNewHistorySearching,
    showNewHistoryNoted,
    sendNewHistoryRequest,
    showNewHistoryErrorModal,
    setNewHistoryFilter,
    showNewHistoryErrors,
  } = useActions()

  const resetPage = () => {
    resetNewHistoryFilter()
  }

  const showErrors = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    showNewHistoryErrors(e.target.checked)
    setNewHistoryFilter({ isError: checked ? true : null })
    startNewHistorySearching()
  }

  const sendOrder = () => {
    sendNewHistoryRequest()
  }

  return (
    <Box
      sx={{
        // position: 'sticky',
        // top: '65px',
        // zIndex: 2,
        backgroundColor: 'background.paper',
        mb: 1,
      }}
    >
      <BackToLastPage text={'История заказов'} />

      <Stack
        direction="row"
        justifyContent="start"
        columnGap={2}
        marginBottom={1}
      >
        <TextFieldCustom
          // onKeyPress={handleKeyPress}
          label="Номер"
          name="orderIdRangeFilter"
          variant="outlined"
          size="small"
          value={filter['orderIdRangeFilter']}
          onChange={handleNewHistoryInputs}
        />

        <LocalizationProvider dateAdapter={DateAdapter} locale={ruLocale}>
          <DateRangePicker
            startText="Начало заказа"
            endText="Конец заказа"
            value={[filter['startDate'], filter['endDate']]}
            mask="__.__.____"
            onChange={(newValue: any) => {
              handleNewHistoryTime(newValue)
            }}
            renderInput={(startProps: any, endProps: any) => (
              <React.Fragment>
                <TextFieldCustom
                  {...startProps}
                  inputProps={{
                    ...startProps.inputProps,
                    placeholder: 'дд/мм/гггг',
                  }}
                  variant="outlined"
                  size="small"
                />
                <Box sx={{ mx: 2 }}> до </Box>
                <TextFieldCustom
                  {...endProps}
                  inputProps={{
                    ...endProps.inputProps,
                    placeholder: 'дд/мм/гггг',
                  }}
                  variant="outlined"
                  size="small"
                />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>

        <TextFieldCustom
          // onKeyPress={handleKeyPress}
          label="Наименование товара"
          name="productNameFilter"
          variant="outlined"
          size="small"
          value={filter['productNameFilter']}
          onChange={handleNewHistoryInputs}
        />

        <React.Fragment>
          <Box sx={style}>
            <DepartmentAutoComplete
              value={filter['departmentIdFilter']}
              isCheckBoxType={true}
              variant={'multiple'}
              handleAutoCompleteFields={(val: IOptionsProps[] | null) =>
                handleNewHistoryAutoComplete(val, 'departmentIdFilter')
              }
            />
          </Box>
        </React.Fragment>

        <React.Fragment>
          <Box sx={style}>
            <SupplierAutoComplete
              value={filter['supplierIdFilter']}
              isCheckBoxType={true}
              variant={'multiple'}
              handleAutoCompleteFields={(val) =>
                handleNewHistoryAutoComplete(val, 'supplierIdFilter')
              }
            />
          </Box>
        </React.Fragment>

        <React.Fragment>
          <StatusAutoComplete
            value={statusValue}
            handleAutoCompleteFields={(val) =>
              handleNewHistoryComboBox(val, 'statusIdFilter')
            }
          />
        </React.Fragment>

        <Button
          variant="contained"
          sx={{ flexShrink: 0, fontSize: '0.775rem', maxHeight: 40 }}
          onClick={() => startNewHistorySearching()}
        >
          Поиск
        </Button>
        <Button
          onClick={resetPage}
          variant="outlined"
          sx={{ flexShrink: 0, fontSize: '0.775rem', maxHeight: 40 }}
        >
          Сбросить
        </Button>

        <Button
          disabled={
            !claims['promarket.order.create'] &&
            !claims['promarket.order.send_supplier']
          }
          variant="contained"
          sx={{ flexShrink: 0, fontSize: '0.775rem', maxHeight: 40 }}
          endIcon={<SendIcon />}
          onClick={sendOrder}
        >
          Отправить заказ
        </Button>
      </Stack>

      <FormControlLabel
        control={
          <Checkbox
            sx={{
              ml: 3,
            }}
            name="onlyMyOrders"
            checked={filter['onlyMyOrders']}
            onChange={handleNewHistoryInputs}
          />
        }
        label={
          <Typography
            sx={{ fontSize: '14px', fontWeight: '400', color: '#5F5F5F' }}
          >
            Мои заказы
          </Typography>
        }
      />

      <FormControlLabel
        control={
          <Checkbox
            sx={{
              color: '#d50505',
              '&.Mui-checked': {
                color: 'darkred',
              },
              ml: 3,
            }}
            name="showErrors"
            checked={isShowErrors}
            onChange={showErrors}
          />
        }
        label={
          <Typography
            sx={{ fontSize: '14px', fontWeight: '400', color: '#d50505' }}
          >
            Показать ошибки
          </Typography>
        }
      />

      <FormControlLabel
        control={
          <Checkbox
            sx={{ ml: 3 }}
            name="showNoted"
            checked={isShowNoted}
            onChange={(e) => showNewHistoryNoted(e.target.checked)}
          />
        }
        label={
          <Typography
            sx={{ fontSize: '14px', fontWeight: '400', color: '#5F5F5F' }}
          >
            Показать отмеченные
          </Typography>
        }
      />

      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: '400',
          color: '#5F5F5F',
          display: 'inline-flex',
        }}
      >
        Всего заказов:
      </Typography>

      <Box display="inline-flex" fontSize="600" ml="5px">
        {total}
      </Box>
    </Box>
  )
}

export default HistoryAppBar
