import React from "react"

import { Route, Switch as RouterSwitch } from "react-router-dom"
import { privateRoutes } from "./routes/routes"

const PrivateRouter = () => {
    return (
        <RouterSwitch>
            {privateRoutes.map((route) => (
                <Route
                    component={route.component}
                    path={route.path}
                    exact={route.exact}
                    key={route.path}
                />
            ))}
        </RouterSwitch>
    )
}

export default PrivateRouter
