export interface ModalState {
  active: boolean
  modalType: null | string
}

export enum ModalActionTypes {
  ALTERNATIVE_PRODUCT_MODAL = 'ALTERNATIVE_PRODUCT_MODAL',
  BASKET_MODAL = 'BASKET_MODAL',
  SUPPLIER_BASKET_MODAL = 'SUPPLIER_BASKET_MODAL',
  MODAL_DISABLE = 'MODAL_DISABLE',
  BASKET_MODAL_CONFIRM = 'BASKET_MODAL_CONFIRM',
  SIDE_FILTERS_MODAL = 'SIDE_FILTERS_MODAL',
  PRODUCT_ITEM_MODAL = 'PRODUCT_ITEM_MODAL',
  COPY_ORDER_MODAL = 'COPY_ORDER_MODAL',
  BONUS_MODAL = 'BONUS_MODAL',
  NEW_HISTORY_ERROR_MODAL = 'NEW_HISTORY_ERROR_MODAL',
  NEW_HISTORY_DELETE_MODAL = 'NEW_HISTORY_DELETE_MODAL',
  SALE_DEPARTMENTS_MODAL = 'SALE_DEPARTMENTS_MODAL',
  STOP_LIST_MODAL = 'STOP_LIST_MODAL',
  UNION_DEPARTMENT_MODAL = 'UNION_DEPARTMENT_MODAL',
  RESET_SUPPLIER_SETTINGS_MODAL = 'RESET_SUPPLIER_SETTINGS_MODAL',
  AUTO_ORDER_SET_FIELDS_MODAL = 'AUTO_ORDER_SET_FIELDS_MODAL',
  AUTO_ORDER_DELETE_MODAL = 'AUTO_ORDER_DELETE_MODAL',
  SUPPLIER_LIST_MODAL = 'SUPPLIER_LIST_MODAL',
  DELETED_POSITION_MODAL = 'DELETED_POSITION_MODAL',
  APPLICATION_ERROR_DETAIL_INFO_MODAL = 'APPLICATION_ERROR_DETAIL_INFO_MODAL'
}

export type ModalAction = {
  type:
    | ModalActionTypes.BASKET_MODAL
    | ModalActionTypes.SUPPLIER_BASKET_MODAL
    | ModalActionTypes.MODAL_DISABLE
    | ModalActionTypes.BASKET_MODAL_CONFIRM
    | ModalActionTypes.SIDE_FILTERS_MODAL
    | ModalActionTypes.PRODUCT_ITEM_MODAL
    | ModalActionTypes.ALTERNATIVE_PRODUCT_MODAL
    | ModalActionTypes.COPY_ORDER_MODAL
    | ModalActionTypes.BONUS_MODAL
    | ModalActionTypes.NEW_HISTORY_ERROR_MODAL
    | ModalActionTypes.NEW_HISTORY_DELETE_MODAL
    | ModalActionTypes.SALE_DEPARTMENTS_MODAL
    | ModalActionTypes.STOP_LIST_MODAL
    | ModalActionTypes.UNION_DEPARTMENT_MODAL
    | ModalActionTypes.RESET_SUPPLIER_SETTINGS_MODAL
    | ModalActionTypes.AUTO_ORDER_SET_FIELDS_MODAL
    | ModalActionTypes.AUTO_ORDER_DELETE_MODAL
    | ModalActionTypes.SUPPLIER_LIST_MODAL
    | ModalActionTypes.DELETED_POSITION_MODAL
    | ModalActionTypes.APPLICATION_ERROR_DETAIL_INFO_MODAL
}
