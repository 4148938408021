import { Order } from '../../../../../../shared/services/commonService'
import { ErrorInfo, LoadDirections } from '../../../../../../shared/types/types'
import { PriceListDeliveryFieldType } from '../../types/priceListTypes'

export interface PriceListDeliveryItems {
  supplierId: number
  supplierName: string
  departmentId: number
  departmentName: string
  deliveryDaysNumberFromMonday: number
  deliveryDaysNumberFromTuesday: number
  deliveryDaysNumberFromWednesday: number
  deliveryDaysNumberFromThursday: number
  deliveryDaysNumberFromFriday: number
  deliveryDaysNumberFromSaturday: number
  deliveryDaysNumberFromSunday: number
}

export interface PriceListDeliveryState {
  enough: boolean
  direction: LoadDirections.DOWN | LoadDirections.UP
  error: ErrorInfo | {}
  loading: boolean
  page: number
  limit: number
  total: number
  filter: Object
  startDate: any
  endDate: any
  daysDiffInput: number
  isCheckedAll: boolean
  checkedItems: number[]
  currentTabDay: number
  defaultDays: any
  shallowCopyProducts: PriceListDeliveryItems[]
  shallowCopyDefaultDays: any
  deliveryMode: number
  products: PriceListDeliveryItems[]
  orderBy: PriceListDeliveryFieldType
  order: Order
}

export enum PriceListDeliveryActionTypes {
  SET_FILTER_PRODUCTS = 'SET_FILTER_PRICE_LIST_DELIVERY',
  RESET_FILTER_PRODUCTS = 'RESET_FILTER_PRICE_LIST_DELIVERY',
  FETCH_PRODUCTS = 'FETCH_PRICE_LIST_DELIVERY',
  FETCH_PRODUCTS_SUCCESS = 'FETCH_PRICE_LIST_DELIVERY_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_PRICE_LIST_DELIVERY_ERROR',
  SET_PRODUCT_PAGE = 'SET_PRICE_LIST_DELIVERY_PAGE',
  FETCH_PRODUCTS_ENOUGH = 'FETCH_PRICE_LIST_DELIVERY_ENOUGH',
  SET_PRODUCT_SORT = 'SET_PRICE_LIST_DELIVERY_SORT',
  LOADING_ENABLE = 'LOADING_PRICE_LIST_DELIVERY_ENABLE',
  LOADING_DISABLE = 'LOADING_PRICE_LIST_DELIVERY_DISABLE',
  SET_CURRENT_DELIVERY_TAB_DAY = 'SET_CURRENT_DELIVERY_TAB_DAY',
  SET_CHECKED_ITEMS = 'SET_PL_DELIVERY_CHECKED_ITEMS',
  ADD_CHECKED_ITEMS = 'ADD_PL_DELIVERY_CHECKED_ITEMS',
  UPDATE_DAYS_COUNT_MODEL = 'UPDATE_PL_DELIVERY_DAYS_COUNT_MODEL',
  SET_IS_CHECKED_ALL = 'SET_PL_DELIVERY_IS_CHECKED_ALL',
  RESET_PL_DELIVERY_TABLE_STATE = 'RESET_PL_DELIVERY_TABLE_STATE',
  SET_PL_DELIVERY_MODE = 'SET_PL_DELIVERY_MODE',
  SET_PL_DELIVERY_DEFAULT_DAYS = 'SET_PL_DELIVERY_DEFAULT_DAYS',
  UPDATE_PL_DELIVERY_DEFAULT_DAYS = 'UPDATE_PL_DELIVERY_DEFAULT_DAYS',
  SET_START_DATE_PL_DELIVERY = 'SET_START_DATE_PL_DELIVERY',
  SET_END_DATE_PL_DELIVERY = 'SET_END_DATE_PL_DELIVERY',
  SET_DIFF_DAYS_PL_DELIVERY_INPUT = 'SET_DIFF_DAYS_PL_DELIVERY_INPUT',
  RESET_PL_DELIVERY_CALENDAR_DEFAULT_STATE = 'RESET_PL_DELIVERY_CALENDAR_DEFAULT_STATE',
  RESET_PL_DELIVERY_CALENDAR_DEP_STATE = 'RESET_PL_DELIVERY_CALENDAR_DEP_STATE',
}

interface SetDiffDatePLDeliveryInputAction {
  type: PriceListDeliveryActionTypes.SET_DIFF_DAYS_PL_DELIVERY_INPUT
  payload: number
}

interface SetDiffDatePLDeliveryInputAction {
  type: PriceListDeliveryActionTypes.SET_DIFF_DAYS_PL_DELIVERY_INPUT
  payload: number
}

interface SetStartDatePLDeliveryAction {
  type: PriceListDeliveryActionTypes.SET_START_DATE_PL_DELIVERY
  payload: Date
}

interface SetEndDatePLDeliveryAction {
  type: PriceListDeliveryActionTypes.SET_END_DATE_PL_DELIVERY
  payload: Date
}

interface UpdatePriceListDeliveryDefaultDaysAction {
  type: PriceListDeliveryActionTypes.UPDATE_PL_DELIVERY_DEFAULT_DAYS
  payload: number
}

interface SetPriceListDeliveryDefaultDaysAction {
  type: PriceListDeliveryActionTypes.SET_PL_DELIVERY_DEFAULT_DAYS
  payload: any
}

interface ResetPriceListDeliveryTableStateAction {
  type: PriceListDeliveryActionTypes.RESET_PL_DELIVERY_TABLE_STATE
}

interface ResetPriceListDeliveryCalendarDefaultStateAction {
  type: PriceListDeliveryActionTypes.RESET_PL_DELIVERY_CALENDAR_DEFAULT_STATE
}

interface ResetPriceListDeliveryCalendarDepStateAction {
  type: PriceListDeliveryActionTypes.RESET_PL_DELIVERY_CALENDAR_DEP_STATE
}

interface SelectAllPriceListDeliveryAction {
  type: PriceListDeliveryActionTypes.SET_IS_CHECKED_ALL
}

interface SetPriceListDeliveryModeAction {
  type: PriceListDeliveryActionTypes.SET_PL_DELIVERY_MODE
  payload: number
}

interface SetDaysDiffPriceListDeliveryAction {
  type: PriceListDeliveryActionTypes.UPDATE_DAYS_COUNT_MODEL
  payload: number
}

interface FetchPriceListDeliveryEnoughAction {
  type: PriceListDeliveryActionTypes.FETCH_PRODUCTS_ENOUGH
  payload: boolean
}

interface SetFilterPriceListDeliveryAction {
  type: PriceListDeliveryActionTypes.SET_FILTER_PRODUCTS
  payload: Object
}

interface SetPriceListDeliveryTabAction {
  type: PriceListDeliveryActionTypes.SET_CURRENT_DELIVERY_TAB_DAY
  payload: number
}

interface ResetFilterPriceListDeliveryAction {
  type: PriceListDeliveryActionTypes.RESET_FILTER_PRODUCTS
}

interface FetchPriceListDeliveryAction {
  type: PriceListDeliveryActionTypes.FETCH_PRODUCTS
}

export interface FetchPriceListDeliverySuccessAction {
  type: PriceListDeliveryActionTypes.FETCH_PRODUCTS_SUCCESS
  payload: { content: any; loadFlag: boolean }
}

export interface FetchPriceListDeliveryErrorAction {
  type: PriceListDeliveryActionTypes.FETCH_PRODUCTS_ERROR
  payload: ErrorInfo | {}
}

interface SetPriceListDeliveryPage {
  type: PriceListDeliveryActionTypes.SET_PRODUCT_PAGE
  payload: number
}

export interface IPriceListDeliveryPayload {
  orderBy: PriceListDeliveryFieldType
  order: Order
}

interface SetPriceListDeliverySort {
  type: PriceListDeliveryActionTypes.SET_PRODUCT_SORT
  payload: IPriceListDeliveryPayload
}

export interface LoadingPriceListDeliveryEnableAction {
  type: PriceListDeliveryActionTypes.LOADING_ENABLE
}

export interface LoadingPriceListDeliveryDisableAction {
  type: PriceListDeliveryActionTypes.LOADING_DISABLE
}

interface SetCheckedItemsPriceListDeliveryAction {
  type: PriceListDeliveryActionTypes.SET_CHECKED_ITEMS
  payload: number[]
}

interface AddCheckedItemsPriceListDeliveryAction {
  type: PriceListDeliveryActionTypes.ADD_CHECKED_ITEMS
  payload: number[]
}

export type PriceListDeliveryAction =
  | FetchPriceListDeliveryEnoughAction
  | SetFilterPriceListDeliveryAction
  | ResetFilterPriceListDeliveryAction
  | FetchPriceListDeliveryAction
  | FetchPriceListDeliverySuccessAction
  | FetchPriceListDeliveryErrorAction
  | SetPriceListDeliveryPage
  | SetPriceListDeliverySort
  | LoadingPriceListDeliveryEnableAction
  | LoadingPriceListDeliveryDisableAction
  | SetPriceListDeliveryTabAction
  | SetCheckedItemsPriceListDeliveryAction
  | AddCheckedItemsPriceListDeliveryAction
  | SetDaysDiffPriceListDeliveryAction
  | SelectAllPriceListDeliveryAction
  | ResetPriceListDeliveryTableStateAction
  | SetPriceListDeliveryModeAction
  | SetPriceListDeliveryDefaultDaysAction
  | UpdatePriceListDeliveryDefaultDaysAction
  | SetStartDatePLDeliveryAction
  | SetEndDatePLDeliveryAction
  | SetDiffDatePLDeliveryInputAction
  | ResetPriceListDeliveryCalendarDefaultStateAction
  | ResetPriceListDeliveryCalendarDepStateAction
