import React, { ChangeEvent, useState } from 'react'

import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from '@mui/material'

import { TypographyStyled } from '../../../../Sale/styled/saleStyled'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import { useActions } from '../../../../../shared/hooks/useActions'
import { IDepartmentProps } from '../types/supplierSettingsTypes'

const minSupplierOrderMinimumCostPercent = 0
const maxSupplierOrderMinimumCostPercent = 100

const SupplierParametrsTab = ({
  departmentValue,
}: {
  departmentValue: IDepartmentProps | null
}) => {
  const {
    changeSupplierSettingsCheckBoxsAction,
    setSupplierOrderMinimumCostPercentSettingsAction,
  } = useActions()

  const departmentId = useTypedSelector(
    (state) => state.supplierSettings.departmentId
  )

  const considerMultiplicityOfBoxQuantity = useTypedSelector(
    (state) => state.supplierSettings.considerMultiplicityOfBoxQuantity // Учитывать коэффициент доступного количества
  )

  const considerMinimumQuantity = useTypedSelector(
    (state) => state.supplierSettings.considerMinimumQuantity // Учитывать минимальное количество
  )

  const distributeSupplierOrderIfCostLessThanMinimumCost = useTypedSelector(
    (state) =>
      state.supplierSettings.distributeSupplierOrderIfCostLessThanMinimumCost // Распределить заказ поставщика, если стоимость заказа меньше минимальной
  )

  const supplierOrderMinimumCostPercentage = useTypedSelector(
    (state) => state.supplierSettings.supplierOrderMinimumCostPercentage // Процент от минимальной стоимости заказа у поставщика
  )

  const handleSupplierSettingsCheckboxs = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    changeSupplierSettingsCheckBoxsAction(e)
  }

  const [minCostPercentage, setMinCostPercentage] = useState(
    () => supplierOrderMinimumCostPercentage
  )

  const handleSupplierOrderMinimumCostPercentInput = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const value = Math.max(
      minSupplierOrderMinimumCostPercent,
      Math.min(maxSupplierOrderMinimumCostPercent, Number(e.target.value))
    )
    setMinCostPercentage(value)
    setSupplierOrderMinimumCostPercentSettingsAction(String(value))
  }

  return (
    <>
      <Stack>
        <FormControlLabel
          control={
            <Checkbox
              disabled={departmentValue === null}
              onChange={handleSupplierSettingsCheckboxs}
              name="considerMultiplicityOfBoxQuantity"
              checked={considerMultiplicityOfBoxQuantity}
            />
          }
          label={<TypographyStyled>Учитывать кратность</TypographyStyled>}
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={departmentValue === null}
              onChange={handleSupplierSettingsCheckboxs}
              name="considerMinimumQuantity"
              checked={considerMinimumQuantity}
            />
          }
          label={
            <TypographyStyled>Учитывать мин. количество </TypographyStyled>
          }
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={departmentValue === null}
              onChange={handleSupplierSettingsCheckboxs}
              name="distributeSupplierOrderIfCostLessThanMinimumCost"
              checked={distributeSupplierOrderIfCostLessThanMinimumCost}
            />
          }
          label={
            <TypographyStyled>
              Распределить заказ поставщика (если стоимость заказа меньше мин.){' '}
            </TypographyStyled>
          }
        />

        <Box display="flex" alignItems="center">
          <TextField
            disabled={
              !distributeSupplierOrderIfCostLessThanMinimumCost ||
              departmentValue === null
            }
            type="number"
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleSupplierOrderMinimumCostPercentInput(e)
            }}
            value={minCostPercentage}
            sx={{
              width: 60,
              '& .MuiOutlinedInput-input': {
                padding: '8px 5px',
                fontSize: '0.8rem',
              },
            }}
            InputProps={{ sx: { height: 25 } }}
          />
          <TypographyStyled sx={{ ml: 1 }}>
            % от мин. стоимости заказа у поставщика
          </TypographyStyled>
        </Box>
      </Stack>
    </>
  )
}

export default SupplierParametrsTab
