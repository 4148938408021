import { styled } from '@mui/material/styles'
import { Box, Tab } from '@mui/material'
import React from 'react'

interface StyledTabProps {
  label: string
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export const CustomTab = styled((props: StyledTabProps) => <Tab {...props} />)(
  ({ theme }) => ({
    textTransform: 'inherit',
    minHeight: theme.spacing(1),
    padding: '0.5rem',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
      minHeight: theme.spacing(1),
    },
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1.1),
    fontSize: '0.75rem',
    color: theme.palette.secondary.main,
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    '&.Mui-selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      borderRadius: '8px',
      border: '1px solid',
      borderColor: theme.palette.secondary.main,
    },
  })
)

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`product-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
