import { Dispatch } from 'redux'

import { postSubAutoOrders } from '../../api/subAutoOrder'
import { AppStateType } from '../../../../shared/store'
import noResultImage from '../../../../shared/assets/images/search.png'
import { ISubAutoOrderPayload, SubAutoOrderAction, SubAutoOrderActionTypes } from './SubAutoOrderReduxTypes'
import { ApplicationAction, ApplicationActionTypes } from '../../../../shared/store/application/applicationReduxTypes'


const dispatchSubAutoOrderError = (dispatch: Dispatch<SubAutoOrderAction>) => {
  dispatch( {
    type: SubAutoOrderActionTypes.FETCH_PRODUCTS_ERROR,
    payload: { message: 'Нет данных', img: noResultImage, loadFlag: false}
  })
}

export const fetchSubAutoOrder = (
    page = 1,
    limit = 30,
    idOrder: string
) => {
  return async (dispatch: Dispatch<SubAutoOrderAction | ApplicationAction>, getState: () => AppStateType) => {
    dispatch({
      type: SubAutoOrderActionTypes.FETCH_PRODUCTS_REQUEST,
      payload: {
        loading: true,
        loadFlag: true,
      },
    })
    try {
      dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })
      const state = getState()
      const { listOrientation } = state.subAutoOrder;

      const filter = [
        {
          propertyName: "SummaryBIdId",
          operation: "In",
          values: [
            idOrder
          ]
        }
      ]

      const response = await postSubAutoOrders(page, limit, filter, listOrientation)
      const content = response?.data?.content || null
      const isEmpty = content ? content?.items?.length === 0 && page === 1 : true;

      if (isEmpty) {
        dispatchSubAutoOrderError(dispatch)
        return
      }

      // const isEnough = content?.items?.length >= limit
      const isEnough = content?.items?.length > 0

      dispatch({
        type: SubAutoOrderActionTypes.FETCH_PRODUCTS_ENOUGH,
        payload: isEnough,
      })

      dispatch({
        type: SubAutoOrderActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: { content, loadFlag: true, loading: false  }
      })
    } catch (e) {
      dispatchSubAutoOrderError(dispatch)
    } finally {
      dispatch({ type: SubAutoOrderActionTypes.LOADING_DISABLE })
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    }
  }
}

export function setSubAutoOrderPage(page: number): SubAutoOrderAction {
  const defaultPage = 1;

  if (page === 0) {
    return {type: SubAutoOrderActionTypes.SET_PRODUCT_PAGE, payload: defaultPage}
  }

  return {type: SubAutoOrderActionTypes.SET_PRODUCT_PAGE, payload: page}
}

export function setSubAutoOrderSort(
  payload: ISubAutoOrderPayload
): SubAutoOrderAction {
  return {type: SubAutoOrderActionTypes.SET_PRODUCT_SORT, payload}
}

export function resetSubAutoOrderItems(): SubAutoOrderAction {
  return {type: SubAutoOrderActionTypes.RESET_ITEMS_PRODUCTS}
}

