import React from 'react'

// mui
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import PriceListDeliveryFloatDay from './PriceListDeliveryFloatDay'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

// hooks
import { useActions } from '../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const PriceListDeliveryRow = ({ row }: { row: any }) => {
  const { handlePriceListDeliveryCheckBox } = useActions()

  const checkedItems = useTypedSelector((state) => state.priceList.checkedItems)

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        '& td,th': {
          fontSize: '0.75rem',
        },
      }}
    >
      <TableCell
        align="left"
        sx={{
          width: 10,
          padding: '5px 5px 5px 16px',
        }}
      >
        <Checkbox
          disableRipple
          id={String(row.departmentId)}
          onChange={handlePriceListDeliveryCheckBox}
          checked={checkedItems.includes(row.departmentId)}
          icon={icon}
          checkedIcon={checkedIcon}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 14 }, padding: 0 }}
        />
      </TableCell>
      <TableCell align="left">{row.departmentName}</TableCell>
      <TableCell align="center">
        <PriceListDeliveryFloatDay row={row} />
      </TableCell>
    </TableRow>
  )
}

export default PriceListDeliveryRow
