import React from 'react'
import Box from '@mui/material/Box'
import { IProduct } from '../../types/productTypes'
import FormattedDate1 from '../../../../shared/components/FormattedDate1'

const ExpDateField = ({ card }: { card: IProduct }) => {
  return (
    <Box sx={{ flexBasis: 0 }}>
      <Box
        sx={{
          overflowWrap: { xs: 'break-word', xl: 'unset' },
          textTransform: 'uppercase',
          textAlign: 'center',
          color: 'primary.main',
          fontWeight: 'bold',
          fontSize: { xs: '9px', xl: '10px' },
          mb: 1,
        }}
      >
        Срок годности
      </Box>
      <Box
        sx={{
          lineHeight: '1.5',
          textTransform: 'uppercase',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: { xs: '11px', xl: '12px' },
        }}
      >
        <FormattedDate1 date={card.expirationDate} />
      </Box>
    </Box>
  )
}

export default ExpDateField
