import React from 'react'
import { Box, Stack } from '@mui/material'
import { checkMultiplicity } from '../../../../shared/services/commonService'
import { IProduct } from '../../types/productTypes'
import ErrorIcon from '@mui/icons-material/Error'
import { RedTooltip } from '../../../../shared/components/styled/RedToolTip'

const BoxQuantityField = ({
  card,
  itemAmount,
}: {
  card: IProduct
  itemAmount: number
}) => {
  const isError = !!checkMultiplicity(itemAmount, card.boxQuantity)

  return (
    <Stack alignItems="center">
      <Box
        sx={{
          overflowWrap: { xs: 'break-word', xl: 'unset' },
          textTransform: 'uppercase',
          textAlign: 'center',
          color: 'primary.main',
          fontWeight: 'bold',
          fontSize: { xs: '9px', xl: '10px' },
          marginBottom: 1,
        }}
      >
        Кратность
      </Box>
      <Stack direction="row" alignItems="center" columnGap="2px">
        <Box
          sx={{
            ...(isError
              ? { color: 'red', ml: '11px' }
              : { color: 'unset', ml: '0' }),
            textTransform: 'uppercase',
            textAlign: 'center',
            overflowWrap: 'break-word',
            fontWeight: 'bold',
            fontSize: { xs: '11px', xl: '12px' },
          }}
        >
          {card.boxQuantity}
        </Box>

        {isError && (
          <RedTooltip title="Некратное кол-во">
            <ErrorIcon sx={{ color: 'red', fontSize: '14px' }} />
          </RedTooltip>
        )}
      </Stack>
    </Stack>
  )
}

export default BoxQuantityField
