import { useState } from "react"

export const useCollapse = () => {
  const [expanded, setExpanded] = useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return {
    expanded,
    handleExpandClick
  }
}