import http from '../../../shared/api/config/instance'
import { IProductWithInfo, IProducts } from '../types/productTypes'
import { onlyNotEmpty } from '../../../shared/services/commonService'

export const getAllCards = (
  departmentId: number | undefined,
  pageNumber: number,
  pageSize: number,
  filters: any
) => {
  const searchInFieldsFilter = filters.searchInFieldsFilter || []

  if (searchInFieldsFilter.length > 0) {
    searchInFieldsFilter.forEach((i: string) => {
      switch (i) {
        case 'country':
          delete filters.producerCountryFilter
          break
        case 'producer':
          delete filters.producerNameFilter
          break
        case 'supplier':
          delete filters.supplierIdFilter
          break
      }
    })
  }
  const newFilters = onlyNotEmpty(filters)

  return http.httpCore.get<{ 
    content: IProducts,
    // isError: boolean
    // description: any
  }>('product', {
    params: {
      departmentId,
      recommendedCount: 5,
      showMarketingEventStatuses: true,
      pageSize,
      showBasketAvailability: true,
      pageNumber,
      ...newFilters,
    },
  })

}

export const getCardById = (id: number | string, departmentId: number | undefined) =>
  http.httpCore.get<{ content: IProductWithInfo }>('product/detailed', {
    params: { id, departmentId },
  })
