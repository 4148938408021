import http from '../../../../../shared/api/config/instance'
import { IFilterProperty } from '../../DepartmentsPreOrder/types/departmentsPreOrderTypes'
import { AUTO_ORDER_FIElDS_KEY } from '../consts/autoOrderConsts'

export const getAutoOrderProducts = (
  pageNumber: number,
  pageSize: number,
  filter: IFilterProperty[],
  order: string
) =>
  http.httpCore.post('auto-order/products/filter', {
    pagination: {
      pageNumber: pageNumber,
      pageSize: pageSize,
    },
    filter,
    order,
  })

export const getAutOrderActiveKeys = () =>
  http.httpCore.get('frontend/settings/key-value')

export const setAutoOrderActiveFields = (filters: { [key: string]: boolean }) =>
  http.httpCore.post('frontend/settings/key-value/upsert', {
    keyValueSettings: [
      {
        key: AUTO_ORDER_FIElDS_KEY,
        value: JSON.stringify(filters),
      },
    ],
  })

export const getAutoOrderCost = () => http.httpCore.get('auto-order/cost')

export const putOrderQuantity = (
  newQuantityToOrder: number,
  autoOrderBidSimilarProductsGroupId: string
) =>
  http.httpCore.put('auto-order/product', {
    autoOrderBidSimilarProductsGroupId,
    newQuantityToOrder,
  })

export const saveAutoOrderProductRequest = () =>
  http.httpCore.post('auto-order/save')

export const deleteAutoOrdersProductsReq = () =>
  http.httpCore.delete('auto-order/clear')

export const postOrderApprove = (orderIds: number[]) =>
  http.httpCore.post('order-approve', {
    orderIdRange: orderIds,
  })

export const deletePosition = (id: string) =>
  http.httpCore.delete(`auto-order/similar-products-group/${id}`)
