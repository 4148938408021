import React, { useState, useEffect, SyntheticEvent } from 'react'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { getDepartment } from '../../../features/SettingsAutoOrder/components/SupplierSettings/api/supplierSettings'
import { IDepartmentProps } from '../../../features/SettingsAutoOrder/components/SupplierSettings/types/supplierSettingsTypes'

export default function AutoOrderDepartmentAutoComplete({
  value,
  handleDepartmentValue,
  handleAutoCompleteFields,
}: {
  value: IDepartmentProps | null
  handleAutoCompleteFields: (newValue: number | null) => void
  handleDepartmentValue: (newValue: IDepartmentProps | null) => void
}) {
  const [options, setOptions] = useState<IDepartmentProps[]>([])

  useEffect(() => {
    getDepartment().then((response) => {
      const items = response.data.content.items
      setOptions(items)
    })
  }, [])

  return (
    <Autocomplete
      value={value}
      size="small"
      sx={{
        width: '230px',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#B5C3DC',
        },
        '& .MuiInputLabel-formControl': {
          fontSize: '0.875rem',
          mt: '2px',
        },
      }}
      onChange={(
        event: SyntheticEvent<Element, Event>,
        newValue: IDepartmentProps | null
      ) => {
        handleDepartmentValue(newValue)
        if (newValue !== null) {
          handleAutoCompleteFields(newValue.id)
        }
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.alias}
      options={options}
      renderInput={(params) => <TextField {...params} label="Подразделение" />}
    />
  )
}
