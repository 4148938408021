import { Dispatch } from "redux"

import { AppStateType } from "../../../../../../shared/store"
import { ApplicationAction, ApplicationActionTypes } from "../../../../../../shared/store/application/applicationReduxTypes"

import { IProcessingAutoOrderPayload, ProcessingAutoOrderAction, ProcessingAutoOrderActionTypes } from "./processingAutoOrderReduxTypes"
import { deleteProcessingAutoOrder, postProcessingAutoOrder } from "../../api/settingsAutoOrder"

//project imports
import noResultImage from '../../../../../../shared/assets/images/search.png'

const dispatchProcessingAutoOrderError = (dispatch: Dispatch<ProcessingAutoOrderAction>) => {
  dispatch( {
    type: ProcessingAutoOrderActionTypes.FETCH_PRODUCTS_ERROR,
    payload: { message: 'Нет данных', img: noResultImage, loadFlag: false}
  })
}

export const fetchProcessingAutoOrder = (
    currentPage = 1,
    limit = 30,
) => {
  return async (dispatch: Dispatch<ProcessingAutoOrderAction | ApplicationAction>, getState: () => AppStateType) => {
    dispatch({
      type: ProcessingAutoOrderActionTypes.FETCH_PRODUCTS_REQUEST,
      payload: {
        loading: true,
        loadFlag: true
      },
    })

    try {
      dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })
      const state = getState()
      const { listOrientation } = state.processingAutoOrder;

      const response = await postProcessingAutoOrder(currentPage, limit, listOrientation)
      const content = response?.data?.content || null
      const isEmpty = content ? content?.items?.length === 0 && currentPage === 1 : true;

      if (isEmpty) {
        dispatchProcessingAutoOrderError(dispatch)
        return
      }

      const isEnough = content?.items?.length > 0

      dispatch({
        type: ProcessingAutoOrderActionTypes.FETCH_PRODUCTS_ENOUGH,
        payload: isEnough,
      })

      dispatch({
        type: ProcessingAutoOrderActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: { content, loadFlag: true, loading: false }
      })
    
    } catch (e) {
      dispatchProcessingAutoOrderError(dispatch)
    } finally {
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    }
  }
}

export function setProcessingAutoOrderPage(page: number): ProcessingAutoOrderAction {
  const defaultPage = 1;

  if (page === 0) {
    return {type: ProcessingAutoOrderActionTypes.SET_PRODUCT_PAGE, payload: defaultPage}
  }

  return {type: ProcessingAutoOrderActionTypes.SET_PRODUCT_PAGE, payload: page}
}

export function setProcessingAutoOrderSort(
  payload: IProcessingAutoOrderPayload
): ProcessingAutoOrderAction {
  return {type: ProcessingAutoOrderActionTypes.SET_PRODUCT_SORT, payload}
}

export function resetProccesingAutoOrderItems(): ProcessingAutoOrderAction{
  return {type: ProcessingAutoOrderActionTypes.RESET_ITEMS_PRODUCTS}
}

export function setResetProccesingAutoOrderItemsFlag(isResetItemsEnd: boolean): ProcessingAutoOrderAction{
  return {type: ProcessingAutoOrderActionTypes.SET_RESET_ITEMS_END_FLAG, payload: isResetItemsEnd}
}

export const sendProccesingAutoOrderDeleteRequest = async (id: string[]) => {
  try {
    await deleteProcessingAutoOrder(id)
  } catch(e) {
    console.error(e)
  }
};



