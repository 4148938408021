import React, { FC } from 'react'
import { InputAdornment, Stack } from '@mui/material'
import { TextFieldNoPaddings } from '../../../../../../shared/components/styled/TextFieldNoPaddings'
import Dash from '../../../../UI/Dash'
import SvgAlert from '../../../../../../shared/components/UI/svgIcons/SvgAlert'

interface RangeInputProps {
  value1: number[]
  handleRangeChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const RangeInputs: FC<RangeInputProps> = ({ value1, handleRangeChange }) => {
  const numberComparison = () => value1[1] < value1[0]

  return (
    <>
      <Stack direction="row" alignItems="center" columnGap="3px">
        <TextFieldNoPaddings
          value={value1[0]}
          onChange={handleRangeChange}
          variant="outlined"
          size="small"
          name="leftRange"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">от</InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">₽</InputAdornment>,
          }}
        />
        <Dash />
        <TextFieldNoPaddings
          value={value1[1]}
          onChange={handleRangeChange}
          variant="outlined"
          size="small"
          required={numberComparison()}
          error={numberComparison()}
          name="rightRange"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">до</InputAdornment>
            ),
            endAdornment: numberComparison() ? (
              <SvgAlert />
            ) : (
              <InputAdornment position="start">₽</InputAdornment>
            ),
          }}
        />
      </Stack>
    </>
  )
}

export default RangeInputs
