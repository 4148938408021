import { Order } from '../../../../shared/services/commonService'
import { IOtherProducts, OtherFieldType } from '../../types/otherTypes'
import { OtherItemSaved } from '../../types/otherTypes'
import { IProductWithInfo } from '../../../Product/types/productTypes'
import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'

export interface ICheckboxs {
  name: boolean
  esName: boolean
  internationalName: boolean
  esCode: boolean
}

export interface OtherState {
  selectedOtherItem: OtherItemSaved
  enough: boolean
  direction: LoadDirections.DOWN | LoadDirections.UP
  error: ErrorInfo | {}
  loading: boolean
  page: number
  limit: number
  total: number
  filter: any
  checkboxs: ICheckboxs
  products: IProductWithInfo[],
  startSearch: boolean
  orderBy: OtherFieldType
  order: Order
}

export enum OtherActionTypes {
  SET_FILTER_PRODUCTS = 'SET_FILTER_OTHER',
  RESET_FILTER_PRODUCTS = 'RESET_FILTER_OTHER',
  RESET_CHECKBOXS = 'RESET_CHECKBOXS',
  FETCH_PRODUCTS = 'FETCH_OTHER',
  FETCH_PRODUCTS_SUCCESS = 'FETCH_OTHER_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_OTHER_ERROR',
  SET_PRODUCT_PAGE = 'SET_OTHER_PAGE',
  FETCH_PRODUCTS_ENOUGH = 'FETCH_OTHER_ENOUGH',
  START_PRODUCT_SEARCH = 'START_OTHER_SEARCH',
  SET_PRODUCT_SORT = 'SET_OTHER_SORT',
  SAVE_OTHER_ITEM = 'SAVE_OTHER_ITEM',
  SET_CHECKBOX_PRODUCTS = 'SET_CHECKBOX_PRODUCTS',
}

interface SearchingOtherAction {
  type: OtherActionTypes.START_PRODUCT_SEARCH
}

interface ResetCheckboxsAction {
  type: OtherActionTypes.RESET_CHECKBOXS
}

interface FetchOtherEnoughAction {
  type: OtherActionTypes.FETCH_PRODUCTS_ENOUGH
  payload: boolean
}

interface SetFilterOtherAction {
  type: OtherActionTypes.SET_FILTER_PRODUCTS
  payload: Object
}

interface SetCheckboxOtherAction {
  type: OtherActionTypes.SET_CHECKBOX_PRODUCTS
  payload: Object
}

interface ResetFilterOtherAction {
  type: OtherActionTypes.RESET_FILTER_PRODUCTS
}

interface FetchOtherAction {
  type: OtherActionTypes.FETCH_PRODUCTS
}

export interface FetchOtherSuccessAction {
  type: OtherActionTypes.FETCH_PRODUCTS_SUCCESS
  payload: { content: IOtherProducts; loadFlag: boolean }
}

export interface FetchOtherErrorAction {
  type: OtherActionTypes.FETCH_PRODUCTS_ERROR
  payload: ErrorInfo | {}
}

interface SetOtherPage {
  type: OtherActionTypes.SET_PRODUCT_PAGE
  payload: number
}

// OtherFieldType
export interface IOtherPayload {
  orderBy: OtherFieldType
  order: Order
}

interface SetOtherSort {
  type: OtherActionTypes.SET_PRODUCT_SORT
  payload: IOtherPayload
}

export interface SaveOtherItemAction {
  type: OtherActionTypes.SAVE_OTHER_ITEM
  payload: OtherItemSaved
}

export type OtherAction =
  | FetchOtherEnoughAction
  | SetFilterOtherAction
  | ResetFilterOtherAction
  | FetchOtherAction
  | FetchOtherSuccessAction
  | FetchOtherErrorAction
  | SetOtherPage
  | SearchingOtherAction
  | SetOtherSort
  | SaveOtherItemAction
  | SetCheckboxOtherAction
  | ResetCheckboxsAction
