import { useEffect, useRef } from 'react'

import { useObserver } from '../../../shared/hooks/useObserver'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'

import { checkLastPage } from '../../../shared/helpers'
import { useActions } from '../../../shared/hooks/useActions'

export const useAutoOrderController = () => {
  const {
    items,
    error,
    page,
    enough,
    order,
    orderBy,
    listOrientation,
    limit,
    total,
    loading,
    checkedItems,
  } = useTypedSelector((state) => state.autoOrder)

  const {
    fetchAutoOrder,
    setAutoOrderPage,
    resetAutoOrderItems,
    resetAutoOrderFilter,
    fetchAutoOrderCondition,
  } = useActions()

  const savePageNum = useRef(page)
  const firstElement = useRef<HTMLTableRowElement>(null)
  const lastElement = useRef<HTMLTableRowElement>(null)
  const inputRef = useRef<null | Record<string, HTMLSpanElement>>({})

  useEffect(() => {
    const fetch = async () => fetchAutoOrder(page, limit)

    fetch().then(() => {
      if (savePageNum.current !== page) {
        const trElement = inputRef.current?.[items[items.length - 1].id as any]
        trElement?.scrollIntoView()
      }
      savePageNum.current = page
    })
  }, [page, listOrientation])

  useEffect(() => {
    return () => {
      resetAutoOrderFilter()
      resetAutoOrderItems()
    }
  }, [])

  useEffect(() => {
    fetchAutoOrderCondition()
  }, [checkedItems])

  // down
  useObserver(lastElement, !checkLastPage(page, limit, total), loading, () => {
    setAutoOrderPage(page + 1)
  })

  // up
  useObserver(
    firstElement,
    enough && page > 1 && inputRef.current !== null,
    loading,
    () => {
      setAutoOrderPage(page - 1)
    }
  )

  return {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    items,
    orderBy,
    order,
    listOrientation,
  }
}
