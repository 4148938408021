import React, { ReactNode } from 'react'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

const StatusTpr = ({
  isTpr,
  children,
}: {
  isTpr: boolean
  children: ReactNode
}) => {
  const shapeStyles = {
    bgcolor: 'primary.main',
    color: 'common.white',
    fontWeight: 'bold',
    width: 40,
    height: 20,
  }
  const rectangle = (
    <Box component="span" sx={shapeStyles}>
      КЗ
    </Box>
  )

  return (
    <>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        justifyContent="center"
      >
        {isTpr && rectangle}
        {children}
      </Stack>
    </>
  )
}

export default StatusTpr
