import React, { useRef, useState } from 'react'

//mui imports
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import FormattedDate1 from '../../../shared/components/FormattedDate1'
import FormattedPrice1 from '../../../shared/components/FormattedPrice1'
import { Checkbox } from '@mui/material'
import { useActions } from '../../../shared/hooks/useActions'
import { IDepartmentsNeedsParams } from '../../../shared/types/types'
import { setDetailsQuantityForOrderAction } from '../model/saleDetails/saleDetailsAction'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'

const style = {
  pointerEvents: 'none' as 'none',
  opacity: '0.5',
}

const SaleDetailsTableRow = ({ row }: { row: any }) => {
  const { stopDetailsListHandlerAction, setDetailsQuantityForOrderAction } =
    useActions()

  const isBlockedRowsActive = useTypedSelector(
    (state) => state.saleComplex.isBlockedTable
  )
  const bonusPriceActive = useTypedSelector(
    (state) =>
      state.saleComplex.options.bonusInPrice.applyBonusInPriceToProductsPrice
  )
  const isConsigneeDepartmentActive = useTypedSelector(
    (state) =>
      state.saleComplex.options.consigneeDepartment?.departmentName.length > 0
  )

  const isTprBlocked =
    isBlockedRowsActive || bonusPriceActive || isConsigneeDepartmentActive

  const minVal = row.minimalQuantity || 1

  const maxVal = row.initialNeededQuantityForOrder

  const quantity = row.boxQuantitiy || 1

  const [prevAmountValue, setPrevAmountValue] = useState(maxVal)

  const handleQuantityForOrder = (params: IDepartmentsNeedsParams) => {
    const { depId, productEscode, amount, id } = params
    let tempResult = prevAmountValue
    if (prevAmountValue < amount)
      tempResult = prevAmountValue + quantity
    if (prevAmountValue < amount && amount < minVal) tempResult = minVal

    if (prevAmountValue > amount)
      tempResult = prevAmountValue - quantity

    const validatedAmount =
      tempResult > maxVal // ограничение по максимуму и по минимальному значению и увеличение по кратности
        ? maxVal
        : tempResult < minVal
        ? 0
        : tempResult

    if (prevAmountValue === validatedAmount) return

    setPrevAmountValue(validatedAmount)// сохранение предыдущего значение между ререндерами
    setDetailsQuantityForOrderAction({
      depId,
      productEscode,
      amount: validatedAmount,
      id,
    })
  }

  return (
    <TableRow
      sx={{
        ...(isTprBlocked ? style : {}),
        '& td,& td div': {
          fontSize: '0.725rem',
        },
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell sx={{ paddingLeft: '20px' }}>
        <Checkbox
          color="error"
          edge="start"
          disableRipple
          size="small"
          checked={row.isInStopList}
          onChange={(e) =>
            stopDetailsListHandlerAction(e, {
              depId: row.departmentId,
              productEscode: row.productEsCode,
            })
          }
        />
      </TableCell>
      <TableCell align="center">{row.productEsCode}</TableCell>
      <TableCell align="center">{row.category}</TableCell>
      <TableCell align="left">{row.productName}</TableCell>
      <TableCell align="left">{row.producerName}</TableCell>
      <TableCell align="center">
        <FormattedDate1 date={row.expirationDate} />
      </TableCell>
      <TableCell align="center">{row.departmentStockQuantity}</TableCell>
      <TableCell align="center">{row.departmentQuantityPurchased} </TableCell>
      <TableCell align="center">{row.departmentSoldIn30Days} </TableCell>
      <TableCell align="center">
        <FormattedPrice1 price={row.proAptekaPrice} />
      </TableCell>
      <TableCell align="center">{minVal} </TableCell>
      <TableCell align="center">{quantity} </TableCell>
      <TableCell align="center">
        <input
          type="number"
          value={prevAmountValue}
          onChange={(e) =>
            handleQuantityForOrder({
              depId: row.departmentId,
              productEscode: row.productEsCode,
              amount: Number(e.target.value),
              id: row.id,
            })
          }
          style={{
            backgroundColor: 'inherit',
            color: 'inherit',
            fontSize: 'inherit',
            height: '28px',
            lineHeight: '28px',
            textAlign: 'center',
            width: '55px',
            border: 0,
            outline: 0,
            marginLeft: '12px',
          }}
        />
      </TableCell>
      <TableCell align="left">{row.priceListName} </TableCell>
      <TableCell align="left">{row.departmentName} </TableCell>
    </TableRow>
  )
}

export default SaleDetailsTableRow
