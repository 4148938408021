import React, { RefObject } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import ErrorTypography from '../../../shared/components/ErrorTypography'

interface IErrorPage {
  error: any
  firstElement: RefObject<HTMLElement>
  lastElement: RefObject<HTMLElement>
}

const ErrorPage = ({ error, firstElement, lastElement }: IErrorPage) => {
  return (
    <>
      <Box ref={firstElement} sx={{ display: 'none' }} />
      <Stack
        alignItems="center"
        height="calc(100vh - 340px)"
        justifyContent="center"
      >
        {error.img && <img src={error.img} alt="image-error" width={100} />}
        <ErrorTypography error={error.message} />
      </Stack>

      <Box ref={lastElement} sx={{ display: 'none' }} />
    </>
  )
}

export default ErrorPage
