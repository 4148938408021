import React from 'react'
import { useActions } from '../../../hooks/useActions'
import { Backdrop, Box, Fade, Modal } from '@mui/material'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import OtherProductContent from './OtherProductContent'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 340,
  bgcolor: 'background.paper',
  border: '2px solid',
  borderColor: 'action.disabled',
  boxShadow: '4px 6px 5px rgba(0, 0, 0, 0.1)',
  borderRadius: '10px',
  p: '2.5rem',
}
const OtherProductModal = () => {
  const { active } = useTypedSelector((state) => state.modal)
  const { modalDisable } = useActions()

  const handleClose = () => {
    modalDisable()
  }

  return (
    <>
      <Modal
        open={active}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={active}>
          <Box sx={style}>
            <OtherProductContent handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default OtherProductModal
