import React, { useEffect } from 'react'

// mui
import { SxProps, Theme } from '@mui/material'
import Typography from '@mui/material/Typography'
import Backdrop from '@mui/material/Backdrop'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'

// project
import ModalCloseButton from '../../../../../../shared/components/UI/ModalCloseButton'
import { useTypedSelector } from '../../../../../../shared/hooks/useTypedSelector'
import { useActions } from '../../../../../../shared/hooks/useActions'
import SupplierPriceList from './SupplierPriceList'

const containerStyle: SxProps<Theme> = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid',
  borderColor: 'action.disabled',
  boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: '2.35rem',
}

const supplierNameStyle: SxProps<Theme> = {
  color: 'primary.main',
  fontWeight: 'bold',
  fontSize: '16px',
  textTransform: 'uppercase',
  textAlign: 'center',
}

const buttonStyle: SxProps<Theme> = { borderRadius: '3px', width: '141.5px' }

const SupplierListModal = () => {
  const { active } = useTypedSelector((state) => state.modal)

  const selectedSupplier = useTypedSelector(
    (state) => state.supplierList.selectedSupplier
  )

  const supplierName = selectedSupplier ? selectedSupplier.supplierName : ''
  const priceListTypes = selectedSupplier ? selectedSupplier.priceListTypes : []

  const {
    modalDisable,
    fetchSupplierList,
    updatePriceListCheckbox,
    savePriceListsWithPreparation,
  } = useActions()

  useEffect(() => {}, [selectedSupplier])

  const handleCheckboxChange = (priceListId: number, checked: boolean) => {
    updatePriceListCheckbox(priceListId, checked)
  }

  const handleClose = () => {
    modalDisable()
  }

  const handleSavePriceList = async () => {
    if (selectedSupplier) {
      await savePriceListsWithPreparation(selectedSupplier)
      await fetchSupplierList()
      await handleClose()
    }
  }

  return (
    <>
      <Modal
        open={active}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={active}>
          <Box sx={containerStyle}>
            <Box sx={{ mb: 1 }}>
              <Typography sx={supplierNameStyle}>
                {`Выберите прайс-листы поставщика`}
              </Typography>
              <Typography sx={supplierNameStyle}>
                {`"${supplierName}"`}
              </Typography>
            </Box>

            <SupplierPriceList
              priceListTypes={priceListTypes}
              handleCheckboxChange={handleCheckboxChange}
            />

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              columnGap="1rem"
            >
              <Button
                variant="contained"
                onClick={handleSavePriceList}
                sx={buttonStyle}
              >
                Сохранить
              </Button>

              <Button onClick={handleClose} sx={buttonStyle} variant="outlined">
                Отменить
              </Button>
            </Stack>
            <ModalCloseButton handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default SupplierListModal
