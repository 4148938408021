import { onlyNotEmpty } from '../../../shared/services/commonService'
import http from '../../../shared/api/config/instance'
import {
  ISaleComplexOptions,
  IUIChanges,
} from '../components/SaleComplex/types/saleComplexTypes'
import { SaleCostBonusesState } from '../components/SaleCostBonuses/model/saleCostBonuses/saleCostBonusesReduxTypes'
import {
  IDepartmentsNeedsParams,
  IStopListParams,
} from '../../../shared/types/types'
import { ISaleDepartmentsProps } from '../components/SaleDepartments/model/saleDepartments/saleDepartmentsReduxTypes'

export const getSaleDetailsItems = (
  pageNumber: number,
  pageSize: number,
  filters: any
) => {
  return http.httpCore.post<any>('complex-order/departments-needs-details', {
    // todo
    pagination: {
      pageNumber,
      pageSize,
    },
    filter: {
      ...filters,
    },
  })
}

export const getSaleComplexItems = (
  pageNumber: number,
  pageSize: number,
  filters: any
) => {
  // const newFilters = onlyNotEmpty(filters)
  return http.httpCore.post<any>('complex-order/departments-needs', {
    pagination: {
      pageNumber,
      pageSize,
    },
    filter: {
      ...filters,
    },
  })
}

//////////////////////////////////////////////////////////////
export const getSaleStopListItems = (
  pageNumber?: number,
  pageSize?: number,
  departmentId?: string | number
) => {
  return http.httpCore.get<any>('complex-order/client-settings/stop-list', {
    params: {
      pageSize,
      pageNumber,
      needInfoAboutProducts: true,
      departmentIds: departmentId,
    },
  })
}

export const deleteAllSaleStopListItems = () => {
  return http.httpCore.delete('complex-order/client-settings/stop-lists', {
    data: {
      showChanges: true,
    },
  })
}

export const getSaleDepartmentsItems = (
  pageNumber?: number,
  pageSize?: number,
  filters = {}
) => {
  const newFilters = onlyNotEmpty(filters)
  return http.httpCore.get<any>('complex-order/client-settings/departments', {
    params: {
      pageSize,
      pageNumber,
      ...newFilters,
    },
  })
}

export const resetSaleComplexOrderApi = () =>
  http.httpCore.delete('complex-order', {
    data: {},
  })

export const getCostBonuses = () =>
  http.httpCore.get<{
    content: SaleCostBonusesState
  }>('complex-order/cost-and-bonuses')

export enum CalculationStatus {
  UNDEFINED = 0,
  CALCULATED = 1,
  NOT_CALCULATED = 2,
}

export const getComplexOrderCondition = () =>
  http.httpCore.get<{
    content: {
      calculationStatus: CalculationStatus
    }
  }>('complex-order/condition')

export const postSaleDepartmentsItems = (array: ISaleDepartmentsProps[]) => {
  return http.httpCore.post('complex-order/client-settings/departments', {
    departments: array,
  })
}

export const postAllSaleDepartments = (selectAll: boolean) => {
  return http.httpCore.post('complex-order/client-settings/departments/all', {
    isAdd: selectAll,
  })
}

export const deleteSaleDepartmentsItems = (array: number[]) =>
  http.httpCore.delete('complex-order/client-settings/departments', {
    data: {
      departmentIds: array,
    },
  })

export const putProductsInWay = (amount: number, departmentId: number) => {
  return http.httpCore.put('complex-order/client-settings/product-on-way', {
    productsOnWay: [
      {
        departmentId: departmentId,
        daysNumberWaitingForOrder: amount,
      },
    ],
  })
}

export const putChangeDepartmentsNeeds = (params: IDepartmentsNeedsParams) => {
  const { depId, productEscode, amount, id } = params
  return http.httpCore.put<IUIChanges>('complex-order/departments-needs', {
    items: [
      {
        productEsCode: productEscode,
        departmentId: depId,
        amount,
        id,
      },
    ],
    showChanges: true,
  })
}

export const putStopListItem = (params: IStopListParams) => {
  const { depId, productEscode } = params
  return http.httpCore.put(
    'complex-order/client-settings/stop-list/add-products',
    {
      showChanges: true,
      stopLists: [
        {
          departmentId: depId,
          productEsCodes: [productEscode],
        },
      ],
    }
  )
}

export const deleteStopListItem = (params: IStopListParams) => {
  const { depId, productEscode } = params
  return http.httpCore.put(
    'complex-order/client-settings/stop-list/remove-products',
    {
      showChanges: true,
      stopLists: [
        {
          departmentId: depId,
          productEsCodes: [productEscode],
        },
      ],
    }
  )
}

export const getSaleComplexOptions = () => {
  return http.httpCore.get<{
    content: ISaleComplexOptions
  }>('complex-order/settings')
}

export const putSaleComplexOptions = (model: ISaleComplexOptions) =>
  http.httpCore.put('complex-order/settings', model)

export const postBonusInPriceEnable = () =>
  http.httpCore.post('complex-order/settings/bonus-in-price', {
    showChanges: true,
  })
