import React from 'react'

import { Typography } from '@mui/material'

const StatusInfo = ({ status }: { status: number | undefined }) => {
  const statusOptions: Record<number, { text: string; color: string }> = {
    0: { text: 'Не определено', color: '#FA2103' },
    1: { text: 'Новые', color: '#40AC58' },
    2: { text: 'Обработанны', color: 'GREY' },
    3: { text: 'Частично обработаны', color: '#FA8803' },
    4: { text: 'В обработке', color: '#5868B4' },
  }

  const selectedOption = statusOptions[status || 0] || {
    text: 'Не определено',
    color: '#FA2103',
  }

  return (
    <Typography fontSize={12} fontWeight={600} color={selectedOption.color}>
      {selectedOption.text}
    </Typography>
  )
}

export default StatusInfo
