import {  IProductData, IProducts, Product } from '../../types/productTypes'
import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'

export interface ProductState {
  enough: boolean
  direction: LoadDirections.DOWN | LoadDirections.UP
  error: ErrorInfo | {}
  loading: boolean
  page: number
  limit: number
  total: number
  filter: any
  selectedProduct: Product
  products: IProductData[]
  isEditModeInitialized: boolean
}

export enum ProductActionTypes {
  SET_PRODUCT_LIMIT = 'SET_PRODUCT_LIMIT',
  SET_FILTER_PRODUCTS = 'SET_FILTER_PRODUCTS',
  SET_FILTER_CUSTOM = 'SET_FILTER_CUSTOM',
  RESET_FILTER_PRODUCTS = 'RESET_FILTER_PRODUCTS',
  FETCH_PRODUCTS = 'FETCH_PRODUCTS',
  FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR',
  SELECT_PRODUCT = 'SELECT_PRODUCT',
  SET_PRODUCT_PAGE = 'SET_PRODUCT_PAGE',
  FETCH_PRODUCTS_ENOUGH = 'FETCH_PRODUCTS_ENOUGH',
  INIT_EDIT_MODE = 'INIT_EDIT_MODE',
}

interface InitEditModeAction {
  type: ProductActionTypes.INIT_EDIT_MODE
  payload: boolean
}

interface FetchProductsEnoughAction {
  type: ProductActionTypes.FETCH_PRODUCTS_ENOUGH
  payload: boolean
}

interface SetFilterProductsAction {
  type: ProductActionTypes.SET_FILTER_PRODUCTS
}

interface SetFilterCustomAction {
  type: ProductActionTypes.SET_FILTER_CUSTOM
  payload: Object
}

interface ResetFilterProductsAction {
  type: ProductActionTypes.RESET_FILTER_PRODUCTS
}

interface FetchProductsAction {
  type: ProductActionTypes.FETCH_PRODUCTS
}

export interface FetchProductsSuccessAction {
  type: ProductActionTypes.FETCH_PRODUCTS_SUCCESS
  payload: { content: IProducts; loadFlag: boolean }
}

export interface FetchProductsErrorAction {
  type: ProductActionTypes.FETCH_PRODUCTS_ERROR
  payload: ErrorInfo | {}
}

export interface SelectProductAction {
  type: ProductActionTypes.SELECT_PRODUCT
  payload: Product
}

interface SetProductPage {
  type: ProductActionTypes.SET_PRODUCT_PAGE
  payload: number
}

interface SetProductLimit {
  type: ProductActionTypes.SET_PRODUCT_LIMIT
  payload: number
}

export type ProductAction =
  | FetchProductsAction
  | FetchProductsSuccessAction
  | FetchProductsErrorAction
  | SelectProductAction
  | SetProductPage
  | SetFilterProductsAction
  | SetProductLimit
  | ResetFilterProductsAction
  | FetchProductsEnoughAction
  | SetFilterCustomAction
  | InitEditModeAction
