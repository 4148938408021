import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react'

// mui imports
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import DangerousIcon from '@mui/icons-material/Dangerous'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

// hooks
import { useHistory } from 'react-router-dom'
import { useActions } from '../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'

// libs
import debounce from 'lodash/debounce'

// styled
import { ButtonStyled, TypographyStyled } from '../../../styled/saleStyled'

const style = {
  pointerEvents: 'none' as 'none',
  opacity: '0.5',
}

const minShelfLifePercent = 0
const maxShelfLifePercent = 100

const minShelfLife = 0
const maxShelfLife = 10 * 1000

const SaleComplexHeader = () => {
  const history = useHistory()

  const {
    showStopListModal,
    fetchSaleComplexOptionsAction,
    changeSaleComplexPeriodAction,
    changeSaleComplexCheckBoxsAction,
    setShelfLifePercentAction,
    setShelfLifeAction,
    fetchSaleDepartmentsProducts,
    resetSaleDepartmentsItems,
    fetchCostBonusesAction,
    fetchSaleComplexProductsAction,
    resetSaleComplexFilterProducts,
    fetchOnlyHighMarginProducts
  } = useActions()

  const claims = useTypedSelector((state) => state.auth.claims);

  const isBlockedRowsActive = useTypedSelector(
    (state) => state.saleComplex.isBlockedTable
  )
  const bonusPriceActive = useTypedSelector(
    (state) =>
      state.saleComplex.options.bonusInPrice.applyBonusInPriceToProductsPrice
  )
  const isConsigneeDepartmentActive = useTypedSelector(
    (state) =>
      state.saleComplex.options.consigneeDepartment?.departmentName.length > 0
  )

  const isTprBlocked =
    isBlockedRowsActive || bonusPriceActive || isConsigneeDepartmentActive

  const isComplexTableShowed = useTypedSelector(
    (state) => state.saleDepartments.isComplexTableShowed
  )

  const isLoadingComplexOrder = useTypedSelector(
    (state) => state.saleComplex.loading
  )

  const saleComplexAvailabilityByQuantity = useTypedSelector(
    (state) =>
      state.saleComplex.options.availabilityByQuantity
        .considerAvailabilityByQuantity // true - учитывать доступность по количеству, иначе - false.
  )

  const saleComplexPeriod = useTypedSelector(
    (state) => state.saleComplex.options.needsPeriod.needsPeriod //Потребность за период (Undefined = 0, OneMonth = 1, TwoMonths = 2).
  )

  const remainingShelfLifeInclude = useTypedSelector(
    (state) =>
      state.saleComplex.options.remainingShelfLifeDuration
        .remainingShelfLifeInclude // true - включить остаточный срок годности, иначе - false.
  )

  const remainingShelfLifeInDays = useTypedSelector(
    (state) =>
      state.saleComplex.options.remainingShelfLifeDuration
        .remainingShelfLifeInDays //Остаточный срок годности в днях.
  )

  const remainingShelfLifePercentInclude = useTypedSelector(
    (state) =>
      state.saleComplex.options.remainingShelfLifePercent
        .remainingShelfLifePercentInclude // true - включить процент остаточного срока годности, иначе - false.
  )

  const remainingShelfLifePercent = useTypedSelector(
    (state) =>
      state.saleComplex.options.remainingShelfLifePercent
        .remainingShelfLifePercent //   Процент остаточного срока годности
  )

  const onlyWithPriceGain = useTypedSelector(
    (state) => state.saleComplex.options.winningByPrice.onlyWithPriceGain // true - только с выигрышем по цене, иначе - false.
  )
  const onlyHighMarginProducts = useTypedSelector(state => state.saleComplex.options.highMarginProduct.onlyHighMarginProducts)
  

  const fetchUpdatedData = useCallback(async () => {
    resetSaleDepartmentsItems()
    resetSaleComplexFilterProducts()
    const fetchSaleComplex = async () => fetchSaleComplexProductsAction()
    const fetchBonuses = async () => fetchCostBonusesAction()
    const fetchDepartments = async () => fetchSaleDepartmentsProducts()
    await fetchSaleComplex()
    await fetchBonuses()
    await fetchDepartments()
  }, [])

  const debouncedRequest = useMemo(
    () => debounce(() => fetchUpdatedData(), 800),
    []
  )

  const handleShelfLifePercentInput = async (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const value = Math.max(
      minShelfLifePercent,
      Math.min(maxShelfLifePercent, Number(e.target.value))
    )
    const fetchData = async () => setShelfLifePercentAction(String(value))
    await fetchData()
    isComplexTableShowed && debouncedRequest()
  }

  const handleShelfLifeInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(
      minShelfLife,
      Math.min(maxShelfLife, Number(e.target.value))
    )
    const fetchData = async () => setShelfLifeAction(String(value))
    await fetchData()
    isComplexTableShowed && debouncedRequest()
  }

  useEffect(() => {
    fetchSaleComplexOptionsAction()
  }, [])

  const handleSaleCheckboxs = async (e: ChangeEvent<HTMLInputElement>) => {
    const fetchData = async () => changeSaleComplexCheckBoxsAction(e)
    await fetchData()
    isComplexTableShowed && fetchUpdatedData()
  }
  const handleChangeVmt = () => {
    if (isComplexTableShowed) return
    fetchOnlyHighMarginProducts(!onlyHighMarginProducts)
  }
  return (
    <>
      <Stack
        direction="row"
        ml={1.3}
        mb={0.7}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          sx={{
            ...(claims[ 'promarket.complex_order_settings.edit' ] &&
            isTprBlocked
              ? style : {}),
          }}
          display="flex"
          columnGap={1}
        >
          <RadioGroup
            sx={{ ...(isComplexTableShowed ? style : {}) }}
            value={saleComplexPeriod}
            onChange={changeSaleComplexPeriodAction}
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={<TypographyStyled>Потребность на 1 мес.</TypographyStyled>}
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label={<TypographyStyled>Потребность на 2 мес.</TypographyStyled>}
            />
          </RadioGroup>

          <Stack>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isLoadingComplexOrder}
                    onChange={handleChangeVmt}
                    name={'onlyHighMarginProducts'}
                    checked={onlyHighMarginProducts}
                  />
                }
                label={
                  <TypographyStyled>
                    ВМТ-комплекс
                  </TypographyStyled>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isLoadingComplexOrder}
                    onChange={handleSaleCheckboxs}
                    name={'remainingShelfLifePercentInclude'}
                    checked={remainingShelfLifePercentInclude}
                  />
                }
                label={
                  <TypographyStyled>
                    % остаточного срока годности
                  </TypographyStyled>
                }
              />
              <TextField
                disabled={!remainingShelfLifePercentInclude}
                type="number"
                size="small"
                onChange={handleShelfLifePercentInput}
                value={remainingShelfLifePercent}
                sx={{
                  width: 60,
                  '& .MuiOutlinedInput-input': {
                    padding: '8px 5px',
                  },
                }}
                InputProps={{ sx: { height: 27 } }}
              />
            </Box>

            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isLoadingComplexOrder}
                    onChange={handleSaleCheckboxs}
                    name="remainingShelfLifeInclude"
                    checked={remainingShelfLifeInclude}
                  />
                }
                label={
                  <TypographyStyled>
                    Остаточный срок годности, дн
                  </TypographyStyled>
                }
              />
              <TextField
                disabled={!remainingShelfLifeInclude}
                sx={{
                  width: 60,
                  '& .MuiOutlinedInput-input': {
                    padding: '8px 5px',
                  },
                }}
                size="small"
                type="number"
                onChange={handleShelfLifeInput}
                value={remainingShelfLifeInDays}
                InputProps={{ sx: { height: 27 } }}
              />
            </Box>
          </Stack>

          <Stack sx={{ ml: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isLoadingComplexOrder}
                  onChange={handleSaleCheckboxs}
                  name="onlyWithPriceGain"
                  checked={onlyWithPriceGain}
                />
              }
              label={
                <TypographyStyled>Только с выигрышем по цене</TypographyStyled>
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  disabled={isLoadingComplexOrder}
                  onChange={handleSaleCheckboxs}
                  name="considerAvailabilityByQuantity"
                  checked={saleComplexAvailabilityByQuantity}
                />
              }
              label={
                <TypographyStyled>
                  Учитывать доступное количество
                </TypographyStyled>
              }
            />
          </Stack>
        </Box>

        <Stack
          sx={{ ...(!isComplexTableShowed ? style : {}) }}
          direction={{ xs: 'column', xl: 'row' }}
          spacing={{ xs: 1, xl: 1.5 }}
        >
          <ButtonStyled
            sx={{ minWidth: '120px' }}
            variant="contained"
            onClick={() => history.push('/sale-details')}
          >
            Детализация
          </ButtonStyled>

          <ButtonStyled
            sx={{ minWidth: '120px' }}
            color="error"
            variant="outlined"
            onClick={() => showStopListModal()}
            startIcon={<DangerousIcon />}
          >
            Стоп-лист
          </ButtonStyled>
        </Stack>
      </Stack>
    </>
  )
}

export default SaleComplexHeader
