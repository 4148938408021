import { ISaleDetailsHeadCells } from '../types/saleDetailsTypes'

export const SALE_DETAILS = {
  MAX_PRODUCT_ITEMS: 60,
}

export const headCellsSaleDetails: ISaleDetailsHeadCells[] = [
  {
    name: 'КОД УС',
    align: 'center',
    field: 'productEsCode',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'КАТЕГОРИЯ',
    align: 'center',
    field: 'category',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'НАИМЕНОВАНИЕ',
    align: 'left',
    field: 'productName',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'ПРОИЗВОДИТЕЛЬ',
    align: 'left',
    field: 'producerName',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },

  {
    name: 'СРОК ГОДНОСТИ',
    align: 'center',
    field: 'expirationDate',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },

  {
    name: 'ТЕКУЩИЙ ОСТАТОК',
    align: 'center',
    field: 'departmentStockQuantity',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'ЗАКУПКИ',
    align: 'center',
    field: 'departmentQuantityPurchased',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'ПРОДАЖИ',
    align: 'center',
    field: 'departmentSoldIn30Days',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },

  {
    name: 'ЦЕНА',
    align: 'center',
    field: 'proAptekaPrice',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'МИН КОЛ.',
    align: 'center',
    field: 'minVal',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'КРАТНОСТЬ',
    align: 'center',
    field: 'quantity',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'КОЛ-ВО ДЛЯ ЗАКАЗА',
    align: 'center',
    field: 'neededQuantityForOrder',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },

  {
    name: 'Прайс-лист',
    align: 'center',
    field: 'priceListName',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },

  {
    name: 'ПОДРАЗДЕЛЕНИЕ',
    align: 'center',
    field: 'departmentName',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
]
