import React from 'react'

// mui
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableBody from '@mui/material/TableBody'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Button from '@mui/material/Button'

// project
import ElementWatch from '../../../../../Product/components/ElementWatch'
import PriceListDeliveryRow from '../PriceListDeliveryRow'

// hooks
import { usePriceListDeliveryController } from '../../controllers/usePriceListDeliveryController'
import { useActions } from '../../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../../shared/hooks/useTypedSelector'

// types
import { SaleDetailsFieldType } from '../../../../../SaleDetails/types/saleDetailsTypes'
import { IPriceListDeliveryHeadCells } from '../../types/priceListTypes'

const headCellsPriceListDelivery: IPriceListDeliveryHeadCells[] = [
  {
    name: 'Подразделения',
    align: 'left',
    field: 'departmentName',
    enableLeftPadding: false,
    enablePaddings: true,
    disableField: false,
  },
  {
    name: 'Кол-во дней',
    align: 'center',
    field: '',
    enableLeftPadding: false,
    enablePaddings: false,
    disableField: false,
    disableSort: true,
  },
]

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const PriceListDeliveryScheduleTableContainer = () => {
  const isCheckedAll = useTypedSelector((state) => state.priceList.isCheckedAll)

  const {
    setPriceListDeliverySortAction,
    selectAllPriceListDeliveryAction,
    resetPriceListDeliveryTableStateAction,
  } = useActions()
  const {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    products,
    order,
    orderBy,
  } = usePriceListDeliveryController()

  const sortHandler = (field: SaleDetailsFieldType) => {
    const isAsc = orderBy === field && order === 'asc'
    setPriceListDeliverySortAction({
      orderBy: field,
      order: isAsc ? 'desc' : 'asc',
    })
  }

  return (
    <>
      <TableContainer
        sx={{
          height: '338px',
          border: '1px solid #E1E3E6',
          boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
          borderRadius: '8px',
        }}
      >
        <Table
          size="small"
          aria-label="a price list delivery table"
          sx={{ tableLayout: 'auto' }}
        >
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  fontSize: '0.6rem',
                  lineHeight: '1rem',
                  color: 'primary.main',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  borderBottom: 0,
                  backgroundColor: 'background.paper',
                },
              }}
            >
              <TableCell
                align="left"
                sx={{
                  width: 10,
                  padding: '12px 5px 5px 16px',
                }}
              >
                <Checkbox
                  disableRipple
                  icon={icon}
                  checkedIcon={checkedIcon}
                  onChange={selectAllPriceListDeliveryAction}
                  checked={isCheckedAll}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 14 }, padding: 0 }}
                />
              </TableCell>

              {headCellsPriceListDelivery.map((item) => (
                <TableCell
                  key={item.name}
                  align={item.align}
                  sx={{
                    padding: item.enablePaddings
                      ? '0.5rem 1rem 0 1rem'
                      : '0.5rem 0 0 0',
                  }}
                >
                  {item.disableSort ? (
                    <Box
                      sx={{
                        pl: item.enableLeftPadding ? '18px' : 'unset',
                      }}
                    >
                      {item.name}
                    </Box>
                  ) : (
                    !item.disableField && (
                      <TableSortLabel
                        active={orderBy === item.field}
                        direction={orderBy === item.field ? order : 'asc'}
                        onClick={() => sortHandler(item.field)}
                      >
                        <Box
                          sx={{
                            pl: item.enableLeftPadding ? '18px' : 'unset',
                          }}
                        >
                          {item.Icon ? <item.Icon /> : item.name}
                        </Box>
                      </TableSortLabel>
                    )
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {products.length > 0 && (
              <ElementWatch
                componentNode={'tr'}
                element={firstElement}
                isLoading={loading}
              />
            )}

            {products.map((row: any) => (
              <React.Fragment key={row.departmentId}>
                <PriceListDeliveryRow row={row} />
                <tr
                  ref={(el) =>
                    // @ts-ignore
                    (inputRef.current[row.departmentId] = el)
                  }
                />
              </React.Fragment>
            ))}

            {products.length > 0 && (
              <ElementWatch
                componentNode={'tr'}
                element={lastElement}
                isLoading={loading}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '1rem',
        }}
      >
        <Button
          onClick={() => resetPriceListDeliveryTableStateAction()}
          variant="outlined"
          sx={{ fontSize: '12px' }}
        >
          Сбросить все
        </Button>
      </Box>
    </>
  )
}

export default PriceListDeliveryScheduleTableContainer
