import React from 'react'
import Box from '@mui/material/Box'
import { IProduct } from '../../types/productTypes'

const EsCodeField = ({ card }: { card: IProduct }) => {  
  return (
    <Box sx={{ flexBasis: 0 }}>
      <Box
        sx={{
          overflowWrap: { xs: 'break-word', xl: 'unset' },
          textTransform: 'uppercase',
          color: 'primary.main',
          fontWeight: 'bold',
          fontSize: { xs: '9px', xl: '10px' },
          mb: 1,
        }}
      >
        {'Код УС'}
      </Box>
      <Box
        sx={{
          textTransform: 'uppercase',
          overflowWrap: 'break-word',
          fontWeight: 'bold',
          fontSize: { xs: '10px', xl: '12px' },
        }}
      >
        {card.esCode ?? 0}
      </Box>
    </Box>
  )
}

export default EsCodeField
