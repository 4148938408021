import { useRef, useState } from 'react'
import { IOptionsProps } from '../../../shared/types/types'
import { getNewSuppliers } from '../../../shared/api/hint'
import { useFetching } from '../../../shared/hooks/useFetching'
import { useObserver } from '../../../shared/hooks/useObserver'

type ArgsType = {
  name?: string
  onlyActive?: string
  withPriceLists?: boolean
  supplierIdRange?: number[]
  fetchEnded: boolean
}

type FetchingArgsType = [
  name?: string,
  onlyActive?: string,
  withPriceLists?: boolean,
  supplierIdRange?: number[],
  page?: number,
  adding?: boolean
]

const pageSize = 200

export const useSuppliersController = () => {
  const [items, setItems] = useState<IOptionsProps[]>([])
  const lastElement = useRef<HTMLDivElement>(null)
  const currentPageNumber = useRef(1)
  const currentFetchArgs = useRef<ArgsType | null>(null)


  const callback = async (
    name?: string,
    onlyActive?: string,
    withPriceLists?: boolean,
    supplierIdRange?: number[],
    page = 1,
    adding = false
  ) => {
    // если вызов не на получение следующей страницы, то запоминаем аргументы
    if (!adding)
      currentFetchArgs.current = {
        name,
        onlyActive,
        withPriceLists,
        supplierIdRange,
        fetchEnded: false,
      }

    // если вызов на пагинацию, используем старые аргументы
    const {
      data: {
        content: { data, pageNumber },
      },
    } = await getNewSuppliers(
      currentFetchArgs.current?.name,
      currentFetchArgs.current?.onlyActive,
      currentFetchArgs.current?.withPriceLists,
      currentFetchArgs.current?.supplierIdRange,
      pageSize,
      true,
      page
    )

    currentPageNumber.current = pageNumber

    // перезаписываем или дополняем массив объектов в зависимости от условий вызова
    if (adding) {
      if (!data.length)
        currentFetchArgs.current = {
          ...currentFetchArgs.current,
          fetchEnded: true,
        }
      setItems((prev) => [...prev, ...data])
    } else setItems(data)
  }

  const [fetchSuppliersItems, loading] = useFetching<FetchingArgsType>(callback)

  const fetchSuppliersNextPage = () => {
    const nextPageNumber = currentPageNumber.current + 1
    if (items.length && currentFetchArgs.current?.fetchEnded === false)
      fetchSuppliersItems(
        undefined,
        undefined,
        undefined,
        undefined,
        nextPageNumber,
        true
      )
  }

  useObserver(lastElement, true, loading, fetchSuppliersNextPage)

  const changeItems = (val: IOptionsProps[]) => setItems(val)

  return {
    items,
    changeItems,
    lastElement,
    loading,
    fetchSuppliersItems,
    callback,
  }
}
