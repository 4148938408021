import http from './config/instance'

export const getAccessToken = (token: string) =>
  http.httpCore.post('auth/login/external', {
    f3ProAccessToken: token,
  })

export const getUserClaims = () =>
  http.httpCore.get<{
    data: {
      items: {
        categoryId: string
        description: string
        ruName: string
        value: string
      }[]
    }
  }>('claim/user/current', {
    withCredentials: true,
    baseURL: process.env.REACT_APP_GRPC_API_URL,
    params: {
      ProjectId: 4,
    },
  })
