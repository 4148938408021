import React, { ChangeEvent, useEffect, useState } from 'react'

import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from '@mui/material'

import { TypographyStyled } from '../../../../Sale/styled/saleStyled'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import { useActions } from '../../../../../shared/hooks/useActions'
import { IDepartmentProps } from '../types/supplierSettingsTypes'

const minShelfLifePercent = 0
const maxShelfLifePercent = 100

const minShelfLife = 0
const maxShelfLife = 10 * 1000

const OrderParametersTab = ({
  departmentValue,
}: {
  departmentValue: IDepartmentProps | null
}) => {
  const {
    setShelfLifePercentSettingsAction,
    changeSupplierSettingsCheckBoxsAction,
    setShelfLifeSettingsAction,
  } = useActions()

  const remainingShelfLifePercent = useTypedSelector(
    (state) => state.supplierSettings.remainingShelfLifePercent // Процент остаточного срока годности
  )

  const remainingShelfLifePercentInclude = useTypedSelector(
    (state) => state.supplierSettings.remainingShelfLifePercentInclude // true - включить процент остаточного срока годности, иначе - false.
  )

  const remainingShelfLifeInDays = useTypedSelector(
    (state) => state.supplierSettings.remainingShelfLifeInDays // Остаточный срок годности в днях.
  )

  const remainingShelfLifeInclude = useTypedSelector(
    (state) => state.supplierSettings.remainingShelfLifeInclude // true - включить остаточный срок годности, иначе - false.
  )

  const onlyProductsWithBestRating = useTypedSelector(
    (state) => state.supplierSettings.onlyProductsWithBestRating // true - товары с лучшим рейтингом, иначе - false.
  )

  const [per, setPer] = useState(() => remainingShelfLifePercent)
  const [days, setDays] = useState(() => remainingShelfLifeInDays)

  const handleShelfLifePercentInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(
      minShelfLifePercent,
      Math.min(maxShelfLifePercent, Number(e.target.value))
    )
    setPer(value)
    setShelfLifePercentSettingsAction(Number(value))
  }

  const handleShelfLifeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(
      minShelfLife,
      Math.min(maxShelfLife, Number(e.target.value))
    )
    setDays(value)
    setShelfLifeSettingsAction(String(value))
  }

  const handleSupplierSettingsCheckboxs = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    changeSupplierSettingsCheckBoxsAction(e)
  }

  return (
    <>
      <Stack>
        <Box display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                disabled={departmentValue === null}
                onChange={handleSupplierSettingsCheckboxs}
                name={'remainingShelfLifePercentInclude'}
                checked={remainingShelfLifePercentInclude}
              />
            }
            label={
              <TypographyStyled>% остаточного срока годности</TypographyStyled>
            }
          />
          <TextField
            disabled={
              !remainingShelfLifePercentInclude || departmentValue === null
            }
            type="number"
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleShelfLifePercentInput(e)
            }}
            value={per}
            sx={{
              width: 60,
              '& .MuiOutlinedInput-input': {
                padding: '8px 5px',
                fontSize: '0.8rem',
              },
            }}
            InputProps={{ sx: { height: 25 } }}
          />
        </Box>

        <Box display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                disabled={departmentValue === null}
                onChange={handleSupplierSettingsCheckboxs}
                name="remainingShelfLifeInclude"
                checked={remainingShelfLifeInclude}
              />
            }
            label={
              <TypographyStyled>Остаточный срок годности, дн</TypographyStyled>
            }
          />
          <TextField
            disabled={!remainingShelfLifeInclude || departmentValue === null}
            sx={{
              width: 60,
              '& .MuiOutlinedInput-input': {
                padding: '8px 5px',
                fontSize: '0.8rem',
              },
            }}
            size="small"
            type="number"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleShelfLifeInput(e)
            }}
            value={days}
            InputProps={{ sx: { height: 25 } }}
          />
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              disabled={departmentValue === null}
              onChange={handleSupplierSettingsCheckboxs}
              name="onlyProductsWithBestRating"
              checked={onlyProductsWithBestRating}
            />
          }
          label={
            <TypographyStyled>
              Только товары с лучшим рейтингом
            </TypographyStyled>
          }
        />
      </Stack>
    </>
  )
}

export default OrderParametersTab
