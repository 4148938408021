import { ModalAction, ModalActionTypes } from './modalsReduxTypes'

export const basketModalShow = (): ModalAction => ({
  type: ModalActionTypes.BASKET_MODAL,
})

export const showSupplierBasketModal = (): ModalAction => ({
  type: ModalActionTypes.SUPPLIER_BASKET_MODAL,
})

export const showOtherProductModal = (): ModalAction => ({
  type: ModalActionTypes.ALTERNATIVE_PRODUCT_MODAL,
})

export const showSideFilters = (): ModalAction => ({
  type: ModalActionTypes.SIDE_FILTERS_MODAL,
})

export const modalDisable = (): ModalAction => ({
  type: ModalActionTypes.MODAL_DISABLE,
})

export const showProductItemModal = (): ModalAction => ({
  type: ModalActionTypes.PRODUCT_ITEM_MODAL,
})

export const showCopyOrderModal = (): ModalAction => ({
  type: ModalActionTypes.COPY_ORDER_MODAL,
})

export const showBonusModal = (): ModalAction => ({
  type: ModalActionTypes.BONUS_MODAL,
})

export const showNewHistoryErrorModal = (): ModalAction => ({
  type: ModalActionTypes.NEW_HISTORY_ERROR_MODAL,
})

export const showSaleDepartmentsModal = (): ModalAction => ({
  type: ModalActionTypes.SALE_DEPARTMENTS_MODAL,
})

export const showNewHistoryDeleteModal = (): ModalAction => ({
  type: ModalActionTypes.NEW_HISTORY_DELETE_MODAL,
})

export const showStopListModal = (): ModalAction => ({
  type: ModalActionTypes.STOP_LIST_MODAL,
})

export const showUnionDepartmentModal = (): ModalAction => ({
  type: ModalActionTypes.UNION_DEPARTMENT_MODAL,
})

export const showResetSupplierSettingsModal = (): ModalAction => ({
  type: ModalActionTypes.RESET_SUPPLIER_SETTINGS_MODAL,
})


export const showAutoOrderFieldsModal = ():ModalAction => ({
  type: ModalActionTypes.AUTO_ORDER_SET_FIELDS_MODAL
}) 

export const showAutOrderDeleteModal = ():ModalAction => ({
  type: ModalActionTypes.AUTO_ORDER_DELETE_MODAL
})
export const showSupplierListModal = (): ModalAction => ({
  type: ModalActionTypes.SUPPLIER_LIST_MODAL,
})

export const showDeletedPositionModal = (): ModalAction => ({
  type: ModalActionTypes.DELETED_POSITION_MODAL
})

export const showErrorDetailInfoModal = (): ModalAction => ({
  type: ModalActionTypes.APPLICATION_ERROR_DETAIL_INFO_MODAL,
})