import { IAutoOrderHeadCell } from '../types/autoOrderProductsTypes'
import { AutoOrderFinalFieldTypeMap } from '../types/autoOrderProductsTypes'

export const AUTO_ORDER_FINAL_CONSTS = {
  DEFAULT_COUNT: 30,
  MAX_PRODUCTS: 30,
  MIN_ITEMS_AFTER_LOADING: 31,
  START_DOWN_INDEX: 30,
  END_DOWN_INDEX: 60,
  START_UP_INDEX: 0,
  END_UP_INDEX: 59,
}

export const AUTO_ORDER_FIElDS_KEY = 'autoOrderActiveFields'

export const headCellsArray: IAutoOrderHeadCell[] = [
  {
    name: 'Код',
    align: 'left',
    field: AutoOrderFinalFieldTypeMap.esCode.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 50,
  },
  {
    name: 'Товар заявки',
    align: 'left',
    field: AutoOrderFinalFieldTypeMap.bitProductName.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 50,
  },
  {
    name: 'Товар',
    align: 'left',
    field: AutoOrderFinalFieldTypeMap.productName.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 50,
  },
  {
    name: 'Производитель',
    align: 'left',
    field: AutoOrderFinalFieldTypeMap.producerName.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 50,
  },
  {
    name: 'Количество для заказа',
    align: 'left',
    field: AutoOrderFinalFieldTypeMap.quantityToOrder.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 50,
  },
  {
    name: 'Кратность',
    align: 'left',
    field: AutoOrderFinalFieldTypeMap.multiplicityOfBoxQuantity.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 80,
  },
  {
    name: 'Цена',
    align: 'left',
    field: AutoOrderFinalFieldTypeMap.price.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 100,
  },
  {
    name: 'Сумма',
    align: 'center',
    field: AutoOrderFinalFieldTypeMap.cost.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 70,
  },
  {
    name: 'Срок годности',
    align: 'center',
    field: AutoOrderFinalFieldTypeMap.expirationDate.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 120,
  },

  {
    name: 'НДС',
    align: 'center',
    field: AutoOrderFinalFieldTypeMap.valueAddedTaxPercentage.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 80,
  },

  {
    name: 'Реестровая цена',
    align: 'center',
    field: AutoOrderFinalFieldTypeMap.registeredPrice.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 80,
  },

  {
    name: 'ЖНВЛП',
    align: 'left',
    field: AutoOrderFinalFieldTypeMap.isVitalMedicinalProduct.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 80,
  },

  {
    name: 'Минимальное количество',
    align: 'center',
    field: AutoOrderFinalFieldTypeMap.minimalQuantity.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 80,
  },

  {
    name: 'Количество в прайс-листе',
    align: 'center',
    field: AutoOrderFinalFieldTypeMap.quantityInPriceList.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 150,
  },

  {
    name: 'Товарный остаток',
    align: 'center',
    field: AutoOrderFinalFieldTypeMap.remainingQuantity.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 100,
  },

  {
    name: 'Проданное количество',
    align: 'center',
    field: AutoOrderFinalFieldTypeMap.quantitySold.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    maxWidth: 100,
  },
]
