import React from 'react'

// mui imports
import Stack from '@mui/material/Stack'
import ReferenceMenu from './ReferenceMenu'

// mui styled
import { LinkCustom } from '../../../../shared/components/styled/LinkCustom'

// routes
import {
  getHistoryRoute,
  getSaleRoute,
  getAutoOrderRoute,
} from '../../../../shared/services/routeService'
import { useMediaQuery } from '@mui/material'
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'

const NavMenu = () => {
  const matchDownWidth = useMediaQuery('(min-width:1290px)')
  const claims = useTypedSelector((state) => state.auth.claims)

  return (
    <>
      <Stack
        mx="5px"
        component="nav"
        direction="row"
        marginLeft="1rem"
        spacing={matchDownWidth ? 4 : 1}
        textAlign="center"
      >
        {claims['promarket.complex_order.edit'] && (
          <LinkCustom to={getSaleRoute()}>Комплексный заказ</LinkCustom>
        )}
        {claims['promarket.order.get'] && (
          <LinkCustom to={getHistoryRoute()}>Заказы</LinkCustom>
        )}
        <ReferenceMenu />
        {claims['promarket.order.create'] &&
          claims['promarket.auto_order.edit'] && (
            <LinkCustom to={getAutoOrderRoute()}>Авторасторговка</LinkCustom>
          )}
      </Stack>
    </>
  )
}

export default NavMenu
