import {
  checkMaxCount,
  checkMinCount,
  checkMultiplicity,
} from '../../../shared/services/commonService'
import { Box, Stack } from '@mui/material'
import { RedTooltip } from '../../../shared/components/styled/RedToolTip'
import ErrorIcon from '@mui/icons-material/Error'
import React from 'react'

const FieldsTemplate = ({
  field,
  isError,
  errorText = 'Превышено допустимое значение',
}: {
  field: string | number
  isError: boolean | null
  errorText?: string
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      columnGap="2px"
    >
      <Box
        sx={{
          ...(isError
            ? { color: 'red', ml: '11px' }
            : { color: 'unset', ml: '0' }),
        }}
      >
        {field}
      </Box>

      {isError && (
        <RedTooltip title={errorText}>
          <ErrorIcon sx={{ color: 'red', fontSize: '14px' }} />
        </RedTooltip>
      )}
    </Stack>
  )
}

export const BoxQuantityField = ({
  boxQuantity,
  amount,
}: {
  boxQuantity: number
  amount: number
}) => {
  const isError = !!checkMultiplicity(amount, boxQuantity)
  return (
    <FieldsTemplate
      field={boxQuantity}
      isError={isError}
      errorText={'Некратное кол-во'}
    />
  )
}

export const MinQuantityField = ({
  minQuantity,
  amount,
}: {
  minQuantity: number
  amount: number
}) => {
  const isError = checkMinCount(Number(amount), Number(minQuantity))
  return (
    <FieldsTemplate
      field={minQuantity}
      isError={isError}
      errorText={'Не cooтвeтcтвyeт мин.кол-ву'}
    />
  )
}

export const QuantityField = ({
  quantity,
  amount,
}: {
  quantity: number
  amount: number
}) => {
  const isError = checkMaxCount(Number(amount), Number(quantity))
  return (
    <FieldsTemplate
      field={quantity}
      isError={isError}
      errorText={'Превышенно допустимое кол-во'}
    />
  )
}
