import React, { ChangeEvent } from 'react'
import { TextFieldCustom } from '../../../shared/components/styled/TextFieldCustom'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'

const SupSearch = ({
  request,
  query,
}: {
  request: (event: ChangeEvent<HTMLInputElement>) => void
  query: string
}) => {
  return (
    <>
      <TextFieldCustom
        fullWidth
        value={query}
        placeholder="Поиск поставщика"
        variant="outlined"
        size="small"
        onChange={request}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}

export default SupSearch
