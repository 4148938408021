import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import numeral from 'numeral'
import configureStore from './shared/store'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallbackPage from './pages/ErrorFallbackPage'
import './fonts'
import './shared/locales/i18n'
import { OIDCAuthIFrame } from "@proapteka/oidc-auth-iframe";
import { authController } from "./shared/api/config/instance";
import PreLoader from "./shared/components/PreLoader/PreLoader";

window.numeral = numeral

const { persistor, store } = configureStore()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
    <Provider store={store}>
      <OIDCAuthIFrame
        authControllerInstance={authController}
        preloader={<PreLoader />
      }>
      <Router>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Router>
      </OIDCAuthIFrame>
    </Provider>
  </ErrorBoundary>
)
