import { BasketItemsStore, ISupplierDetails } from '../../types/basketTypes'

export interface BasketState {
  itemsCount: number
  itemsSum: number
  basketRecords: BasketItemsStore
  supplierDetails: ISupplierDetails[]
}

export enum BasketActionTypes {
  SAVE_BASKET_RECORDS_META = 'SAVE_BASKET_RECORDS_META',
  DELETE_BASKET_RECORD = 'DELETE_BASKET_RECORD',
  DELETE_BASKET_RECORDS = 'DELETE_BASKET_RECORDS',
  DELETE_ALL_BASKET_RECORDS = 'DELETE_ALL_BASKET_RECORDS',
  ADD_BASKET_RECORDS = 'ADD_BASKET_RECORDS',
}

export interface SaveBasketRecordsAction {
  type: BasketActionTypes.SAVE_BASKET_RECORDS_META
  payload: {
    itemsSum: number
    itemsCount: number
    supplierDetails: ISupplierDetails[]
  }
}

export interface DeleteAllBasketRecordAction {
  type: BasketActionTypes.DELETE_ALL_BASKET_RECORDS
}

export interface DeleteBasketRecordAction {
  type: BasketActionTypes.DELETE_BASKET_RECORD
  payload: string
}

export interface DeleteBasketRecordsAction {
  type: BasketActionTypes.DELETE_BASKET_RECORDS
  payload: string[]
}

export interface AddBasketRecordsAction {
  type: BasketActionTypes.ADD_BASKET_RECORDS
  payload: BasketItemsStore
}

export type BasketAction =
  | SaveBasketRecordsAction
  | AddBasketRecordsAction
  | DeleteBasketRecordAction
  | DeleteBasketRecordsAction
  | DeleteAllBasketRecordAction
