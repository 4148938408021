import { Tooltip } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import { ICompensatedDiscount } from '../../../../features/Product/types/productTypes'

const SvgHealthIcon = ({
  renderCondition = false,
  health,
  initialSizes = {
    width: '15px',
    height: '15px',
  },
}: {
  renderCondition?: boolean
  health?: ICompensatedDiscount[]
  initialSizes?: {
    [key: string]: string
  }
}) => {
  const healthScreen = health?.reduce(
    (acc, curr, idx, arr) => acc + curr.name + (arr[idx + 1]?.name ? ', ' : ''),
    ''
  )
  if (!renderCondition) return null
  return (
    <Tooltip title={healthScreen || ''}>
      <SvgIcon
        viewBox="0 0 20 20"
        style={{
          ...initialSizes,
          marginBottom: '2px',
        }}
      >
        <HealthAndSafetyIcon color="secondary" />
      </SvgIcon>
    </Tooltip>
  )
}

export default SvgHealthIcon
