import { useEffect, useState } from 'react'
import { IOrderFiltered1 } from '../types/newHistoryTypes'
import { transformHistory } from '../../../shared/services/historyService'
import { useFetching } from '../../../shared/hooks/useFetching'
import { getOrderHistory } from '../api/newHistory'

export const useHistoryTableController = (id: number | string) => {
  const [data, setData] = useState([] as IOrderFiltered1[])

  const [fetchData, isLoading, error] = useFetching(async (id: number) => {
    const {
      data: {
        content: { data },
      },
    } = await getOrderHistory({ productIdFilter: id })
    let result = transformHistory(data)
    setData(result)
  })

  useEffect(() => {
    fetchData(id)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoading,
    error,
    data,
  }
}
