import React, { useEffect, useState, useRef } from 'react'

import { Divider, IconButton, InputBase, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

import { ISearchFilter } from '../../../shared/types/filterTypes'
import { useActions } from '../../../shared/hooks/useActions'
import HeaderMenu from './HeaderMenu'

const SearchPanel = () => {
  const { resetFilterProducts, setFilterProducts } = useActions()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const isSearchQueryNull = searchQuery?.length !== 0
  const isMounted = useRef(false)

  useEffect(() => {
    if (searchQuery.length === 0 && inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') submitHandler()
  }

  const resetSearch = () => {
    const searchInFieldsFilter = Object.keys(filter).filter(
      (key) => filter[key as keyof typeof filter]
    )
    sessionStorage.setItem(
      'filterCash2',
      JSON.stringify({
        nameFilter: '',
        searchInFieldsFilter,
      })
    )
    setSearchQuery('')
    resetFilterProducts()
    inputRef?.current?.focus()
  }

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }

  const submitHandler = () => {
    setFilterProducts()
  }

  const [filter, setFilter] = useState<ISearchFilter>({
    name: false,
    producer: false,
    supplier: false,
    country: false,
  })

  useEffect(() => {
    const searchInFieldsFilter = Object.keys(filter).filter(
      (key) => filter[key as keyof typeof filter]
    )
    if (isMounted.current) {
      sessionStorage.setItem(
        'filterCash2',
        JSON.stringify({
          nameFilter: searchQuery.trim(),
          searchInFieldsFilter,
        })
      )
    }

    const event = new StorageEvent('storage', {
      key: 'user-value',
      newValue: JSON.stringify(searchInFieldsFilter),
    })
    window.dispatchEvent(event)
  }, [filter, searchQuery])

  useEffect(() => {
    const defaultSearchQuery = JSON.parse(
      sessionStorage.getItem('filterCash2') as string
    )?.nameFilter
    if (defaultSearchQuery?.length) setSearchQuery(defaultSearchQuery || '')

    const defaultSearchFilters = JSON.parse(
      sessionStorage.getItem('filterCash2') as string
    )?.searchInFieldsFilter as string[]

    let defaultSearchObj: ISearchFilter = { ...filter }

    defaultSearchFilters?.length &&
      defaultSearchFilters.forEach((eachFilter) => {
        defaultSearchObj = {
          ...defaultSearchObj,
          [eachFilter]: true,
        }
      })

    if (defaultSearchFilters?.length) setFilter(defaultSearchObj)
    isMounted.current = true
  }, [])

  return (
    <>
      <HeaderMenu filter={filter} setFilter={(payload) => setFilter(payload)} />
      <Paper
        sx={{
          mr: '32px',
          display: 'flex',
          alignItems: 'center',
          height: '2.5rem',
          minWidth: '276px',
        }}
        elevation={0}
      >
        <InputBase
          inputRef={inputRef}
          value={searchQuery}
          onFocus={handleFocus}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          sx={{ ml: 2, flex: 1 }}
          placeholder="Поиск по товарам"
          inputProps={{ 'aria-label': 'search items' }}
        />
        {isSearchQueryNull && (
          <IconButton color="primary" sx={{ p: '10px' }} onClick={resetSearch}>
            <ClearIcon />
          </IconButton>
        )}
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

        <IconButton sx={{ p: '10px' }} onClick={submitHandler}>
          <SearchIcon />
        </IconButton>
      </Paper>
    </>
  )
}

export default SearchPanel
