import { styled } from "@mui/material/styles"
import { TextField } from "@mui/material"

export const TextFieldNoPaddings= styled(TextField)({
  "& .MuiOutlinedInput-root": {
    paddingLeft:"5px",
    paddingRight:"5px",
    "& fieldset": {
      borderColor: "#B5C3DC"
    }
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.875rem",
    lineHeight: "1.7375em"
  },
  "& .MuiInputAdornment-root":{
    "& p": {
      fontSize: "0.625rem"
    }
  }
})