import qs from 'qs'

export const getHomeRoute = () => '/'
export const getBasketRoute = () => '/store/basket'

export const getOtherRouteWithPayload = (payload: string) =>
  `/store/other-products?${payload}`

export const getOtherProductsRoute = () => '/store/other-products'

export const getOtherRouteById = (payload: string) =>
  `/store/other-products?${payload}`

export const getHistoryRoute = () => '/store/new-history'
export const getSimilarRoute = (payload: string) => `/store/similar?${payload}`

export const getHistoryRouteById = (id: string) => {
  let payload = qs.stringify({
    productIdFilter: id,
  })
  return `/store/new-history?${payload}`
}


export const getEditHistoryRouteByOrderId = (idOrder: string | number) =>
  `/store/new-history/edit/${idOrder}`
export const getViewHistoryRouteByOrderId = (idOrder: string | number) =>
  `/store/new-history/view/${idOrder}`
export const getSuppliersRoute = () => '/store/suppliers'
export const getHelpRoute = () => '/help/'
export const getFeaturesRoute = () => '/help/features-products'
export const getOptionsRoute = () => '/options'
export const getThemesRoute = () => '/options/themes'
export const getBindsRoute = () => '/binds'
export const getSaleRoute = () => '/for-sale'
export const getAutoOrderRoute = () => '/auto-order'
export const getViewAutoOrderRouteByOrderId = (idOrder: string) =>
  `/store/auto-order/view/${idOrder}`
export const getAutoOrderSettingsRoute = () => '/auto-order-settings'
export const getAutoOrderFinalRoute = () => '/auto-order-final'
