import { LoadDirections } from "../../../../../shared/types/types"
import { IPreOrder } from "../../DepartmentsPreOrder/types/departmentsPreOrderTypes"
import { 
    IAutoOrderProduct,
    IAutoOrderProducts
    } from 
    "../types/autoOrderProductsTypes" 


export interface AutoOrderFinalState {
    autoOrders: IAutoOrderProduct[],
    autoOrderSorts: string,
    loading: boolean,
    page: number,
    limit: number,
    enough: boolean,
    direction: LoadDirections,
    total: number,
    activeFields: {
        [key: string]: boolean
    },
    totalSum: number,
    deletedPosition: string,
}


export interface IChangeAmountResponse {
    autoOrderCost: {
        orderCost: number
    },
    departmentPreOrder: IPreOrder,
    newAutoOrderBidProduct: IAutoOrderProduct
}

export enum AutoOrderFinalActionTypes {
    SAVE_AUTO_ORDER_PRODUCTS = 'SAVE_AUTO_ORDER_PRODUCTS',
    SAVE_AUTO_ORDER_PRODUCTS_FILTER = 'SAVE_AUTO_ORDER_PRODUCTS_FILTER',
    SET_AUTO_ORDER_FINAL_SORT = 'SET_AUTO_ORDER_FINAL_SORT', 
    LOADING_AUTO_ORDER_PRODUCT_ENB = 'LOADING_AUTO_ORDER_PRODUCT_ENB',
    LOADING_AUTO_ORDER_PRODUCT_DSB = 'LOADING_AUTO_ORDER_PRODUCT_DSB',
    SET_AUTO_ORDER_FINAL_PAGE = 'SET_AUTO_ORDER_FINAL_PAGE',
    AUTO_ORDER_PRODUCTS_ENOUGH = 'AUTO_ORDER_PRODUCTS_ENOUGH',
    SET_AUTO_ORDER_ACTIVE_FIELS = 'SET_AUTO_ORDER_ACTIVE_FIELS',
    SAVE_AUTO_ORDER_SUM = 'SAVE_AUTO_ORDER_SUM',
    CLEAR_AUTO_ORDERS = 'CLEAR_AUTO_ORDERS',
    CHANGE_AMOUNT_RESPONSE = 'CHANGE_AMOUNT_RESPONSE', 
    AUTO_ORDER_REQ = 'AUTO_ORDER_REQ',
    SAVE_DELTED_POSITION = 'SAVE_DELTED_POSITION',
    DELETE_POSITION  = 'DELETE_POSITION',
}



export interface AutoOrderProductsAction {
    type: AutoOrderFinalActionTypes.SAVE_AUTO_ORDER_PRODUCTS,
    payload: {
        content: IAutoOrderProducts,
        loadFlag: boolean,
        loading: boolean
    }
}

export interface AutoOrderProductSaveSort {
    type: AutoOrderFinalActionTypes.SAVE_AUTO_ORDER_PRODUCTS_FILTER,
    payload: string
}


export interface SetAutoOrderFinalSort {
    type: AutoOrderFinalActionTypes.SET_AUTO_ORDER_FINAL_SORT,
    payload: string,
}
export interface AutoOrderProductsLoadingEnable {
    type: AutoOrderFinalActionTypes.LOADING_AUTO_ORDER_PRODUCT_ENB,
    payload: boolean
}
export interface AutoOrderProductsLoadingDisable {
    type: AutoOrderFinalActionTypes.LOADING_AUTO_ORDER_PRODUCT_DSB,
    payload: boolean
}
export interface AutoOrderFinalSetPage {
    type: AutoOrderFinalActionTypes.SET_AUTO_ORDER_FINAL_PAGE,
    payload: number
}

export interface AutOrderProductsEnough {
    type: AutoOrderFinalActionTypes.AUTO_ORDER_PRODUCTS_ENOUGH,
    payload: boolean
}

export interface AutoOrderSetActiveFields {
    type: AutoOrderFinalActionTypes.SET_AUTO_ORDER_ACTIVE_FIELS,
    payload: {
        [key:string]: boolean
    }
}
export interface AutoOrderSaveCost {
    type: AutoOrderFinalActionTypes.SAVE_AUTO_ORDER_SUM,
    payload: number
}

export interface ClearAutoOrders {
    type: AutoOrderFinalActionTypes.CLEAR_AUTO_ORDERS,
}

export interface AutorderChangeAmount {
    type: AutoOrderFinalActionTypes.CHANGE_AMOUNT_RESPONSE,
    payload: IChangeAmountResponse
}

interface FetchAutoOrderProductsAction {
    type: AutoOrderFinalActionTypes.AUTO_ORDER_REQ
    payload: { loadFlag: boolean; loading: boolean }
}

interface SaveDeletedPosition {
    type: AutoOrderFinalActionTypes.SAVE_DELTED_POSITION,
    payload: string
}

interface DeletePosition {
    type: AutoOrderFinalActionTypes.DELETE_POSITION,
    payload: string
}



export type AutoOrderFinalAction =
    | AutoOrderProductsAction
    | AutoOrderProductSaveSort
    | SetAutoOrderFinalSort
    | AutoOrderProductsLoadingDisable
    | AutoOrderProductsLoadingEnable
    | AutoOrderFinalSetPage
    | AutOrderProductsEnough
    | AutoOrderSetActiveFields
    | AutoOrderSaveCost
    | ClearAutoOrders
    | AutorderChangeAmount
    | FetchAutoOrderProductsAction
    | SaveDeletedPosition
    | DeletePosition