import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Popper, Typography } from '@mui/material'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface IAutoCompleteTemplateProps {
  width?: string
  customId?: string
  customName?: string
  variant?: 'multiple' | 'standard'
  customPopover?: boolean
  limitTags?: number
  value?: any[] | null
  label: string
  options: any[]
  debouncedRequest: any
  loading: boolean
  handleOpen: () => void
  handleClose: () => void
  handleAutoCompleteFields: (newValue: any) => void
  handleDepartmentValue?: (newValue: any) => void
}

const PopperMy = (props: any) => {
  return (
    <Popper
      {...props}
      style={{
        width: '100%',
        minWidth: '250px',
        maxWidth: '450px',
      }}
      placement="bottom-start"
    />
  )
}

const AutoCompleteTemplate = (props: IAutoCompleteTemplateProps) => {
  const {
    width = '100%',
    customId = 'id',
    customName = 'name',
    variant,
    customPopover,
    limitTags = 2,
    value,
    label,
    options,
    debouncedRequest,
    loading,
    handleOpen,
    handleClose,
    handleDepartmentValue,
    handleAutoCompleteFields,
  } = props

  const isMultiple = variant === 'multiple'
  const noOptionsText = 'Нет опций'
  const size = 'small'

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      event.key === 'Backspace' &&
      (event.currentTarget as HTMLInputElement).value
    ) {
      event.preventDefault()
    }
  }

  const handleAutoCompleteChange = (event: any, newValue: string) => {
    if (event.key === 'Backspace') {
      return
    }
    handleAutoCompleteFields(newValue)
  }

  return (
    <>
      <Autocomplete
        disableCloseOnSelect={isMultiple}
        disablePortal
        PopperComponent={customPopover ? PopperMy : undefined}
        sx={{
          '& .MuiInputLabel-formControl': {
            fontSize: '0.875rem',
            mt: '3px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#B5C3DC',
          },
          width,
        }}
        value={value}
        limitTags={limitTags}
        multiple={isMultiple}
        size={size}
        options={options}
        noOptionsText={noOptionsText}
        onOpen={handleOpen}
        onClose={handleClose}
        onKeyDown={handleInputKeyDown}
        onInputChange={debouncedRequest}
        getOptionLabel={(option) => option[customName]}
        onChange={(event: any, newValue: any) => {
          if (handleDepartmentValue) {
            handleDepartmentValue(newValue)
          }
          handleAutoCompleteFields(newValue)
        }}
        isOptionEqualToValue={(option, value) =>
          option[customId] === value[customId]
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 5 }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
              checked={selected}
            />
            <Typography style={{ fontSize: '12px' }}>
              {option[customName]}
            </Typography>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  )
}

export default AutoCompleteTemplate
