import React from 'react'
import numeral from 'numeral'
import Box from '@mui/material/Box'

const FormattedPrice1 = ({
  price,
  bold = true,
}: {
  price: string | number | null
  bold?: boolean
}) => {
  const content = numeral(price).format('0,0.00').replace(',', ' ')

  return (
    <Box
      component={'span'}
      sx={{
        fontWeight: bold ? 'bold' : 'unset',
        whiteSpace: 'nowrap',
      }}
    >
      {content} &#8381;
    </Box>
  )
}

export default FormattedPrice1
