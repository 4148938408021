import { SaleDepartmentsFieldType } from '../../types/saleDepartmentsTypes'
import { Order } from '../../../../../../shared/services/commonService'
import {
  ErrorInfo,
  IOptionsProps,
  LoadDirections,
} from '../../../../../../shared/types/types'

export interface SaleDepartmentsProducts {
  countInStopList: number
  departmentBonusCost: number
  departmentBonusPercentage: number
  departmentId: number
  departmentName: string
  departmentOrderCost: number
  initialDepartmentBonusCost: number
  initialDepartmentOrderCost: number
  productOnWay: number
}

export interface ISaleDepartmentsProps {
  departmentId: number
  departmentName: string
}

export interface SaleDepartmentsState {
  enough: boolean
  direction: LoadDirections.DOWN | LoadDirections.UP
  error: ErrorInfo | {}
  loading: boolean
  page: number
  limit: number
  total: number
  filter: Object
  products: SaleDepartmentsProducts[]
  orderBy: SaleDepartmentsFieldType
  order: Order
  departments: IOptionsProps[]
  checkedSaleDepartments: ISaleDepartmentsProps[]
  checkedSaleDepartmentsCopy: ISaleDepartmentsProps[]
  selectedStopListDepartment: string | number
  isComplexTableShowed: boolean
}

export enum SaleDepartmentsActionTypes {
  RESET_COPY_DEPARTMENTS = 'RESET_COPY_DEPARTMENTS',
  UPDATE_COPY_DEPARTMENTS = 'UPDATE_COPY_DEPARTMENTS',
  SET_COPY_CHECKED_ITEMS = '  SET_COPY_CHECKED_ITEMS',
  RESET_SD_CHECKED_ITEMS = 'RESET_SD_CHECKED_ITEMS',
  DELETE_SALE_DEPARTMENT_ITEM = 'DELETE_SALE_DEPARTMENT_ITEM',
  SET_FILTER_PRODUCTS = 'SET_FILTER_SALE_DEPARTMENTS',
  RESET_FILTER_PRODUCTS = 'RESET_FILTER_SALE_DEPARTMENTS',
  FETCH_PRODUCTS = 'FETCH_SALE_DEPARTMENTS',
  FETCH_PRODUCTS_SUCCESS = 'FETCH_SALE_DEPARTMENTS_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_SALE_DEPARTMENTS_ERROR',
  SET_PRODUCT_PAGE = 'SET_SALE_DEPARTMENTS_PAGE',
  FETCH_PRODUCTS_ENOUGH = 'FETCH_SALE_DEPARTMENTS_ENOUGH',
  SET_PRODUCT_SORT = 'SET_SALE_DEPARTMENTS_SORT',
  SAVE_DEPARTMENTS_LIST = 'SAVE_DEPARTMENTS_LIST',
  SET_CHECKED_ITEMS = 'SET_SL_CHECKED_ITEMS',
  ADD_CHECKED_ITEMS = 'ADD_SL_CHECKED_ITEMS',
  SAVE_STOP_LIST_DEPARTMENT = 'SAVE_STOP_LIST_DEPARTMENT',
  SHOW_SALE_COMPLEX_TABLE = 'SHOW_SALE_COMPLEX_TABLE',
  RESET_STOP_LIST_DEPARTMENT = 'RESET_STOP_LIST_DEPARTMENT',
  RESET_SALE_DEPARTMENTS_ITEMS = 'RESET_SALE_DEPARTMENTS_ITEMS',
  UPDATE_SALE_DEPARTMENTS_ITEMS = 'UPDATE_SALE_DEPARTMENTS_ITEMS',
  LOADING_ENABLE = 'LOADING_SALE_DEPARTMENTS__ENABLE',
  LOADING_DISABLE = 'LOADING_SALE_DEPARTMENTS__DISABLE',
}

interface ResetSaleDepartmentsCopyAction {
  type: SaleDepartmentsActionTypes.RESET_COPY_DEPARTMENTS
}

interface UpdateSaleDepartmentsCopyAction {
  type: SaleDepartmentsActionTypes.UPDATE_COPY_DEPARTMENTS
}

interface UpdateSaleDepartmentsItemsAction {
  type: SaleDepartmentsActionTypes.UPDATE_SALE_DEPARTMENTS_ITEMS
  payload: SaleDepartmentsProducts[]
}

interface ResetSaleDepartmentsCheckedItemsAction {
  type: SaleDepartmentsActionTypes.RESET_SD_CHECKED_ITEMS
}

interface ResetSaleDepartmentsItemsAction {
  type: SaleDepartmentsActionTypes.RESET_SALE_DEPARTMENTS_ITEMS
}

interface ResetStopListDepartmentAction {
  type: SaleDepartmentsActionTypes.RESET_STOP_LIST_DEPARTMENT
}

interface ShowSaleComplexTableAction {
  type: SaleDepartmentsActionTypes.SHOW_SALE_COMPLEX_TABLE
}

interface FetchSaleDepartmentsEnoughAction {
  type: SaleDepartmentsActionTypes.FETCH_PRODUCTS_ENOUGH
  payload: boolean
}

interface DeleteSaleDepartmentItemAction {
  type: SaleDepartmentsActionTypes.DELETE_SALE_DEPARTMENT_ITEM
  payload: number
}

interface SetFilterSaleDepartmentsAction {
  type: SaleDepartmentsActionTypes.SET_FILTER_PRODUCTS
  payload: Object
}

interface ResetFilterSaleDepartmentsAction {
  type: SaleDepartmentsActionTypes.RESET_FILTER_PRODUCTS
}

interface FetchSaleDepartmentsAction {
  type: SaleDepartmentsActionTypes.FETCH_PRODUCTS
}

export interface FetchSaleDepartmentsSuccessAction {
  type: SaleDepartmentsActionTypes.FETCH_PRODUCTS_SUCCESS
  payload: { content: any; loadFlag: boolean }
}

export interface FetchSaleDepartmentsErrorAction {
  type: SaleDepartmentsActionTypes.FETCH_PRODUCTS_ERROR
  payload: ErrorInfo | {}
}

interface SetSaleDepartmentsPage {
  type: SaleDepartmentsActionTypes.SET_PRODUCT_PAGE
  payload: number
}

export interface ISaleDepartmentsPayload {
  orderBy: SaleDepartmentsFieldType
  order: Order
}

interface SetSaleDepartmentsSort {
  type: SaleDepartmentsActionTypes.SET_PRODUCT_SORT
  payload: ISaleDepartmentsPayload
}

interface SetCheckedSaleDepartmentsAction {
  type: SaleDepartmentsActionTypes.SET_CHECKED_ITEMS
  payload: ISaleDepartmentsProps[]
}

interface SetCopyCheckedSaleDepartmentsAction {
  type: SaleDepartmentsActionTypes.SET_COPY_CHECKED_ITEMS
  payload: ISaleDepartmentsProps[]
}

interface AddCheckedSaleDepartmentsAction {
  type: SaleDepartmentsActionTypes.ADD_CHECKED_ITEMS
  payload: ISaleDepartmentsProps[]
}

export interface SaveDepartmentsListAction {
  type: SaleDepartmentsActionTypes.SAVE_DEPARTMENTS_LIST
  payload: IOptionsProps[]
}

export interface SelectStopListDepartmentAction {
  type: SaleDepartmentsActionTypes.SAVE_STOP_LIST_DEPARTMENT
  payload: string | number
}

export interface LoadingSaleDepartmentsEnableAction {
  type: SaleDepartmentsActionTypes.LOADING_ENABLE
}

export interface LoadingSaleDepartmentsDisableAction {
  type: SaleDepartmentsActionTypes.LOADING_DISABLE
}

export type SaleDepartmentsAction =
  | FetchSaleDepartmentsEnoughAction
  | SetFilterSaleDepartmentsAction
  | ResetFilterSaleDepartmentsAction
  | FetchSaleDepartmentsAction
  | FetchSaleDepartmentsSuccessAction
  | FetchSaleDepartmentsErrorAction
  | SetSaleDepartmentsPage
  | SetSaleDepartmentsSort
  | DeleteSaleDepartmentItemAction
  | SetCheckedSaleDepartmentsAction
  | AddCheckedSaleDepartmentsAction
  | SaveDepartmentsListAction
  | SelectStopListDepartmentAction
  | ShowSaleComplexTableAction
  | ResetStopListDepartmentAction
  | ResetSaleDepartmentsItemsAction
  | ResetSaleDepartmentsCheckedItemsAction
  | UpdateSaleDepartmentsItemsAction
  | LoadingSaleDepartmentsEnableAction
  | LoadingSaleDepartmentsDisableAction
  | SetCopyCheckedSaleDepartmentsAction
  | UpdateSaleDepartmentsCopyAction
  | ResetSaleDepartmentsCopyAction
