import {
  textErrorMinimalQuantity,
  textErrorMultiplicity,
  textErrorQuantity,
} from './commonService'
import {
  IOrderFiltered1,
  IOrderHistoryData,
  IOrderHistoryItems,
} from '../../features/NewHistory/types/newHistoryTypes'

const checkErrors = (array: IOrderHistoryItems[]) => {
  const checkArray = array.flatMap((i) =>
    [
      textErrorMultiplicity(i),
      textErrorMinimalQuantity(i),
      textErrorQuantity(i),
    ].filter((i) => i !== null)
  )
  return Array.from(new Set(checkArray))
}

export const transformHistory = (
  arr: IOrderHistoryData[]
): IOrderFiltered1[] => {  
  return arr.map((item) => ({
    id: item.id,
    createdAt: item.createdAt,
    department: item.departmentName,
    supplier: item.supplierName,
    totalSum: item.totalSum,
    totalCount: item.totalCount,
    userName: item.userName,
    statusId: item.statusId,
    typeId: item.typeId,
    priceListName: item.priceListName + ' ' + item.priceListNameExtended,
    hasErrors: checkErrors(item.orderItems),
  }))
}
