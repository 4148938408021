import React, { ChangeEvent, useState } from 'react'

// mui
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'

// project
import { TypographyStyled } from '../../../../Sale/styled/saleStyled'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import { useActions } from '../../../../../shared/hooks/useActions'
import FactorRow from './FactorRow'
import { FactorItem, IDepartmentProps } from '../types/supplierSettingsTypes'

const cellStyle: React.CSSProperties = {
  textTransform: 'uppercase',
  fontSize: '0.7rem',
  width: '25%',
}

const FactorTab = ({
  departmentValue,
}: {
  departmentValue: IDepartmentProps | null
}) => {
  const departmentId = useTypedSelector(
    (state) => state.supplierSettings.departmentId
  )

  const considerQuantityAvailabilityFactor = useTypedSelector(
    (state) => state.supplierSettings.considerQuantityAvailabilityFactor // Учитывать коэффициент доступного количества
  )

  const factors = useTypedSelector((state) => state.supplierSettings.factors)

  const [minPriceInput, setMinPriceInput] = useState('0')
  const [maxPriceInput, setMaxPriceInput] = useState('0')
  const [coefficientInput, setCoefficientInput] = useState('1')

  const [newMinPrice, setNewMinPrice] = useState('0')
  const [newMaxPrice, setNewMaxPrice] = useState('0')
  const [newCoefficient, setNewCoefficient] = useState('1')

  const [factorList, setFactorList] = useState([...factors])

  const [editingIndex, setEditingIndex] = useState(-1)

  const [minPriceError, setMinPriceError] = useState(false)
  const [maxPriceError, setMaxPriceError] = useState(false)

  // Cостояние, чтобы отслеживать, редактируется ли какая-либо строка.
  const [isEditing, setIsEditing] = useState(false)

  const {
    changeSupplierSettingsCheckBoxsAction,
    setSupplierSettingsOptionFactorsList,
  } = useActions()

  const handleSupplierSettingsCheckboxs = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    changeSupplierSettingsCheckBoxsAction(e)
  }

  const handleAddFactor = () => {
    setFactorList([
      ...factorList,
      {
        key: {
          minPrice: newMinPrice,
          maxPrice: newMaxPrice,
        },
        factor: newCoefficient,
      },
    ])
    setNewMinPrice('0')
    setNewMaxPrice('0')
    setNewCoefficient('1')

    // Устанавливаем индекс новой строки в состояние editingIndex
    setEditingIndex(factorList.length)
    setIsEditing(true)
  }

  const handleRemoveFactor = (index: number) => {
    const updatedFactors = [...factorList]
    updatedFactors.splice(index, 1)
    setSupplierSettingsOptionFactorsList(updatedFactors)
  }

  const handleEditClick = (index: number) => {
    setEditingIndex(-1)
    setEditingIndex(index)
    setIsEditing(true)
  }

  const handleCancelClick = () => {
    setEditingIndex(-1)
    setIsEditing(false)
  }

  const handleSaveClick = (index: number) => {
    setEditingIndex(-1)
    setIsEditing(false)

    // Парсим значения в числа перед обновлением factorList
    const updatedFactors = [...factorList]
    updatedFactors[index] = {
      key: {
        minPrice: updatedFactors[index].key.minPrice,
        maxPrice: updatedFactors[index].key.maxPrice,
      },
      factor: updatedFactors[index].factor,
    }

    setSupplierSettingsOptionFactorsList(updatedFactors)
    setFactorList(updatedFactors)
  }

  const isValidPriceValue = (value: number) => {
    return !isNaN(value) && value >= 0
  }

  const isValidFactorValue = (value: number) => {
    return !isNaN(value) && value >= 1
  }

  const handlePriceInputChange = (
    newValue: number,
    property: keyof FactorItem['key'],
    index: number
  ) => {
    const updatedFactors = [...factorList]

    if (isValidPriceValue(newValue)) {
      // Проверяем значения полей ввода (мин. цена не должна быть больше макс. цены)
      const updatedFactor = { ...updatedFactors[index] }
      updatedFactor.key[property] = parseFloat(newValue.toString())

      if (
        property === 'minPrice' &&
        updatedFactor.key.minPrice > updatedFactor.key.maxPrice
      ) {
        setMinPriceError(true)
        return
      } else {
        setMinPriceError(false)
      }

      if (
        property === 'maxPrice' &&
        updatedFactor.key.maxPrice < updatedFactor.key.minPrice
      ) {
        setMaxPriceError(true)
        return
      } else {
        setMaxPriceError(false)
      }
      //

      updatedFactors[index].key[property] = newValue
      property === 'minPrice'
        ? setMinPriceInput(newValue.toString())
        : setMaxPriceInput(newValue.toString())
    }

    setFactorList(updatedFactors)
  }

  const handleFactorInputChange = (newValue: number, index: number) => {
    const updatedFactors = [...factorList]

    if (isValidFactorValue(newValue)) {
      updatedFactors[index].factor = newValue
      setCoefficientInput(newValue.toString())
    }

    setFactorList(updatedFactors)
  }

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
    property: string
  ) => {
    const newValue = parseFloat(e.target.value)

    if (property === 'minPrice' || property === 'maxPrice') {
      handlePriceInputChange(
        newValue,
        property as keyof FactorItem['key'],
        index
      )
    } else if (property === 'factor') {
      handleFactorInputChange(newValue, index)
    }
  }

  return (
    <Stack>
      <Box display="flex" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              disabled={departmentValue === null}
              name="considerQuantityAvailabilityFactor"
              checked={considerQuantityAvailabilityFactor}
              onChange={handleSupplierSettingsCheckboxs}
            />
          }
          label={
            <TypographyStyled>
              Учитывать коэффициент доступного количества
            </TypographyStyled>
          }
        />

        <IconButton
          size="small"
          onClick={handleAddFactor}
          disabled={isEditing || departmentValue === null}
        >
          <AddCircleIcon />
        </IconButton>
      </Box>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ height: '35px' }}>
              <TableCell style={cellStyle}>Мин. цена</TableCell>
              <TableCell style={cellStyle}>Макс. цена</TableCell>
              <TableCell style={cellStyle}>Коэффициент</TableCell>
              <TableCell style={cellStyle}>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {factorList.map((factor, index) => (
              <FactorRow
                key={index}
                factor={factor}
                index={index}
                editingIndex={editingIndex}
                considerQuantityAvailabilityFactor={
                  considerQuantityAvailabilityFactor
                }
                onEditClick={handleEditClick}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                onRemoveFactor={handleRemoveFactor}
                handleInputChange={handleInputChange}
                minPriceError={minPriceError}
                maxPriceError={maxPriceError}
                departmentValue={departmentValue}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default FactorTab
