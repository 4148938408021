import http from '../../../shared/api/config/instance'
import { IOrderHistoryItems } from '../../NewHistory/types/newHistoryTypes'

export const deleteItemOrders = (
  orderId: number,
  orderItemsIdRange: number[]
) =>
  http.httpCore.delete('order/delete-item', {
    data: {
      orderId,
      orderItemsIdRange,
    },
  })
export const putChangeItemOrders = (
  amount: number,
  orderItemId: number,
  orderId: number
) =>
  http.httpCore.put<{ content: IOrderHistoryItems }>('order/change-amount', {
    amount,
    orderId,
    orderItemId,
  })
