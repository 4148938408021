import './react-datepicker.css'
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import ruLocale from 'date-fns/locale/ru'

registerLocale('ruLocale', ruLocale)

const CustomDatePicker = ({
  startDate,
  endDate,
  onChange,
  excludeDateIntervals,
}: {
  startDate: any
  endDate: any
  onChange: any
  excludeDateIntervals?: any
}) => {
  return (
    <DatePicker
      locale="ruLocale"
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      inline
      excludeDateIntervals={excludeDateIntervals}
    />
  )
}

export default CustomDatePicker
