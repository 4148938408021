import React, { useEffect } from 'react'

// libs
import { Route, useRouteMatch } from 'react-router-dom'

// mui
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import { Divider } from '@mui/material'

// hooks
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { useActions } from '../../shared/hooks/useActions'
import { useSuppliersController } from './controllers/useSuppliersController'

// project
import ElementWatch from '../Product/components/ElementWatch'
import SupSearchActivity from './components/SupSearchActivity'
import BackToLastPage from '../../shared/components/BackToLastPage'
import SupHeader from './components/SupHeader'
import NoSuppliers from './components/NoSuppliers'
import SupplierList from './components/SupplierList'
import PriceList from './components/PriceList/PriceList'
import RouteWarning from '../../shared/components/RouteWarning'

const listStyle = {
  minWidth: '260px',
  width: '100%',
  height: 'calc(100vh + 28px)',
  border: '1px solid',
  borderColor: 'action.hover',
  boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
  borderRadius: '8px',
  overflow: 'auto',
}

const SuppliersMain = () => {
  const { resetSuppliersIds } = useActions()
  const { supplierIdRange } = useTypedSelector((state) => state.supplier)
  const withPl = useTypedSelector((state) => state.supplier.withPLButton)
  let { path } = useRouteMatch()

  const {
    items,
    fetchSuppliersItems,
    callback,
    lastElement,
    loading,
    changeItems,
  } = useSuppliersController()

  const isEmptyItems = items.length === 0

  useEffect(() => {
    if (supplierIdRange.length > 0) {
      fetchSuppliersItems(undefined, undefined, undefined, supplierIdRange)
      return
    }

    if (withPl) {
      fetchSuppliersItems(undefined, undefined, withPl)
    } else {
      fetchSuppliersItems()
    }
  }, [supplierIdRange])

  useEffect(() => {
    return () => {
      resetSuppliersIds()
    }
  }, [])

  return (
    <Box
      sx={{
        px: { xs: 0, xl: 35 },
        py: 2,
      }}
    >
      <BackToLastPage text={'Поставщики'} />
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Box
            sx={{
              mb: 1,
              minWidth: '260px',
            }}
          >
            <SupSearchActivity
              callback={callback}
              fetchSuppliersItems={fetchSuppliersItems}
            />
          </Box>
          <List
            subheader={
              <SupHeader
                changeItems={changeItems}
                fetchSuppliersItems={fetchSuppliersItems}
              />
            }
            sx={listStyle}
          >
            <Divider />
            <NoSuppliers isEmptyItems={isEmptyItems} />
            <SupplierList path={path} items={items} changeItems={changeItems} />
            <ElementWatch element={lastElement} isLoading={loading} />
          </List>
        </Grid>
        <Grid item xs={8}>
          <Route path={`${path}/:supplierId`}>
            <PriceList />
          </Route>
          <Route path={path} exact>
            <RouteWarning text={'Выберите поставщика'} />
          </Route>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SuppliersMain
