import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import { BackPageTypography } from './styled/BackPageTypography'

const BackToLastPage = ({
  text,
  marginDisabled,
}: {
  text?: string
  marginDisabled?: boolean
}) => {
  const history = useHistory()
  return (
    <Box
      sx={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}
      marginBottom={marginDisabled ? 0 : 1}
    >
      <IconButton
        size="large"
        onClick={() => history.goBack()}
      >
        <ArrowBackIcon />
      </IconButton>
      <BackPageTypography>{text}</BackPageTypography>
    </Box>
  )
}

export default BackToLastPage
