import * as React from 'react'
import { useEffect } from 'react'
import { useActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import StopListContentTable from './StopListContentTable'
import Button from '@mui/material/Button'
import { deleteAllSaleStopListItems } from '../../../../features/Sale/api/sale'
import { Box } from '@mui/material'
import { updateCostBonusesAction } from '../../../../features/Sale/components/SaleCostBonuses/model/saleCostBonuses/saleCostBonusesAction'

const StopListModalContent = ({ handleClose }: { handleClose: () => void }) => {
  const {
    fetchStopListData,
    deleteAllSaleStopListItemsAction,
    resetStopListSelectedDepartment,
    updateSaleDepartmentsAction,
    updateCostBonusesAction,
  } = useActions()
  const stopLists = useTypedSelector((state) => state.saleStopLists.products)
  const selectedDepartmentId = useTypedSelector(
    (state) => state.saleDepartments.selectedStopListDepartment || undefined
  )

  const isBlockedRowsActive = useTypedSelector(
    (state) => state.saleComplex.isBlockedTable
  )
  const bonusPriceActive = useTypedSelector(
    (state) =>
      state.saleComplex.options.bonusInPrice.applyBonusInPriceToProductsPrice
  )
  const isConsigneeDepartmentActive = useTypedSelector(
    (state) =>
      state.saleComplex.options.consigneeDepartment?.departmentName.length > 0
  )

  const isTprBlocked =
    isBlockedRowsActive || bonusPriceActive || isConsigneeDepartmentActive

  useEffect(() => {
    fetchStopListData(selectedDepartmentId)
    return () => {
      resetStopListSelectedDepartment()
    }
  }, [])

  const deleteAllStopListsHandler = async () => {
    let { data } = await deleteAllSaleStopListItems()
    updateSaleDepartmentsAction(data.content.uiChanges.departments)
    updateCostBonusesAction(data.content.uiChanges.bonuses)
    deleteAllSaleStopListItemsAction()
    handleClose()
  }

  return (
    <Box
      sx={{
        marginTop: '2rem',
        width: 1050,
        height: 500,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        {stopLists.map((table: any) => {
          if (table.products.length)
            return (
              <StopListContentTable
                items={table.products}
                header={table.departmentName}
                departmentId={table.departmentId}
                limit={table.productNumberLimit}
              />
            )
        })}
      </Box>

      <Box
        sx={{
          marginTop: '8px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          disabled={isTprBlocked}
          onClick={() => deleteAllStopListsHandler()}
          sx={{
            textTransform: 'none',
          }}
          variant={'contained'}
          size="small"
        >
          Очистить стоп - лист
        </Button>
      </Box>
    </Box>
  )
}

export default StopListModalContent
