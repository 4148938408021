import {
  SupplierAction,
  SupplierActionTypes,
  SupplierState,
} from './supplierReduxTypes'

const initialState: SupplierState = {
  supplierIdRange: [],
  withPLButton: false
}

export const supplierReducer = (
  state = initialState,
  action: SupplierAction
): SupplierState => {
  switch (action.type) {
    case SupplierActionTypes.SAVE_SUPPLIERS_IDS:
      return {
        ...state,
        supplierIdRange: action.payload,
      }
    case SupplierActionTypes.RESET_SUPPLIERS_IDS:
      return {
        ...state,
        supplierIdRange: [],
      }
      case SupplierActionTypes.TOGGLE_WITH_PL_BUTTON_STATE:
        return {
          ...state,
          withPLButton: action.payload,
          
        }
    default:
      return state
  }
}
