import React from 'react'

// third lib
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'

// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'

// project
import { useActions } from '../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import { IDepartmentProps } from '../types/supplierSettingsTypes'

const propertyName: { [key: number]: string } = {
  0: 'Минимальная цена',
  1: 'Срок годности',
  2: 'Кратность',
  3: 'Минимальное количество',
  4: 'Приоритет поставщика',
}

const PriorityTab = ({
  departmentValue,
}: {
  departmentValue: IDepartmentProps | null
}) => {
  const prioritiesOrders = useTypedSelector(
    (state) => state.supplierSettings.prioritiesOrder
  )

  const { updateSupplierSettingsPrioritiesOrder } = useActions()

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const reorderedItems = Array.from(prioritiesOrders)
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1)
    reorderedItems.splice(result.destination.index, 0, reorderedItem)

    // Делаем priority у prioritiesOrders равным значению нового индекса
    const updatedPriorities = reorderedItems.map((item, index) => ({
      ...item,
      priority: index,
    }))

    // Обновляем prioritiesOrders после перетаскивания
    updateSupplierSettingsPrioritiesOrder(updatedPriorities)
  }

  // Сортируем prioritiesOrders по обновленным приоритетам
  const sortedOrdersByPriority = prioritiesOrders.sort(
    (a, b) => a.priority - b.priority
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '1%', fontSize: '0.7rem' }}>
                №
              </TableCell>
              <TableCell
                style={{ textTransform: 'uppercase', fontSize: '0.7rem' }}
              >
                Свойство
              </TableCell>
            </TableRow>
          </TableHead>
          <Droppable droppableId="priority-list" direction="vertical">
            {(provided) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {sortedOrdersByPriority.map((item, index) => (
                  <Draggable
                    key={item.order}
                    draggableId={String(item.order)}
                    index={index}
                    isDragDisabled={departmentValue === null}
                  >
                    {(provided) => (
                      <TableRow
                        sx={{ height: '35px' }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <TableCell style={{ fontSize: '0.8rem' }}>
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ fontSize: '0.8rem' }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {`${propertyName[item.order]}`}
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </TableContainer>
    </DragDropContext>
  )
}

export default PriorityTab
