import React from 'react'

// mui
import Box from '@mui/material/Box'
import HistoryIcon from '@mui/icons-material/History'

// styled
import { TextCircle } from '../../../../shared/components/styled/TextCircle'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

// hooks
import { useHistory } from 'react-router-dom'

// types
import { IProduct } from '../../types/productTypes'

// services
import { getOtherRouteWithPayload } from '../../../../shared/services/routeService'
import { qsStringify } from '../../../../shared/services/commonService'

interface IconsFieldsProp {
  card: IProduct
  handleExpandClick: () => void
  expanded: boolean
}

const IconsFields = ({ card, handleExpandClick }: IconsFieldsProp) => {
  const history = useHistory()

  const saveBasketItemAndPush = (card: IProduct) => {
    const { esCode, esName, name, internationalName } = card
    const requestString = qsStringify({
      esCode,
      name: esName,
      internationalName,
      productTitle: esName || name,
    })
    history.push(getOtherRouteWithPayload(requestString))
  }

  const saveOtherItemAndPush = (card: IProduct) => {
    const { esCode, esName, name } = card
    const requestString = qsStringify({ esCode, productTitle: esName || name })
    history.push(getOtherRouteWithPayload(requestString))
  }

  return (
    <Box
      sx={{
        '& > :not(style)': {
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
        },
      }}
    >
      <Box>
        <Tooltip title="История">
          <IconButton onClick={handleExpandClick} size="small">
            <HistoryIcon sx={{ color: 'primary.main' }} />
          </IconButton>
        </Tooltip>

        {Boolean(card.esCode) && (
          <Tooltip title="Альтернативные товары">
            <IconButton
              size="small"
              onClick={() => saveBasketItemAndPush(card)}
            >
              <TextCircle>A</TextCircle>
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Box>
        {Boolean(card.esCode) && (
          <Tooltip title="Другие товары">
            <IconButton size="small" onClick={() => saveOtherItemAndPush(card)}>
              <TextCircle>Д</TextCircle>
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}

export default IconsFields
