import React from 'react'
import { Dispatch } from 'redux'
import {
  ApplicationAction,
  ApplicationActionTypes,
  SnackTypes,
} from '../../../../shared/store/application/applicationReduxTypes'

import {
  INewHistoryPayload,
  NewHistoryAction,
  NewHistoryActionTypes,
} from './newHistoryReduxTypes'

import { postApproveOrders } from '../../../../shared/api/order'
import { IOptionsProps } from '../../../../shared/types/types'
import { IOrderStatusProps } from '../../types/newHistoryTypes'
import { HISTORY_STATUS } from '../../consts/newHistory'
import noResultImage from '../../../../shared/assets/images/search.png'
import { AppStateType } from '../../../../shared/store'
import { showSnack } from '../../../../shared/store/application/applicationAction'
import { ModalAction } from '../../../../shared/store/modal/modalsReduxTypes'
import { showCopyOrderModal, showNewHistoryDeleteModal } from '../../../../shared/store/modal/modalAction'
import { transformRequest } from '../../../../shared/services/commonService'
import { deleteOrders, getOrderHistory } from '../../api/newHistory'

export const fetchNewHistoryProducts = (
  page = 1,
  limit = 30,
  filter = {},
  loadFlag = true
) => {
  return async (dispatch: Dispatch<NewHistoryAction | ApplicationAction>) => {
    try {
      dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })
      const filterTransformed = Object.fromEntries(
        Object.entries(filter).map(([k, v]) => [k, transformRequest(v)])
      )
      const { data } = await getOrderHistory(filterTransformed, page, limit)
      const content = data.content
      const isEmpty = content.data.length === 0
      if (isEmpty) return
      const isEnough = content.data.length >= limit
      dispatch({
        type: NewHistoryActionTypes.FETCH_PRODUCTS_ENOUGH,
        payload: isEnough,
      })
      dispatch({
        type: NewHistoryActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: { content, loadFlag },
      })
    } catch (e) {
      dispatch({
        type: NewHistoryActionTypes.FETCH_PRODUCTS_ERROR,
        payload: { message: 'Поиск завершился ошибкой', img: noResultImage },
      })
    } finally {
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    }
  }
}

export function setNewHistoryPage(page: number): NewHistoryAction {
  if (page === 0) page = 1
  return { type: NewHistoryActionTypes.SET_PRODUCT_PAGE, payload: page }
}

export function setNewHistorySort(
  payload: INewHistoryPayload
): NewHistoryAction {
  return { type: NewHistoryActionTypes.SET_PRODUCT_SORT, payload }
}

export function setNewHistoryFilter(payload: {
  [key: string]: any
}): NewHistoryAction {
  return {
    type: NewHistoryActionTypes.SET_FILTER_PRODUCTS,
    payload,
  }
}

export function resetNewHistoryFilter(): NewHistoryAction {
  return { type: NewHistoryActionTypes.RESET_FILTER_PRODUCTS }
}

export function setNewHistoryCheckedItems(payload: number[]): NewHistoryAction {
  return {
    type: NewHistoryActionTypes.SET_CHECKED_ITEMS,
    payload,
  }
}

export function showNewHistoryNoted(payload: boolean): NewHistoryAction {
  return {
    type: NewHistoryActionTypes.SHOW_IS_NOTED_ITEMS,
    payload,
  }
}

export function showNewHistoryErrors(payload: boolean): NewHistoryAction {
  return {
    type: NewHistoryActionTypes.SHOW_IS_ERRORS_IN_ITEMS,
    payload,
  }
}

export function startNewHistorySearching(): NewHistoryAction {
  return { type: NewHistoryActionTypes.START_NEW_HISTORY_SEARCH }
}

export const handleNewHistoryAutoComplete = (
  newValue: IOptionsProps[] | null,
  key: string
) => {
  return async (dispatch: Dispatch<NewHistoryAction>) => {
    if (Array.isArray(newValue)) {
      dispatch(setNewHistoryFilter({ [key]: newValue }))
    } else {
      dispatch(setNewHistoryFilter({ [key]: [] }))
    }
  }
}

export const handleNewHistoryInputs = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  return async (dispatch: Dispatch<NewHistoryAction>) => {
    const key = event.target.name
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    dispatch(setNewHistoryFilter({ [key]: value }))
  }
}

export const handleNewHistoryComboBox = (
  newValue: IOrderStatusProps | null,
  key: string
) => {
  return async (dispatch: Dispatch<NewHistoryAction>) => {
    dispatch(setNewHistoryFilter({ [key]: newValue ? [newValue] : [] }))
  }
}

export const handleNewHistoryTime = (newValue: any) => {
  return async (dispatch: Dispatch<NewHistoryAction>) => {
    dispatch({
      type: NewHistoryActionTypes.SET_FILTER_PRODUCTS,
      payload: newValue
        ? { startDate: newValue[0], endDate: newValue[1] }
        : { startDate: null, endDate: null },
    })
  }
}

export const handleNewHistoryCheckBox = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  return async (
    dispatch: Dispatch<NewHistoryAction>,
    getState: () => AppStateType
  ) => {
    const id = Number(event.target.id)
    const checked = event.target.checked

    dispatch({
      type: NewHistoryActionTypes.ADD_CHECKED_ITEMS,
      payload: [id],
    })

    const state = getState()
    const checkedItems = state.newHistory.checkedItems

    if (!checked)
      dispatch({
        type: NewHistoryActionTypes.SET_CHECKED_ITEMS,
        payload: checkedItems.filter((i) => i !== id),
      })
  }
}

export const handleNewsHistoryDelete = (id: number) => {
  return async (
    dispatch: Dispatch<NewHistoryAction>,
  ) => {
    dispatch({
      type: NewHistoryActionTypes.SET_DELETED_ITEM,
      payload: id,
    })
  }
}

export const newHistorySelectAllItems = () => {
  return async (
    dispatch: Dispatch<NewHistoryAction>,
    getState: () => AppStateType
  ) => {
    const state = getState()

    if (state.newHistory.isCheckedAll) {
      dispatch({
        type: NewHistoryActionTypes.SET_IS_CHECKED_ALL,
      })
      dispatch({
        type: NewHistoryActionTypes.SET_CHECKED_ITEMS,
        payload: [],
      })
    } else {
      dispatch({
        type: NewHistoryActionTypes.SET_IS_CHECKED_ALL,
      })

      const products = state.newHistory.products
      const tempArray = products.filter(
        (i) => i.statusId !== HISTORY_STATUS.UPLOADED
      )

      dispatch({
        type: NewHistoryActionTypes.SET_CHECKED_ITEMS,
        payload: tempArray.map((li) => li.id),
      })
    }
  }
}

export const sendNewHistoryRequest = () => {
  return async (
    dispatch: Dispatch<NewHistoryAction | ApplicationAction>,
    getState: () => AppStateType
  ) => {
    const state = getState()

    postApproveOrders(state.newHistory.checkedItems)
      .then(() => {
        dispatch(
          showSnack({
            typeSnack: SnackTypes.SUCCESS,
            messageSnack: 'Выгружено',
          })
        )
      })
      .then(() => {
        dispatch(startNewHistorySearching())
        dispatch(setNewHistoryCheckedItems([]))
      })
  }
}

export const updateNewHistoryRequest = () => {
  return async (
    dispatch: Dispatch<NewHistoryAction | ApplicationAction>,
    getState: () => AppStateType
  ) => {
    const state = getState()

    postApproveOrders(state.newHistory.checkedItems)
      .then(() => {
        dispatch(
          showSnack({
            typeSnack: SnackTypes.SUCCESS,
            messageSnack: 'Выгружено',
          })
        )
      })
      .then(() => {
        dispatch({
          type: NewHistoryActionTypes.UPDATE_PRODUCT_BY_ID,
        })
        dispatch(setNewHistoryCheckedItems([]))
      })
  }
}

export const sendNewHistoryDeleteRequest = () => {
  return async (
    dispatch: Dispatch<NewHistoryAction | ApplicationAction>,
    getState: () => AppStateType
  ) => {
    const state = getState()
    deleteOrders(state.newHistory.deletedItem)
      .then(() => {
        dispatch(
          showSnack({
            typeSnack: SnackTypes.SUCCESS,
            messageSnack: 'Удалено',
          })
        )
      })
      .then(() => {
        dispatch({
          type: NewHistoryActionTypes.DELETE_PRODUCT_BY_ID,
        })
        dispatch(startNewHistorySearching())
        dispatch(setNewHistoryCheckedItems([]))
      })
  }
}

export const selectCopyOrder = (id: number) => {
  return (dispatch: Dispatch<NewHistoryAction | ModalAction>) => {
    dispatch({
      type: NewHistoryActionTypes.SAVE_ORDER_ID,
      payload: id,
    })
    dispatch(showCopyOrderModal())
  }
}
