import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export const ButtonStyled = styled(Button)({
  flexShrink: 0,
  fontSize: '0.625rem',
})
export const TypographyStyled = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '0.625rem',
  color: theme.palette.primary.main,
}))
