import React from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import SvgBlueLogo from '../../shared/components/UI/svgIcons/SvgBlueLogo'
import SvgGreenLogo from '../../shared/components/UI/svgIcons/SvgGreenLogo'
import SvgOrangeLogo from '../../shared/components/UI/svgIcons/SvgOrangeLogo'

import SvgHospital from '../../shared/components/UI/svgIcons/SvgHospital'
import SvgMolecule from '../../shared/components/UI/svgIcons/SvgMolecule'
import SvgCheck from '../../shared/components/UI/svgIcons/SvgCheck'
import SvgAccounting from '../../shared/components/UI/svgIcons/SvgAccounting'
import SvgDocument from '../../shared/components/UI/svgIcons/SvgDocument'
import SvgBad from '../../shared/components/UI/svgIcons/SvgBad'
import SvgCircleM from '../../shared/components/UI/svgIcons/SvgCircleM'
import SvgCircleU from '../../shared/components/UI/svgIcons/SvgCircleU'
import SvgClock from '../../shared/components/UI/svgIcons/SvgClock'
import SvgPercentage from '../../shared/components/UI/svgIcons/SvgPercentage'
import { DRAWER } from '../../shared/consts/drawer'
import { Link as RouterLink, Route, useRouteMatch } from 'react-router-dom'
import { getFeaturesRoute } from '../../shared/services/routeService'
import RouteWarning from '../../shared/components/RouteWarning'
import SvgHealthIcon from '../../shared/components/UI/svgIcons/SvgHealth'

const initialIconSizes = {
  width: '20px',
  height: '20px',
}

const features1 = [
  {
    logo: (
      <SvgBlueLogo initialSizes={initialIconSizes} renderCondition={true} />
    ),
    text: '“Проаптека”- товар содержится в действующем маркетинговом мероприятии, у рекомендованного поставщика',
  },
  {
    logo: (
      <SvgGreenLogo initialSizes={initialIconSizes} renderCondition={true} />
    ),
    text: '“Ассортиментные планы”- товар содержится в ассортиментном плане',
  },
  {
    logo: (
      <SvgOrangeLogo initialSizes={initialIconSizes} renderCondition={true} />
    ),
    text: '“Бонус первостольнику”- за продажу товара первостольникам начисляется бонус в кошелек, ММ с типом “Продажи по кол-ву”: Мотивация_и Прогрмма Провизора_',
  },
  {
    logo: <SvgHealthIcon renderCondition={true} />,
    text: '“Моё здоровье”- товар есть в журнале компенсируемых скидок программы Мое Здоровье',
  },
]

const features2 = [
  {
    logo: (
      <SvgHospital initialSizes={initialIconSizes} renderCondition={true} />
    ),
    text: 'список ЖНВЛП (жизненно-необходимые и важнейшие препараты)',
  },
  {
    logo: (
      <SvgMolecule initialSizes={initialIconSizes} renderCondition={true} />
    ),
    text: 'список МИБП (медицинские иммунобиологические препараты)',
  },
  {
    logo: <SvgCheck initialSizes={initialIconSizes} renderCondition={true} />,
    text: 'список ОА (обязательный ассортимент)',
  },
  {
    logo: (
      <SvgAccounting initialSizes={initialIconSizes} renderCondition={true} />
    ),
    text: 'список ПКУ (предметно-количественного учета)',
  },
  {
    logo: (
      <SvgDocument initialSizes={initialIconSizes} renderCondition={true} />
    ),
    text: 'по рецепту',
  },
  {
    logo: <SvgBad initialSizes={initialIconSizes} renderCondition={true} />,
    text: 'БАД',
  },
]

const features3 = [
  { logo: <SvgCircleM renderCondition={true} />, text: 'маркированный товар' },
  { logo: <SvgCircleU renderCondition={true} />, text: 'уценка' },
  {
    logo: <SvgClock renderCondition={true} />,
    text: 'истекающий срок годности',
  },
  { logo: <SvgPercentage renderCondition={true} />, text: 'акция' },
]

const FeaturesHeaders = ({ text }: { text: string }) => {
  return (
    <Typography
      component="h2"
      fontSize="14px"
      fontWeight="600"
      lineHeight="16px"
      letterSpacing="0em"
      textAlign="left"
      marginY="1rem"
      color="primary.main"
    >
      {text}
    </Typography>
  )
}

const FeaturesList = ({ logo, text }: { logo: JSX.Element; text: string }) => {
  return (
    <Box display="flex">
      <span style={{ marginTop: '3px', marginRight: '5px' }}>{logo}</span>
      <Typography fontSize="14px" fontWeight="400" lineHeight={1.3}>
        {text}
      </Typography>
    </Box>
  )
}

const HelpMain = () => {
  let { path } = useRouteMatch()
  return (
    <>
      <Box display="flex">
        <Box width={DRAWER.WIDTH} marginTop={'1rem'}>
          <Typography
            to={getFeaturesRoute()}
            component={RouterLink}
            sx={{
              color: 'primary.main',
              fontSize: '16px',
              fontWeight: '400',
              textDecoration: 'unset',
              ml: '2.875rem',
              '&:hover': {
                color: '#5868B4',
                cursor: 'pointer',
                textDecoration: 'underline',
              },
            }}
          >
            Признаки товара
          </Typography>
        </Box>

        <Route path={`${path}/features-products`}>
          <Box>
            <FeaturesHeaders text={'Маркетинг'} />
            {features1.map(({ logo, text }) => (
              <FeaturesList key={text} logo={logo} text={text} />
            ))}
            <FeaturesHeaders
              text={'Характеристика товара из Единого Справочника'}
            />
            {features2.map(({ logo, text }) => (
              <FeaturesList key={text} logo={logo} text={text} />
            ))}
            <FeaturesHeaders
              text={'Признак передается в прайс-листе поставщика'}
            />
            {features3.map(({ logo, text }) => (
              <FeaturesList key={text} logo={logo} text={text} />
            ))}
          </Box>
        </Route>
        <Route path={path} exact>
          <RouteWarning text={'Выберите раздел справки'} />
        </Route>
      </Box>
    </>
  )
}

export default HelpMain
