import { combineReducers } from 'redux'
import { modalReducer } from './modal/modalReducer'
import { applicationReducer } from './application/applicationReducer'
import { productReducer } from '../../features/Product/model/product/productReducer'
import { basketReducer } from '../../features/Basket/model/basket/basketReducer'
import { authReducer } from './auth/authReducer'
import { otherReducer } from '../../features/Others/model/other/otherReducer'
import { supplierReducer } from '../../features/Supplier/model/supplier/supplierReducer'
import { similarReducer } from '../../features/Similar/model/similar/similarReducer'
import { newHistoryReducer } from '../../features/NewHistory/model/newHistory/newHistoryReducer'
import { saleDetailsReducer } from '../../features/SaleDetails/model/saleDetails/saleDetailsReducer'
import { saleDepartmentsReducer } from '../../features/Sale/components/SaleDepartments/model/saleDepartments/saleDepartmentsReducer'
import { saleComplexReducer } from '../../features/Sale/components/SaleComplex/model/saleComplex/saleComplexReducer'
import { saleStopListsReducer } from './saleStopLists/saleStopListsReducer'
import { saleCostBonusesReducer } from '../../features/Sale/components/SaleCostBonuses/model/saleCostBonuses/saleCostBonusesReducer'
import { priceListReducer } from '../../features/Supplier/components/PriceList/model/priceList/priceListReducer'
import { autoOrderReducer } from '../../features/AutoOrder/model/autoOrder/autoOrderReducer'
import { subAutoOrderReducer } from '../../features/SubAutoOrder/model/subAutoOrder/subAutoOrderReducer'
import { processingAutoOrderReducer } from '../../features/SettingsAutoOrder/components/ProcessingAutoOrder/model/processingAutoOrder/processingAutoOrderReducer'
import { supplierSettingsReducer } from '../../features/SettingsAutoOrder/components/SupplierSettings/model/supplierSettings/supplierSettingsReducer'
import { departmentsPreOrdersReducer } from '../../features/AutoOrderFinal/components/DepartmentsPreOrder/model/departmentsPreOrdersReducer'
import { autoOrderProducts } from '../../features/AutoOrderFinal/components/AutoOrderProducts/model/autoOrderFinalReducer'
import { supplierListReducer } from './../../features/SettingsAutoOrder/components/SupplierList/model/supplierList/supplierListReducer'

export const rootReducer = combineReducers({
  product: productReducer,
  basket: basketReducer,
  modal: modalReducer,
  app: applicationReducer,
  auth: authReducer,
  other: otherReducer,
  supplier: supplierReducer,
  similar: similarReducer,
  newHistory: newHistoryReducer,
  saleDetails: saleDetailsReducer,
  saleDepartments: saleDepartmentsReducer,
  saleComplex: saleComplexReducer,
  saleStopLists: saleStopListsReducer,
  saleCostBonuses: saleCostBonusesReducer,
  priceList: priceListReducer,
  autoOrder: autoOrderReducer,
  subAutoOrder: subAutoOrderReducer,
  processingAutoOrder: processingAutoOrderReducer,
  supplierSettings: supplierSettingsReducer,
  autoOrderFinalDepartments: departmentsPreOrdersReducer,
  autoOrderProducts: autoOrderProducts,
  supplierList: supplierListReducer,
})

export type RootState = ReturnType<typeof rootReducer>
