import React from 'react'

// mui
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

interface IPriceListControlStack {
  saveFunc?: () => void
  resetFunc?: () => void
  justifyContent?: string
}

const PriceListControlStack = ({
  saveFunc,
  resetFunc,
  justifyContent = 'unset',
}: IPriceListControlStack) => {
  return (
    <>
      <Stack
        marginY={1}
        direction="row"
        columnGap="10px"
        justifyContent={justifyContent}
      >
        <Button variant="contained" onClick={saveFunc}>
          Сохранить
        </Button>

        <Button variant="outlined" onClick={resetFunc}>
          Сброс
        </Button>
      </Stack>
    </>
  )
}

export default PriceListControlStack
