import React from 'react'

//mui imports
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import ErrorIcon from '@mui/icons-material/Error'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

// services
import { onlyNumbers } from '../../../../../shared/services/commonService'

// libs
import { Instance } from '@popperjs/core'

// api
import { deleteSaleDepartmentsItems } from '../../../api/sale'

// hooks
import { useActions } from '../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'

// project
import FormattedPrice1 from '../../../../../shared/components/FormattedPrice1'

const style = {
  pointerEvents: 'none' as 'none',
}

const SaleDepartmentsRow = ({
  row,
  changeItemAmount,
}: {
  row: any //todo ts
  changeItemAmount: (amount: number, departmentId: number) => void
}) => {
  const {
    deleteSaleDepartmentsItemAction,
    showStopListModal,
    selectStopListDepartment,
  } = useActions()
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })
  const popperRef = React.useRef<Instance>(null)
  const areaRef = React.useRef<HTMLDivElement>(null)

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY }

    if (popperRef.current != null) {
      popperRef.current.update()
    }
  }

  const handleRowClick = (rowId: number) => {
    deleteSaleDepartmentsItems([rowId])
    deleteSaleDepartmentsItemAction(rowId)
  }

  const handleClickModal = (departmentId: string) => {
    selectStopListDepartment(departmentId)
    showStopListModal()
  }

  const isComplexTableShowed = useTypedSelector(
    (state) => state.saleDepartments.isComplexTableShowed
  )

  return (
    <>
      <TableRow
        sx={{
          '& td,th': {
            fontSize: '0.725rem',
            ...(isComplexTableShowed ? style : {}),
          },
          '&:last-child td, &:last-child th': { border: 0 },
        }}
      >
        <TableCell component="th" scope="row" align="left">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Tooltip
              title={
                <React.Fragment>
                  <Button
                    onClick={() => handleRowClick(row.departmentId)}
                    variant="text"
                    sx={{
                      fontSize: 8,
                      color: 'inherit',
                    }}
                  >
                    Удалить
                  </Button>
                </React.Fragment>
              }
              placement="top"
              arrow
              PopperProps={{
                popperRef,
                anchorEl: {
                  getBoundingClientRect: () => {
                    return new DOMRect(
                      positionRef.current.x,
                      areaRef.current!.getBoundingClientRect().y,
                      0,
                      0
                    )
                  },
                },
              }}
            >
              <Box ref={areaRef} onMouseMove={handleMouseMove}>
                {row.departmentName}
              </Box>
            </Tooltip>
            {row.countInStopList > 0 && (
              <IconButton
                size="small"
                sx={{
                  pointerEvents: 'auto',
                }}
                onClick={() => handleClickModal(row.departmentId)}
              >
                <ErrorIcon
                  sx={{
                    color: 'primary.main',
                    fontSize: '16px',
                  }}
                />
              </IconButton>
            )}
          </Stack>
        </TableCell>

        <TableCell align="center">
          <FormattedPrice1 price={row.departmentOrderCost} />
        </TableCell>
        <TableCell align="center">
          <FormattedPrice1 price={row.departmentBonusCost} />
        </TableCell>
        <TableCell align="center">{row.departmentBonusPercentage}</TableCell>
        {!isComplexTableShowed && (
          <TableCell align="center">
            <input
              type="number"
              onChange={(e) =>
                changeItemAmount(Number(e.target.value), row.departmentId)
              }
              defaultValue={row.productOnWay}
              onInput={onlyNumbers}
              style={{
                backgroundColor: 'inherit',
                color: 'inherit',
                lineHeight: '28px',
                fontSize: '12px',
                height: '28px',
                textAlign: 'center',
                width: '55px',
                border: 0,
                outline: 0,
              }}
            />
          </TableCell>
        )}
      </TableRow>
    </>
  )
}

export default SaleDepartmentsRow
