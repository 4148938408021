import React from 'react'
import { IAutoCompleteProps } from '../../types/types'
import { useAutoCompleteController } from '../../controllers/useAutoCompleteController'
import AutoCompleteTemplate from './AutoCompleteTemplate'
import { getSaleDepartmentsItems } from '../../../features/Sale/api/sale'

const SaleDepartmentAutoComplete = ({
  width,
  customPopover,
  customId,
  customName,
  limitTags,
  variant,
  value,
  handleAutoCompleteFields,
  isCheckBoxType,
}: IAutoCompleteProps) => {
  const { options, debouncedRequest, loading, handleOpen, handleClose } =
    useAutoCompleteController(
      getSaleDepartmentsItems,
      'content.departments',
      isCheckBoxType,
      customId,
      customName
    )

  return (
    <AutoCompleteTemplate
      width={width}
      customId={customId}
      customName={customName}
      customPopover={customPopover}
      variant={variant}
      label={'Грузополучатель'}
      value={value}
      limitTags={limitTags}
      options={options}
      debouncedRequest={debouncedRequest}
      loading={loading}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleAutoCompleteFields={handleAutoCompleteFields}
    />
  )
}

export default SaleDepartmentAutoComplete
