import { TableAlignType } from '../../../shared/types/types'
import { SubAutoOrderOrientation } from '../model/subAutoOrder/SubAutoOrderReduxTypes';

export interface ISubAutoOrder {
  items: ISubAutoOrderItem[]
  pagination: ISubAutoOrderPagination
  isError: boolean
  description: string
  order: string
}

export interface ISubAutoOrderItem {
  id: string,
  summaryBidId: string,
  bidId: string,
  productName: string
  quantityToOrder: number
  remainingQuantity: number
  quantitySold: number
  orderedQuantity: number
  remainingQuantityToOrder: number
  isRefusalToOrder: boolean
  supplierName: string | null
  proMarketOrderId: number | null
  proMarketOrderDate: any
  productOrderCost: number | null
  products: ISubAutoOrderProduct[]
  supplierId: string | null
}

export interface ISubAutoOrderProduct {
  esCode: number
  isProductOrdered: boolean
  productName: string
  productPrice: number | null
  rating: number
  supplierId: number | null
  supplierName: string | null
}

export interface ISubAutoOrderPagination {
  pageNumber: number
  pageSize: number
  total: number
}

export interface ISubAutoOrderFilterItem {
  propertyName: string
  operation: string,
  values: any[]
}

export interface ISubAutoOrderHeadCells {
  name: string
  align: TableAlignType
  field: string
  enableLeftPadding?: boolean
  enablePadding?: boolean
  disableField?: boolean
  disableSort?: boolean
}

export type SubAutoOrderFieldType =
  | 'productName'
  | 'quantityToOrder'
  | 'remainingQuantity'
  | 'quantitySold'
  | 'orderedQuantity'
  | 'remainingQuantityToOrder'
  | 'isRefusalToOrder'
  | 'supplierName'
  | 'proMarketOrderId'
  | 'proMarketOrderDate'
  | 'productOrderCost'

interface ISubAutoOrderFieldsTypeMap {
  value: string,
  asc: SubAutoOrderOrientation
  desc: SubAutoOrderOrientation
}

export const SubAutoOrderFieldTypeMap: Record<SubAutoOrderFieldType, ISubAutoOrderFieldsTypeMap> = {
  productName: {
    value: 'productName',
    asc: SubAutoOrderOrientation.productNameAsc,
    desc: SubAutoOrderOrientation.productNameDesc
  },
  quantityToOrder: {
    value: 'quantityToOrder',
    asc: SubAutoOrderOrientation.quantityToOrderAsc,
    desc: SubAutoOrderOrientation.quantityToOrderDesc
  },
  remainingQuantity: {
    value: 'remainingQuantity',
    asc: SubAutoOrderOrientation.remainingQuantityAsc,
    desc: SubAutoOrderOrientation.remainingQuantityDesc
  },
  quantitySold: {
    value: 'quantitySold',
    asc: SubAutoOrderOrientation.quantitySoldAsc,
    desc: SubAutoOrderOrientation.quantitySoldDesc
  },
  orderedQuantity: {
    value: 'orderedQuantity',
    asc: SubAutoOrderOrientation.orderedQuantityAsc,
    desc: SubAutoOrderOrientation.orderedQuantityDesc
  },
  remainingQuantityToOrder: {
    value: 'remainingQuantityToOrder',
    asc: SubAutoOrderOrientation.remainingQuantityToOrderAsc,
    desc: SubAutoOrderOrientation.remainingQuantityToOrderDesc
  },
  isRefusalToOrder: {
    value: 'isRefusalToOrder',
    asc: SubAutoOrderOrientation.isRefusalToOrderAsc,
    desc: SubAutoOrderOrientation.isRefusalToOrderDesc
  },
  supplierName: {
    value: 'supplierName',
    asc: SubAutoOrderOrientation.supplierNameAsc,
    desc: SubAutoOrderOrientation.supplierNameDesc
  },
  proMarketOrderId: {
    value: 'proMarketOrderId',
    asc: SubAutoOrderOrientation.proMarketOrderIdAsc,
    desc: SubAutoOrderOrientation.proMarketOrderIdDesc
  },
  proMarketOrderDate: {
    value: 'proMarketOrderDate',
    asc: SubAutoOrderOrientation.proMarketOrderDateAsc,
    desc: SubAutoOrderOrientation.proMarketOrderDateDesc
  },
  productOrderCost: {
    value: 'productOrderCost',
    asc: SubAutoOrderOrientation.productOrderCostAsc,
    desc: SubAutoOrderOrientation.productOrderCostDesc
  }
}

