import {
  ProductAction,
  ProductActionTypes,
  ProductState,
} from './productReduxTypes'
import { PRODUCT } from '../../consts/product'
import { Product } from '../../types/productTypes'
import { convertFilterData } from '../../../../shared/services/filterService'
import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'

const initialState: ProductState = {
  enough: false,
  direction: LoadDirections.DOWN,
  loading: false,
  error: {} as ErrorInfo,
  page: 1,
  limit: Number(localStorage.getItem('pageLimit')) || 20,
  total: 20,
  filter: {},
  selectedProduct: {} as Product,
  products: [],
  isEditModeInitialized: false,
}

export const productReducer = (
  state = initialState,
  action: ProductAction
): ProductState => {
  switch (action.type) {
    case ProductActionTypes.SET_FILTER_PRODUCTS:      
      const result = convertFilterData()            
      return {
        ...state,
        products: [],
        page: 1,
        filter: {
          ...result,
          ...(state.isEditModeInitialized
            ? { priceListTypeIdFilter: state.filter.priceListTypeIdFilter }
            : {}),
          ...(state.isEditModeInitialized
            ? { supplierIdFilter: state.filter.supplierIdFilter }
            : {}),
        },
      }
    case ProductActionTypes.RESET_FILTER_PRODUCTS:
      return {
        ...state,
        products: [],
        page: 1,
        filter: {},
      }

    case ProductActionTypes.SET_FILTER_CUSTOM:
      return {
        ...state,
        products: [],
        page: 1,
        filter: { ...action.payload },
      }

    case ProductActionTypes.FETCH_PRODUCTS_SUCCESS:
      const { content, loadFlag } = action.payload      
      const productsArray = loadFlag
        ? [...state.products, ...content.data]
        : [...content.data, ...state.products]

      const productFilteredArray = productsArray.filter(
        //удаляем дубликаты
        (v, i, a) =>
          a.findIndex((t) => t.productWithInfo.product.priceListItemId === v.productWithInfo.product.priceListItemId) === i
      )
      const productFilteredSlicedArray = loadFlag
        ? productFilteredArray.slice(-PRODUCT.MAX_PRODUCT_ITEMS)
        : productFilteredArray.slice(0, PRODUCT.MAX_PRODUCT_ITEMS)

      return {
        ...state,
        products: productFilteredSlicedArray,
        error: {},
        total: content.total,
        direction: loadFlag ? LoadDirections.DOWN : LoadDirections.UP,
      }
    case ProductActionTypes.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ProductActionTypes.SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      }

    case ProductActionTypes.INIT_EDIT_MODE:
      return {
        ...state,
        isEditModeInitialized: action.payload,
      }

    case ProductActionTypes.SET_PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    case ProductActionTypes.SET_PRODUCT_LIMIT:
      return {
        ...state,
        products: [],
        page: 1,
        limit: action.payload,
      }

    case ProductActionTypes.FETCH_PRODUCTS_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }

    default:
      return state
  }
}
