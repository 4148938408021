import { ChangeEvent } from 'react'

// mui
import {
  IconButton,
  SxProps,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'

// mui icons
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'

// project
import { FactorItem, IDepartmentProps } from '../types/supplierSettingsTypes'

const inputTextStyle: SxProps<Theme> = {
  width: 60,
  '& .MuiOutlinedInput-input': {
    padding: '8px 5px',
    fontSize: '0.8rem',
  },
}

const iconButtonStyle: SxProps<Theme> = {
  fontSize: '1.2rem',
}

interface IFactorRowProps {
  factor: FactorItem
  index: number
  editingIndex: number
  considerQuantityAvailabilityFactor: boolean
  onEditClick: (index: number) => void
  onCancelClick: () => void
  onSaveClick: (index: number) => void
  onRemoveFactor: (index: number) => void
  handleInputChange: (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
    property: string
  ) => void
  minPriceError: boolean
  maxPriceError: boolean
  departmentValue: IDepartmentProps | null
}

const FactorRow = ({
  factor,
  index,
  editingIndex,
  considerQuantityAvailabilityFactor,
  onEditClick,
  onCancelClick,
  onSaveClick,
  onRemoveFactor,
  handleInputChange,
  minPriceError,
  maxPriceError,
  departmentValue,
}: IFactorRowProps) => (
  <TableRow key={index}>
    <TableCell>
      {editingIndex === index ? (
        <Tooltip
          title={
            minPriceError ? 'Мин. цена не может быть больше макс. цены' : ''
          }
          placement="top-start"
          open={minPriceError}
        >
          <TextField
            value={factor.key.minPrice}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, index, 'minPrice')
            }
            disabled={
              !considerQuantityAvailabilityFactor || departmentValue === null
            }
            type="number"
            size="small"
            sx={inputTextStyle}
            InputProps={{
              sx: { height: 25 },
              inputProps: { min: 0, step: 0.01 },
            }}
            error={minPriceError}
          />
        </Tooltip>
      ) : (
        <Typography variant="body2">{factor.key.minPrice}</Typography>
      )}
    </TableCell>
    <TableCell>
      {editingIndex === index ? (
        <Tooltip
          title={
            maxPriceError ? 'Макс. цена не может быть меньше мин. цены' : ''
          }
          placement="top-start"
          open={maxPriceError}
        >
          <TextField
            value={factor.key.maxPrice}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, index, 'maxPrice')
            }
            disabled={
              !considerQuantityAvailabilityFactor || departmentValue === null
            }
            type="number"
            size="small"
            sx={inputTextStyle}
            InputProps={{
              sx: { height: 25 },
              inputProps: { min: 0, step: 0.01 },
            }}
            error={maxPriceError}
          />
        </Tooltip>
      ) : (
        <Typography variant="body2">{factor.key.maxPrice}</Typography>
      )}
    </TableCell>
    <TableCell>
      {editingIndex === index ? (
        <TextField
          value={factor.factor}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e, index, 'factor')
          }
          disabled={
            !considerQuantityAvailabilityFactor || departmentValue === null
          }
          type="number"
          size="small"
          sx={inputTextStyle}
          InputProps={{
            sx: { height: 25 },
            inputProps: { min: 1, step: 0.01 },
          }}
        />
      ) : (
        <Typography variant="body2">{factor.factor}</Typography>
      )}
    </TableCell>
    <TableCell>
      {editingIndex === index ? (
        <>
          <IconButton
            onClick={() => onSaveClick(index)}
            disabled={
              minPriceError || maxPriceError || departmentValue === null
            }
          >
            <SaveIcon sx={iconButtonStyle} />
          </IconButton>
          <IconButton onClick={() => onRemoveFactor(index)}>
            <DeleteIcon sx={iconButtonStyle} />
          </IconButton>
        </>
      ) : (
        <>
          <IconButton
            onClick={() => onEditClick(index)}
            disabled={departmentValue === null}
          >
            <EditIcon sx={iconButtonStyle} />
          </IconButton>
          <IconButton
            onClick={() => onRemoveFactor(index)}
            disabled={departmentValue === null}
          >
            <DeleteIcon sx={iconButtonStyle} />
          </IconButton>
        </>
      )}
    </TableCell>
  </TableRow>
)

export default FactorRow
