import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { CloseModal } from '../../../../shared/types/types'
import Box from '@mui/material/Box'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ModalCloseButton from '../../../../shared/components/UI/ModalCloseButton'
import { useHistory } from 'react-router-dom'
import { getSuppliersRoute } from '../../../../shared/services/routeService'

const SupplierBasketContent = ({ handleClose }: CloseModal) => {
  const history = useHistory()
  const handleSubmit = () => {
    history.push(getSuppliersRoute())
    handleClose()
  }

  return (
    <>
      <Box display="flex" justifyContent="center" marginTop={3}>
        <ErrorOutlineIcon color="error" sx={{ fontSize: '60px' }} />
      </Box>

      <Typography
        component="h4"
        sx={{
          color: 'secondary.main',
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: '600',
          fontSize: '1.25rem',
          my: 3,
        }}
      >
        Укажите минимальную сумму заказа для поставщика
      </Typography>

      <Stack flexDirection="row" alignItems="center" columnGap={'1rem'}>
        <Button onClick={handleSubmit} variant="contained" fullWidth>
          Указать
        </Button>

        <Button onClick={() => handleClose()} variant="outlined" fullWidth>
          Отменить
        </Button>
      </Stack>
      <ModalCloseButton handleClose={handleClose} />
    </>
  )
}

export default SupplierBasketContent
