import React, { RefObject } from 'react'
import { Box } from '@mui/material'

interface IElementWatch {
  componentNode?: React.ElementType
  element: RefObject<HTMLElement>
  isLoading: boolean
}

const ElementWatch = ({ element, isLoading, componentNode }: IElementWatch) => {
  return (
    <>
      <Box
        ref={element}
        component={componentNode ?? 'div'}
        sx={{
          height: '1px',
          width: '20px',
          backgroundColor: 'none',
          display: isLoading ? 'none' : 'block',
        }}
      />
    </>
  )
}

export default ElementWatch
