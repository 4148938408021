// MUI
import { Stack, Tooltip, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
// project imports
import 'dayjs/locale/ru'
import { useEffect, useState } from 'react'

// types
import { IDateRange } from '../../../types/types'

const CustomDateRangePicker = ({
  onDateRangeChange,
  rangePickerValue,
}: {
  onDateRangeChange: (newValue: IDateRange) => void
  rangePickerValue: IDateRange
}) => {
  const [rangeDate, setRangeDate] = useState<IDateRange>(rangePickerValue)

  const [isStartEmpty, setIsStartEmpty] = useState(false)
  const [isEndEmpty, setIsEndEmpty] = useState(false)

  const handleDateChange = async (newValue: Date, key: string) => {
    setRangeDate((prev) => ({
      ...prev,
      [key]: newValue,
    }))
  }

  useEffect(() => {
    const isStartEmpty = rangeDate.startDate === null
    const isEndEmpty = rangeDate.endDate === null

    const dateRange: [Date | null, Date | null] = [
      rangeDate.startDate,
      rangeDate.endDate,
    ]

    // Ловим ошибки
    setIsStartEmpty(isStartEmpty)
    setIsEndEmpty(isEndEmpty)

    // Если оба поля с датами пустые, снимаем ошибку
    if ((isStartEmpty && isEndEmpty) || dateRange === null) {
      setIsStartEmpty(false)
      setIsEndEmpty(false)
    }

    onDateRangeChange(rangeDate)
  }, [rangeDate])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <Stack
        direction="row"
        position={'relative'}
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          maxHeight: 40,
          height: 40,
          justifyContent: 'center',
          fontSize: 14,
        }}
      >
        <DatePicker
          slotProps={{ textField: { size: 'small' } }}
          maxDate={rangePickerValue.endDate}
          value={rangePickerValue.startDate}
          label="Начало завяки"
          onChange={(newStartDate) =>
            handleDateChange(newStartDate as Date, 'startDate')
          }
        />

        <Typography>до</Typography>
        <DatePicker
          slotProps={{ textField: { size: 'small' } }}
          sx={{
            maxHeight: 40,
            height: 40,
            fontSize: 14,
          }}
          value={rangePickerValue.endDate}
          minDate={rangePickerValue.startDate}
          label="Конец заявки"
          onChange={(newStartDate) =>
            handleDateChange(newStartDate as Date, 'endDate')
          }
        />
        {(isStartEmpty || isEndEmpty) && (
          <Tooltip title="Заполните оба поля с датами" arrow>
            <ErrorOutlineIcon
              sx={{
                paddingTop: '1px',
                paddingLeft: '2px',
                fontSize: '17px',
                color: 'red',
                verticalAlign: 'middle',
              }}
            />
          </Tooltip>
        )}
      </Stack>
    </LocalizationProvider>
  )
}

export default CustomDateRangePicker
