import { useActions } from '../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { useEffect, useRef, useState } from 'react'
import { useObserver } from '../../../shared/hooks/useObserver'
import { OTHERS } from '../consts/others'
import qs from 'qs'
import { LoadDirections } from '../../../shared/types/types'

type argsType = [number, number, any, boolean]

export const useOtherController = () => {
  const { fetchOtherProducts, setOtherPage, setOtherFilter, resetOtherFilter } =
    useActions()

  const {
    products,
    error,
    page,
    limit,
    filter,
    direction,
    enough,
    startSearch,
    order,
    orderBy,
  } = useTypedSelector((state) => state.other)

  const loading = useTypedSelector((state) => state.app.loading)

  const inputRef = useRef<null | Record<string, HTMLSpanElement>>({})
  const savePageNum = useRef(page)
  const firstElement = useRef<HTMLTableRowElement>(null)
  const lastElement = useRef<HTMLTableRowElement>(null)

  const isFullLength = products.length >= OTHERS.MAX_PRODUCT_ITEMS

  useObserver(firstElement, enough && page > 1 && isFullLength, loading, () => {
    const isNotUpDirection = direction !== LoadDirections.UP && isFullLength

    setOtherPage(
      isNotUpDirection
        ? page - Math.ceil(OTHERS.MAX_PRODUCT_ITEMS / limit)
        : page - 1
    )
  })

  useObserver(lastElement, enough, loading, () => {
    const isNotDownDirection = direction !== LoadDirections.DOWN && isFullLength

    setOtherPage(
      isNotDownDirection
        ? page + Math.ceil(OTHERS.MAX_PRODUCT_ITEMS / limit)
        : page + 1
    )
  })

  const [mounted, setMounted] = useState(false)

  const departmentId = useTypedSelector(
    (state) => state.app.defaultDepartment?.id
  )

  useEffect(() => {
    return () => {
      resetOtherFilter()
    }
  }, [])

  useEffect(() => {
    let newFilter = { ...filter }

    if (window.location.search && !mounted) {
      let parsedParams = qs.parse(window.location.search.substring(1))
      if (parsedParams.esName && parsedParams.name) {
        delete parsedParams.name
      }
      newFilter = { ...newFilter, ...parsedParams, departmentId }
      setOtherFilter(newFilter)
      setMounted(true)
    }

    let args: argsType = [page, limit, newFilter, savePageNum.current < page]
    const fetch = async (...args: argsType) => fetchOtherProducts(...args)

    fetch(...args).then(() => {
      if (!(isFullLength && inputRef.current !== null)) {
        savePageNum.current = page
        return
      }

      if (savePageNum.current < page) {
        inputRef.current[
          products[products.length - 1].product.id
        ]?.scrollIntoView() // скролл вниз
        return
      }

      if (savePageNum.current > page)
        inputRef.current[products[0].product.id]?.scrollIntoView() // скролл вверх
      savePageNum.current = page
    })
  }, [page, startSearch])

  return {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    products,
    order,
    orderBy,
  }
}
