import http from './config/instance'
import { IOptionsProps } from '../types/types'

export const getDepartments = (
  name?: string | null,
  pageSize?: number | null,
  departmentIds?: string | number
) =>
  http.httpCore.get<{ content: { data: IOptionsProps[] } }>('department', {
    params: {
      name,
      pageSize,
      departmentIds,
    },
  })
