import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Box, Button, SxProps, Theme, Typography } from '@mui/material'
import { IDepartmentProps } from '../types/supplierSettingsTypes'
import { getDepartment } from '../api/supplierSettings'
import SupplierSettingsTabs from './SupplierSettingsTabs'
import { useActions } from '../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import AutoOrderDepartmentAutoComplete from '../../../../../shared/components/autoCompletes/AutoOrderDepartmentAutoComplete'
import { SupplierSettingsState } from '../model/supplierSettings/supplierSettingsReduxTypes'

const style = {
  pointerEvents: 'none' as 'none',
  opacity: '0.5',
}

const mainBoxStyle: SxProps<Theme> = {
  border: '1px solid',
  borderColor: 'action.disabled',
  boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
  borderRadius: '8px',
  pb: 2,
  overflowX: 'initial',
  height: 'calc(100vh - 600px)',
  overflowY: 'auto',
  minHeight: '400px',
}

const buttonStyle: SxProps<Theme> = {
  flexShrink: 0,
  fontSize: '0.675rem',
  height: '80%',
  ml: 1,
}

const resetbuttonStyle: SxProps<Theme> = {
  flexShrink: 0,
  fontSize: '0.675rem',
  height: '80%',
  ml: 1,
}

function SupplierSettingsBlock() {
  const [departmentValue, setDepartmentValue] =
    useState<IDepartmentProps | null>(null)

  const claims = useTypedSelector((state) => state.auth.claims)

  const {
    fetchSupplierSettings,
    showResetSupplierSettingsModal,
    saveSupplierSettings,
  } = useActions()

  const departmentId = useTypedSelector(
    (state) => state.supplierSettings.departmentId
  )

  const supplierSettings = useTypedSelector((state) => state.supplierSettings)

  const updateByUserName = useTypedSelector(
    (state) => state.supplierSettings.updateByUserName
  )

  const updatedAt = useTypedSelector(
    (state) => state.supplierSettings.updatedAt
  )

  const processingItems = useTypedSelector(
    (state) => state.processingAutoOrder.items
  )

  const isResetProcessingAutoOrdersEnd = useTypedSelector(
    (state) => state.processingAutoOrder.isResetItemsEnd
  )

  useEffect(() => {
    let isMounted = true

    async function fetchDepartment() {
      if (isMounted) {
        const response = await getDepartment()
        const firstDepartment = response.data.content.items[0]
        if (firstDepartment) {
          setDepartmentValue(firstDepartment)
          fetchSupplierSettings(firstDepartment.id)
        }
      }
    }

    fetchDepartment()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (!isResetProcessingAutoOrdersEnd && processingItems.length === 0) {
      setDepartmentValue(null)
      fetchSupplierSettings(null)
    }
  }, [processingItems, isResetProcessingAutoOrdersEnd])

  let formattedDateTime = ''
  if (updatedAt) {
    const updatedAtDate = new Date(updatedAt)
    if (!isNaN(updatedAtDate.getTime())) {
      formattedDateTime = format(updatedAtDate, 'dd.MM.yyyy HH:mm')
    }
  }

  const handleSaveSupplierSettings = async (
    supplierSettings: SupplierSettingsState
  ) => {
    await saveSupplierSettings(supplierSettings)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!claims['promarket.auto_order_settings.edit']) {
      event.preventDefault()
    }
  }

  return (
    <Box
      onKeyDown={handleKeyPress}
      sx={{
        ...mainBoxStyle,
        ...(!claims['promarket.auto_order_settings.edit'] ? style : {}),
      }}
    >
      <Box
        sx={{
          m: 1,
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mt: '10px' }}>
          <Box sx={{ width: 230, flexShrink: 0 }}>
            <AutoOrderDepartmentAutoComplete
              value={departmentValue}
              handleDepartmentValue={setDepartmentValue}
              handleAutoCompleteFields={(val: number | null) =>
                fetchSupplierSettings(val)
              }
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        >
          {updateByUserName && departmentValue !== null && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                gap: 1,
                mb: 1,
              }}
            >
              <Typography sx={{ fontSize: '0.7rem' }}>
                {`Последнее обновление: ${updateByUserName} от ${formattedDateTime}`}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              disabled={departmentValue === null}
              variant="contained"
              sx={buttonStyle}
              onClick={() => handleSaveSupplierSettings(supplierSettings)}
            >
              Сохранить
            </Button>
            <Button
              disabled={departmentValue === null}
              variant="contained"
              sx={buttonStyle}
              onClick={() => fetchSupplierSettings(departmentId)}
            >
              Отменить
            </Button>
            <Button
              disabled={departmentValue === null}
              variant="outlined"
              sx={resetbuttonStyle}
              onClick={showResetSupplierSettingsModal}
            >
              Сбросить
            </Button>
          </Box>
        </Box>
      </Box>

      <SupplierSettingsTabs departmentValue={departmentValue} />
    </Box>
  )
}

export default SupplierSettingsBlock
