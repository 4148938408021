import { AutoOrderFieldTypeMap, IAutoOrderHeadCells } from '../types/autoOrderTypes'

export const AUTO_ORDER = {
  MIN_ITEMS_AFTER_LOADING: 31,
  START_DOWN_INDEX: 30,
  END_DOWN_INDEX: 90,
  START_UP_INDEX: 0,
  END_UP_INDEX: 59,
}

export enum AUTO_ORDER_STATUS {
  UNKNOWN,
  NEW,
  PROCESSED,
  PARTIALLY_PROCESSED,
  IN_PROCESSING,
}

export const headCellsArray: IAutoOrderHeadCells[] = [
  {
    name: 'Дата создания',
    align: 'left',
    field: AutoOrderFieldTypeMap.creationDate.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
  {
    name: '№ Док.',
    align: 'inherit',
    field: AutoOrderFieldTypeMap.code.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
  {
    name: 'Грузополучатель',
    align: 'left',
    field: AutoOrderFieldTypeMap.departmentName.value,
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
    disableSort: false,
  },
  {
    name: 'Статус',
    align: 'left',
    field: AutoOrderFieldTypeMap.status.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
  {
    name: 'Дата обработки',
    align: 'left',
    field: AutoOrderFieldTypeMap.processDate.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
  {
    name: 'Комментарий',
    align: 'left',
    field: AutoOrderFieldTypeMap.description.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
]
