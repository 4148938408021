import React from 'react'

// mui
import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
} from '@mui/material'

import ErrorIcon from '@mui/icons-material/Error'

// project
import { headCellsArray } from '../consts/departmentsPreOrder'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
//

// import { RedTooltip } from '../../../../../shared/components/styled/RedToolTip'
import { useActions } from '../../../../../shared/hooks/useActions'
import {
  DepartmentsPreOrderFieldTypeMap,
  IPreOrder,
} from '../types/departmentsPreOrderTypes'
import { useDepartMentsPreOrderController } from '../controller/useDepartmentsPreOrderController'
import ElementWatch from '../../../../Product/components/ElementWatch'

const tableContainerStyle: SxProps<Theme> = {
  border: '1px solid',
  borderColor: 'action.disabled',
  boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
  borderRadius: '8px',
  pb: 1,
  overflowX: 'initial',
  height: 'calc(100vh - 200px)',
  overflowY: 'auto',
  maxWidth: 350,
  minWidth: 350,
}
const tableHeadStyle: SxProps<Theme> = {
  '& th': {
    whiteSpace: 'nowrap',
    fontSize: '0.53rem',
    color: 'primary.main',
    fontWeight: '770',
    textTransform: 'uppercase',
    borderBottom: 0,
    backgroundColor: 'background.paper',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
}

const DepartmentsPreOrderTable = () => {
  const { firstElement, lastElement, loading, inputRef } =
    useDepartMentsPreOrderController()

  const {
    saveSelectPreOrder,
    savePreOrderFilters,
    clearAutoOrders,
    clearDepartment,
    updatePreOrders,
    tempShow,
  } = useActions()

  const { preOrders, preOrderSort, selectPreOrder, tempShow: tempShowCheck } = useTypedSelector(
    (state) => state.autoOrderFinalDepartments
  )

  const checkActiveSort = (field: string) => {
    const currentSort =
      DepartmentsPreOrderFieldTypeMap[
        field as keyof typeof DepartmentsPreOrderFieldTypeMap
      ]
    return preOrderSort === currentSort.asc
  }

  const haldeSortHandler = (field: string) => {
    const currentSort =
      DepartmentsPreOrderFieldTypeMap[
        field as keyof typeof DepartmentsPreOrderFieldTypeMap
      ]
    if (preOrderSort === currentSort.asc) savePreOrderFilters(currentSort.desc)
    else savePreOrderFilters(currentSort.asc)
    clearAutoOrders()
    clearDepartment()
  }

  const handleSelectPreOrder = (row: IPreOrder) => {
    if (selectPreOrder?.id === row.id) return
    saveSelectPreOrder(row)
    clearAutoOrders()
    updatePreOrders(true)
    if(tempShowCheck) tempShow(false)  // todo refactor 
  }

  return (
    <TableContainer sx={tableContainerStyle}>
      <Table size="small" aria-label="departments pre-order table">
        <TableHead>
          <TableRow sx={tableHeadStyle}>
            {headCellsArray.map((item) => (
              <TableCell
                key={item.name}
                align={item.align}
                sx={{
                  padding: item.enablePaddings
                    ? '0.5rem 1rem 0 1rem'
                    : '0.5rem 0 0 0',
                  cursor: 'pointer',
                  '& th': {
                    minWidth: '240px'
                  }
                }}
                
              >
                <TableSortLabel
                  active={checkActiveSort(item.field)}
                  direction={checkActiveSort(item.field) ? 'asc' : 'desc'}
                  onClick={() => haldeSortHandler(item.field)}
                >
                  <Box sx={{ pl: item.enableLeftPadding ? '18px' : 'unset' }}>
                    {item.name}
                  </Box>
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <ElementWatch
            componentNode={'tr'}
            element={firstElement}
            isLoading={loading}
          />
          {preOrders.map((row, id) => (
            <React.Fragment key={row.id}>
              <TableRow
                key={row.id}
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'action.disabled',
                  '&:last-child td, &:last-child th': { border: 0 },
                  '& td': {
                    fontSize: 12,
                    verticalAlign: 'top',
                  },
                    backgroundColor:
                  selectPreOrder?.id === row.id ? 'ButtonShadow' : '',

                  height: 40,
                  maxHeight: 40,
                  maxWidth: 250,
                  cursor: 'pointer',

                  '& p': {
                    marginTop: '2px',
                    marginBottom: '2px',
                  },
                }}
                onClick={() => handleSelectPreOrder(row)}
              >
                <TableCell align="left">
                  <Box component={'p'}>{row.supplierName}</Box>
                  <Box component={'p'}>{row.departmentName}</Box>
                  <Box component={'p'}>{row.priceListTypeName}</Box>
                </TableCell>
                <TableCell align="left">{row.orderCost}</TableCell>
                {/* <TableCell align='left'>
                <RedTooltip title="Error">
                <ErrorIcon sx={{ color: 'red', fontSize: '18px' }} />
                </RedTooltip>
              </TableCell> */}
              </TableRow>
              <tr
                // @ts-ignore
                ref={(el) => (inputRef.current[row.esCode] = el)}
              />
            </React.Fragment>
          ))}
          {preOrders.length > 0 && (
            <ElementWatch
              componentNode={'tr'}
              element={lastElement}
              isLoading={loading}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DepartmentsPreOrderTable
