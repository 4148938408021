import React, { useEffect } from 'react'

// project imports
import BackToLastPage from '../../shared/components/BackToLastPage'
import SaleDepartmentsTable from './components/SaleDepartments/components/SaleDepartmentsTable'
import SaleCostBonuses from './components/SaleCostBonuses/components/SaleCostBonuses'
import SaleComplexTable from './components/SaleComplex/components/SaleComplexTable'
import SaleDepartmentsHeader from './components/SaleDepartments/components/SaleDepartmentsHeader'
import SaleComplexHeader from './components/SaleComplex/components/SaleComplexHeader'

// mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { alpha, IconButton, Slide, Switch, useTheme } from '@mui/material' // todo ref
import Tooltip from '@mui/material/Tooltip'

// redux
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import Stack from '@mui/material/Stack'

// todo ref
import { styled } from '@mui/material/styles'
import { TypographyStyled } from './styled/saleStyled'
import {
  CalculationStatus,
  getComplexOrderCondition,
  resetSaleComplexOrderApi,
} from './api/sale'
import { useActions } from '../../shared/hooks/useActions'

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import StopCircleIcon from '@mui/icons-material/StopCircle'

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#139409' : '#09cb13',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

const SaleMain = () => {
  const isComplexTableShowed = useTypedSelector(
    (state) => state.saleDepartments.isComplexTableShowed
  )

  const consigneeDepartment = useTypedSelector(
    (state) => state.saleComplex.options.consigneeDepartment
  )



  const {
    showComplexTable,
    fetchSaleDepartmentsProducts,
    resetSaleDepartmentsItems,
    resetSaleComplexState,
    resetCostBonusesStateAction,
    fetchSaleComplexOptionsAction,
  } = useActions()

  const [checked, setChecked] = React.useState(true)

  const handleChange = () => {
    setChecked((prev) => !prev)
  }



  const startResetSaleComplexOrder = () => {
    showComplexTable()
    if (isComplexTableShowed) {
      const fetchDepartments = async () => fetchSaleDepartmentsProducts()
      resetSaleComplexOrderApi().then(() => {
        resetSaleDepartmentsItems()
        resetSaleComplexState()
        resetCostBonusesStateAction()
        fetchDepartments()
        fetchSaleComplexOptionsAction()
      })
    }
  }

  useEffect(() => {
    getComplexOrderCondition().then((res) => {
      let status = res.data.content.calculationStatus
      if (status === CalculationStatus.CALCULATED) {
        if (!isComplexTableShowed) showComplexTable()
      }
    })
  }, [])

  const theme = useTheme()

  return (
    <Box
      mr={2}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center" columnGap={'1.34rem'}>
        <BackToLastPage text={'Комплексный заказ'} marginDisabled />
        <Stack direction="row" spacing={1} alignItems="center">
          <AntSwitch onChange={handleChange} checked={checked} />
          <TypographyStyled>Вкл доп.инфо</TypographyStyled>
        </Stack>

        <Tooltip
          placement="right-start"
          title={
            !isComplexTableShowed ? 'Сформировать заказ' : 'Отменить заказ'
          }
        >
          <IconButton
            color={!isComplexTableShowed ? 'success' : 'error'}
            onClick={startResetSaleComplexOrder}
          >
            {!isComplexTableShowed ? (
              <PlayCircleOutlineIcon />
            ) : (
              <StopCircleIcon />
            )}
          </IconButton>
        </Tooltip>
        
      </Box>

      <Grid
        container
        columnSpacing={1}
        sx={{ minHeight: 'calc(100vh - 240px)' }}
      >
        <Slide direction="down" in={checked} timeout={300}>
          <Grid
            sx={{ position: 'relative' }}
            item
            xs={
              isComplexTableShowed
                ? !checked
                  ? 0.1
                  : 3.4
                : !checked
                ? 0.1
                : 4.3
            }
          >
            <SaleDepartmentsHeader />
            <SaleDepartmentsTable />
            {consigneeDepartment?.departmentName.length > 0 && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '1.5rem',
                  left: '1.5rem',
                  right: '2rem',
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                  backgroundColor: 'background.paper',
                  color: 'primary.main',
                  borderRadius: '8px',
                  padding: '0.5rem 1rem',
                  fontSize: '0.725rem',
                  '&:before': {
                    content: '" "',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    zIndex: 1,
                  },
                }}
              >
                Товарополучатель КЗ:{' '}
                <span style={{ fontWeight: 700 }}>
                  {consigneeDepartment?.departmentName}
                </span>
              </Box>
            )}
          </Grid>
        </Slide>

        <Grid
          item
          xs={
            isComplexTableShowed
              ? !checked
                ? true
                : 8.6
              : !checked
              ? true
              : 7.7
          }
        >
          <SaleComplexHeader />
          {isComplexTableShowed && <SaleComplexTable />}
        </Grid>
      </Grid>
      <SaleCostBonuses />
    </Box>
  )
}

export default SaleMain
