import React from 'react'
import { PRICE_LIST_RANGE } from '../consts/priceListRange'

const { MIN_DISTANCE, MAX_RANGE } = PRICE_LIST_RANGE

export const useRange = (
  setMinRange: (payload: number) => void,
  setMaxRange: (payload: number) => void,
  minValue: number,
  maxValue: number
) => {
  const handleChangeSlider = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return
    }
    if (activeThumb === 0) {
      setMinRange(Math.min(newValue[0], maxValue - MIN_DISTANCE))
    } else {
      setMaxRange(Math.max(newValue[1], minValue + MIN_DISTANCE))
    }
  }

  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.name
    let newValue = Number(event.target.value)
    if (isNaN(newValue)) return
    if (newValue > MAX_RANGE) return
    if (name === 'leftRange') {
      setMinRange(newValue)
    } else {
      setMaxRange(newValue)
    }
  }
  return {
    handleRangeChange,
    handleChangeSlider,
  }
}
