import React, { useEffect } from 'react'
import { Button, Slide } from '@mui/material'
import Box from '@mui/material/Box'
import { DRAWER } from '../../shared/consts/drawer'
import {
  getEditHistoryRouteByOrderId,
  getHomeRoute,
} from '../../shared/services/routeService'
import { ModeList } from '../../shared/store/application/applicationReduxTypes'
import { useActions } from '../../shared/hooks/useActions'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { useHistory, useLocation } from 'react-router-dom'

const editRoutes = [getHomeRoute()]

const PopoverWarning = ({ isMainPage }: { isMainPage: boolean }) => {
  const history = useHistory()
  const location = useLocation()

  const { editItemsModeEnable, resetFilterProducts, initProductEditMode } =
    useActions()

  const filter = useTypedSelector((state) => state.product.filter)
  const { data: idOrder } = useTypedSelector((state) => state.app.appMode)
  const isCurrentMode = useTypedSelector((state) => state.app.appMode.mode)
  const isEditModeInitialized = useTypedSelector(
    (state) => state.product.isEditModeInitialized
  )

  const routeHandler = () => {
    history.push(getEditHistoryRouteByOrderId(idOrder))
  }

  useEffect(() => {
    const isEditMode = isCurrentMode === ModeList.EDIT && isEditModeInitialized
    const inRoute = editRoutes.includes(location.pathname)
    if (inRoute && isEditMode) return
    editItemsModeEnable({ mode: ModeList.UNSET, data: '' })
    initProductEditMode(false)

    if (Object.keys(filter).length > 0) {
      resetFilterProducts()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Slide
      direction="up"
      in={!!idOrder}
      mountOnEnter
      unmountOnExit
      timeout={800}
    >
      <Box
        onClick={routeHandler}
        sx={{
          backgroundColor: 'background.default',
          position: 'fixed',
          right: 0,
          bottom: '15px',
          width: !isMainPage ? '100%' : `calc(100% - ${DRAWER.WIDTH}px)`,
          zIndex: 1,
          p: '16px 16px',
          borderRadius: '3px',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Box
          fontWeight={500}
          fontSize={14}
          sx={{
            display: 'flex',
            columnGap: '8px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Вернуться в редактирование заказа № {idOrder}
          <Button color="primary" variant="contained">
            Перейти
          </Button>
        </Box>
      </Box>
    </Slide>
  )
}

export default PopoverWarning
