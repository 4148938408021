import React from 'react'
import { TextFieldCustom } from '../../../../shared/components/styled/TextFieldCustom'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import RenderIcons from '../RenderIcons'

interface ISpecialTextField {
  filter: any
  setFilter: (payload: any) => void
  handleTextFields: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => void
  handleOrderChange: (key: string) => void
  name: string
  label: string
  nameOrder: string
}

const StandardTextField = ({
  filter,
  setFilter,
  handleTextFields,
  handleKeyPress,
  handleOrderChange,
  name,
  label,
  nameOrder,
}: ISpecialTextField) => {
  return (
    <>
      <TextFieldCustom
        InputProps={{
          endAdornment: (
            <>
              {filter[name] && (
                <IconButton
                  size="small"
                  onClick={() => setFilter({ ...filter, [name]: '' })}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
            </>
          ),
        }}
        id={name}
        name={name}
        label={label}
        value={filter[name]}
        onChange={handleTextFields}
        variant="outlined"
        size="small"
        fullWidth
        onKeyPress={handleKeyPress}
      />
      <IconButton
        sx={{ ml: '3px' }}
        onClick={() => handleOrderChange(nameOrder)}
      >
        <RenderIcons condition={filter[nameOrder]} />
      </IconButton>
    </>
  )
}

export default StandardTextField
