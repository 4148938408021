// import types

import { LoadDirections } from '../../../../../shared/types/types'
import { AUTO_ORDER_FINAL_CONSTS } from '../consts/autoOrderConsts'
import {
  AutoOrderFinalAction,
  AutoOrderFinalActionTypes,
  AutoOrderFinalState,
} from './autoOrderFInalReduxTypes'

const initialState: AutoOrderFinalState = {
  autoOrders: [],
  autoOrderSorts: 'BitProductName',
  loading: false,
  page: 1,
  limit: AUTO_ORDER_FINAL_CONSTS.DEFAULT_COUNT,
  total: 0,
  enough: false,
  direction: LoadDirections.DOWN,
  activeFields: {
    esCode: true,
    quantityToOrder: true,
    expirationDate: true,
    valueAddedTaxPercentage: true,
    registeredPrice: true,
    isVitalMedicinalProduct: true,
    minimalQuantity: true,
    quantitySold: true,
    quantityInPriceList: true,
    multiplicityOfBoxQuantity: true,
    producerName: true,
    price: true,
    cost: true,
    remainingQuantity: true,
    bitProductName: true,
    productName: true,
  },
  totalSum: 0,
  deletedPosition: '',
}

export const autoOrderProducts = (
  state = initialState,
  action: AutoOrderFinalAction
) => {
  switch (action.type) {
    case AutoOrderFinalActionTypes.AUTO_ORDER_REQ:
      return {
        ...state,
        loading: action.payload.loading,
        enough: false,
        direction: action.payload.loadFlag
          ? LoadDirections.DOWN
          : LoadDirections.UP,
      }
    case AutoOrderFinalActionTypes.SAVE_AUTO_ORDER_PRODUCTS:
      const { content, loadFlag, loading } = action.payload

      const {
        MIN_ITEMS_AFTER_LOADING,
        START_DOWN_INDEX,
        END_DOWN_INDEX,
        START_UP_INDEX,
        END_UP_INDEX,
      } = AUTO_ORDER_FINAL_CONSTS

      if (state.autoOrders.length > MIN_ITEMS_AFTER_LOADING) {
        if (state.direction === LoadDirections.DOWN) {
          const itemsCopy = state.autoOrders.slice(
            START_DOWN_INDEX,
            END_DOWN_INDEX
          )
          return {
            ...state,
            autoOrders: [...itemsCopy, ...content.items],
            total: content.pagination.total,
            loading,
          }
        } else if (state.direction === LoadDirections.UP) {
          const itemsCopy = state.autoOrders.slice(START_UP_INDEX, END_UP_INDEX)
          return {
            ...state,
            autoOrders: [...content.items, ...itemsCopy],
            total: content.pagination.total,
            loading,
          }
        }
      }

      return {
        ...state,
        autoOrders: [...state.autoOrders, ...content.items],
        total: content.pagination.total,
        direction: loadFlag ? LoadDirections.DOWN : LoadDirections.UP,
      }
    case AutoOrderFinalActionTypes.CHANGE_AMOUNT_RESPONSE:
      const { autoOrderCost, newAutoOrderBidProduct } = action.payload
      const newTotoal = autoOrderCost.orderCost
      const copyAutoOrders = [...state.autoOrders].map((autoOrder) => {
        if (autoOrder.id === newAutoOrderBidProduct.id)
          return { ...autoOrder, cost: newAutoOrderBidProduct.cost }
        return autoOrder
      })
      return {
        ...state,
        totalSum: newTotoal,
        autoOrders: copyAutoOrders,
      }
    case AutoOrderFinalActionTypes.SET_AUTO_ORDER_FINAL_SORT:
      return {
        ...state,
        autoOrderSorts: action.payload,
        page: 1,
      }
    case AutoOrderFinalActionTypes.LOADING_AUTO_ORDER_PRODUCT_ENB:
      return {
        ...state,
        loading: true,
      }
    case AutoOrderFinalActionTypes.LOADING_AUTO_ORDER_PRODUCT_DSB:
      return {
        ...state,
        loading: false,
      }
    case AutoOrderFinalActionTypes.SET_AUTO_ORDER_FINAL_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    case AutoOrderFinalActionTypes.AUTO_ORDER_PRODUCTS_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }
    case AutoOrderFinalActionTypes.SET_AUTO_ORDER_ACTIVE_FIELS:
      return {
        ...state,
        activeFields: action.payload,
      }
    case AutoOrderFinalActionTypes.CLEAR_AUTO_ORDERS:
      return {
        ...state,
        autoOrders: [],
      }
    case AutoOrderFinalActionTypes.SAVE_AUTO_ORDER_SUM:
      return {
        ...state,
        totalSum: action.payload,
      }
    case AutoOrderFinalActionTypes.SAVE_DELTED_POSITION:
      return {
        ...state,
        deletedPosition: action.payload,
      }
    case AutoOrderFinalActionTypes.DELETE_POSITION:
      const deletedId = action.payload
      const copyOrders = [...state.autoOrders]
      const newAutoOrderList = copyOrders.filter(
        (order) => order.autoOrderBidSimilarProductsGroupId !== deletedId
      )
      return {
        ...state,
        autoOrders: newAutoOrderList,
      }
    default:
      return state
  }
}
