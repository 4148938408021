import React, { useEffect } from "react";

// project
import ErrorPage from "../Product/components/ErrorPage";
import ElementWatch from "../Product/components/ElementWatch";
import OthersAppBar from "./OthersAppBar";
import OthersTableRow from "./OthersTableRow";

// mui
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableSortLabel from "@mui/material/TableSortLabel";

// hooks
import { useOtherController } from "./controllers/useOtherController";
import { useActions } from "../../shared/hooks/useActions";

// types
import { OtherFieldType } from "./types/otherTypes";

// const
import { headCellsArray1 } from "./consts/others";

// libs
import qs from "qs";
import { useTypedSelector } from "../../shared/hooks/useTypedSelector";

const OthersMain = () => {
  const basketItemId = qs.parse(
    window.location.search.substring(1)
  ).basketItemId;

  const { setOtherSort, resetCheckboxs } = useActions();
  const claims = useTypedSelector((state) => state.auth.claims);

  const {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    products,
    order,
    orderBy
  } = useOtherController();

  const sortHandler = (field: OtherFieldType) => {
    const isAsc = orderBy === field && order === "asc";
    setOtherSort({ orderBy: field, order: isAsc ? "desc" : "asc" });
  };

  useEffect(() => {
    return () => {
      resetCheckboxs();
    };
  }, []);

  if (Object.keys(error).length > 0) {
    return (
      <ErrorPage
        {...{
          error,
          firstElement,
          lastElement
        }}
      />
    );
  }
  return (
    <Box>
      <OthersAppBar />
      <TableContainer
        sx={{
          border: "1px solid",
          borderColor: "action.disabled",
          boxShadow: "0px 8px 24px -4px rgba(27, 46, 94, 0.08)",
          borderRadius: "8px",
          pb: 2,
          overflowX: "initial"
        }}
      >
        <Table size="small" aria-label="a alternative-product table">
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  whiteSpace: "nowrap",
                  fontSize: "0.75rem",
                  color: "primary.main",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  borderBottom: 0,
                  borderRadius: "15px",
                  backgroundColor: "background.paper",
                  position: "sticky",
                  top: 65,
                  zIndex: 1
                }
              }}
            >
              <TableCell />
              {headCellsArray1.map((item) => (
                <TableCell key={item.name} align={item.align}>
                  {!item.disableField && (
                    <TableSortLabel
                      active={orderBy === item.field}
                      direction={orderBy === item.field ? order : "asc"}
                      onClick={() => sortHandler(item.field)}
                    >
                      <Box sx={{ pl: item.enablePadding ? "18px" : "unset" }}>
                        {item.name}
                      </Box>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
              {claims["promarket.basket_item.upsert"] &&
                !basketItemId
                &&
                <TableCell align="center">Кол-во</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            <ElementWatch
              componentNode={'tr'}
              element={firstElement}
              isLoading={loading}
            />
            {products.map((row, index) => (
              <React.Fragment key={row.product.id + index}>
                <OthersTableRow row={row} />
                <tr
                  // @ts-ignore
                  ref={(el) => (inputRef.current[row.product.id] = el)}
                />
              </React.Fragment>
            ))}
            <ElementWatch
              componentNode={'tr'}
              element={lastElement}
              isLoading={loading}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OthersMain;
