import React, { useEffect } from 'react'

// third lib
import { useHistory } from 'react-router-dom'

//mui
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import SendIcon from '@mui/icons-material/Send'

//selector
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'

//project
import BackToAutoOrderPage from '../../shared/components/BackToAutoOrderPage'
import ProcessingAutoOrderTable from './components/ProcessingAutoOrder/components/ProcessingAutoOrderTable'
import SupplierListBlock from './components/SupplierList/components/SupplierListBlock'
import SupplierSettingsBlock from './components/SupplierSettings/components/SupplierSettingsBlock'
import { getAutoOrderFinalRoute } from '../../shared/services/routeService'

const SettingsAutoOrderMain = () => {
  const history = useHistory()

  const processingItems = useTypedSelector(
    (state) => state.processingAutoOrder.items
  )
  const { calculationStatus } = useTypedSelector(
    (state) => state.autoOrder.conditionStatus
  )

  const handleView = () => {
    history.push(getAutoOrderFinalRoute())
  }

  useEffect(() => {
    if (calculationStatus === 1) history.push(getAutoOrderFinalRoute())
  }, [calculationStatus])

  return (
    <>
      <BackToAutoOrderPage marginDisabled text={'Настройки заявки'} />
      <Stack direction="row" justifyContent="end" mr={2} mb={2} mt={0}>
        <Button
          onClick={handleView}
          disabled={processingItems.length < 1}
          variant="contained"
          endIcon={<SendIcon />}
          sx={{ flexShrink: 0, fontSize: '0.675rem', height: 35 }}
        >
          Сформировать
        </Button>
      </Stack>

      <Box display="flex" flexDirection="column" m={2} mt={0}>
        <Box display="flex" gap={1} mb={1}>
          <Box flexGrow={3}>
            <SupplierListBlock />
          </Box>

          <Box flexGrow={6}>
            <SupplierSettingsBlock />
          </Box>
        </Box>

        <Box>
          <ProcessingAutoOrderTable />
        </Box>
      </Box>
    </>
  )
}

export default SettingsAutoOrderMain
