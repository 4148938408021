export const TRANSLATIONS_EN = {
  dialog: {
    none: 'no options',
    new: 'new',
    completed: 'completed',
    sended: 'sended',
    parted_approved: 'parted approved',
    approved: 'approved',
    refused: 'refused',
  },
}
