import React from 'react'
import Badge from '@mui/material/Badge'
import RemoveIcon from '@mui/icons-material/Remove'
import { Tooltip } from '@mui/material'

interface IBadgeRejectedProps {
  confirmed: number
  totalCount: number
}
const BadgeRejected = (props: IBadgeRejectedProps) => {
  const { confirmed, totalCount } = props
  return (
    <>
      <Tooltip
        title={`Одобрено ${confirmed} шт. из оформленных ${totalCount} шт.`}
      >
        <Badge
          sx={{
            '& .MuiBadge-badge.MuiBadge-standard': {
              zIndex: 0,
              width: '14px',
              minWidth: '14px',
              height: '14px',
            },
          }}
          color="error"
          badgeContent={<RemoveIcon sx={{ fontSize: '12px' }} />}
        />
      </Tooltip>
    </>
  )
}

export default BadgeRejected
