import { styled } from "@mui/material/styles"
import { TextField } from "@mui/material"

export const TextFieldCustom = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B5C3DC"
    }
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.875rem",
    lineHeight: "1.7375em"
  },
  "& input::placeholder": {
    fontSize: "0.875rem",
    fontWeight: "600",
    color: "#909DB5"
  }
})