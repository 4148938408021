import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ProductControllerType } from '../../../../shared/controllers/useProductItemController';


export default function ProductDetailedTable({ tableValues, product }: 
    { tableValues: Array<{ label: string, field: string}>, product: ProductControllerType }) 
    {
  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {tableValues.map((row) => (
            <TableRow
              key={row.field}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{
                padding: '0 4px',
                border: 'none',
                display: 'flex',
                alignItems: 'flex-start'
              }} component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell sx={{
                padding: '1px 0',
                border: 'none',
                verticalAlign: 'top'
              }} align="left">{product[row.field]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
