import React, { useState } from 'react'

// mui
import { Box, Tab, Tabs } from '@mui/material'

// project
import PriorityTab from './PriorityTab'
import OrderParametersTab from './OrderParametersTab'
import SupplierParametrsTab from './SupplierParametrsTab'
import FactorTab from './FactorTab'
import { IDepartmentProps } from '../types/supplierSettingsTypes'

const SupplierSettingsTabs = ({
  departmentValue,
}: {
  departmentValue: IDepartmentProps | null
}) => {
  interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
  }

  const [currentTab, setCurrentTab] = useState(0)

  const handleCurrentTab = (val: number) => {
    setCurrentTab(val)
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        style={{ height: '100%', overflow: 'auto' }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Box sx={{ fontSize: '0.775rem' }}>{children}</Box>
          </Box>
        )}
      </div>
    )
  }

  function tabsProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  interface IBasicTabs {
    currentTab: number
    handleCurrentTab: (val: number) => void
  }

  const BasicTabs = ({ currentTab, handleCurrentTab }: IBasicTabs) => {

    const handleChange = (e: React.SyntheticEvent, newValue: number) => {
      e.stopPropagation()
      handleCurrentTab(newValue)
    }

    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{ width: '25%', fontSize: '0.675rem' }}
              label="Параметры товаров"
              {...tabsProps(0)}
            />
            <Tab
              sx={{ width: '25%', fontSize: '0.675rem' }}
              label="Параметры поставщиков"
              {...tabsProps(1)}
            />
            <Tab
              sx={{ width: '25%', fontSize: '0.675rem' }}
              label="Приоритеты"
              {...tabsProps(2)}
            />
            <Tab
              sx={{ width: '25%', fontSize: '0.675rem' }}
              label="КДК"
              {...tabsProps(3)}
            />
          </Tabs>
        </Box>

        {/* параметры товаров */}
        <CustomTabPanel value={currentTab} index={0}>
          <OrderParametersTab departmentValue={departmentValue} />
        </CustomTabPanel>

        {/* Параметры поставщиков */}
        <CustomTabPanel value={currentTab} index={1}>
          <SupplierParametrsTab departmentValue={departmentValue} />
        </CustomTabPanel>

        {/* Приоритеты */}
        <CustomTabPanel value={currentTab} index={2}>
          <PriorityTab departmentValue={departmentValue} />
        </CustomTabPanel>

        {/* КДК */}
        <CustomTabPanel value={currentTab} index={3}>
          <FactorTab departmentValue={departmentValue} />
        </CustomTabPanel>
      </Box>
    )
  }

  return (
    <BasicTabs handleCurrentTab={handleCurrentTab} currentTab={currentTab} />
  )
}

export default SupplierSettingsTabs

