import React from 'react'

// mui
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'

// project
import { TextFieldCustom } from '../../../../shared/components/styled/TextFieldCustom'
import PriceListControlStack from './components/PriceListControlStack'
import PriceListSettings from './components/PriceListSettings/PriceListSettings'
import PriceListDeliverySchedule from './components/PriceListDeliverySchedule/PriceListDeliverySchedule'

// hooks
import { usePriceListParamsController } from './controllers/usePriceListParamsController'
import { onlyNumbers } from '../../../../shared/services/commonService'

export const gridItemStyle = {
  display: 'flex',
  flexDirection: 'row' as 'row',
  alignItems: 'flex-start',
  columnGap: '10px',
}

export const textFieldStyle = {
  flexBasis: 193,
  width: 193,
}

export const PaperStyle = {
  border: '1px solid #E1E3E6',
  boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
  borderRadius: '8px',
  padding: '25px 20px',
}

const PriceList = () => {
  const {
    validationsErrors,
    handleTextField,
    onSubmit,
    debounceRequest,
    isEmptyList,
    plist,
    handleOnChange,
    resetLists,
    orderParameters,
    setOrderParameters,
    handleChangePriority,
    handleCheckbox,
  } = usePriceListParamsController()

  return (
    <>
      <Box component="div" sx={PaperStyle}>
        <Typography
          fontWeight={600}
          fontSize={12}
          color="primary.main"
          sx={{ textTransform: 'uppercase' }}
        >
          Параметры заказа
        </Typography>

        <Grid container spacing={2} marginY={0.5}>
          <Grid item xs={6} sx={gridItemStyle}>
            <Typography
              component="div"
              fontWeight={400}
              fontSize={14}
              flexBasis={266}
              width={266}
            >
              Минимальная сумма заказа
            </Typography>
            <TextFieldCustom
              error={validationsErrors.minSumError}
              helperText={
                validationsErrors.minSumError && 'некорректное значение'
              }
              onChange={handleTextField}
              sx={textFieldStyle}
              variant="outlined"
              size="small"
              value={orderParameters.minSum}
              InputProps={{
                endAdornment: (
                  <>
                    {/* old version pm-547 */}
                    {/* {orderParameters['minSum'] && (
                      <IconButton
                        size="small"
                        onClick={() =>
                          setOrderParameters({ ...orderParameters, minSum: '' })
                        }
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    )} */}

                    <IconButton
                      size="small"
                      onClick={() =>
                        setOrderParameters({ ...orderParameters, minSum: '0' })
                      }
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </>
                ),
              }}
            />
          </Grid>

          {/* // TODO не удалять! Для будущих разработок */}
          {/* <Grid item xs={6} justifyContent="flex-end" sx={gridItemStyleHide}>
            <Typography
              fontWeight={400}
              fontSize={14}
              flexBasis={178}
              width={178}
            >
              Дней для оплаты заказа
            </Typography>
            <TextFieldCustom
              sx={textFieldStyle}
              variant="outlined"
              size="small"
              defaultValue="30"
              InputProps={{
                endAdornment: (
                  <IconButton size="small">
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </Grid> */}

          <Grid item xs={6} sx={gridItemStyle}>
            <Typography fontWeight={400} fontSize={14}>
              Приоритет поставщика при авторасторговке
            </Typography>
            <TextFieldCustom
              sx={textFieldStyle}
              variant="outlined"
              style={{
                backgroundColor: 'inherit',
                color: 'inherit',
                lineHeight: '30px',
                fontSize: '14px',
                textAlign: 'center',
                width: '55px',
                border: 0,
                outline: 0,
                marginLeft: '12px',
              }}
              size="small"
              type="number"
              value={orderParameters.prority}
              onChange={handleChangePriority}
            />
          </Grid>

          {/* // TODO не удалять! Для будущих разработок */}
          {/* <Grid item xs={6} justifyContent="flex-end" sx={gridItemStyleHide}>
            <Typography fontWeight={400} fontSize={14}>
              Дней для доставки заказа
            </Typography>
            <TextFieldCustom
              sx={textFieldStyle}
              variant="outlined"
              size="small"
              onInput={onlyNumbers}
              defaultValue="30"
              InputProps={{
                endAdornment: (
                  <IconButton size="small">
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </Grid> */}
        </Grid>

        <Box marginTop={4}>
          <FormControlLabel
            control={<Checkbox checked={orderParameters.isActive} />}
            onChange={handleCheckbox}
            label={
              <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                Поставщик участвует в авторасторговке
              </Typography>
            }
          />
        </Box>

        <PriceListControlStack
          justifyContent={'flex-end'}
          saveFunc={onSubmit}
          resetFunc={() =>
            setOrderParameters({ ...orderParameters, minSum: '0' })
          }
        />
      </Box>

      <PriceListSettings
        request={debounceRequest}
        list={plist}
        isEmpty={isEmptyList}
        handleOnChange={handleOnChange}
        onSubmit={onSubmit}
        resetFunc={resetLists}
      />

      <PriceListDeliverySchedule />
    </>
  )
}

export default PriceList
