import React, { useState } from 'react'

// mui
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Checkbox, SxProps, Theme } from '@mui/material'

// project
import FormattedDate1 from '../../../shared/components/FormattedDate1'
import FormattedPrice1 from '../../../shared/components/FormattedPrice1'
import { ISubAutoOrderItem } from '../types/subAutoOrderTypes'
import SubAutoOrderProduct from './SubAutoOrderProduct'


const collapseStyle: React.CSSProperties = {
  paddingBottom: 0,
  paddingTop: 0,
  paddingLeft: 3,
  backgroundColor: '#D9D9D933',
}

const rowStyle: SxProps<Theme> = {
  backgroundColor: 'unset',
  '&:last-child td, &:last-child th': { border: 0 },
  '& td': {
    fontSize: '0.875rem',
    textAlign: 'center',
  },
}

export function SubAutoOrderRow({ row }: { row: ISubAutoOrderItem }) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <TableRow sx={rowStyle}>
        <TableCell sx={{ maxWidth: 50 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{ textAlign: 'left', minWidth: '250px' }}>
          {row.productName}
        </TableCell>
        <TableCell>{row.quantityToOrder}</TableCell>
        <TableCell>{row.remainingQuantity}</TableCell>
        <TableCell>{row.quantitySold}</TableCell>
        <TableCell>{row.orderedQuantity}</TableCell>
        <TableCell>{row.remainingQuantityToOrder}</TableCell>
        <TableCell>
          <Checkbox
            size="small"
            checked={row.isRefusalToOrder}
            sx={{ cursor: 'default' }}
          />
        </TableCell>
        <TableCell>{row.supplierName}</TableCell>
        <TableCell>{row.proMarketOrderId}</TableCell>
        <TableCell>
          <FormattedDate1 date={row.proMarketOrderDate} />
        </TableCell>
        <TableCell>
          <FormattedPrice1 price={row.productOrderCost} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={collapseStyle} colSpan={12}>
          <SubAutoOrderProduct open={open} products={row.products} />
        </TableCell>
      </TableRow>
    </>
  )
}
