import {
  ApplicationActionTypes,
  SnackTypes,
} from '../../store/application/applicationReduxTypes'
import { httpCore } from './instance'
import { useDispatch } from 'react-redux'

// Определение типа для объекта ошибки
interface ErrorResponse {
  response: {
    status: string
    data: {
      description: {
        tooltipMessage: string
        detailedInformation: string
        guideToAction: string
      }
    }
  }
  request: unknown
  message: string
}

// Функция для обработки ошибок и диспатча действия SHOW_SNACK
const handleErrorResponse = (error: ErrorResponse, dispatch: any) => {
  let messageSnack = ''
  let messageSnackErrorInfo = ''
  let messageSnackErrorGuide = ''
  let messageSnackErrorCode = ''

  if (error.response) {
    // Обработка ошибки с ответом
    console.error('HTTP Error:', error.response.status, error.response.data)
    const errorDescription = error.response.data.description
    messageSnack = errorDescription.tooltipMessage
    messageSnackErrorInfo = errorDescription.detailedInformation
    messageSnackErrorGuide = errorDescription.guideToAction
    messageSnackErrorCode = error.response.status

    // Временно не обрабатываем такого рода ошибки, но пока не удаляем
    // } else if (error.request) {
    //   // Запрос был отправлен, но нет ответа
    //   console.error('No Response:', error.request);
    //   messageSnack = 'Нет ответа от сервера';
    //   messageSnackErrorInfo = 'Запрос был отправлен, но нет ответа';
    //   messageSnackErrorGuide = 'Обратитесь в службу поддержки';
  } else if (messageSnackErrorCode == '401') {
    return
  } else {
    // Произошла ошибка при настройке запроса
    console.error('Request Error:', error.message)
    messageSnack = 'Ошибка запроса'
    messageSnackErrorInfo = 'Произошла ошибка при настройке запроса'
    messageSnackErrorGuide = 'Обратитесь в службу поддержки'
  }

  // Диспатч действия SHOW_SNACK
  dispatch({
    type: ApplicationActionTypes.SHOW_SNACK,
    payload: {
      typeSnack: SnackTypes.ERROR,
      messageSnack,
      messageSnackErrorInfo,
      messageSnackErrorGuide,
      messageSnackErrorCode,
    },
  })
}

// Функциональный компонент, который будет инициализировать интерсептор
function ErrorInterceptor() {
  const dispatch = useDispatch()

  // Axios Interceptor для обработки ошибок
  const interceptor = () => {
    httpCore.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response.status === 401) return

        // Обработка ошибки и диспатч действия
        handleErrorResponse(error, dispatch)

        // Возвращаем Promise.reject, чтобы передать ошибку дальше
        return Promise.reject(error)
      }
    )
  }

  // Инициализация интерсептора
  interceptor()

  // Возвращаем null, так как этот компонент ничего не рендерит
  return null
}

export default ErrorInterceptor
