import { useEffect, useMemo, useRef } from 'react'
import { useActions } from '../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import { useObserver } from '../../../../../shared/hooks/useObserver'
import { IFilterProperty } from '../../DepartmentsPreOrder/types/departmentsPreOrderTypes'
import { checkLastPage } from '../../../../../shared/helpers'
import debounce from 'lodash/debounce'

type argsType = [IFilterProperty[], number, string, number, boolean]
export const useAutoOrderProductsController = () => {
  const {
    fetchAutoOrderProducts,
    setAutoOrdreFinalSetPage,
    fetchAutoOrderActiveFields,
    setNewQuantityOrder,
  } = useActions()

  const { selectPreOrder } = useTypedSelector(
    (state) => state.autoOrderFinalDepartments
  )

  const { autoOrderSorts, page, limit, autoOrders, enough, loading, total } =
    useTypedSelector((state) => state.autoOrderProducts)

  const savePageNum = useRef(page)
  const firstElement = useRef<HTMLTableRowElement>(null)
  const lastElement = useRef<HTMLTableRowElement>(null)
  const inputRef = useRef<null | Record<string, HTMLSpanElement>>({})
  const isMounted = useRef<boolean>(false)

  const filter = selectPreOrder?.filter || []

  const handleChangeQuantityOrder = useMemo(
    () =>
      debounce(
        (
          newQuantityToOrder: number,
          autoOrderBidSimilarProductsGroupId: string
        ) => {
          setNewQuantityOrder(
            newQuantityToOrder,
            autoOrderBidSimilarProductsGroupId
          )
        },
        800
      ),
    [setNewQuantityOrder, autoOrders]
  )

  useObserver(firstElement, page > 1, loading, () => {
    setAutoOrdreFinalSetPage(page - 1)
  })

  useObserver(
    lastElement,
    enough && !checkLastPage(page, limit, total),
    loading,
    () => {
      setAutoOrdreFinalSetPage(page + 1)
    }
  )

  useEffect(() => {
    fetchAutoOrderActiveFields()
  }, [ ])

  useEffect(() => {
    if(!isMounted.current) isMounted.current = true
    if (!filter.length) return
    const args: argsType = [
      filter,
      page,
      autoOrderSorts,
      limit,
      savePageNum.current < page,
    ]
    const fetch = async (...args: argsType) => fetchAutoOrderProducts(...args)
    fetch(...args).then(() => {
      if (savePageNum.current !== page) {
        inputRef?.current?.[
          autoOrders?.[autoOrders.length - 1]?.esCode
        ]?.scrollIntoView() // скролл вниз
      }
      savePageNum.current = page
      
    })

  }, [page, filter, autoOrderSorts])

  return {
    firstElement,
    lastElement,
    loading,
    inputRef,
    autoOrderSorts,
    autoOrders,
    handleChangeQuantityOrder,
  }
}
