import { Dispatch } from 'redux'
import noResultImage from '../../../../shared/assets/images/search.png'
import { transformRequest } from '../../../../shared/services/commonService'
import { IOtherPayload, OtherAction, OtherActionTypes } from './otherReduxTypes'
import {
  ApplicationAction,
  ApplicationActionTypes,
} from '../../../../shared/store/application/applicationReduxTypes'
import { getOtherProducts } from '../../api/other'
import { OtherItemSaved } from '../../types/otherTypes'

export const saveOtherItem = (payload: OtherItemSaved): OtherAction => ({
  type: OtherActionTypes.SAVE_OTHER_ITEM,
  payload: payload,
})

export const fetchOtherProducts = (
  page = 1,
  limit = 30,
  filter = {},
  loadFlag = true
) => {
  return async (dispatch: Dispatch<OtherAction | ApplicationAction>) => {
    try {
      dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })
      const filterTransformed = Object.fromEntries(
        Object.entries(filter).map(([k, v]) => [k, transformRequest(v)])
      )
      const { data } = await getOtherProducts(filterTransformed, page, limit)
      const content = data.content
      const isEmpty = content.data.length === 0
      if (isEmpty) return
      const isEnough = content.data.length >= limit

      dispatch({
        type: OtherActionTypes.FETCH_PRODUCTS_ENOUGH,
        payload: isEnough,
      })
      dispatch({
        type: OtherActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: { content, loadFlag },
      })
    } catch (e) {
      dispatch({
        type: OtherActionTypes.FETCH_PRODUCTS_ERROR,
        payload: { message: 'Поиск не дал результатов', img: noResultImage },
      })
    } finally {
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    }
  }
}

export function setOtherPage(page: number): OtherAction {
  if (page === 0) page = 1
  return { type: OtherActionTypes.SET_PRODUCT_PAGE, payload: page }
}

export function setOtherSort(payload: IOtherPayload): OtherAction {
  return { type: OtherActionTypes.SET_PRODUCT_SORT, payload }
}

export function resetCheckboxs(): OtherAction {
  return { type: OtherActionTypes.RESET_CHECKBOXS }
}

export function setOtherFilter(payload: Record<string, string>): OtherAction {
  return {
    type: OtherActionTypes.SET_FILTER_PRODUCTS,
    payload,
  }
}

export function setOtherCheckboxs(
  payload: Record<string, boolean>
): OtherAction {
  return {
    type: OtherActionTypes.SET_CHECKBOX_PRODUCTS,
    payload,
  }
}

export function resetOtherFilter(): OtherAction {
  return { type: OtherActionTypes.RESET_FILTER_PRODUCTS }
}

export function startOtherSearching(): OtherAction {
  return { type: OtherActionTypes.START_PRODUCT_SEARCH }
}
