export const PRODUCT_ITEM_MODAL = {
  PRODUCT_FIELDS: [
    { label: "Поставщик:", field: "supplierName" },
    { label: "Производитель:", field: "producerName" },
    { label: "Страна:", field: "countryName" },
    { label: "Цена ₽:", field: "price" },
    { label: "Срок годности:", field: "expirationDate" },
    { label: "Ост. срок годности:", field: "remainExpiration" },
    { label: "Мнн:", field: "internationalName" },
    { label: "Крат:", field: "boxQuantity" },
    { label: "Мин:", field: "minimalQuantity" },
    { label: "Дата ПЛ:", field: "priceListDate" },
    { label: "НДС:", field: "vatRate" },
    { label: "Уп:", field: "packQuantity" },
    { label: "Оптовая наценка:", field: "wholesaleMarkup" },
    { label: "Код УС:", field: "esCode" },
    { label: "Реестровая цена", field: "registerPrice" },
    { label: "Код:", field: "code" },
    { label: "УС наименование:", field: "esName" },
    { label: "Наименование ПЛ:", field: "priceListName" },
    { label: "Расширенное наименование ПЛ:", field: "priceListExtendedName" },

  ]
}