import React from 'react'

// mui
import Tabs from '@mui/material/Tabs'
import { CustomTab } from '../../../../../shared/components/styled/CustomTab'

const PriceDeliveryCustomTabs = ({
  changeMode,
  value,
}: {
  changeMode: (event: React.SyntheticEvent, newValue: number) => void
  value: number
}) => {
  return (
    <>
      <Tabs
        sx={{
          marginLeft: '52px',
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
        value={value}
        onChange={changeMode}
      >
        <CustomTab label="По умолчанию" />
        <CustomTab label="Подразделения" />
      </Tabs>
    </>
  )
}

export default PriceDeliveryCustomTabs
