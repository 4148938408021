import { useActions } from '../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { useEffect, useRef } from 'react'
import { useObserver } from '../../../shared/hooks/useObserver'
import { PRODUCT } from '../consts/product'
import { LoadDirections } from '../../../shared/types/types'
import _ from 'lodash'
import { convertFilterData } from '../../../shared/services/filterService'

export const useProductController = () => {
  const { fetchAllProducts, setProductPage } = useActions()
  const { products, error, page, limit, filter, direction, enough } =
    useTypedSelector((state) => state.product)

  const loading = useTypedSelector((state) => state.app.loading)
  const defaultDepartment = useTypedSelector(
    (state) => state.app.defaultDepartment
  )

  const resultFilters = convertFilterData();
  const filterParams = _.isEmpty(filter) ? { ...resultFilters } : filter
  const inputRef = useRef<null | HTMLSpanElement[]>([])
  const savePageNum = useRef(page)
  const firstElement = useRef<HTMLDivElement>(null)
  const lastElement = useRef<HTMLDivElement>(null)

  const isFullLength = products.length >= PRODUCT.MAX_PRODUCT_ITEMS

  useObserver(firstElement, enough && page > 1 && isFullLength, loading, () => {
    const isNotUpDirection = direction !== LoadDirections.UP && isFullLength
    setProductPage(
      isNotUpDirection
        ? page - Math.ceil(PRODUCT.MAX_PRODUCT_ITEMS / limit)
        : page - 1
    )
  })

  useObserver(lastElement, enough, loading, () => {
    const isNotDownDirection = direction !== LoadDirections.DOWN && isFullLength
    setProductPage(
      isNotDownDirection
        ? page + Math.ceil(PRODUCT.MAX_PRODUCT_ITEMS / limit)
        : page + 1
    )
  })

  useEffect(() => {
    // если переходим в корзину (на другую страницу) и обратно на страницу то запрос не повторяем
    if (
      (products.length !== 0 && savePageNum.current === page) 
      ||
      // loading ||
      !defaultDepartment
    )
      return

    const idDepartment = defaultDepartment.id


    const fetch = async () =>
      fetchAllProducts(
        idDepartment,
        page,
        limit,
        filterParams,
        savePageNum.current < page
      )

    fetch().then(() => {
      if (!(isFullLength && inputRef.current !== null)) return
      if (savePageNum.current < page)
        inputRef.current[
          products[products.length - 1].productWithInfo.product.priceListItemId
        ]?.scrollIntoView() // скролл вниз
      if (savePageNum.current > page)
        inputRef.current[products[0].productWithInfo.product.priceListItemId]?.scrollIntoView() // скролл вверх
      savePageNum.current = page
    })
  }, [page, filter, limit, defaultDepartment?.id])

  return {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    products,
  }
}
