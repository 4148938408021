import { BasketAction, BasketActionTypes } from './basketReduxTypes'
import { Dispatch } from 'redux'
import {
  deleteBasketStorage,
  getBasketCount,
  getBasketStorage,
  postBasketStorage,
} from '../../api/basket'
import { BasketItemsStore, ISupplierDetails } from '../../types/basketTypes'
import { BASKET_STORAGE_KEY } from '../../../../shared/consts/basket'
import { AppStateType } from '../../../../shared/store'

export const saveBasketCount = (payload: {
  itemsSum: number
  itemsCount: number
  supplierDetails: ISupplierDetails[]
}): BasketAction => ({
  type: BasketActionTypes.SAVE_BASKET_RECORDS_META,
  payload: payload,
})

export const fetchBasketRecords = () => {
  return async (dispatch: Dispatch<BasketAction>) => {
    try {
      const {
        data: {
          content: { count, productsTotalSum, supplierDetails },
        },
      } = await getBasketCount()

      const {
        data: {
          content: { keyValueSettings },
        },
      } = await getBasketStorage()

      if (keyValueSettings.length > 0) {
        const customStorage = keyValueSettings.find(
          (item: any) => item.key === BASKET_STORAGE_KEY
        )
        if (customStorage.value.length > 0) {
          const customStorageParsed = JSON.parse(customStorage.value)
          if (Object.keys(customStorageParsed).length === 0) {
            dispatch({
              type: BasketActionTypes.DELETE_ALL_BASKET_RECORDS,
            })
          }
          dispatch(storeBasketRecords(customStorageParsed))
        }
      }

      dispatch(
        saveBasketCount({
          itemsCount: count,
          itemsSum: productsTotalSum,
          supplierDetails,
        })
      )
    } catch (e) {
      // alert('Ошибка получения позиций корзины')
    }
  }
}

export const storeBasketRecords = (items: BasketItemsStore): BasketAction => ({
  type: BasketActionTypes.ADD_BASKET_RECORDS,
  payload: items,
})

export const storeBasketRecordsInCloud = (items: BasketItemsStore) => {
  return async (dispatch: Dispatch<BasketAction>) => {

    try {
      const stringedParams = JSON.stringify(items)
      await postBasketStorage(stringedParams)
      dispatch(storeBasketRecords(items))
    } catch (e) {
      // alert('Ошибка сохранения корзины')
    }
  }
}

export const deleteBasketRecord = (productId: string) => {
  return async (
    dispatch: Dispatch<BasketAction>,
    getState: () => AppStateType
  ) => {
    try {
      const state = getState()
      const newBasketRecords = state.basket.basketRecords
      delete newBasketRecords[productId]

      const stringedParams = JSON.stringify(newBasketRecords)
      await postBasketStorage(stringedParams)

      dispatch({
        type: BasketActionTypes.DELETE_BASKET_RECORD,
        payload: productId,
      })
    } catch (e) {
      // alert('Ошибка сохранения корзины')
    }
  }
}

export const deleteBasketRecords = (productIds: string[]) => {
  return async (
    dispatch: Dispatch<BasketAction>,
    getState: () => AppStateType
  ) => {
    try {
      const state = getState()
      const newBasketRecords = state.basket.basketRecords

      productIds.forEach((property) => {
        delete newBasketRecords[property]
      })
      const stringedParams = JSON.stringify(newBasketRecords)
      await postBasketStorage(stringedParams)

      dispatch({
        type: BasketActionTypes.DELETE_BASKET_RECORDS,
        payload: productIds,
      })
    } catch (e) {
      // alert('Ошибка сохранения корзины')
    }
  }
}

export const deleteAllBasketRecords = () => {
  return async (dispatch: Dispatch<BasketAction>) => {
    try {
      await deleteBasketStorage()

      dispatch({
        type: BasketActionTypes.DELETE_ALL_BASKET_RECORDS,
      })
    } catch (e) {
      // alert('Ошибка сохранения корзины')
    }
  }
}
