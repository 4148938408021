import React from 'react'

// mui
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { TextFieldCustom } from '../../../../../../shared/components/styled/TextFieldCustom'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'

// project
import PriceListSettingsList from './PriceListSettingsList'
import PriceListControlStack from '../PriceListControlStack'

// const
import { PaperStyle } from '../../PriceList'

interface IPriceListSettings {
  request: any
  list: any
  isEmpty: boolean
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: () => void
  resetFunc: () => void
}

const PriceListSettings = ({
  request,
  list,
  isEmpty,
  handleOnChange,
  onSubmit,
  resetFunc,
}: IPriceListSettings) => {
  return (
    <>
      <Box
        component="div"
        sx={{
          ...PaperStyle,
          my: 2,
        }}
      >
        <Typography
          fontWeight={600}
          fontSize={12}
          color="primary.main"
          sx={{ textTransform: 'uppercase' }}
        >
          Настройка отображения прайс-листа
        </Typography>

        <TextFieldCustom
          sx={{ mt: 2 }}
          placeholder="Поиск"
          variant="outlined"
          size="small"
          onChange={request}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <PriceListSettingsList
          list={list}
          isEmpty={isEmpty}
          handleOnChange={handleOnChange}
        />

        <PriceListControlStack
          saveFunc={onSubmit}
          resetFunc={resetFunc}
          justifyContent={'flex-end'}
        />
      </Box>
    </>
  )
}

export default PriceListSettings
