import { Dispatch } from 'redux'
import { AuthAction, AuthActionTypes } from './authReduxTypes'
import { getUserClaims } from '../../api/auth'

export const getUserClaimsAsync = () => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      const response = await getUserClaims()

      const claims = response.data.data.items.reduce(function (result, item) {
        return {
          ...result,
          [item.value]: true,
        }
      }, {})
      dispatch({
        type: AuthActionTypes.CHECK_USER_CLAIMS,
        payload: claims,
      })
    } catch (error) {
      console.log('Error:', error)
    }
  }
}
