import { ISimilarHeadCells } from '../types/similarTypes'

export const SIMILAR = {
  MAX_PRODUCT_ITEMS: 60,
}

export const headCellsSimilar: ISimilarHeadCells[] = [
  {
    name: 'НАИМЕНОВАНИЕ ТОВАРА',
    align: 'center',
    field: 'name',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'name',
    align: 'center',
    field: 'name',
    enablePadding: true,
    disableField: true,
  },
  {
    name: 'МИН КОЛ-ВО',
    align: 'center',
    field: 'minimalQuantity',
    enablePadding: true,
    disableField: true,
  },
  {
    name: 'КРАТНОСТЬ',
    align: 'center',
    field: 'boxQuantity',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'ДОСТУПНОСТЬ',
    align: 'center',
    field: 'quantity',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'ПЛ',
    align: 'center',
    field: 'priceListName',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'СРОК ГОДНОСТИ',
    align: 'center',
    field: 'expirationDate',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'СТОИМОСТЬ',
    align: 'center',
    field: 'price',
    enablePadding: true,
    disableField: false,
  },
]
