import {
  SimilarAction,
  SimilarActionTypes,
  SimilarState,
} from './similarReduxTypes'
import { SIMILAR } from '../../consts/similar'
import { getComparator } from '../../../../shared/services/commonService'
import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'

const initialState: SimilarState = {
  enough: false,
  direction: LoadDirections.DOWN,
  loading: false,
  error: {} as ErrorInfo,
  page: 1,
  limit: 30,
  total: 30,
  filter: {},
  products: [],
  orderBy: 'name',
  order: 'asc',
}

export const similarReducer = (
  state = initialState,
  action: SimilarAction
): SimilarState => {
  switch (action.type) {
    case SimilarActionTypes.SET_FILTER_PRODUCTS:
      return {
        ...state,
        products: [],
        page: 1,
        filter: { ...action.payload },
      }
    case SimilarActionTypes.RESET_FILTER_PRODUCTS:
      return {
        ...state,
        products: [],
        page: 1,
        filter: {},
      }
    case SimilarActionTypes.FETCH_PRODUCTS_SUCCESS:
      const { content, loadFlag } = action.payload

      const sortedArray = content.data.sort(
        // getComparator(state.order, state.orderBy) // временно
      )

      const productsArray = loadFlag
        ? state.products.concat(sortedArray)
        : sortedArray.concat(state.products)

      // удаляем дубликаты
      const productFilteredArray = productsArray.filter(
        (v, i, a) => a.findIndex((t) => t.productWithInfo.product.id === v.productWithInfo.product.id) === i
      )
      const productSlicedArray = loadFlag
        ? productFilteredArray.slice(-SIMILAR.MAX_PRODUCT_ITEMS)
        : productFilteredArray.slice(0, SIMILAR.MAX_PRODUCT_ITEMS)

      return {
        ...state,
        products: productSlicedArray,
        error: {},
        total: content.total,
        direction: loadFlag ? LoadDirections.DOWN : LoadDirections.UP,
      }

    case SimilarActionTypes.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case SimilarActionTypes.SET_PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case SimilarActionTypes.FETCH_PRODUCTS_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }

    case SimilarActionTypes.SET_PRODUCT_SORT:
      const order = action.payload.order
      const orderBy = action.payload.orderBy
      return {
        ...state,
        products: state.products.slice(),
        // .sort(getComparator(order, orderBy)), // временно
        orderBy,
        order,
      }

    default:
      return state
  }
}
