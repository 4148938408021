import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const TextCircle = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  lineHeight: '16px',
  borderRadius: '15px',
  fontSize: '10px',
  color: theme.palette.primary.main,
  textAlign: 'center',
  border: `2px solid ${theme.palette.primary.main}`,
}))
