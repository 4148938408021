import { createTheme } from '@mui/material/styles'
import { getAdditionalOptions } from './additionalOptions'

export const blackTheme = createTheme({
  textTooltip: 'Черная тема',
  palette: {
    primary: {
      main: '#000',
    },
  },
})

blackTheme.components = getAdditionalOptions(blackTheme)
