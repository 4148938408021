import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { useObserver } from '../../../shared/hooks/useObserver'

import { checkLastPage } from '../../../shared/helpers'
import { useActions } from '../../../shared/hooks/useActions'


export const useSubAutoOrderController = () => {
  const {
    items,
    error,
    page,
    enough,
    order,
    orderBy,
    listOrientation,
    limit,
    total,
    loading,
  } = useTypedSelector((state) => state.subAutoOrder)

  const { idOrder }: { idOrder: string } = useParams()

  const { fetchSubAutoOrder, resetSubAutoOrderItems, setSubAutoOrderPage } =
    useActions()

  const savePageNum = useRef(page)
  const firstElement = useRef<HTMLTableRowElement>(null)
  const lastElement = useRef<HTMLTableRowElement>(null)
  const inputRef = useRef<null | Record<string, HTMLSpanElement>>({})


  useEffect(() => {
    const fetch = async () => fetchSubAutoOrder(page, limit, idOrder)

    fetch().then(() => {
      if (savePageNum.current !== page) {
        const trElement = inputRef.current?.[items[items.length - 1].id as any]
        trElement?.scrollIntoView()
      }
      savePageNum.current = page
    })
  }, [page, listOrientation])

  useEffect(() => {
    return () => {
      resetSubAutoOrderItems()
    }
  }, [])

  // lastElement,enough && !checkLastPage(page, limit, total) && inputRef.current !== null,loading,



  // down
  useObserver(lastElement, enough && !checkLastPage(page, limit, total), loading, () => {
    setSubAutoOrderPage(page + 1)
  })

  // up
  useObserver(
    firstElement,
    enough && page > 1 && inputRef.current !== null,
    loading,
    () => {
      setSubAutoOrderPage(page - 1)
    }
  )

  return {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    items,
    orderBy,
    order,
    listOrientation,
  }
}
