import ProductPage from '../../../pages/ProductPage'
import BasketPage from '../../../pages/BasketPage'
import OthersPage from '../../../pages/OthersPage'
import NewHistoryPage from '../../../pages/NewHistoryPage'
import SubHistoryPage from '../../../pages/SubHistoryPage'
import SupplierPage from '../../../pages/SupplierPage'
import HelpPage from '../../../pages/HelpPage'
import OptionsPage from '../../../pages/OptionsPage'
import BindsPage from '../../../pages/BindsPage'
import SalePage from '../../../pages/SalePage'
import SimilarPage from '../../../pages/SimilarPage'
import SaleDetailsPage from '../../../pages/SaleDetailsPage'
import AutoOrderPage from '../../../pages/AutoOrderPage'
import SubAutoOrderPage from '../../../pages/SubAutoOrderPage'
import SettingsAutoOrderPage from '../../../pages/SettingsAutoOrderPage'
import AutoOrderFinalPage from '../../../pages/AutoOrderFinalPage'

export const privateRoutes = [
  { path: '/', component: ProductPage, exact: true },
  { path: '/store/basket', component: BasketPage, exact: true },
  { path: '/help', component: HelpPage, exact: false },
  { path: '/options', component: OptionsPage, exact: false },
  { path: '/binds', component: BindsPage, exact: false },
  { path: '/for-sale', component: SalePage, exact: false },
  { path: '/sale-details', component: SaleDetailsPage, exact: false },
  { path: '/store/other-products', component: OthersPage, exact: true },
  {
    path: '/store/other-products/replace-id=:replaceId',
    component: OthersPage,
    exact: false,
  },
  { path: '/store/new-history', component: NewHistoryPage, exact: true },
  { path: '/store/suppliers', component: SupplierPage, exact: false },
  { path: '/store/new-history/:id', component: NewHistoryPage, exact: true },
  {
    path: '/store/new-history/view/:idOrder',
    component: SubHistoryPage,
    exact: false,
  },
  {
    path: '/store/new-history/edit/:idOrder',
    component: SubHistoryPage,
    exact: false,
  },
  { path: '/auto-order', component: AutoOrderPage, exact: false },
  {
    path: '/store/auto-order/view/:idOrder',
    component: SubAutoOrderPage,
    exact: false,
  },
  { path: '/auto-order-settings', component: SettingsAutoOrderPage, exact: false },
  { path: '/auto-order-final', component: AutoOrderFinalPage, exact: false },
  { path: '/store/similar', component: SimilarPage, exact: false },
]

export const publicRoutes = []
