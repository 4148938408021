import { LoadDirections } from "../../../../../../shared/types/types"
import { ISupplierListItem } from "../../types/supplierListTypes"

export interface SupplierListState {
  loading: boolean
  items: ISupplierListItem[]
  page: number
  limit: number
  total: number
  enough: boolean
  direction: LoadDirections.DOWN | LoadDirections.UP
  selectedSupplier: null | ISupplierListItem
}

export enum SupplierListActionTypes {
  FETCH_SUPPLIER_LIST_SUCCESS = 'FETCH_SUPPLIER_LIST_SUCCESS',
  LOADING_ENABLE = 'LOADING_SUPPLIER_LIST_ENABLE',
  LOADING_DISABLE = 'LOADING_SUPPLIER_LIST_DISABLE',
  SET_SELECTED_SUPPLIER = 'SET_SELECTED_SUPPLIER',
  UPDATE_PRICE_LIST_CHECKBOX = 'UPDATE_PRICE_LIST_CHECKBOX',
}

interface FetchSupplierListSuccessAction {
  type: SupplierListActionTypes.FETCH_SUPPLIER_LIST_SUCCESS,
  payload: ISupplierListItem[]
}

export interface LoadingSupplierListEnableAction {
  type: SupplierListActionTypes.LOADING_ENABLE
}

export interface LoadingSupplierListDisableAction {
  type: SupplierListActionTypes.LOADING_DISABLE
}

export interface SetSupplierListSelectedItemAction {
  type: SupplierListActionTypes.SET_SELECTED_SUPPLIER
  payload: ISupplierListItem | null
}

export interface updateSupplierListPriceListCheckBoxAction {
  type: SupplierListActionTypes.UPDATE_PRICE_LIST_CHECKBOX,
  payload: {
    priceListId: number,
    isActiveInAutoOrder: boolean
  }
}

export type SupplierListAction =
  | FetchSupplierListSuccessAction
  | LoadingSupplierListEnableAction
  | LoadingSupplierListDisableAction
  | SetSupplierListSelectedItemAction
  | updateSupplierListPriceListCheckBoxAction