import React from 'react'
import { AlertTitle, Snackbar } from '@mui/material'
import { useActions } from '../hooks/useActions'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useTypedSelector } from '../hooks/useTypedSelector'

// React.forwardRef<HTMLDivElement,React.PropsWithChildren<AlertProps>>
// todo типизировать
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const SnackBarCustom = () => {
  const { hideSnack, showErrorDetailInfoModal } = useActions()
  const { openSnack, messageSnack, typeSnack } = useTypedSelector(
    (state) => state.app.snack
  )
  const isErrorTypeSnack = typeSnack === 'error'

  const handleLinkClick = () => {
    showErrorDetailInfoModal()
  }

  const muiAlertContent = (
    <>
      <AlertTitle>{messageSnack}</AlertTitle>
      {isErrorTypeSnack && (
        <span
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={handleLinkClick}
        >
          подробнее
        </span>
      )}
    </>
  )

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openSnack}
        // autoHideDuration={6000}
        autoHideDuration={100000}
        onClose={() => {
          hideSnack()
        }}
      >
        <Alert
          onClose={() => {
            hideSnack()
          }}
          severity={typeSnack}
          sx={{ width: '100%' }}
          children={muiAlertContent}
        />
      </Snackbar>
    </>
  )
}

export default SnackBarCustom
