import React from 'react'

// services
import {
  getCardPrimaryKey,
  onlyNumbers,
  qsGetProperty,
} from '../../shared/services/commonService'

// mui imports
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import { alpha, useTheme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

// mui assets
import IconButton from '@mui/material/IconButton'
import AutorenewIcon from '@mui/icons-material/Autorenew'

// types
import { IProductWithInfo } from '../Product/types/productTypes'

// hooks
import { useBuyController } from '../../shared/controllers/useBuyController'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'

// project imports
import FormattedDate1 from '../../shared/components/FormattedDate1'
import FormattedPrice1 from '../../shared/components/FormattedPrice1'

// redux
import { useActions } from '../../shared/hooks/useActions'

// project assets
import SvgHospital from '../../shared/components/UI/svgIcons/SvgHospital'
import SvgMolecule from '../../shared/components/UI/svgIcons/SvgMolecule'
import SvgBad from '../../shared/components/UI/svgIcons/SvgBad'
import SvgAccounting from '../../shared/components/UI/svgIcons/SvgAccounting'
import SvgDocument from '../../shared/components/UI/svgIcons/SvgDocument'
import SvgCheck from '../../shared/components/UI/svgIcons/SvgCheck'
import SvgBlueLogo from '../../shared/components/UI/svgIcons/SvgBlueLogo'
import SvgOrangeLogo from '../../shared/components/UI/svgIcons/SvgOrangeLogo'
import SvgGreenLogo from '../../shared/components/UI/svgIcons/SvgGreenLogo'
import SvgPlus from '../../shared/components/UI/svgIcons/SvgPlus'
import SvgRedLogo from '../../shared/components/UI/svgIcons/SvgRedLogo'
import SvgHealthIcon from '../../shared/components/UI/svgIcons/SvgHealth'

const OthersTableRow = ({ row }: { row: IProductWithInfo }) => {
  const { product, info } = row
  const { changeItemAmount } = useBuyController(product)
  const claims = useTypedSelector((state) => state.auth.claims)

  const theme = useTheme()

  const {
    isBad,
    isBenefit,
    isFirstHundredBonus,
    isImmunoBiological,
    isJvnls,
    isMyHealth,
    isOa,
    isPrPkkn,
    isProApt,
    isReceipt,
  } = product || {}

  const {
    isInFirstTableBonusMm,
    isInHighMarginProductMm,
    isInMatrixMm,
    isInProAptMm,
    highMarginProductMarketingEvents,
    firstTableBonusMarketingEvents,
    proAptMarketingEvents,
    matrixMarketingEvents,
  } = info.marketingEvents

  const healthPm = info.compensatedDiscounts

  const basketRecords = useTypedSelector((state) => state.basket.basketRecords)

  const basketItemId = qsGetProperty('basketItemId')
  const payerCode = qsGetProperty('payerCode')
  const storeCode = qsGetProperty('storeCode')
  const supplierGoodId = qsGetProperty('supplierGoodId')
  const priceListTypeId = qsGetProperty('priceListTypeId')

  const isRelevant =
    product.storeCode === storeCode &&
    product.payerCode === payerCode &&
    product.supplierGoodId === Number(supplierGoodId) &&
    product.priceListTypeId === Number(priceListTypeId)

  const primaryKey = getCardPrimaryKey(product)

  const initialIconSizes = {
    width: '20px',
    height: '20px',
  }

  const { selectOtherProduct, selectProductModal } = useActions()

  const button = basketItemId && (
    <TableCell>
      <Tooltip title="Заменить">
        <IconButton size="small" onClick={() => selectOtherProduct(product)}>
          <AutorenewIcon fontSize="small" sx={{ color: 'primary.main' }} />
        </IconButton>
      </Tooltip>
    </TableCell>
  )

  return (
    <TableRow
      sx={{
        backgroundColor: isRelevant
          ? alpha(theme.palette.primary.main, 0.5)
          : 'unset',
        '& td': { fontSize: '0.875rem' },
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell>
        <Box>
          <Box display="flex">
            <SvgHospital
              initialSizes={initialIconSizes}
              renderCondition={isJvnls}
            />
            <SvgMolecule
              initialSizes={initialIconSizes}
              renderCondition={isImmunoBiological}
            />
            <SvgBad initialSizes={initialIconSizes} renderCondition={isBad} />
          </Box>
          <Box display="flex">
            <SvgAccounting
              initialSizes={initialIconSizes}
              renderCondition={isPrPkkn}
            />
            <SvgDocument
              initialSizes={initialIconSizes}
              renderCondition={isReceipt}
            />
            <SvgCheck initialSizes={initialIconSizes} renderCondition={isOa} />
          </Box>
        </Box>
      </TableCell>
      <TableCell align="center">{product.esCode}</TableCell>
      <TableCell
        align="left"
        onClick={() => selectProductModal(product)}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textTransform: 'uppercase',
          fontSize: { xs: '9px', xl: '10px' },
          '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
          },
        }}
      >
        {product.name}
      </TableCell>
      <TableCell align="left">
        <Box>
          <Box display="flex">
            <SvgBlueLogo
              initialSizes={initialIconSizes}
              events={proAptMarketingEvents}
              renderCondition={isInProAptMm}
            />
            <SvgOrangeLogo
              initialSizes={initialIconSizes}
              events={firstTableBonusMarketingEvents}
              renderCondition={isInFirstTableBonusMm}
            />
          </Box>
          <Box display="flex">
            <SvgGreenLogo
              initialSizes={initialIconSizes}
              events={matrixMarketingEvents}
              renderCondition={isInMatrixMm}
            />
            <SvgHealthIcon
              health={healthPm}
              initialSizes={{
                width: '12px',
                height: '12px',
              }}
              renderCondition={!!healthPm.length}
            />
          </Box>
          <Box display="flex">
            <SvgRedLogo
              initialSizes={initialIconSizes}
              events={highMarginProductMarketingEvents}
              renderCondition={isInHighMarginProductMm}
            />
            <SvgGreenLogo
              initialSizes={initialIconSizes}
              renderCondition={false}
            />
            <SvgPlus renderCondition={isMyHealth} />
          </Box>
        </Box>
      </TableCell>
      <TableCell align="left">{product.producerName}</TableCell>
      <TableCell align="left">{product.supplierName}</TableCell>
      <TableCell align="center">{product.countryName}</TableCell>
      <TableCell align="center">{product.minimalQuantity}</TableCell>
      <TableCell align="center">{product.boxQuantity}</TableCell>
      <TableCell align="center">{product.quantity}</TableCell>
      <TableCell align="center">
        <FormattedDate1 date={product.expirationDate} />
      </TableCell>

      <TableCell align="center">
        <Box
          component="span"
          sx={{
            padding: '5px 5px',
            margin: '0 auto',
            height: '24px',
            lineHeight: '24px',
            borderRadius: '15px',
            fontSize: '12px',
            color: 'common.white',
            textAlign: 'center',
            background: '#40AC58',
          }}
        >
          <FormattedPrice1 price={product.price} />
        </Box>
      </TableCell>

      {claims['promarket.basket_item.upsert'] && !basketItemId && (
        <TableCell align={'center'}>
          <input
            type="number"
            defaultValue={basketRecords[primaryKey]?.amount ?? 0}
            onInput={onlyNumbers}
            onChange={(event) => changeItemAmount(Number(event.target.value))}
            min={0}
            style={{
              backgroundColor: 'inherit',
              color: 'inherit',
              lineHeight: '24px',
              textAlign: 'center',
              width: '45px',
              marginLeft: '8px',
              border: 0,
              outline: 0,
            }}
          />
        </TableCell>
      )}
      {button}
    </TableRow>
  )
}

export default OthersTableRow
