import React from 'react'

// mui imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

//types
import { IProduct } from '../../Product/types/productTypes'

// controllers
import { useHistoryTableController } from '../controllers/useHistoryTableController'

// project imports
import { LinearProgress } from '@mui/material'
import ErrorTypography from '../../../shared/components/ErrorTypography'
import FormattedTime from '../../../shared/components/UI/FormattedTime'
import FormattedPrice1 from '../../../shared/components/FormattedPrice1'
import StatusInfo from '../UI/StatusInfo'
import FormattedDate1 from '../../../shared/components/FormattedDate1'
import { getHistoryRouteById } from '../../../shared/services/routeService'
import { useHistory } from 'react-router-dom'

const NewHistoryTable = ({ card }: { card: IProduct }) => {
  const { isLoading, error, data } = useHistoryTableController(card.id)

  const history = useHistory()
  const routerHandler = () => {
    history.push(getHistoryRouteById(String(card.id)))
  }
  if (isLoading) return <LinearProgress />
  if (error) return <ErrorTypography error={error} />

  return (
    <>
      <Box sx={{ my: 2, pt: 1, backgroundColor: 'background.default' }}>
        <Stack ml={2} direction="row" spacing={2} alignItems="center">
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontSize: '12px',
              fontWeight: '700',
              color: 'primary.main',
            }}
          >
            История
          </Typography>
          <Button
            variant="outlined"
            onClick={routerHandler}
            sx={{
              mt: 1,
              fontSize: '10px',
              textTransform: 'uppercase',
            }}
          >
            Полная история
          </Button>
        </Stack>
        <TableContainer>
          <Table size={'small'} aria-label="a history table">
            <TableHead>
              <TableRow
                sx={{
                  '& th': {
                    fontSize: '0.7rem',
                    textTransform: 'uppercase',
                    color: 'primary.main',
                  },
                }}
              >
                <TableCell align="center">№</TableCell>
                <TableCell align="center">Дата заказа</TableCell>
                <TableCell align="center">Время заказа</TableCell>
                <TableCell align="center">Поставщик</TableCell>
                <TableCell align="center">Грузополучатель</TableCell>
                <TableCell align="center">Кем создан</TableCell>
                <TableCell align="center">Дата прайс-листа</TableCell>
                <TableCell align="center">Время прайс-листа</TableCell>
                <TableCell align="center">Количество</TableCell>
                <TableCell align="center">Сумма</TableCell>
                <TableCell align="center">Статус заказа</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(0, 5).map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '& td': { fontSize: '0.7rem' },
                  }}
                >
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">
                    <FormattedDate1 date={row.createdAt} />
                  </TableCell>
                  <TableCell align="center">
                    <FormattedTime date={row.createdAt} />
                  </TableCell>
                  <TableCell align="center">{row.supplier}</TableCell>
                  <TableCell align="center">{row.department}</TableCell>
                  <TableCell align="center">{row.userName}</TableCell>
                  <TableCell align="center">
                    <FormattedDate1 date={row.createdAt} />
                  </TableCell>
                  <TableCell align="center">
                    <FormattedTime date={row.createdAt} />
                  </TableCell>
                  <TableCell align="center">
                    <FormattedPrice1 price={row.totalSum} />
                  </TableCell>
                  <TableCell align="center">{row.totalCount}</TableCell>
                  <TableCell align="center">
                    <StatusInfo status={row.statusId} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
export default NewHistoryTable
