import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import { visuallyHidden } from '@mui/utils'
import { Order } from '../../../shared/services/commonService'

interface HeadCell {
  enablePadding: boolean
  id: any // todo ts
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    enablePadding: true,
    label: 'НАИМЕНОВАНИЕ ТОВАРА',
  },

  {
    id: '',
    numeric: false,
    enablePadding: false,
    label: '',
  },

  {
    id: 'minimalQuantity.match',
    numeric: true,
    enablePadding: true,
    label: 'МИН КОЛ-ВО',
  },
  {
    id: 'boxQuantity.match',
    numeric: true,
    enablePadding: true,
    label: 'КРАТНОСТЬ',
  },

  {
    id: 'quantity.match',
    numeric: true,
    enablePadding: true,
    label: 'ДОСТУПНО',
  },

  {
    id: 'priceListName',
    numeric: true,
    enablePadding: true,
    label: 'ПЛ',
  },

  {
    id: 'expirationDate.match',
    numeric: true,
    enablePadding: true,
    label: 'СРОК ГОДНОСТИ',
  },

  {
    id: 'price.match',
    numeric: true,
    enablePadding: true,
    label: 'ЦЕНА',
  },

  {
    id: 'amount',
    numeric: true,
    enablePadding: true,
    label: 'КОЛ-ВО',
  },

  {
    id: 'priceAmountMatch',
    numeric: true,
    enablePadding: true,
    label: 'СУММА',
  },
]

interface EnhancedCellsBlockProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void
  order: Order
  orderBy: string
}

export function EnhancedCellsBlock(props: EnhancedCellsBlockProps) {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <>
      {headCells.map((headCell, index) =>
        headCell.id ? (
          <TableCell
            key={index}
            align={headCell.numeric ? 'center' : 'left'}
            sx={{
              paddingLeft: headCell.enablePadding ? '8px' : 'unset',
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Box
                sx={{
                  pl: headCell.numeric ? '18px' : 'unset',
                }}
              >
                {headCell.label}
              </Box>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ) : (
          <TableCell key={index} />
        )
      )}
    </>
  )
}
