import { createTheme } from '@mui/material/styles'
import { getAdditionalOptions } from './additionalOptions'

export const darkTheme = createTheme({
  textTooltip: 'Темная тема',
  palette: {
    mode: 'dark',
  },
})

darkTheme.components = getAdditionalOptions(darkTheme)
