import React, { useState } from 'react'
// mui
import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Box,
} from '@mui/material'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'

// project imports
import { AutoOrderProductRow } from './AutoOrderProductRow'
import { headCellsArray } from '../consts/autoOrderConsts'
import { AutoOrderFinalFieldTypeMap } from '../types/autoOrderProductsTypes'
import { useActions } from '../../../../../shared/hooks/useActions'
import ElementWatch from '../../../../Product/components/ElementWatch'
import { useAutoOrderProductsController } from '../controller/useAutoOrderProductsController'

const tableContainerStyle: SxProps<Theme> = {
  border: '1px solid',
  borderColor: 'action.disabled',
  boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
  borderRadius: '8px',
  pb: 1,
  overflowX: 'initial',
  height: 'calc(100vh - 200px)',
  overflowY: 'auto',
}

const tableHeadStyle: SxProps<Theme> = {
  '& th': {
    whiteSpace: 'normal',
    fontSize: '0.65rem',
    color: 'primary.main',
    fontWeight: '700',
    textTransform: 'uppercase',
    borderBottom: 0,
    backgroundColor: 'background.paper',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
}

const AutoOrderProductsTable = () => {
  const {
    firstElement,
    lastElement,
    loading,
    inputRef,
    autoOrderSorts: orders,
    autoOrders,
    handleChangeQuantityOrder,
  } = useAutoOrderProductsController()

  const { setAutoOrdersFinalSort, clearAutoOrders } = useActions()

  const activeFields = useTypedSelector(
    (state) => state.autoOrderProducts.activeFields
  )
  const { showPreOrder, tempShow } = useTypedSelector(
    (state) => state.autoOrderFinalDepartments
  )

  const [selectedRow, setSelectedRow] = useState('')

  const checkActiveSort = (field: string) => {
    const currentSort =
      AutoOrderFinalFieldTypeMap[
        field as keyof typeof AutoOrderFinalFieldTypeMap
      ]
    return orders === currentSort.asc
  }

  const haldeSortHandler = (field: string) => {
    const currentSort =
      AutoOrderFinalFieldTypeMap[
        field as keyof typeof AutoOrderFinalFieldTypeMap
      ]
    if (orders === currentSort.asc) setAutoOrdersFinalSort(currentSort.desc)
    else setAutoOrdersFinalSort(currentSort.asc)
    clearAutoOrders()
  }
  const handleChangeSelectRow = (id: string) => {
    setSelectedRow(id)
  }

  return (
    <TableContainer
      sx={{
        ...tableContainerStyle,
        width: `calc(100vw - ${showPreOrder || tempShow ? 380 : 35}px)`,
      }}
    >
      <Table size="small" aria-label="auto-order products table">
        <TableHead>
          <TableRow sx={tableHeadStyle}>
            {headCellsArray.map(
              (item) =>
                activeFields[item.field as keyof typeof activeFields] && (
                  <TableCell
                    key={item.field}
                    align={item.align}
                    sx={{
                      minWidth: item.maxWidth,
                      padding: item.enablePaddings
                        ? '0.5rem 1rem 0 1rem'
                        : '0.5rem 0 0 0',
                      cursor: 'pointer',
                    }}
                  >
                    <TableSortLabel
                      active={checkActiveSort(item.field)}
                      direction={checkActiveSort(item.field) ? 'asc' : 'desc'}
                      onClick={() => haldeSortHandler(item.field)}
                    >
                      <Box
                        sx={{
                          pl: item.enableLeftPadding ? '18px' : 'unset',
                        }}
                      >
                        {item.name}
                      </Box>
                    </TableSortLabel>
                  </TableCell>
                )
            )}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            width: 800,
          }}
        >
          <ElementWatch
            componentNode={'tr'}
            element={firstElement}
            isLoading={loading}
          />
          {autoOrders.map((row, id) => (
            <React.Fragment key={id + 1}>
              <AutoOrderProductRow
                selectedRow={selectedRow}
                onChangeSelect={handleChangeSelectRow}
                onChangeQuantity={handleChangeQuantityOrder}
                row={row}
              />
              <tr
                // @ts-ignore
                ref={(el) => (inputRef.current[row.esCode] = el)}
              />
            </React.Fragment>
          ))}

          {autoOrders.length > 0 && (
            <ElementWatch
              componentNode={'tr'}
              element={lastElement}
              isLoading={loading}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AutoOrderProductsTable
