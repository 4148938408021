import { LoadDirections } from "../../../../../../shared/types/types"
import { SupplierListActionTypes, SupplierListState, SupplierListAction } from "./supplierListReduxTypes"

const initialState: SupplierListState = {
  loading: false,
  items: [],
  page: 1,
  limit: 30,
  total: 30,
  enough: false,
  direction: LoadDirections.DOWN,
  selectedSupplier: null
}

export const supplierListReducer = (
  state = initialState,
  action: SupplierListAction
): SupplierListState => {
  switch (action.type) {
    case SupplierListActionTypes.FETCH_SUPPLIER_LIST_SUCCESS:
      return {...state, items: action.payload }
    case SupplierListActionTypes.LOADING_ENABLE:
      return { ...state, loading: true }
    case SupplierListActionTypes.LOADING_DISABLE:
      return { ...state, loading: false }
    case SupplierListActionTypes.SET_SELECTED_SUPPLIER:
      return {...state, selectedSupplier: action.payload }
    case SupplierListActionTypes.UPDATE_PRICE_LIST_CHECKBOX:
      const { priceListId, isActiveInAutoOrder } = action.payload;
      const selectedSupplier = state.selectedSupplier;

      if (!selectedSupplier) {
        return state;
      }

      const updatedPriceListTypes = selectedSupplier.priceListTypes.map((priceList) => {
        if (priceList.id === priceListId) {
          return { ...priceList, isActiveInAutoOrder };
        }
        return priceList;
      });

      const updatedSelectedSupplier = {
        ...selectedSupplier,
        priceListTypes: updatedPriceListTypes,
      };

      return {
        ...state,
        selectedSupplier: updatedSelectedSupplier,
      };

    default:
      return state
  }
}