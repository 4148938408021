import React from 'react'

// mui imports
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableSortLabel from '@mui/material/TableSortLabel'
import { SxProps, Theme } from '@mui/material'

// const
import { headCellsArray } from './consts/subAutoOrder'

// actions
import { useActions } from '../../shared/hooks/useActions'

// types
import {
  ISubAutoOrderItem,
  SubAutoOrderFieldType,
  SubAutoOrderFieldTypeMap,
} from './types/subAutoOrderTypes'

// project
import { SubAutoOrderRow } from './components/SubAutoOrderRow'
import BackToAutoOrderPage from '../../shared/components/BackToAutoOrderPage'
import { useSubAutoOrderController } from './controllers/useSubAutoOrderController'
import ElementWatch from '../Product/components/ElementWatch'

const tableHeadRowStyle: SxProps<Theme> = {
  '& th': {
    whiteSpace: 'nowrap',
    fontSize: '0.75rem',
    color: 'primary.main',
    fontWeight: '700',
    textTransform: 'uppercase',
    borderBottom: 0,
    borderRadius: '8px',
    backgroundColor: 'background.paper',
    position: 'sticky',
    top: 60,
    zIndex: 1,
  },
}

const tableContainerStyle: SxProps<Theme> = {
  boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
  border: '1px solid #E1E3E6',
  borderRadius: '8px',
  overflowX: 'initial',
  pb: 2,
}

const SubAutoOrderMain = () => {
  const {
    firstElement,
    lastElement,
    inputRef,
    loading,
    items,
    order,
    listOrientation,
  } = useSubAutoOrderController()

  const { setSubAutoOrderSort } = useActions()

  const sortHandler = (field: string) => {
    const sortDataMap = SubAutoOrderFieldTypeMap[field as SubAutoOrderFieldType]

    if (order === 'asc') {
      setSubAutoOrderSort({
        orientation: sortDataMap.desc,
        order: 'desc',
      })
    } else {
      setSubAutoOrderSort({
        orientation: sortDataMap.asc,
        order: 'asc',
      })
    }
  }

  const activeSort = (field: string) => {
    const sortDataMap = SubAutoOrderFieldTypeMap[field as SubAutoOrderFieldType]

    if (sortDataMap) {
      return (
        sortDataMap.asc === listOrientation ||
        sortDataMap.desc === listOrientation
      )
    } else {
      return false
    }
  }

  return (
    <Box sx={{ mx: 2 }}>
      <BackToAutoOrderPage marginDisabled text={'Просмотр заявки'} />

      <TableContainer sx={tableContainerStyle}>
        <Table size="small" aria-label="auto-order bids details table">
          <TableHead>
            <TableRow sx={tableHeadRowStyle}>
              {headCellsArray.map((item) => (
                <TableCell
                  key={item.name}
                  align={item.align}
                  sx={{
                    padding: item.enablePadding
                      ? '0.5rem 1rem 0 1rem'
                      : '0.5rem 0 0 0',
                  }}
                >
                  <TableSortLabel
                    active={activeSort(item.field)}
                    direction={order}
                    onClick={() => sortHandler(item.field)}
                  >
                    <Box sx={{ pl: item.enableLeftPadding ? '18px' : 'unset' }}>
                      {item.name}
                    </Box>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <ElementWatch
              componentNode={'tr'}
              element={firstElement}
              isLoading={loading}
            />
            {items.map((row: ISubAutoOrderItem, index) => (
              <React.Fragment key={row.id + index}>
                <SubAutoOrderRow row={row} />
                <tr
                  // @ts-ignore
                  ref={(el) => (inputRef.current[row.id] = el)}
                />
              </React.Fragment>
            ))}
            <ElementWatch
              componentNode={'tr'}
              element={lastElement}
              isLoading={loading}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default SubAutoOrderMain
