import { getComparator } from '../../../../../../shared/services/commonService'
import { ErrorInfo, LoadDirections } from '../../../../../../shared/types/types'
import {
  PriceListDeliveryAction,
  PriceListDeliveryActionTypes,
  PriceListDeliveryItems,
  PriceListDeliveryState,
} from './priceListReduxTypes'
import { PRICE_LIST } from '../../consts/priceList'
import { DayToIndex } from '../../types/priceListTypes'

const initialState: PriceListDeliveryState = {
  enough: false,
  direction: LoadDirections.DOWN,
  loading: false,
  error: {} as ErrorInfo,
  page: 1,
  limit: 30,
  total: 30,
  filter: {},
  isCheckedAll: false,
  currentTabDay: 0,
  deliveryMode: 0,
  startDate: null,
  endDate: null,
  daysDiffInput: 1,
  products: [],
  defaultDays: [],
  shallowCopyProducts: [],
  shallowCopyDefaultDays: [],
  checkedItems: [],
  orderBy: 'departmentId',
  order: 'asc',
}

export const priceListReducer = (
  state = initialState,
  action: PriceListDeliveryAction
): PriceListDeliveryState => {
  switch (action.type) {
    case PriceListDeliveryActionTypes.SET_FILTER_PRODUCTS:
      return {
        ...state,
        products: [],
        page: 1,
        filter: { ...action.payload },
      }
    case PriceListDeliveryActionTypes.RESET_FILTER_PRODUCTS:
      return {
        ...state,
        products: [],
        page: 1,
        filter: {},
      }
    case PriceListDeliveryActionTypes.FETCH_PRODUCTS_SUCCESS:
      const { content, loadFlag } = action.payload

      const sortedArray = content.settings.sort(
        getComparator(state.order, state.orderBy)
      )

      const productsArray = loadFlag
        ? state.products.concat(sortedArray)
        : sortedArray.concat(state.products)

      // удаляем дубликаты
      const productFilteredArray = productsArray.filter(
        //@ts-ignore
        (v, i, a) => a.findIndex((t) => t.departmentId === v.departmentId) === i
      )
      const productSlicedArray = loadFlag
        ? productFilteredArray.slice(-PRICE_LIST.MAX_PRODUCT_ITEMS)
        : productFilteredArray.slice(0, PRICE_LIST.MAX_PRODUCT_ITEMS)

      return {
        ...state,
        products: productSlicedArray,
        shallowCopyProducts: productSlicedArray,
        error: {},
        total: content.total,
        direction: loadFlag ? LoadDirections.DOWN : LoadDirections.UP,
      }

    case PriceListDeliveryActionTypes.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case PriceListDeliveryActionTypes.SET_DIFF_DAYS_PL_DELIVERY_INPUT:
      return {
        ...state,
        daysDiffInput: action.payload,
      }

    case PriceListDeliveryActionTypes.SET_START_DATE_PL_DELIVERY:
      return {
        ...state,
        startDate: action.payload,
      }

    case PriceListDeliveryActionTypes.SET_END_DATE_PL_DELIVERY:
      return {
        ...state,
        endDate: action.payload,
      }

    case PriceListDeliveryActionTypes.SET_PL_DELIVERY_DEFAULT_DAYS:
      return {
        ...state,
        defaultDays: action.payload,
        shallowCopyDefaultDays: action.payload,
      }

    case PriceListDeliveryActionTypes.UPDATE_PL_DELIVERY_DEFAULT_DAYS: {
      const currentDay = DayToIndex[state.currentTabDay]

      return {
        ...state,
        defaultDays: {
          ...state.defaultDays,
          [currentDay]: action.payload,
        },
      }
    }

    case PriceListDeliveryActionTypes.SET_PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case PriceListDeliveryActionTypes.SET_CURRENT_DELIVERY_TAB_DAY:
      return {
        ...state,
        currentTabDay: action.payload,
      }

    case PriceListDeliveryActionTypes.FETCH_PRODUCTS_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }

    case PriceListDeliveryActionTypes.SET_PRODUCT_SORT:
      const order = action.payload.order
      const orderBy = action.payload.orderBy
      return {
        ...state,
        products: state.products.slice().sort(getComparator(order, orderBy)),
        orderBy,
        order,
      }

    case PriceListDeliveryActionTypes.LOADING_ENABLE:
      return { ...state, loading: true }

    case PriceListDeliveryActionTypes.LOADING_DISABLE:
      return { ...state, loading: false }

    case PriceListDeliveryActionTypes.SET_CHECKED_ITEMS:
      return {
        ...state,
        checkedItems: action.payload,
      }

    case PriceListDeliveryActionTypes.SET_PL_DELIVERY_MODE:
      return {
        ...state,
        deliveryMode: action.payload,
      }

    case PriceListDeliveryActionTypes.ADD_CHECKED_ITEMS:
      return {
        ...state,
        checkedItems: [...state.checkedItems, ...action.payload],
      }

    case PriceListDeliveryActionTypes.UPDATE_DAYS_COUNT_MODEL:
      const currentDay = DayToIndex[state.currentTabDay]

      const transformedArray = state.products.map((item) => {
        if (state.checkedItems.includes(item.departmentId)) {
          return {
            ...item,
            [currentDay]: action.payload,
          }
        }
        return item
      })

      return {
        ...state,
        products: transformedArray,
      }

    case PriceListDeliveryActionTypes.SET_IS_CHECKED_ALL:
      return {
        ...state,
        isCheckedAll: !state.isCheckedAll,
      }

    case PriceListDeliveryActionTypes.RESET_PL_DELIVERY_TABLE_STATE:
      return {
        ...state,
        isCheckedAll: false,
        checkedItems: [],
        orderBy: 'departmentId',
        order: 'asc',
      }

    case PriceListDeliveryActionTypes.RESET_PL_DELIVERY_CALENDAR_DEFAULT_STATE: {
      const currentDay = DayToIndex[state.currentTabDay]
      const defaultValue = state.shallowCopyDefaultDays[currentDay]

      return {
        ...state,
        defaultDays: {
          ...state.defaultDays,
          [currentDay]: defaultValue,
        },
      }
    }

    case PriceListDeliveryActionTypes.RESET_PL_DELIVERY_CALENDAR_DEP_STATE: {
      const currentDay = DayToIndex[state.currentTabDay]

      const transformedArray = state.products.map((a) => {
        if (state.checkedItems.includes(a.departmentId)) {
          let index = state.shallowCopyProducts.findIndex(
            (b) => b.departmentId === a.departmentId
          )
          let objValue =
            state.shallowCopyProducts[index][
              currentDay as keyof PriceListDeliveryItems
            ]
          return {
            ...a,
            [currentDay]: objValue,
          }
        }
        return a
      })
      return {
        ...state,
        products: transformedArray,
      }
    }

    default:
      return state
  }
}
