import React from 'react'

// project imports
import ElementWatch from '../Product/components/ElementWatch'
import SaleDetailsTableRow from './components/SaleDetailsTableRow'
import { headCellsSaleDetails } from './consts/saleDetails'
import SaleDetailsAppBar from './components/SaleDetailsAppBar'
import TableNoItems from '../../shared/components/TableNoItems'
import { ReactComponent as StopListlIcon } from '../../shared/assets/images/StopSign.svg'

//mui imports
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import { useActions } from '../../shared/hooks/useActions'
import { SaleDetailsFieldType } from './types/saleDetailsTypes'
import { useSaleDetailsController } from './controllers/useSaleDetailsController'
import SvgIcon from '@mui/material/SvgIcon'

const SaleDetailsMain = () => {
  const {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    products,
    order,
    orderBy,
  } = useSaleDetailsController()

  const { setSaleDetailsSortAction } = useActions()

  const sortHandler = (field: SaleDetailsFieldType) => {
    const isAsc = orderBy === field && order === 'asc'
    setSaleDetailsSortAction({ orderBy: field, order: isAsc ? 'desc' : 'asc' })
  }

  return (
    <>
      {products.length > 0 && (
        <ElementWatch element={firstElement} isLoading={loading} />
      )}
      <SaleDetailsAppBar />
      <TableContainer
        sx={{
          border: '1px solid',
          borderColor: 'action.disabled',
          boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
          borderRadius: '8px',
          pb: 2,
          overflowX: 'initial',
        }}
      >
        <Table size="small" aria-label="a sale-details table">
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  whiteSpace: 'nowrap',
                  fontSize: '0.75rem',
                  color: 'primary.main',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  borderBottom: 0,
                  borderRadius: '15px',
                  backgroundColor: 'background.paper',
                  position: 'sticky',
                  top: 65,
                  zIndex: 1,
                },
              }}
            >
              <TableCell>
                <SvgIcon
                  viewBox="0 0 18 18"
                  style={{
                    marginTop: '15px',
                  }}
                >
                  <StopListlIcon />
                </SvgIcon>
              </TableCell>
              {headCellsSaleDetails.map((item) => (
                <TableCell
                  key={item.name}
                  align={item.align}
                  sx={{
                    padding: item.enablePaddings
                      ? '0.5rem 1rem 0 1rem'
                      : '0.5rem 0 0 0',
                  }}
                >
                  {!item.disableField && (
                    <TableSortLabel
                      active={orderBy === item.field}
                      direction={orderBy === item.field ? order : 'asc'}
                      onClick={() => sortHandler(item.field)}
                    >
                      <Box
                        sx={{ pl: item.enableLeftPadding ? '18px' : 'unset' }}
                      >
                        {item.name}
                      </Box>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableNoItems loading={loading} isEmpty={products.length === 0} />
            {products.map((row: any) => (
              <React.Fragment>
                <SaleDetailsTableRow row={row} key={row.id} />
                <tr
                  // @ts-ignore
                  ref={(el) => (inputRef.current[row.productEsCode] = el)}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {products.length > 0 && (
        <ElementWatch element={lastElement} isLoading={loading} />
      )}
    </>
  )
}

export default SaleDetailsMain
