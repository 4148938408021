import React from 'react'

//mui
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'

// assets
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

// project imports
import DepartmentsWithNeededProducts from './DepartmentsWithNeededProducts'

const collapseStyle = {
  paddingBottom: 0,
  paddingTop: 0,
  paddingLeft: 3,
  backgroundColor: '#D9D9D933',
}

const SaleComplexTableRow = ({ row }: { row: any }) => {
  //todo
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <TableRow
        sx={{
          '& td,th': {
            fontSize: '0.725rem',
          },
          '&:last-child td, &:last-child th': { border: 0 },
        }}
      >
        <TableCell sx={{ padding: '0px 0px 0px 4px' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" sx={{ paddingLeft: '8px' }}>
          {row.productEsCode}
        </TableCell>
        <TableCell align="center"> {row.category}</TableCell>
        <TableCell align="left">{row.productName}</TableCell>
        <TableCell align="left">{row.producerName}</TableCell>
        <TableCell align="center">{row.departmentsStockQuantity}</TableCell>
        <TableCell align="center">{row.departmentsQuantityPurchased}</TableCell>
        <TableCell align="center">{row.departmentsSoldIn30Days} </TableCell>
        <TableCell align="center">{row.quantityProductOnWay} </TableCell>
        <TableCell align="center">{row.neededQuantityForOrder} </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={10} style={collapseStyle}>
          <DepartmentsWithNeededProducts
            open={open}
            departmentsWithNeededProducts={row.departmentsWithNeededProducts}
          />
        </TableCell>
      </TableRow>
    </>
  )
}

export default SaleComplexTableRow
