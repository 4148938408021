import { useState } from 'react'

export const useFetching = <T extends unknown[]>(callback: any) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const fetching = async (...args: T) => {
    try {
      setLoading(true)
      await callback(...args)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }
  return [fetching, loading, error] as const
}
