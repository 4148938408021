import { DepartmentsPreOrderFieldTypeMap, IDepartmentsPreOrderHeadCells } from "../types/departmentsPreOrderTypes";

export const DEPARTMENT_CONSTS = {
  DEFAULT_ORDERS: 'BitProductName',
  PROUDCT_COUNT: 15,
  OPERATION: 'Equals',
  MIN_ITEMS_AFTER_LOADING: 16,
  START_DOWN_INDEX: 15,
  END_DOWN_INDEX: 75,
  START_UP_INDEX: 0,
  END_UP_INDEX: 45,
}



export const nameFilterKeys: string[] = [ 'DepartmentId', 'SupplierId',  'PriceListTypeId' ]

export const filterKeys: string[] = [ 'departmentId', 'supplierId', 'priceListTypeId' ]
export const headCellsArray: IDepartmentsPreOrderHeadCells[] = [
  {
    name: 'Предзаказ',
    align: 'left',
    field: DepartmentsPreOrderFieldTypeMap.departmentName.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    minWidth: '100'
  },
  {
    name: 'Сумма',
    align: 'left',
    field: DepartmentsPreOrderFieldTypeMap.orderCost.value,
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
    minWidth: '100'
  },
  // {
  //   name: '',
  //   align: 'left',
  //   field: DepartmentsPreOrderFieldTypeMap.orderCost.value,
  //   enableLeftPadding: true,
  //   disableField: false,
  //   disableSort: false,
  //   enablePaddings: false,
  // },
]
