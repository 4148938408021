import React, { useMemo, useState } from 'react'
import { TextFieldCustom } from '../../../shared/components/styled/TextFieldCustom'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import Box from '@mui/material/Box'
import { Listbox } from '../../../shared/components/styled/ListBox'
import { transformList } from '../../../shared/services/othersService'

interface ICustomList {
  value: string
  label: string
  name: string
  handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => void
  handleChangeList: (event: any) => void
  resetField: (key: string) => () => void
  disabled: boolean
}

const CustomList = ({
  value,
  label,
  name,
  handleChangeFilter,
  handleKeyPress,
  disabled,
  resetField,
  handleChangeList,
}: ICustomList) => {
  const [visibility, setVisibility] = useState(false)

  const hidePopover = () => {
    if (visibility) {
      setTimeout(() => {
        setVisibility(false)
      }, 200)
    }
  }
  const showPopover = () => setVisibility(true)

  const handleClick = (e: any) => {
    handleChangeList(e)
    hidePopover()
  }

  const suggestionList = useMemo(() => transformList(value.trim()), [value])

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        verticalAlign: 'top',
      }}
    >
      <TextFieldCustom
        label={label}
        name={name}
        variant="outlined"
        size="small"
        onChange={handleChangeFilter}
        onKeyPress={handleKeyPress}
        disabled={disabled}
        onFocus={showPopover}
        onBlur={hidePopover}
        value={value}
        sx={{ width: 225 }}
        InputProps={{
          endAdornment: (
            <>
              {value && (
                <IconButton size="small" onClick={resetField('name')}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
            </>
          ),
        }}
      />
      {suggestionList.length > 1 && visibility ? (
        <Listbox>
          {suggestionList.map((item, index) => (
            <li key={index} onClick={(e) => handleClick(e)}>
              {item}
            </li>
          ))}
        </Listbox>
      ) : null}
    </Box>
  )
}

export default CustomList
