import { TableAlignType } from "../../../../../shared/types/types"
import { ProcessingAutoOrderOrientation } from "../model/processingAutoOrder/processingAutoOrderReduxTypes"

export interface IProcessingAutoOrder {
  items: IProcessingAutoOrderItem[]
  pagination: IProcessingAutoOrderPagination
  isError: boolean
  description: string
  order: string
}

export interface IProcessingAutoOrderItem {
  id: string
  initialSummaryBidId: string,
  actualSummaryBidId: string,
  type: number,
  status: number,
  code: string,
  date: string,
  departmentId: number,
  departmentName: string,
  description: string
}

export interface IProcessingAutoOrderPagination {
  pageNumber: number
  pageSize: number
  total: number
}

export interface IProcessingAutoOrderHeadCells {
  name: string
  align: TableAlignType
  field: string
  enableLeftPadding?: boolean
  enablePaddings?: boolean
  disableField?: boolean
  disableSort?: boolean
}

interface IFieldsTypeMap {
  value: string,
  asc: ProcessingAutoOrderOrientation
  desc: ProcessingAutoOrderOrientation
}

export const ProcessingAutoOrderFieldTypeMap: Record<ProcessingAutoOrderFieldType, IFieldsTypeMap> = {
  date: {
    value: 'date',
    asc: ProcessingAutoOrderOrientation.StartDate,
    desc: ProcessingAutoOrderOrientation.EndDate
  },
  code: {
    value: 'code',
    asc: ProcessingAutoOrderOrientation.CodesAsc,
    desc: ProcessingAutoOrderOrientation.CodesDesc
  },
  departmentName: {
    value: 'departmentName',
    asc: ProcessingAutoOrderOrientation.DepartmentNameAsc,
    desc: ProcessingAutoOrderOrientation.DepartmentNameDesc
  },
  status: {
    value: 'status',
    asc: ProcessingAutoOrderOrientation.StatusAsc,
    desc: ProcessingAutoOrderOrientation.StatusDesc
  },
  description: {
    value: 'description',
    asc: ProcessingAutoOrderOrientation.DescriptionsAsc,
    desc: ProcessingAutoOrderOrientation.DescriptionsDesc
  }
}

export type ProcessingAutoOrderFieldType =
  | 'date'
  | 'code'
  | 'departmentName'
  | 'status'
  | 'description'
