import {
  SaleComplexAction,
  SaleComplexActionTypes,
  SaleComplexState,
} from './saleComplexReduxTypes'
import { SALE_COMPLEX } from '../../consts/saleComplex'
import cloneDeep from 'lodash/cloneDeep'
import { ErrorInfo, LoadDirections } from '../../../../../../shared/types/types'

const initialState: SaleComplexState = {
  isBlockedTable: false,
  enough: false,
  direction: LoadDirections.DOWN,
  loading: false,
  error: {} as ErrorInfo,
  page: 1,
  limit: 30,
  total: 30,
  filter: { order: '+productName' },
  products: [],
  orderBy: 'productName',
  order: 'asc',
  startSearch: false,
  options: {
    availabilityByQuantity: {
      considerAvailabilityByQuantity: false,
    },
    needsPeriod: {
      needsPeriod: 1,
    },
    remainingShelfLifeDuration: {
      remainingShelfLifeInclude: false,
      remainingShelfLifeInDays: 0,
    },
    remainingShelfLifePercent: {
      remainingShelfLifePercentInclude: false,
      remainingShelfLifePercent: 0,
    },
    winningByPrice: {
      onlyWithPriceGain: false,
    },
    bonusInPrice: {
      applyBonusInPriceToProductsPrice: false,
    },
    consigneeDepartment: { departmentName: '', departmentId: 0 },
    highMarginProduct: {
      onlyHighMarginProducts: false,
    },
  },
}

export const saleComplexReducer = (
  state = initialState,
  action: SaleComplexAction
): SaleComplexState => {
  switch (action.type) {
    case SaleComplexActionTypes.SET_FILTER_PRODUCTS:
      return {
        ...state,
        products: [],
        page: 1,
        filter: { ...action.payload },
      }
    case SaleComplexActionTypes.RESET_FILTER_PRODUCTS:
      return {
        ...state,
        products: [],
        page: 1,
        filter: {},
      }
    case SaleComplexActionTypes.FETCH_PRODUCTS_SUCCESS:
      const { content, loadFlag } = action.payload

      const resultArray = content.productsStockAndSales

      const productsArray = loadFlag
        ? state.products.concat(resultArray)
        : resultArray.concat(state.products)

      const productFilteredArray = productsArray.filter(
        //удаляем дубликаты
        //@ts-ignore
        (v, i, a) =>
          a.findIndex((t: any) => t.productEsCode === v.productEsCode) === i
      )

      const productSlicedArray = loadFlag
        ? productFilteredArray.slice(-SALE_COMPLEX.MAX_PRODUCT_ITEMS)
        : productFilteredArray.slice(0, SALE_COMPLEX.MAX_PRODUCT_ITEMS)

      return {
        ...state,
        products: productSlicedArray,
        error: {},
        total: content.pagination.total,
        direction: loadFlag ? LoadDirections.DOWN : LoadDirections.UP,
      }

    case SaleComplexActionTypes.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case SaleComplexActionTypes.SET_PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_PERIOD:
      const period = Number(action.payload)
      return {
        ...state,
        options: {
          ...state.options,
          needsPeriod: {
            needsPeriod: period,
          },
        },
      }

    case SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_SHELF_LIFE_PERCENT:
      const percentInclude = action.payload
      return {
        ...state,
        options: {
          ...state.options,
          remainingShelfLifePercent: {
            ...state.options.remainingShelfLifePercent,
            remainingShelfLifePercentInclude: percentInclude,
          },
        },
      }

    case SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_SHELF_LIFE:
      const shelfLifeInclude = action.payload
      return {
        ...state,
        options: {
          ...state.options,
          remainingShelfLifeDuration: {
            ...state.options.remainingShelfLifeDuration,
            remainingShelfLifeInclude: shelfLifeInclude,
          },
        },
      }

    case SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_AVAILABILITY:
      return {
        ...state,
        options: {
          ...state.options,
          availabilityByQuantity: {
            ...state.options.availabilityByQuantity,
            considerAvailabilityByQuantity: action.payload,
          },
        },
      }

    case SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTION_PRICE_GAIN:
      return {
        ...state,
        options: {
          ...state.options,
          winningByPrice: {
            ...state.options.winningByPrice,
            onlyWithPriceGain: action.payload,
          },
        },
      }

    case SaleComplexActionTypes.SAVE_SALE_COMPLEX_TEXT_SHELF_LIFE:
      return {
        ...state,
        options: {
          ...state.options,
          remainingShelfLifeDuration: {
            ...state.options.remainingShelfLifeDuration,
            remainingShelfLifeInDays: action.payload,
          },
        },
      }

    case SaleComplexActionTypes.SAVE_SALE_COMPLEX_TEXT_SHELF_LIFE_PERCENT:
      return {
        ...state,
        options: {
          ...state.options,
          remainingShelfLifePercent: {
            ...state.options.remainingShelfLifePercent,
            remainingShelfLifePercent: action.payload,
          },
        },
      }

    case SaleComplexActionTypes.FETCH_HIGH_MARGIN:
      return {
        ...state,
        options: {
          ...state.options,
          highMarginProduct: {
            onlyHighMarginProducts: action.payload,
          },
        },
      }
    // case SaleComplexActionTypes.ENABLE_SALE_COMPLEX_OPTION_BONUS_PRICE: // todo
    //   return {
    //     ...state,
    //     options: {
    //       ...state.options,
    //       bonusInPrice: {
    //         ...state.options.bonusInPrice,
    //         applyBonusInPriceToProductsPrice: true,
    //       },
    //     },
    //   }

    case SaleComplexActionTypes.ENABLE_SALE_COMPLEX_BLOCK_TABLE:
      return {
        ...state,
        isBlockedTable: true,
      }

    /////////////////////////////////////////////////////////////

    case SaleComplexActionTypes.FETCH_PRODUCTS_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }

    case SaleComplexActionTypes.SAVE_SALE_COMPLEX_OPTIONS:
      return {
        ...state,
        options: action.payload,
      }

    case SaleComplexActionTypes.DELETE_ALL_SALE_COMPLEX_STOP_LIST_ITEMS: {
      let tempArr = cloneDeep(state.products)
      tempArr.map((item: any) =>
        item.departmentsWithNeededProducts.map(
          (item: any) => (item.isInStopList = false)
        )
      )
      return {
        ...state,
        products: tempArr,
      }
    }

    case SaleComplexActionTypes.ADD_SALE_COMPLEX_STOP_LIST_ITEM: {
      let tempArr = cloneDeep(state.products)
      const itemStockAndSales = tempArr.find(
        (item: any) => item.productEsCode === action.payload.productEscode
      )

      if (itemStockAndSales) {
        const itemDepartments =
          itemStockAndSales.departmentsWithNeededProducts.filter(
            (item: any) => item.departmentId === action.payload.depId
          )

        if (itemDepartments.length > 0) {
          itemDepartments.map((item: any) => (item.isInStopList = true))
        }

        const totalSum = itemStockAndSales.departmentsWithNeededProducts
          .filter((item: any) => item.isInStopList === false)
          .reduce(
            (accum: any, item: any) => accum + item.neededQuantityForOrder,
            0
          )
        itemStockAndSales.neededQuantityForOrder = totalSum
      }

      return {
        ...state,
        products: tempArr,
      }
    }

    case SaleComplexActionTypes.SAVE_SALE_COMPLEX_QUANTITY_FOR_ORDER: {
      let tempArr = cloneDeep(state.products)
      const itemStockAndSales = tempArr.find(
        (item: any) => item.productEsCode === action.payload.productEscode
      )
      if (itemStockAndSales) {
        const itemDepartment =
          itemStockAndSales.departmentsWithNeededProducts.find(
            (item: any) => item.id === action.payload.id
          )
        if (itemDepartment) {
          itemDepartment.neededQuantityForOrder = action.payload.amount
        }
        const totalSum = itemStockAndSales.departmentsWithNeededProducts
          .filter((item: any) => item.isInStopList === false)
          .reduce(
            (accum: any, item: any) => accum + item.neededQuantityForOrder,
            0
          )
        itemStockAndSales.neededQuantityForOrder = totalSum
      }

      return {
        ...state,
        products: tempArr,
      }
    }

    case SaleComplexActionTypes.DELETE_SALE_COMPLEX_STOP_LIST_ITEM: {
      let tempArr = cloneDeep(state.products)

      const itemStockAndSales = tempArr.find(
        (item: any) => item.productEsCode === action.payload.productEscode
      )
      if (itemStockAndSales) {
        const itemDepartments =
          itemStockAndSales.departmentsWithNeededProducts.filter(
            (item: any) => item.departmentId === action.payload.depId
          )

        if (itemDepartments.length > 0) {
          itemDepartments.map((item: any) => (item.isInStopList = false))
        }

        const totalSum = itemStockAndSales.departmentsWithNeededProducts
          .filter((item: any) => item.isInStopList === false)
          .reduce(
            (accum: any, item: any) => accum + item.neededQuantityForOrder,
            0
          )
        itemStockAndSales.neededQuantityForOrder = totalSum
      }

      return {
        ...state,
        products: tempArr,
      }
    }

    case SaleComplexActionTypes.SET_PRODUCT_SORT:
      const order = action.payload.order
      const orderBy = action.payload.orderBy
      const newOrder = order === 'asc' ? '+' : '-'
      return {
        ...state,
        filter: { ...state.filter, order: newOrder + orderBy },
        products: [],
        page: 1,
        enough: false,
        startSearch: !state.startSearch,
        orderBy,
        order,
      }

    case SaleComplexActionTypes.LOADING_ENABLE:
      return { ...state, loading: true }
    case SaleComplexActionTypes.LOADING_DISABLE:
      return { ...state, loading: false }

    case SaleComplexActionTypes.RESET_SALE_COMPLEX_STATE:
      return {
        ...state,
        ...initialState,
        startSearch: !state.startSearch, //todo
        options: state.options,
      }
    default:
      return state
  }
}
