import { ErrorInfo } from "react"
import { FactorItem, ISupplierSettingsOptions, PriorityItem } from "../../types/supplierSettingsTypes"

export interface SupplierSettingsState {
  // options: ISupplierSettingsOptions,

  considerMinimumQuantity: boolean,
  considerQuantityAvailabilityFactor: boolean,
  factors: FactorItem[],

  departmentId: number | null,

  considerMultiplicityOfBoxQuantity: boolean,

  prioritiesOrder: PriorityItem[]
  
  onlyProductsWithBestRating: boolean,

  remainingShelfLifeInDays: string | number,
  remainingShelfLifeInclude: boolean,
  remainingShelfLifePercent: string | number,
  remainingShelfLifePercentInclude: boolean,

  distributeSupplierOrderIfCostLessThanMinimumCost: boolean,
  supplierOrderMinimumCostPercentage: string | number
  
  updateByUserName: string | null,
  updatedAt: string,
}

export enum SupplierSettingsActionTypes {
  FETCH_SUPPLIER_SETTINGS_SUCCESS = 'FETCH_SUPPLIER_SETTINGS_SUCCESS',
  FETCH_SETTINGS_ERROR = 'FETCH_SUPPLIERS_SETTINGS_ERROR',

  // Order parametrs tab
  SET_SUPPLIER_SETTINGS_TEXT_SHELF_LIFE_PERCENT = 'SET_SUPPLIER_SETTINGS_TEXT_SHELF_LIFE_PERCENT',
  SET_SUPPLIER_SETTINGS_TEXT_SHELF_LIFE = 'SET_SUPPLIER_SETTINGS_TEXT_SHELF_LIFE',

  SET_SUPPLIER_SETTINGS_OPTION_SHELF_LIFE_PERCENT = 'SET_SUPPLIER_SETTINGS_OPTION_SHELF_LIFE_PERCENT',
  SET_SUPPLIER_SETTINGS_OPTION_SHELF_LIFE = 'SET_SUPPLIER_SETTINGS_OPTION_SHELF_LIFE',

  SET_SUPPLIER_SETTINGS_OPTION_BEST_RATING = 'SET_SUPPLIER_SETTINGS_OPTION_BEST_RATING',

  // Supplier parametrs tab
  SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_MULTIPLICITY = 'SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_MULTIPLICITY',
  SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_MIN_QUANTITY = 'SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_MIN_QUANTITY',

  SET_SUPPLIER_SETTINGS_OPTION_DISTRIBUTE_SUPPLIER_ORDER = 'SET_SUPPLIER_SETTINGS_OPTION_DISTRIBUTE_SUPPLIER_ORDER',
  SET_SUPPLIER_SETTINGS_TEXT_MIN_COST_PERCENT = 'SET_SUPPLIER_SETTINGS_TEXT_MIN_COST_PERCENT',

   // Priority table tab
  UPDATE_SUPPLIER_SETTINGS_PRIORITY_ORDER = 'UPDATE_SUPPLIER_SETTINGS_PRIORITY_ORDER',

  // Quantity available ratio tab
  SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_FACTOR = 'SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_FACTOR',
  SET_SUPPLIER_SETTINGS_OPTION_FACTORS_LIST = 'SET_SUPPLIER_SETTINGS_OPTION_FACTORS_LIST',
}

interface FetchSupplierSettingsSuccessAction {
  type: SupplierSettingsActionTypes.FETCH_SUPPLIER_SETTINGS_SUCCESS,
  payload: ISupplierSettingsOptions
}

interface FetchSupplierSettingsErrorAction {
  type: SupplierSettingsActionTypes.FETCH_SETTINGS_ERROR,
  payload: ErrorInfo | {}
}

interface SetSupplierSettingsTextShelfLifePercentAction {
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_TEXT_SHELF_LIFE_PERCENT
  payload: string
}

interface SetSupplierSettingsTextShelfLifeAction {
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_TEXT_SHELF_LIFE
  payload: number
}

interface SetSupplierSettingsOptionShelfLifePercentAction {
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_SHELF_LIFE_PERCENT
  payload: boolean
}

interface SetSupplierSettingsOptionShelfLifeAction {
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_SHELF_LIFE
  payload: boolean
}

interface SetSupplierSettingsOptionPriceGainAction {
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_BEST_RATING
  payload: boolean
}

interface SetSupplierSettingsOptionConsiderMultiplicityAction {
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_MULTIPLICITY
  payload: boolean
}

interface SetSupplierSettingsOptionConsiderMinQuantityAction {
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_MIN_QUANTITY
  payload: boolean
}

interface SetSupplierSettingsOptionDistributeSupplierOrderAction {
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_DISTRIBUTE_SUPPLIER_ORDER
  payload: boolean
}

interface SetSupplierSettingsTextMinCostPercentAction {
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_TEXT_MIN_COST_PERCENT
  payload: string
}

interface UpdateSupplierSettingsPriorityOrderAction {
  type: SupplierSettingsActionTypes.UPDATE_SUPPLIER_SETTINGS_PRIORITY_ORDER
  payload: PriorityItem[]
}

interface SetSupplierSettingsOptionConsiderFactorAction {
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_CONSIDER_FACTOR
  payload: boolean
}

interface SetSupplierSettingsOptionFactorsListAction {
  type: SupplierSettingsActionTypes.SET_SUPPLIER_SETTINGS_OPTION_FACTORS_LIST
  payload: FactorItem[]
}

export type SupplierSettingsAction =
  | FetchSupplierSettingsSuccessAction
  | FetchSupplierSettingsErrorAction
  | SetSupplierSettingsTextShelfLifePercentAction
  | SetSupplierSettingsTextShelfLifeAction
  | SetSupplierSettingsOptionShelfLifePercentAction
  | SetSupplierSettingsOptionShelfLifeAction
  | SetSupplierSettingsOptionPriceGainAction
  | SetSupplierSettingsOptionConsiderMultiplicityAction
  | SetSupplierSettingsOptionConsiderMinQuantityAction
  | SetSupplierSettingsOptionDistributeSupplierOrderAction
  | SetSupplierSettingsTextMinCostPercentAction
  | UpdateSupplierSettingsPriorityOrderAction
  | SetSupplierSettingsOptionConsiderFactorAction
  | SetSupplierSettingsOptionFactorsListAction 