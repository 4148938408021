import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
//third party
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ru'

// selectors
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'

// hooks
import { useRange } from '../../../../shared/hooks/useRange'
import { useActions } from '../../../../shared/hooks/useActions'
import { useFilterFetchController } from '../../controllers/useFiltersFetchController'

// mui imports
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'

// project imports
import SliderCustom from './SliderCustom/SliderCustom'
import SupplierAutoComplete from '../../../../shared/components/autoCompletes/SupplierAutoComplete'
import CountryAutoComplete from '../../../../shared/components/autoCompletes/CountryAutoComplete'
import ProducerAutoComplete from '../../../../shared/components/autoCompletes/ProducerAutoComplete'
import RenderIcons from '../RenderIcons'
import ButtonBar from './ButtonBar'
import ExpirationDateDatePicker from './ExpirationDateDatePicker'
import ExpirationPercentTextField from './ExpirationPercentTextField'
import StandardTextField from './StandardTextField'
import NdsAutoComplete from '../../../../shared/components/autoCompletes/NdsAutoComplete'

// constants
import { FILTER_OPTIONS } from '../../../../shared/consts/filter'

// service
import { getNext } from '../../../../shared/services/commonService'

// types
import { IOptionsProps } from '../../../../shared/types/types'
import { IFilter } from '../../../../shared/types/filterTypes'

// api
import { postFilterValues } from '../../../../shared/api/filter'
import { MarketingEventsAutoComplete } from './MarketingEventsSelect'

// project import
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import qs from 'qs'
import { getSupplier } from '../../../../shared/api/hint'
import dayjs from 'dayjs'

const initialData = FILTER_OPTIONS.map((item) => ({
  [item.name]: item.state,
  [item.nameOrder]: item.stateOrder,
}))

const resetData = FILTER_OPTIONS.map((item) => {
  return [
    item.name,
    {
      [item.name]: item.state,
      [item.nameOrder]: item.stateOrder,
    },
  ]
})

const initialState = Object.assign({}, ...initialData)

const FilterSideBar = () => {
  const { resetFilterProducts, setFilterProducts, fetchCurrentDepartment } =
    useActions()
  const location = useLocation()
  const history = useHistory()

  const searchParams = qs.parse(location.search.substring(1))
  const defaultFilters = JSON.parse(
    sessionStorage.getItem('filterCash1') as string
  )
  const defaultSearchFilters = JSON.parse(
    sessionStorage.getItem('filterCash2') as string
  )

  useFilterFetchController()
  const { filterValues, sideBarFilter } = useTypedSelector((state) => state.app)

  const [filter, setFilter] = useState<IFilter>(initialState)
  const [headFilters, setHeadFilters] = useState<string[]>([])
  const isMounted = useRef(false)

  const setMinRange = (payload: number) => {
    setFilter({
      ...filter,
      priceFilter: {
        ...filter?.priceFilter,
        priceFilterMin: payload,
      },
    })
  }

  const setMaxRange = (payload: number) => {
    setFilter({
      ...filter,
      priceFilter: {
        ...filter?.priceFilter,
        priceFilterMax: payload,
      },
    })
  }

  const { handleRangeChange, handleChangeSlider } = useRange(
    setMinRange,
    setMaxRange,
    filter?.priceFilter?.priceFilterMin,
    filter?.priceFilter?.priceFilterMax
  )

  const [componentKey, setComponentKey] = useState(0)

  const resetHandler = () => {
    setFilter(initialState)
    resetFilterProducts()
    setComponentKey(componentKey + 1)
  }

  const handleSubmit = () => {
    const filterParams = _.isEmpty(filter)
      ? { ...defaultFilters, ...defaultSearchFilters }
      : filter
    postFilterValues({
      ...filterParams,
    })
    setFilterProducts()
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') handleSubmit()
  }

  const handleTextFields = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.name
    setFilter({ ...filter, [key]: event.target.value })
  }

  const handleExpPercentField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let key = event.target.name
    setFilter({
      ...filter,
      remainExpirationPercentFilter: {
        ...filter.remainExpirationPercentFilter,
        [key]: event.target.value,
      },
    })
  }

  const handleTimeChange = (newValue: Date | string | null, key: string) => {
    setFilter({
      ...filter,
      expirationDateFilter: {
        ...filter.expirationDateFilter,
        [key]: newValue,
      },
    })
  }

  const handleAutoCompleteFields = (
    newValue: IOptionsProps[] | null,
    key: string
  ) => {
    if (Array.isArray(newValue))
      setFilter({ ...filter, [key]: !newValue ? [] : newValue })
  }

  const handleNdsAutoComplete = (
    newValue: { val: number; desc: string } | null
  ) => {
    setFilter({
      ...filter,
      vatRateFilter: {
        vatRateFilterMin: !newValue ? null : newValue.val,
        vatRateFilterMax: !newValue ? null : newValue.val,
      },
    })
  }

  const handleOrderChange = (key: string) => {
    setFilter({
      ...filter,
      [key]: getNext(filter[key as keyof typeof filter]),
    })
  }

  useEffect(() => {
    setFilter((prev) => ({ ...prev, ...filterValues }))
  }, [filterValues])

  useEffect(() => {
    if (!isMounted.current) return
    sessionStorage.setItem('filterCash1', JSON.stringify(filter))
  }, [filter])

  useEffect(() => {
    const createFilterObject = resetData
      .filter(([k]) => sideBarFilter[k as keyof typeof sideBarFilter])
      .map(([_, obj]) => Object.keys(obj))

    const filteredState = Object.fromEntries(
      Object.entries(filter).filter(([k]) =>
        createFilterObject.flat().includes(k)
      )
    )

    setFilter({
      ...initialState,
      ...filteredState,
    })
  }, [sideBarFilter])

  useEffect(() => {
    const checkFilterData = (e: StorageEvent) => {
      if (e.key !== 'user-value') return
      let filterArray = JSON.parse(String(e.newValue))
      setHeadFilters(filterArray)
    }
    window.addEventListener('storage', checkFilterData)
    return () => {
      window.removeEventListener('storage', checkFilterData)
    }
  }, [])

  /// TODO REFACTOR
  const saveSearchFilters = async () => {
    try {
      const departmentId = searchParams.departmentId?.toString()!
      const firstSupplierId = (
        Array.isArray(searchParams?.supplierIdFilter)
          ? searchParams.supplierIdFilter[0]
          : ''
      ) as string
      const esCodeFilter = searchParams.esCodeFilter as string
      const dateValue = dayjs(
        searchParams.expirationDateFilterEnd as string
      ).format('YYYY-MM-DDTHH:mm:ss')

      fetchCurrentDepartment(departmentId)

      const response = await getSupplier(firstSupplierId)
      const currentSupplier = response.data.content.data

      setFilter({
        ...filter,
        supplierIdFilter: !currentSupplier ? [] : currentSupplier,
        expirationDateFilter: {
          expirationDateFilterEnd: dateValue,
          expirationDateFilterStart: null,
        },
        esCodeFilter,
      })
      handleSubmit()
    } catch (e) {
      // Обработайте ошибку, если необходимо
    }
  }

  const resetSearchFilters = () => {
    history.replace({
      search: '',
    })
  }

  useEffect(() => {
    if (!Object.values(searchParams).length || !isMounted) return
    // todo refactor
    saveSearchFilters()
    resetSearchFilters()
  }, [isMounted])

  const hanleChangeMarketingFilters = (newValue: Array<{ id: number }>) => {
    setFilter((prev) => ({
      ...prev,
      marketingEventTypesFilter: newValue,
    }))
  }

  useEffect(() => {
    if (Object.keys(searchParams).length) {
      isMounted.current = true
      return
    } else if (!_.isEmpty(defaultFilters)) setFilter(defaultFilters)
    isMounted.current = true
  }, [])
  //////

  return (
    <React.Fragment>
      {sideBarFilter['priceFilter'] && (
        <ListItem disableGutters sx={{ marginTop: 1 }} alignItems="flex-start">
          <SliderCustom
            value1={[
              filter?.priceFilter.priceFilterMin,
              filter?.priceFilter.priceFilterMax,
            ]}
            handleRangeChange={handleRangeChange}
            handleChangeSlider={handleChangeSlider}
          />
          <IconButton
            sx={{ ml: '3px' }}
            onClick={() => handleOrderChange('priceOrderRule')}
          >
            <RenderIcons condition={filter['priceOrderRule']} />
          </IconButton>
        </ListItem>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        {sideBarFilter['expirationDateFilter'] && (
          <ListItem disableGutters>
            <ExpirationDateDatePicker
              filter={filter}
              setFilter={(payload) => setFilter(payload)}
              handleTimeChange={handleTimeChange}
              handleOrderChange={handleOrderChange}
            />
          </ListItem>
        )}

        {!headFilters.includes('supplier') && (
          <ListItem disableGutters>
            <SupplierAutoComplete
              isCheckBoxType={true}
              key={componentKey}
              value={filter['supplierIdFilter']}
              variant={'multiple'}
              handleAutoCompleteFields={(val) =>
                handleAutoCompleteFields(val, 'supplierIdFilter')
              }
            />
            <IconButton
              sx={{ ml: '3px' }}
              onClick={() => handleOrderChange('supplierOrderRule')}
            >
              <RenderIcons condition={filter['supplierOrderRule']} />
            </IconButton>
          </ListItem>
        )}

        {!headFilters.includes('country') && (
          <ListItem disableGutters>
            <CountryAutoComplete
              isCheckBoxType
              key={componentKey}
              value={filter['producerCountryFilter']}
              variant={'multiple'}
              handleAutoCompleteFields={(val) =>
                handleAutoCompleteFields(val, 'producerCountryFilter')
              }
            />
            <IconButton
              sx={{ ml: '3px' }}
              onClick={() => handleOrderChange('producerCountryOrderRule')}
            >
              <RenderIcons condition={filter['producerCountryOrderRule']} />
            </IconButton>
          </ListItem>
        )}

        {!headFilters.includes('producer') && (
          <ListItem disableGutters>
            <ProducerAutoComplete
              isCheckBoxType
              key={componentKey}
              value={filter['producerNameFilter']}
              variant={'multiple'}
              handleAutoCompleteFields={(val) =>
                handleAutoCompleteFields(val, 'producerNameFilter')
              }
            />
            <IconButton
              sx={{ ml: '3px' }}
              onClick={() => handleOrderChange('producerNameOrderRule')}
            >
              <RenderIcons condition={filter['producerNameOrderRule']} />
            </IconButton>
          </ListItem>
        )}

        {sideBarFilter['remainExpirationPercentFilter'] && (
          <ListItem disableGutters>
            <ExpirationPercentTextField
              filter={filter}
              setFilter={(payload) => setFilter(payload)}
              handleTextFields={handleExpPercentField}
              handleKeyPress={handleKeyPress}
              handleOrderChange={handleOrderChange}
            />
          </ListItem>
        )}

        {sideBarFilter['esCodeFilter'] && (
          <ListItem disableGutters>
            <StandardTextField
              filter={filter}
              setFilter={(payload) => setFilter(payload)}
              handleTextFields={handleTextFields}
              handleKeyPress={handleKeyPress}
              handleOrderChange={handleOrderChange}
              name={'esCodeFilter'}
              label={'Код УС'}
              nameOrder={'esCodeOrderRule'}
            />
          </ListItem>
        )}
        {sideBarFilter['gtinFilter'] && (
          <ListItem disableGutters>
            <StandardTextField
              filter={filter}
              setFilter={(payload) => setFilter(payload)}
              handleTextFields={handleTextFields}
              handleKeyPress={handleKeyPress}
              handleOrderChange={handleOrderChange}
              name={'gtinFilter'}
              label={'GTIN'}
              nameOrder={'gtinOrderRule'}
            />
          </ListItem>
        )}
        {sideBarFilter['nameFilter'] && !headFilters.includes('name') && (
          <ListItem disableGutters>
            <StandardTextField
              filter={filter}
              setFilter={(payload) => setFilter(payload)}
              handleTextFields={handleTextFields}
              handleKeyPress={handleKeyPress}
              handleOrderChange={handleOrderChange}
              name={'nameFilter'}
              label={'Товар'}
              nameOrder={'nameOrderRule'}
            />
          </ListItem>
        )}
        {sideBarFilter['boxQuantityFilterMin'] && (
          <ListItem disableGutters>
            <StandardTextField
              filter={filter}
              setFilter={(payload) => setFilter(payload)}
              handleTextFields={handleTextFields}
              handleKeyPress={handleKeyPress}
              handleOrderChange={handleOrderChange}
              name={'boxQuantityFilterMin'}
              label={'Крат.'}
              nameOrder={'boxQuantityOrderRule'}
            />
          </ListItem>
        )}
        {sideBarFilter['minimalQuantityFilterMin'] && (
          <ListItem disableGutters>
            <StandardTextField
              filter={filter}
              setFilter={(payload) => setFilter(payload)}
              handleTextFields={handleTextFields}
              handleKeyPress={handleKeyPress}
              handleOrderChange={handleOrderChange}
              name={'minimalQuantityFilterMin'}
              label={'Мин'}
              nameOrder={'minimalQuantityOrderRule'}
            />
          </ListItem>
        )}
        {sideBarFilter['packQuantityFilterMin'] && (
          <ListItem disableGutters>
            <StandardTextField
              filter={filter}
              setFilter={(payload) => setFilter(payload)}
              handleTextFields={handleTextFields}
              handleKeyPress={handleKeyPress}
              handleOrderChange={handleOrderChange}
              name={'packQuantityFilterMin'}
              label={'Уп.'}
              nameOrder={'packQuantityOrderRule'}
            />
          </ListItem>
        )}
        {sideBarFilter['internationalNameFilter'] && (
          <ListItem disableGutters>
            <StandardTextField
              filter={filter}
              setFilter={(payload) => setFilter(payload)}
              handleTextFields={handleTextFields}
              handleKeyPress={handleKeyPress}
              handleOrderChange={handleOrderChange}
              name={'internationalNameFilter'}
              label={'МНН'}
              nameOrder={'internationalNameOrderRule'}
            />
          </ListItem>
        )}
        {sideBarFilter['registerPriceFilterMin'] && (
          <ListItem disableGutters>
            <StandardTextField
              filter={filter}
              setFilter={(payload) => setFilter(payload)}
              handleTextFields={handleTextFields}
              handleKeyPress={handleKeyPress}
              handleOrderChange={handleOrderChange}
              name={'registerPriceFilterMin'}
              label={'Реестровая цена'}
              nameOrder={'registerPriceOrderRule'}
            />
          </ListItem>
        )}
        {sideBarFilter['vatRateFilter'] && (
          <ListItem disableGutters>
            <NdsAutoComplete
              handleAutoCompleteFields={(val) => handleNdsAutoComplete(val)}
            />
            <IconButton
              sx={{ ml: '3px' }}
              onClick={() => handleOrderChange('vatRateOrderRule')}
            >
              <RenderIcons condition={filter['vatRateOrderRule']} />
            </IconButton>
          </ListItem>
        )}
        {sideBarFilter['producerPriceFilterMin'] && (
          <ListItem disableGutters>
            <StandardTextField
              filter={filter}
              setFilter={(payload) => setFilter(payload)}
              handleTextFields={handleTextFields}
              handleKeyPress={handleKeyPress}
              handleOrderChange={handleOrderChange}
              name={'producerPriceFilterMin'}
              label={'Цена производителя'}
              nameOrder={'producerPriceOrderRule'}
            />
          </ListItem>
        )}
        {sideBarFilter['wholesaleMarkupFilterMin'] && (
          <ListItem disableGutters>
            <StandardTextField
              filter={filter}
              setFilter={(payload) => setFilter(payload)}
              handleTextFields={handleTextFields}
              handleKeyPress={handleKeyPress}
              handleOrderChange={handleOrderChange}
              name={'wholesaleMarkupFilterMin'}
              label={'Опт. наценка'}
              nameOrder={'wholeSaleOrderRule'}
            />
          </ListItem>
        )}
        {sideBarFilter['marketingEventTypesFilter'] && (
          <ListItem disableGutters>
            <MarketingEventsAutoComplete
              handleChangeMarketingEvents={hanleChangeMarketingFilters}
              handleOrderChange={handleOrderChange}
              filter={filter}
              nameOrder="marketingEventTypesOrderRule"
            />
          </ListItem>
        )}
      </LocalizationProvider>

      <ButtonBar handleSubmit={handleSubmit} resetHandler={resetHandler} />
    </React.Fragment>
  )
}
export default FilterSideBar
