import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import FormattedPrice1 from '../../../shared/components/FormattedPrice1'
import Tooltip from '@mui/material/Tooltip'
import ErrorIcon from '@mui/icons-material/Error'
import { IBasketVal } from '../types/basketTypes'

const BasketComparisonPriceCell = ({ row }: { row: IBasketVal }) => {
  return (
    <Stack>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={row.price.isChanged ? 'green' : 'unset'}
      >
        <FormattedPrice1 price={row.priceAmountMatch} />

        {row.price.isChanged && (
          <Tooltip title="Значение изменилось">
            <ErrorIcon sx={{ color: 'green', fontSize: '12px' }} />
          </Tooltip>
        )}
      </Box>

      {row.price.isChanged && (
        <Box
          marginRight={row.price.isChanged ? '12px' : 0}
          color={'action.disabled'}
        >
          <FormattedPrice1 price={row.priceAmountInit} />
        </Box>
      )}
    </Stack>
  )
}

export default BasketComparisonPriceCell
