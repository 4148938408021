import React from 'react'

// third libs
import { useHistory } from 'react-router-dom'

// mui
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'

// project
import { sendProccesingAutoOrderDeleteRequest } from '../model/processingAutoOrder/processingAutoOrderActions'
import StatusInfo from '../../../../AutoOrder/UI/StatusInfo'
import { useActions } from '../../../../../shared/hooks/useActions'
import FormattedDate1 from '../../../../../shared/components/FormattedDate1'
import { IProcessingAutoOrderItem } from '../types/processingAutoOrderTypes'
import { getViewAutoOrderRouteByOrderId } from '../../../../../shared/services/routeService'

export function ProcessingAutoOrderRow({
  row,
}: {
  row: IProcessingAutoOrderItem
}) {
  const history = useHistory()

  const {
    fetchProcessingAutoOrder,
    resetProccesingAutoOrderItems,
    setResetProccesingAutoOrderItemsFlag,
  } = useActions()

  const handleDeleteOrderById = async (id: string[]) => {
    await setResetProccesingAutoOrderItemsFlag(true)
    await sendProccesingAutoOrderDeleteRequest(id)
    await resetProccesingAutoOrderItems()
    await fetchProcessingAutoOrder()
    await setResetProccesingAutoOrderItemsFlag(false)
  }

  const handleEdit = () => {
    history.push(getViewAutoOrderRouteByOrderId(row.initialSummaryBidId))
  }

  const mouseDownHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event.button === 1) {
      window.open(
        getViewAutoOrderRouteByOrderId(row.initialSummaryBidId),
        '_blank'
      )
    }
  }

  return (
    <TableRow
      sx={{
        backgroundColor: 'unset',
        '& td': {
          fontSize: '0.725rem',
        },
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell align="left">
        <FormattedDate1 date={row.date} />
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: 'unset',
          fontWeight: '600',
        }}
      >
        <span>{row.code}</span>
      </TableCell>
      <TableCell align="left" sx={{ minWidth: '160px' }}>
        {row.departmentName}
      </TableCell>
      <TableCell align="left">
        <StatusInfo status={row.status} />
      </TableCell>
      <TableCell align="left" sx={{ minWidth: '160px' }}>
        {row.description}
      </TableCell>
      <TableCell>
        <Stack direction="row">
          <Tooltip title={'Просмотреть заявку'}>
            <span>
              <IconButton
                size="small"
                onClick={handleEdit}
                onMouseDown={mouseDownHandler}
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={'Удалить заявку'}>
            <span>
              <IconButton
                onClick={() => handleDeleteOrderById([row.initialSummaryBidId])}
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  )
}
