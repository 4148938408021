import { useEffect } from 'react'

// mui
import Box from '@mui/material/Box'
import { alpha, TextField, useTheme } from '@mui/material'

// project
import PriceListControlStack from '../PriceListControlStack'
import CustomDatePicker from '../../../../../../shared/components/datePickers/CustomDatePicker'

// hooks
import { useActions } from '../../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../../shared/hooks/useTypedSelector'

// libs
import { subDays, addDays, differenceInDays } from 'date-fns'

// utils
import { getNextWeekDay } from '../../utils/priceList'

// types
import { DeliveryMode } from '../../types/priceListTypes'

const PriceListDeliveryScheduleCalendar = () => {
  const theme = useTheme()

  const {
    savePriceListDeliveryAction,
    updateCountDayInModelAction,
    setEndDatePLDeliveryAction,
    setDayDiffPLDeliveryInputAction,
    initCalendarAction,
    resetPriceListDeliveryCalendarDefaultStateAction,
    resetPriceListDeliveryCalendarDepStateAction,
  } = useActions()

  const mode = useTypedSelector((state) => state.priceList.deliveryMode)
  const checkedItems = useTypedSelector((state) => state.priceList.checkedItems)
  const products = useTypedSelector((state) => state.priceList.products)
  const defaultDays = useTypedSelector((state) => state.priceList.defaultDays)

  const currentTabDay = useTypedSelector(
    (state) => state.priceList.currentTabDay
  )
  const startDate = getNextWeekDay(currentTabDay).setHours(0, 0, 0, 0)
  const endDate = useTypedSelector((state) => state.priceList.endDate)
  const daysDiffInput = useTypedSelector(
    (state) => state.priceList.daysDiffInput
  )

  const excludeIntervals = [
    {
      start: new Date(2000, 0, 1, 0, 0, 0, 0),
      end: subDays(getNextWeekDay(currentTabDay), 1),
    },
  ]

  const onChangeDatePicker = (dates: Date[]) => {
    const [start, end] = dates
    setEndDatePLDeliveryAction(end)
    if (!end) return
    const days = differenceInDays(end, start)
    setDayDiffPLDeliveryInputAction(days)
    updateCountDayInModelAction(days)
  }

  const onChangeInputDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(1, Math.min(100, Number(event.target.value)))
    const endDate = addDays(startDate, value)
    setEndDatePLDeliveryAction(endDate)
    const days = differenceInDays(endDate, startDate)
    setDayDiffPLDeliveryInputAction(days)
    updateCountDayInModelAction(days)
  }

  const resetCalendar = () => {
    mode === DeliveryMode.default
      ? resetPriceListDeliveryCalendarDefaultStateAction()
      : resetPriceListDeliveryCalendarDepStateAction()
  }

  useEffect(() => {
    initCalendarAction(startDate)
  }, [checkedItems, products, defaultDays])

  return (
    <Box sx={{ position: 'relative' }}>
      <CustomDatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={onChangeDatePicker}
        excludeDateIntervals={excludeIntervals}
      />
      <PriceListControlStack
        saveFunc={savePriceListDeliveryAction}
        resetFunc={resetCalendar}
        justifyContent={'space-between'}
      />
      <TextField
        value={daysDiffInput}
        onChange={onChangeInputDate}
        type="number"
        sx={{
          width: '45px',
          position: 'absolute',
          bottom: '3.7rem',
          right: '1rem',

          '& .MuiOutlinedInput-root': {
            '& > fieldset': {
              borderColor: alpha(theme.palette.primary.main, 0.2),
              borderRadius: '8px',
            },
          },
        }}
        inputProps={{
          style: {
            padding: 5,
            fontSize: '12px',
          },
        }}
      />
    </Box>
  )
}

export default PriceListDeliveryScheduleCalendar
