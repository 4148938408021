import React from 'react'
import Badge from '@mui/material/Badge'
import DoneIcon from '@mui/icons-material/Done'
import { Tooltip } from '@mui/material'

const BadgeCheck = () => {
  return (
    <>
      <Tooltip title="одобрен">
        <Badge
          sx={{
            '& .MuiBadge-badge.MuiBadge-standard': {
              zIndex: 0,
              width: '14px',
              minWidth: '14px',
              height: '14px',
            },
          }}
          color="success"
          badgeContent={<DoneIcon sx={{ fontSize: '12px' }} />}
        />
      </Tooltip>
    </>
  )
}

export default BadgeCheck
