import {
  SubAutoOrderFieldType,
  ISubAutoOrder,
  ISubAutoOrderItem
} from '../../types/subAutoOrderTypes'
import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'

import { Order } from '../../../../shared/services/commonService'

export interface SubAutoOrderState {
  enough: boolean
  direction: LoadDirections.DOWN | LoadDirections.UP
  error: ErrorInfo | {}
  loading: boolean
  page: number
  limit: number
  total: number
  id: string | null
  orderBy: SubAutoOrderFieldType
  order: Order,
  listOrientation: SubAutoOrderOrientation,
  items: ISubAutoOrderItem[],
  ratingBy: 'asc' | 'desc'
}

export enum SubAutoOrderOrientation {
  productNameAsc = 'productName',
  productNameDesc = '-productName',
  quantityToOrderAsc = 'quantityToOrder',
  quantityToOrderDesc = '-quantityToOrder',
  remainingQuantityAsc = 'remainingQuantity',
  remainingQuantityDesc = '-remainingQuantity',
  quantitySoldAsc = 'quantitySold',
  quantitySoldDesc = '-quantitySold',
  orderedQuantityAsc = 'orderedQuantity',
  orderedQuantityDesc = '-orderedQuantity',
  remainingQuantityToOrderAsc = 'remainingQuantityToOrder',
  remainingQuantityToOrderDesc = '-remainingQuantityToOrder',
  isRefusalToOrderAsc = 'isRefusalToOrder',
  isRefusalToOrderDesc = '-isRefusalToOrder',
  supplierNameAsc = 'supplierName',
  supplierNameDesc = '-supplierName',
  proMarketOrderIdAsc = 'proMarketOrderId',
  proMarketOrderIdDesc = '-proMarketOrderId',
  proMarketOrderDateAsc = 'proMarketOrderDate',
  proMarketOrderDateDesc = '-proMarketOrderDate',
  productOrderCostAsc = 'productOrderCost',
  productOrderCostDesc = '-productOrderCost'
}

export enum SubAutoOrderActionTypes {
  RESET_ITEMS_PRODUCTS = 'RESET_SUB_AUTO_ORDER_ITEMS',
  LOADING_DISABLE = 'LOADING_SUB_AUTO_ORDER_DISABLE',

  FETCH_PRODUCTS_REQUEST = 'FETCH_SUB_AUTO_ORDER',
  FETCH_PRODUCTS_SUCCESS = 'FETCH_SUB_AUTO_ORDER_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_SUB_AUTO_ORDER_ERROR',
  FETCH_PRODUCTS_ENOUGH = 'FETCH_SUB_AUTO_ORDER_ENOUGH',

  SET_PRODUCT_PAGE = 'SET_SUB_AUTO_ORDER_PAGE',
  SET_PRODUCT_SORT = 'SET_SUB_AUTO_ORDER_SORT',

}

export interface FetchSubAutoOrderDisableAction {
  type: SubAutoOrderActionTypes.LOADING_DISABLE
}

interface FetchSubAutoOrderEnoughAction {
  type: SubAutoOrderActionTypes.FETCH_PRODUCTS_ENOUGH
  payload: boolean
}

interface FetchSubAutoOrderAction {
  type: SubAutoOrderActionTypes.FETCH_PRODUCTS_REQUEST
  payload: { loadFlag: boolean; loading: boolean }
}

export interface FetchSubAutoOrderSuccessAction {
  type: SubAutoOrderActionTypes.FETCH_PRODUCTS_SUCCESS
  payload: { content: ISubAutoOrder; loadFlag: boolean; loading: boolean }
}

interface ISubAutoOrderError extends ErrorInfo {
  loadFlag: boolean
}

export interface FetchSubAutoOrderErrorAction {
  type: SubAutoOrderActionTypes.FETCH_PRODUCTS_ERROR
  payload: ISubAutoOrderError
}

export interface SetSubAutoOrderPage {
  type: SubAutoOrderActionTypes.SET_PRODUCT_PAGE
  payload: number
}

export interface ISubAutoOrderPayload {
  orientation: SubAutoOrderOrientation
  order: Order
  orderBy?: SubAutoOrderFieldType
}
export interface SetSubAutoOrderSort {
  type: SubAutoOrderActionTypes.SET_PRODUCT_SORT
  payload: ISubAutoOrderPayload
}

interface ResetItemsSubAutoOrderAction {
  type: SubAutoOrderActionTypes.RESET_ITEMS_PRODUCTS
}


export type SubAutoOrderAction =
  | FetchSubAutoOrderEnoughAction
  | FetchSubAutoOrderDisableAction
  | FetchSubAutoOrderAction
  | FetchSubAutoOrderSuccessAction
  | FetchSubAutoOrderErrorAction
  | SetSubAutoOrderPage
  | SetSubAutoOrderSort
  | ResetItemsSubAutoOrderAction