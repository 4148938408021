import React from "react"
import { Typography } from "@mui/material"

const NoSuppliers = ({ isEmptyItems }: { isEmptyItems: boolean }) => {
  return (
    <>
      {isEmptyItems && <Typography textAlign="center">
        Нет результатов
      </Typography>
      }
    </>
  )
}

export default NoSuppliers