import { Dispatch } from 'redux'
import noResultImage from '../../../../../../shared/assets/images/search.png'
import {
  IPriceListDeliveryPayload,
  PriceListDeliveryAction,
  PriceListDeliveryActionTypes,
  PriceListDeliveryItems,
} from './priceListReduxTypes'
import {
  getPriceListDelivery,
  getPriceListDeliveryDefault,
  savePriceListDelivery,
} from '../../api/priceList'
import { AppStateType } from '../../../../../../shared/store'
import {
  ApplicationAction,
  SnackTypes,
} from '../../../../../../shared/store/application/applicationReduxTypes'
import { showSnack } from '../../../../../../shared/store/application/applicationAction'
import { DayToIndex, DeliveryMode } from '../../types/priceListTypes'
import { addDays } from 'date-fns'

export const fetchPriceListDeliveryAction = (
  page = 1,
  limit = 30,
  filter = {},
  loadFlag = true
) => {
  return async (dispatch: Dispatch<PriceListDeliveryAction>) => {
    try {
      dispatch({ type: PriceListDeliveryActionTypes.LOADING_ENABLE })
      const { data } = await getPriceListDelivery(page, limit, filter)
      const content = data.content
      const isEmpty = content.settings.length === 0 && page === 1

      if (isEmpty) {
        dispatch({
          type: PriceListDeliveryActionTypes.FETCH_PRODUCTS_ERROR,
          payload: { message: 'Поиск не дал результатов', img: noResultImage },
        })
        return
      }

      const isEnough = content.settings.length >= limit

      dispatch({
        type: PriceListDeliveryActionTypes.FETCH_PRODUCTS_ENOUGH,
        payload: isEnough,
      })
      dispatch({
        type: PriceListDeliveryActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: { content, loadFlag },
      })
    } catch (e) {
      dispatch({
        type: PriceListDeliveryActionTypes.FETCH_PRODUCTS_ERROR,
        payload: { message: 'Поиск завершился ошибкой', img: noResultImage },
      })
    } finally {
      dispatch({ type: PriceListDeliveryActionTypes.LOADING_DISABLE })
    }
  }
}

export function setPriceListDeliveryPageAction(
  page: number
): PriceListDeliveryAction {
  if (page === 0) page = 1
  return { type: PriceListDeliveryActionTypes.SET_PRODUCT_PAGE, payload: page }
}

export function setCurrentVerticalTabAction(
  tab: number
): PriceListDeliveryAction {
  return {
    type: PriceListDeliveryActionTypes.SET_CURRENT_DELIVERY_TAB_DAY,
    payload: tab,
  }
}

export function setPriceListDeliveryFilterAction(
  payload: Object
): PriceListDeliveryAction {
  return {
    type: PriceListDeliveryActionTypes.SET_FILTER_PRODUCTS,
    payload,
  }
}

export function setDayDiffPLDeliveryInputAction(
  payload: number
): PriceListDeliveryAction {
  return {
    type: PriceListDeliveryActionTypes.SET_DIFF_DAYS_PL_DELIVERY_INPUT,
    payload,
  }
}

export function setStartDatePLDeliveryAction(
  payload: any
): PriceListDeliveryAction {
  return {
    type: PriceListDeliveryActionTypes.SET_START_DATE_PL_DELIVERY,
    payload,
  }
}

export function setEndDatePLDeliveryAction(
  payload: any
): PriceListDeliveryAction {
  return {
    type: PriceListDeliveryActionTypes.SET_END_DATE_PL_DELIVERY,
    payload,
  }
}

export function resetPriceListDeliveryFilter(): PriceListDeliveryAction {
  return { type: PriceListDeliveryActionTypes.RESET_FILTER_PRODUCTS }
}

export function resetPriceListDeliveryTableStateAction(): PriceListDeliveryAction {
  return { type: PriceListDeliveryActionTypes.RESET_PL_DELIVERY_TABLE_STATE }
}

export function resetPriceListDeliveryCalendarDefaultStateAction(): PriceListDeliveryAction {
  return {
    type: PriceListDeliveryActionTypes.RESET_PL_DELIVERY_CALENDAR_DEFAULT_STATE,
  }
}

export function resetPriceListDeliveryCalendarDepStateAction(): PriceListDeliveryAction {
  return {
    type: PriceListDeliveryActionTypes.RESET_PL_DELIVERY_CALENDAR_DEP_STATE,
  }
}

export function setPriceListDeliveryModeAction(
  payload: number
): PriceListDeliveryAction {
  return { type: PriceListDeliveryActionTypes.SET_PL_DELIVERY_MODE, payload }
}

export function setPriceListDeliverySortAction(
  payload: IPriceListDeliveryPayload
): PriceListDeliveryAction {
  return { type: PriceListDeliveryActionTypes.SET_PRODUCT_SORT, payload }
}

export const initCalendarAction = (startDate: Date | number) => {
  return async (
    dispatch: Dispatch<PriceListDeliveryAction>,
    getState: () => AppStateType
  ) => {
    const state = getState()
    const deliveryMode = state.priceList.deliveryMode
    const products = state.priceList.products
    const checkedItems = state.priceList.checkedItems
    const currentTabDay = state.priceList.currentTabDay
    const defaultDays = state.priceList.defaultDays

    if (deliveryMode === DeliveryMode.default) {
      const countDay = defaultDays[DayToIndex[currentTabDay]]
      dispatch(setStartDatePLDeliveryAction(startDate))
      dispatch(setEndDatePLDeliveryAction(addDays(startDate, countDay)))
      dispatch(setDayDiffPLDeliveryInputAction(countDay))
      return
    }

    const sortedArray = products.filter((a) =>
      checkedItems.includes(a.departmentId)
    )

    if (!sortedArray.length) return
    const currentDay = DayToIndex[currentTabDay]

    const firstElementValue =
      sortedArray[0][currentDay as keyof PriceListDeliveryItems]

    const isSomeValues = sortedArray.every(
      (a) =>
        a[currentDay as keyof PriceListDeliveryItems] ===
        sortedArray[0][currentDay as keyof PriceListDeliveryItems]
    )

    if (isSomeValues) {
      const endDate = addDays(startDate, Number(firstElementValue))
      dispatch(setEndDatePLDeliveryAction(endDate))
      dispatch(setDayDiffPLDeliveryInputAction(Number(firstElementValue)))
      return
    }
    dispatch(setEndDatePLDeliveryAction(startDate))
    dispatch(setDayDiffPLDeliveryInputAction(1))
  }
}

export const updateCountDayInModelAction = (payload: number) => {
  return async (
    dispatch: Dispatch<PriceListDeliveryAction>,
    getState: () => AppStateType
  ) => {
    const state = getState()
    const deliveryMode = state.priceList.deliveryMode

    if (deliveryMode === DeliveryMode.default) {
      dispatch({
        type: PriceListDeliveryActionTypes.UPDATE_PL_DELIVERY_DEFAULT_DAYS,
        payload: payload,
      })
      return
    }
    dispatch({
      type: PriceListDeliveryActionTypes.UPDATE_DAYS_COUNT_MODEL,
      payload,
    })
  }
}

export const handlePriceListDeliveryCheckBox = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  return async (
    dispatch: Dispatch<PriceListDeliveryAction>,
    getState: () => AppStateType
  ) => {
    const departmentId = Number(event.target.id)
    const checked = event.target.checked

    dispatch({
      type: PriceListDeliveryActionTypes.ADD_CHECKED_ITEMS,
      payload: [departmentId],
    })

    const state = getState()
    const checkedItems = state.priceList.checkedItems

    if (!checked)
      dispatch({
        type: PriceListDeliveryActionTypes.SET_CHECKED_ITEMS,
        payload: checkedItems.filter((i) => i !== departmentId),
      })
  }
}

export const selectAllPriceListDeliveryAction = () => {
  return async (
    dispatch: Dispatch<PriceListDeliveryAction>,
    getState: () => AppStateType
  ) => {
    const state = getState()

    if (state.priceList.isCheckedAll) {
      dispatch({
        type: PriceListDeliveryActionTypes.SET_IS_CHECKED_ALL,
      })
      dispatch({
        type: PriceListDeliveryActionTypes.SET_CHECKED_ITEMS,
        payload: [],
      })
    } else {
      dispatch({
        type: PriceListDeliveryActionTypes.SET_IS_CHECKED_ALL,
      })

      const products = state.priceList.products

      dispatch({
        type: PriceListDeliveryActionTypes.SET_CHECKED_ITEMS,
        payload: products.map((li) => li.departmentId),
      })
    }
  }
}

export const savePriceListDeliveryAction = () => {
  return async (
    dispatch: Dispatch<PriceListDeliveryAction | ApplicationAction>,
    getState: () => AppStateType
  ) => {
    try {
      const state = getState()
      const products = state.priceList.products
      const checkedItems = state.priceList.checkedItems
      const defaultDays = state.priceList.defaultDays
      const deliveryMode = state.priceList.deliveryMode

      if (deliveryMode === DeliveryMode.default) {
        const transformedArray = [
          {
            ...defaultDays,
          },
        ]
        await savePriceListDelivery(transformedArray)
        dispatch(
          showSnack({
            typeSnack: SnackTypes.SUCCESS,
            messageSnack: 'Данные сохранены',
          })
        )
        return
      }

      const sortedArray = products.filter((item) =>
        checkedItems.includes(item.departmentId)
      )

      if (!sortedArray.length) {
        dispatch(
          showSnack({
            typeSnack: SnackTypes.ERROR,
            messageSnack: 'Выберите подразделения',
          })
        )
        return
      }

      await savePriceListDelivery(sortedArray)
      dispatch(
        showSnack({
          typeSnack: SnackTypes.SUCCESS,
          messageSnack: 'Данные сохранены',
        })
      )
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Ошибка cохранения',
      //   })
      // )
    }
  }
}

export const fetchPLDeliveryDefaultSettingsAction = (supplierId: string) => {
  return async (
    dispatch: Dispatch<PriceListDeliveryAction | ApplicationAction>
  ) => {
    try {
      let { data } = await getPriceListDeliveryDefault(supplierId)
      let defaultDays = data.content.settings[0]

      dispatch({
        type: PriceListDeliveryActionTypes.SET_PL_DELIVERY_DEFAULT_DAYS,
        payload: defaultDays,
      })
    } catch (e) {
      dispatch(
        showSnack({
          typeSnack: SnackTypes.ERROR,
          messageSnack: 'Ошибка cохранения',
        })
      )
    }
  }
}
