import React, { useEffect } from 'react'

// mui
import { Box, Slide } from '@mui/material'

// project
import AutoOrderFinalBar from './components/AutoOrderFinalBar'
import DepartmentsPreOrderTable from './components/DepartmentsPreOrder/components/DepartmentsPreOrderTable'
import AutoOrderProductsTable from './components/AutoOrderProducts/components/AutoOrderProductsTable'
import { useActions } from '../../shared/hooks/useActions'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'

const AutoOrderFinalMain = () => {
  const { clearAutoOrders, clearDepartment, updatePreOrders } = useActions()

  const { showPreOrder, tempShow } = useTypedSelector(
    (state) => state.autoOrderFinalDepartments
  )

  useEffect(() => {
    return () => {
      clearDepartment()
      clearAutoOrders()
      updatePreOrders(true)
    }
  }, [])

  return (
    <Box
      mr={2}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <AutoOrderFinalBar />
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        flex="1"
        height="calc(100vh - 370px)"
      >
        <Slide
          easing={'ease-in-out'}
          in={showPreOrder || tempShow}
          direction="right"
          timeout={300}
        >
          <Box>
            {(showPreOrder || tempShow) && <DepartmentsPreOrderTable />}
          </Box>
        </Slide>
        <Box>
          <AutoOrderProductsTable />
        </Box>
      </Box>
    </Box>
  )
}

export default AutoOrderFinalMain
