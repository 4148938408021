//mui
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Checkbox from '@mui/material/Checkbox'
import { ISubAutoOrderProduct } from '../types/subAutoOrderTypes'

const SubAutoOrderProductRow = ({
  product,
}: {
  product: ISubAutoOrderProduct
}) => {
  return (
    <TableRow
      sx={{
        '& td': {
          fontSize: '0.8rem',
          padding: 0,
          maxWidth: 100,
          textAlign: 'center',
        },
        '&:last-child td': { border: 0 },
      }}
    >
      <TableCell style={{ padding: 0 }}>
        <Checkbox
          size="small"
          checked={product.isProductOrdered}
          sx={{ cursor: 'default' }}
        />
      </TableCell>
      <TableCell>{product.esCode}</TableCell>
      <TableCell>{product.productName}</TableCell>
      <TableCell>{product.supplierName}</TableCell>
      <TableCell>{product.rating}</TableCell>
      <TableCell>{product.productPrice}</TableCell>
    </TableRow>
  )
}

export default SubAutoOrderProductRow
