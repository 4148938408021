import { LoadDirections, ErrorInfo } from '../../../../../../shared/types/types'
import { PROCESSING_AUTO_ORDER } from '../../consts/processingAutoOrder'
import {
  ProcessingAutoOrderAction,
  ProcessingAutoOrderActionTypes,
  ProcessingAutoOrderOrientation,
  ProcessingAutoOrderState,
} from './processingAutoOrderReduxTypes'

const initialState: ProcessingAutoOrderState = {
  enough: false,
  direction: LoadDirections.DOWN,
  loading: false,
  error: {} as ErrorInfo,
  page: 1,
  limit: 30,
  total: 30,
  id: null,
  orderBy: 'date',
  order: 'asc',
  listOrientation: ProcessingAutoOrderOrientation.StartDate,
  items: [],
  isResetItemsEnd: false,
}

export const processingAutoOrderReducer = (
  state = initialState,
  action: ProcessingAutoOrderAction
): ProcessingAutoOrderState => {
  switch (action.type) {
    case ProcessingAutoOrderActionTypes.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
        enough: false,
        direction: action.payload.loadFlag
          ? LoadDirections.DOWN
          : LoadDirections.UP,
      }

    case ProcessingAutoOrderActionTypes.FETCH_PRODUCTS_SUCCESS:
      const { content, loading } = action.payload
      const {
        MIN_ITEMS_AFTER_LOADING,
        START_DOWN_INDEX,
        END_DOWN_INDEX,
        START_UP_INDEX,
        END_UP_INDEX,
      } = PROCESSING_AUTO_ORDER

      if (state.items.length > MIN_ITEMS_AFTER_LOADING) {
        if (state.direction === LoadDirections.DOWN) {
          const itemsCopy = state.items.slice(START_DOWN_INDEX, END_DOWN_INDEX)

          return {
            ...state,
            items: [...itemsCopy, ...content.items],
            total: content.pagination.total,
            loading,
          }
        } else if (state.direction === LoadDirections.UP) {
          const itemsCopy = state.items.slice(START_UP_INDEX, END_UP_INDEX)

          return {
            ...state,
            items: [...content.items, ...itemsCopy],
            total: content.pagination.total,
            loading,
          }
        }
      }

      return {
        ...state,
        items: [...state.items, ...content.items],
        total: content.pagination.total,
        loading,
      }

    case ProcessingAutoOrderActionTypes.FETCH_PRODUCTS_ERROR:
      return { ...state, loading: false, error: action.payload }

    case ProcessingAutoOrderActionTypes.SET_PRODUCT_SORT:
      return {
        ...state,
        items: [],
        enough: false,
        listOrientation: action.payload.orientation,
        order: action.payload.order,
      }

    case ProcessingAutoOrderActionTypes.FETCH_PRODUCTS_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }

    case ProcessingAutoOrderActionTypes.SET_PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case ProcessingAutoOrderActionTypes.RESET_ITEMS_PRODUCTS:
      return {
        ...state,
        items: [],
        page: 1,
      }

    case ProcessingAutoOrderActionTypes.SET_RESET_ITEMS_END_FLAG:
      return {
        ...state,
        isResetItemsEnd: action.payload,
      }

    default:
      return state
  }
}
