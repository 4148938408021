import React from 'react'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import basket from '../../../shared/assets/images/empty_basket1.png'

const NoItemsBasket = () => {
  return (
    <>
      <Container>
        <Box display="flex" justifyContent="center">
          <Stack alignItems={'center'}>
            <img src={basket} alt="empty_basket" width="100px" />
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontSize: '18px',
                fontWeight: 600,
                maxWidth: '350px',
                color: 'text.secondary',
                marginTop: '10px',
                textAlign: 'center',
              }}
            >
              Добавьте товар в корзину
            </Typography>
          </Stack>
        </Box>
      </Container>
    </>
  )
}

export default NoItemsBasket
