// mui
import { styled } from '@mui/material/styles'

export const ErrorImageText = styled('h2')({
  fontSize: '28px',
  color: '#2f8e89',
  marginTop: '34px',
  marginBottom: 0,
})

export const ErrorInfoText = styled('p')({
  fontSize: '14px',
  color: '#2f8e89',
  whiteSpace: 'pre-wrap',
  margin: 0,
})
