import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { IOptionsProps } from '../types/types'
import debounce from 'lodash/debounce'
import get from 'lodash/get'

export const useAutoCompleteController = (
  callback: (val?: any) => any,
  path: string,
  isCheckBoxType: boolean,
  customId = 'id',
  customName = 'name'
) => {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState<IOptionsProps[]>([])
  const loading = open && options.length === 0

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const debouncedRequest = useMemo(
    () => debounce((event, value) => updateOptions(value), 800),
    []
  )

  const updateOptions = async (value: string) => {
    if (value !== '' && value.length > 2) {
      const { data } = await callback(value)
      const content = get(data, path)
      setOptions(content)
      return
    }
  }

  useEffect(() => {
    let active = true
    if (!loading) return
    const fetchData = async () => {
      const { data } = await callback()
      const content = get(data, path)
      if (!active) return
      setOptions(content)
    }
    fetchData()

    return () => {
      active = false
    }
  }, [loading])

  useEffect(() => {
    if (!open) setOptions([])
  }, [open])


  return {
    options,
    debouncedRequest,
    loading,
    handleOpen,
    handleClose
  }
}
