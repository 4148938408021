import { nextMonday, addDays, setDay, getDay } from 'date-fns'

export const getNextWeekDay = (currentTabDay: number) => {
  if (currentTabDay === getDay(new Date()) - 1) {
    return new Date()
  }
  if (currentTabDay > getDay(new Date()) - 1) {
    return setDay(new Date(), currentTabDay + 1)
  }
  return addDays(nextMonday(new Date()), currentTabDay)
}
