import { useState } from 'react'
import { onlyNumbers } from '../../../../../shared/services/commonService'
import { IAutoOrderProduct } from '../types/autoOrderProductsTypes'

const QuantityToOrderField = ({
  defaultValue,
  inputRef,
  onChangeQuantity,
  row,
}: {
  defaultValue: number
  inputRef: React.RefObject<HTMLInputElement>
  row: IAutoOrderProduct
  onChangeQuantity: (val: number, val2: string) => void
}) => {
  const [value, setValue] = useState(defaultValue || 1)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    let converted =
      newValue.startsWith('0') && newValue.length >= 2
        ? newValue.slice(1)
        : !newValue
        ? '1'
        : newValue
    setValue(+converted)
    onChangeQuantity(+converted, row.autoOrderBidSimilarProductsGroupId)
  }

  return (
    <input
      ref={inputRef}
      defaultValue={defaultValue || 1}
      value={value}
      type="number"
      onChange={handleInputChange}
      onInput={onlyNumbers}
      style={{
        backgroundColor: 'inherit',
        color: 'inherit',
        lineHeight: '28px',
        fontSize: '14px',
        height: '28px',
        textAlign: 'center',
        width: '55px',
        border: 0,
        outline: 0,
        marginLeft: '12px',
      }}
    />
  )
}

export default QuantityToOrderField
