import React from 'react'

// mui
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'

const PriceListSettingsList = ({
  list,
  isEmpty,
  handleOnChange,
}: {
  list: any
  isEmpty: boolean
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <>
      <Grid container spacing={1} marginY={0.5}>
        {isEmpty && <Box margin={'30px auto'}>Нет результатов</Box>}
        {list.map((item: any) => (
          <Grid item xs={'auto'} lg={3} xl={4} key={item.typeId}>
            <FormControlLabel
              control={
                <Checkbox
                  id={String(item.typeId)}
                  onChange={handleOnChange}
                  checked={item.isActive}
                  disableRipple
                />
              }
              label={
                <Typography
                  sx={{
                    maxWidth: '160px',
                    overflowWrap: 'break-word',
                    fontSize: '14px',
                    fontWeight: '400',
                    color: 'primary.main',
                  }}
                >
                  {item.name}
                </Typography>
              }
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default PriceListSettingsList
