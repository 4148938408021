import {
  SubAutoOrderAction,
  SubAutoOrderActionTypes,
  SubAutoOrderOrientation,
  SubAutoOrderState,
} from './SubAutoOrderReduxTypes'

import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'
import { SUB_AUTO_ORDER } from '../../consts/subAutoOrder'
import { ISubAutoOrderItem } from '../../types/subAutoOrderTypes'

const initialState: SubAutoOrderState = {
  enough: false,
  direction: LoadDirections.DOWN,
  loading: false,
  error: {} as ErrorInfo,
  page: 1,
  limit: 30,
  total: 30,
  id: null,
  orderBy: 'productName',
  order: 'asc',
  listOrientation: SubAutoOrderOrientation.productNameAsc,
  items: [],
  ratingBy: 'asc'
}

export const subAutoOrderReducer = (
  state = initialState,
  action: SubAutoOrderAction
): SubAutoOrderState => {
  switch (action.type) {
    case SubAutoOrderActionTypes.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
        enough: false,
        direction: action.payload.loadFlag
          ? LoadDirections.DOWN
          : LoadDirections.UP,
      }

    case SubAutoOrderActionTypes.FETCH_PRODUCTS_SUCCESS:
      const { content, loading } = action.payload

      const {
        MIN_ITEMS_AFTER_LOADING,
        START_DOWN_INDEX,
        END_DOWN_INDEX,
        START_UP_INDEX,
        END_UP_INDEX,
      } = SUB_AUTO_ORDER

      if (state.items.length > MIN_ITEMS_AFTER_LOADING) {
        if (state.direction === LoadDirections.DOWN) {
          const itemsCopy = state.items.slice(START_DOWN_INDEX, END_DOWN_INDEX)

          return {
            ...state,
            items: [...itemsCopy, ...content.items],
            total: content.pagination.total,
            loading,
          }
        } else if (state.direction === LoadDirections.UP) {
          const itemsCopy = state.items.slice(START_UP_INDEX, END_UP_INDEX)

          return {
            ...state,
            items: [...content.items, ...itemsCopy],
            total: content.pagination.total,
            loading,
          }
        }
      }
  
      return {
        ...state,
        items: [...state.items, ...content.items],
        total: content.pagination.total,
        loading,
      }

    case SubAutoOrderActionTypes.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case SubAutoOrderActionTypes.FETCH_PRODUCTS_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }

    case SubAutoOrderActionTypes.SET_PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case SubAutoOrderActionTypes.SET_PRODUCT_SORT:
      return {
        ...state,
        items: [],
        enough: false,
        listOrientation: action.payload.orientation,
        order: action.payload.order,
      }
    default:
      return state
  }
}
