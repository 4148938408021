import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

const ButtonBar = ({
  handleSubmit,
  resetHandler,
}: {
  handleSubmit(): void
  resetHandler(): void
}) => {
  return (
    <Box
      sx={{
        mt: 2,
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: 'inherit',
      }}
      component={'li'}
    >
      <Box marginY={4} display="flex" columnGap="5px">
        <Button
          sx={{ borderRadius: '3px', width: '100%' }}
          onClick={handleSubmit}
          variant="contained"
        >
          Поиск
        </Button>

        <Button
          sx={{ borderRadius: '3px', width: '100%' }}
          onClick={resetHandler}
          variant="outlined"
        >
          Сбросить
        </Button>

        <Box sx={{ width: '38px', flexShrink: 0 }} />
      </Box>
    </Box>
  )
}

export default ButtonBar
