import React from 'react'
import { useActions } from '../../../hooks/useActions'
import { Backdrop, Box, Fade, Modal } from '@mui/material'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import ErrorDetailInfoContent from './ErrorDetailInfoContent'
import ModalCloseButton from '../../UI/ModalCloseButton'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  bgcolor: 'background.paper',
  boxShadow: '4px 6px 5px rgba(0, 0, 0, 0.1)',
  borderRadius: '10px',
  p: '1rem 2.5rem 2.5rem 2.5rem',
}
const ErrorDetailInfoModal = () => {
  const { active } = useTypedSelector((state) => state.modal)
  const { modalDisable } = useActions()

  const handleClose = () => {
    modalDisable()
  }

  return (
    <>
      <Modal
        open={active}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={active}>
          <Box sx={style}>
            <ErrorDetailInfoContent handleClose={handleClose} />
            <ModalCloseButton handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default ErrorDetailInfoModal
