import { onlyNotEmpty } from '../../../shared/services/commonService'
import http from '../../../shared/api/config/instance'
import { IProducts } from '../../Product/types/productTypes'

export const getSimilarItems = (
  supplierGoodId: number,
  priceListTypeId: number,
  pageNumber: number,
  pageSize: number,
  filters: any
) => {
  const newFilters = onlyNotEmpty(filters)
  return http.httpCore.get<{ content: IProducts }>('product/similar', {
    params: {
      supplierGoodId,
      priceListTypeId,
      pageSize,
      pageNumber,
      ...newFilters,
    },
  })
}
