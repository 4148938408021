import React from 'react'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import ErrorIcon from '@mui/icons-material/Error'
import Stack from '@mui/material/Stack'
import { IProductField } from '../types/basketTypes'

const BasketComparisonCell = ({ field }: { field: IProductField }) => {
  return (
    <Stack>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={field.isChanged ? 'green' : 'unset'}
      >
        {field.match}

        {field.isChanged && (
          <Tooltip title="Значение изменилось">
            <ErrorIcon sx={{ color: 'green', fontSize: '12px' }} />
          </Tooltip>
        )}
      </Box>
      {field.isChanged && (
        <Box
          marginRight={field.isChanged ? '12px' : 0}
          color={'action.disabled'}
        >
          {field.initial}
        </Box>
      )}
    </Stack>
  )
}

export default BasketComparisonCell
