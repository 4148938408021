import { IOptionsProps } from '../../../../../../shared/types/types'

export interface SaleCostBonusesState {
  loading: boolean
  bonuses: {
    initialOrderCost: number
    initialBonusCost: number
    lostProfitCost: number
    orderCost: number
    totalBonusCost: number
    totalBonusPlanPercentage: number
    bonusForImplementationCost: number
    bonusPlanForImplementationPercentage: number
    bonusForMarketingEventCost: number
    bonusPlanForMarketingEventPercentage: number
    extraBonusCost: number
    extraBonusPlanPercentage: number
    bonusInPriceCost: number
    bonusInPricePercentage: number
    minimalCompetitorPriceBonusCost: number
    minimalCompetitorPriceOrderCost: number
  }
  departments: IOptionsProps[]
}

export enum SaleCostBonusesActionTypes {
  FETCH_SALE_COST_BONUSES_SUCCESS = 'FETCH_SALE_COST_BONUSES_SUCCESS',
  RESET_SALE_COST_BONUSES = 'RESET_SALE_COST_BONUSES',
  SAVE_DEPARTMENTS_LIST = 'SAVE_DEPARTMENTS_LIST',
  LOADING_ENABLE = 'LOADING_SALE_COST_BONUSES_ENABLE',
  LOADING_DISABLE = 'LOADING_SALE_COST_BONUSES_DISABLE',
}

export interface ResetCostBonusesStateAction {
  type: SaleCostBonusesActionTypes.RESET_SALE_COST_BONUSES
}

export interface SaveCostBonusesDepartmentsListAction {
  type: SaleCostBonusesActionTypes.SAVE_DEPARTMENTS_LIST
  payload: IOptionsProps[]
}

export interface FetchCostBonusesSuccessAction {
  type: SaleCostBonusesActionTypes.FETCH_SALE_COST_BONUSES_SUCCESS
  payload: SaleCostBonusesState
}

export interface LoadingSaleCostBonusesEnableAction {
  type: SaleCostBonusesActionTypes.LOADING_ENABLE
}

export interface LoadingSaleCostBonusesDisableAction {
  type: SaleCostBonusesActionTypes.LOADING_DISABLE
}

export type SaleCostBonusesAction =
  | FetchCostBonusesSuccessAction
  | ResetCostBonusesStateAction
  | SaveCostBonusesDepartmentsListAction
  | LoadingSaleCostBonusesEnableAction
  | LoadingSaleCostBonusesDisableAction
