import { IProcessingAutoOrder } from './../../types/processingAutoOrderTypes';
//types
import { ErrorInfo, LoadDirections } from "../../../../../../shared/types/types";
import { IProcessingAutoOrderItem } from "../../types/processingAutoOrderTypes";
import { ProcessingAutoOrderFieldType } from '../../../ProcessingAutoOrder/types/processingAutoOrderTypes'

//project
import { Order } from '../../../../../../shared/services/commonService';

export interface ProcessingAutoOrderState {
  enough: boolean
  direction: LoadDirections.DOWN | LoadDirections.UP
  error: ErrorInfo | {}
  loading: boolean
  page: number
  limit: number
  total: number
  id: string | null
  orderBy: ProcessingAutoOrderFieldType
  order: Order,
  listOrientation: ProcessingAutoOrderOrientation,
  items: IProcessingAutoOrderItem[]
  isResetItemsEnd: boolean
}

export enum ProcessingAutoOrderOrientation {
  StartDate = 'Date',
  EndDate = '-Date',
  StatusAsc = 'Status',
  StatusDesc = '-Status',
  CodesAsc = 'Codes',
  CodesDesc = '-Codes',
  DepartmentAsc = 'DepartmentId',
  DepartmentDesc = '-DepartmentId',
  DepartmentNameAsc = 'DepartmentName',
  DepartmentNameDesc = '-DepartmentName',
  DescriptionsAsc = 'Descriptions',
  DescriptionsDesc = '-Descriptions'
}

export enum ProcessingAutoOrderActionTypes {
  RESET_ITEMS_PRODUCTS = 'RESET_ITEMS_PROCESSING_AUTO_ORDER',

  LOADING_ENABLE = 'LOADING_PROCESSING_AUTO_ORDER_ENABLE',
  LOADING_DISABLE = 'LOADING_PROCESSING_AUTO_ORDER_DISABLE',

  FETCH_PRODUCTS_REQUEST = 'FETCH_PROCESSING_AUTO_ORDER',
  FETCH_PRODUCTS_SUCCESS = 'FETCH_PROCESSING_AUTO_ORDER_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_PROCESSING_AUTO_ORDER_ERROR',
  FETCH_PRODUCTS_ENOUGH = 'FETCH_PROCESSING_AUTO_ORDER_ENOUGH',

  DELETE_PRODUCT_BY_ID = 'DELETE_PROCESSING_AUTO_ORDER_BY_ID',
  SET_PRODUCT_PAGE = 'SET_PROCESSING_AUTO_ORDER_PAGE',

  SET_PRODUCT_SORT = 'SET_PROCESSING_AUTO_ORDER_SORT',
  SET_AUTO_ORDER_DIRECTION = 'SET_PROCESSING_AUTO_ORDER_DIRECTION',

  SET_RESET_ITEMS_END_FLAG = 'SET_RESET_ITEMS_END_FLAG',
}

interface ResetItemsProcessingAutoOrderAction {
  type: ProcessingAutoOrderActionTypes.RESET_ITEMS_PRODUCTS
}

interface DeleteIdsProcessingAutoOrderAction {
  type: ProcessingAutoOrderActionTypes.DELETE_PRODUCT_BY_ID
}

interface FetchProcessingAutoOrderEnoughAction {
  type: ProcessingAutoOrderActionTypes.FETCH_PRODUCTS_ENOUGH
  payload: boolean
}

interface FetchProcessingAutoOrderAction {
  type: ProcessingAutoOrderActionTypes.FETCH_PRODUCTS_REQUEST
  payload: { loadFlag: boolean; loading: boolean }
}

export interface FetchProcessingAutoOrderSuccessAction {
  type: ProcessingAutoOrderActionTypes.FETCH_PRODUCTS_SUCCESS
  payload: { content: IProcessingAutoOrder; loadFlag: boolean; loading: boolean }
}

export interface FetchProcessingAutoOrderDisableAction {
  type: ProcessingAutoOrderActionTypes.LOADING_DISABLE
}

export interface FetchProcessingAutoOrderErrorAction {
  type: ProcessingAutoOrderActionTypes.FETCH_PRODUCTS_ERROR
  payload: ErrorInfo | {}
}

interface SetProcessingAutoOrderPage {
  type: ProcessingAutoOrderActionTypes.SET_PRODUCT_PAGE
  payload: number
}

export interface IProcessingAutoOrderPayload {
  orientation: ProcessingAutoOrderOrientation
  order: Order
  orderBy?: ProcessingAutoOrderFieldType
}
export interface SetProcessingAutoOrderSort {
  type: ProcessingAutoOrderActionTypes.SET_PRODUCT_SORT
  payload: IProcessingAutoOrderPayload
}

export interface LoadingProcessingAutoOrderEnableAction {
  type: ProcessingAutoOrderActionTypes.LOADING_ENABLE
}

export interface LoadingProcessingAutoOrderDisableAction {
  type: ProcessingAutoOrderActionTypes.LOADING_DISABLE
}

export interface SetProcessingAutoOrderResetFlagAction {
  type: ProcessingAutoOrderActionTypes.SET_RESET_ITEMS_END_FLAG
  payload: boolean
}

export type ProcessingAutoOrderAction =
  | FetchProcessingAutoOrderEnoughAction
  | FetchProcessingAutoOrderAction
  | FetchProcessingAutoOrderSuccessAction
  | FetchProcessingAutoOrderErrorAction
  | SetProcessingAutoOrderPage
  | DeleteIdsProcessingAutoOrderAction
  | SetProcessingAutoOrderSort
  | LoadingProcessingAutoOrderEnableAction
  | LoadingProcessingAutoOrderDisableAction
  | ResetItemsProcessingAutoOrderAction
  | SetProcessingAutoOrderResetFlagAction