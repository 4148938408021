import React, { useEffect, useMemo, useState } from 'react'

// libs
import { useHistory, useParams } from 'react-router-dom'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'

// const
import { HISTORY_STATUS, NEW_HISTORY } from '../NewHistory/consts/newHistory'
import { headCellsArray } from './consts/subHistory'

// actions
import { useActions } from '../../shared/hooks/useActions'

// types
import { IOrderItemsFiltered } from '../NewHistory/types/newHistoryTypes'
import {
  ModeList,
  SnackTypes,
} from '../../shared/store/application/applicationReduxTypes'
import { SubHistoryFieldType } from './types/subHistoryTypes'

// routes
import {
  getHomeRoute,
  getViewHistoryRouteByOrderId,
} from '../../shared/services/routeService'

//hooks
import { useFetching } from '../../shared/hooks/useFetching'

// mui imports
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableSortLabel from '@mui/material/TableSortLabel'

// project imports
import { SubHistoryRow } from './components/SubHistoryRow'
import ErrorTypography from '../../shared/components/ErrorTypography'
import FormattedPrice1 from '../../shared/components/FormattedPrice1'
import BackToHistoryPage from '../../shared/components/BackToHistoryPage'

// service
import { getComparator, Order } from '../../shared/services/commonService'

// api
import { getOrderHistory } from '../NewHistory/api/newHistory'
import { deleteItemOrders, putChangeItemOrders } from './api/subHistory'
import { useTheme } from '@mui/material'

interface IMetaProps {
  header: {
    id: string
    supplierName: string
    departmentName: string
  }
  priceListTypeIdFilter: string | number
  supplierIdFilter: string | number
  departmentId: string | number
  totalSum: number
  status: number
  description: string
}

const isOrderCompleted = (confirmedCount: number, orderCount: number) => {
  if (confirmedCount === 0) {
    return false
  } else if (confirmedCount < orderCount) {
    return null
  } else if (confirmedCount === orderCount) {
    return true
  }
  return null
}

const SubHistoryMain = () => {
  const history = useHistory()
  const theme = useTheme()
  const { HISTORY_LIMIT_PAGE } = NEW_HISTORY
  const {
    showSnack,
    editItemsModeEnable,
    initProductEditMode,
    setFilterCustom,
  } = useActions()
  const { idOrder }: { idOrder: string } = useParams()

  const [items, setItems] = useState<IOrderItemsFiltered[]>([])  
  const [meta, setMeta] = useState<IMetaProps>({
    header: {
      id: '',
      supplierName: '',
      departmentName: '',
    },
    priceListTypeIdFilter: '',
    supplierIdFilter: '',
    departmentId: '',
    totalSum: 0,
    status: 0,
    description: '',
  })

  const isCanEdit =
    history.location.pathname !== getViewHistoryRouteByOrderId(idOrder) &&
    meta.status < HISTORY_STATUS.UPLOADED

  const callback = async (pageSize: number, orderIdRangeFilter: string) => {
    const {
      data: {
        content: { data },
      },
    } = await getOrderHistory({
      pageSize,
      orderIdRangeFilter,
      onlyMyOrders: false,
    })

    let result: IOrderItemsFiltered[] = []
    data.forEach((it1) =>
      it1.orderItems.forEach((it2) => {
        const orderCount = it2.supplierAnswer?.supplierOrderQuantity
        const confirmedCount = it2.supplierAnswer?.supplierConfirmedQuantity
        result.push({
          completed: isOrderCompleted(confirmedCount, orderCount),
          id: it2.id,
          esCode: it2.product.esCode,
          producerName: it2.product.producerName,
          name: it2.product.name,
          expirationDate: it2.product.expirationDate,
          price: it2.product.price,
          amount: it2.amount,
          boxQuantity: it2.product.boxQuantity,
          minimalQuantity: it2.product.minimalQuantity,
          quantity: it2.product.quantity,
          orderCount,
          confirmedCount,
        })
      })
    )
    setItems(result)
    setMeta({
      header: {
        id: String(data[0].id),
        supplierName: data[0].supplierName,
        departmentName: data[0].departmentName,
      },
      priceListTypeIdFilter: data[0].priceListTypeId,
      supplierIdFilter: data[0].supplierId,
      departmentId: data[0].departmentId,
      totalSum: data[0].totalSum,
      status: data[0].statusId,
      description: data[0].description,
    })
  }
  
  const [fetchOrderItems, isLoading, error] = useFetching(callback)

  useEffect(() => {
    fetchOrderItems(1000000, idOrder)
  }, [])
  
  const debouncedRequest = useMemo(
    () =>
      debounce(
        (
          amount: number,
          orderItemId: number,
          orderId: number,
          meta: IMetaProps
        ) =>
          putChangeItemOrders(amount, orderItemId, orderId).then(() => {
            getOrderHistory({
              pageSize: HISTORY_LIMIT_PAGE,
              orderIdRangeFilter: idOrder,
            }).then((res) => {
              let totalSum = res.data.content.data[0].totalSum
              setMeta({ ...meta, totalSum: totalSum })
            })
          }),
        800
      ),
    []
  )

  const changeItemAmount = (amount: number, rowId: number) => {
    if (idOrder) debouncedRequest(amount, rowId, Number(idOrder), meta)
  }

  const deleteItem = (rowId: number) => {
    let tempArray = cloneDeep(items)
    const deleteIndex = tempArray.findIndex((item) => item.id === rowId)
    if (deleteIndex > -1) {
      deleteItemOrders(Number(idOrder), [rowId])
        .then(() => {
          tempArray.splice(deleteIndex, 1)
          setItems(tempArray)
        })
        .then(() =>
          showSnack({
            typeSnack: SnackTypes.WARNING,
            messageSnack: 'Позиция удалена',
          })
        )
    }
  }

  const addItem = () => {
    if (!idOrder) return
    editItemsModeEnable({ mode: ModeList.EDIT, data: idOrder })
    initProductEditMode(true)
    const { priceListTypeIdFilter, supplierIdFilter, departmentId } = meta
    setFilterCustom({
      priceListTypeIdFilter,
      supplierIdFilter,
      departmentId,
    })
    history.push(getHomeRoute())
  }

  ///////////////////////////////////////////////////////////////
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<SubHistoryFieldType>('esCode')

  const sortHandler = (field: SubHistoryFieldType) => {
    const isAsc = orderBy === field && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(field)
  }

  //////////////////////////////////////////////////////////
  
  if (error) {
    return <ErrorTypography error={error} />
  }

  return (
    <Box sx={{ mx: 2 }}>
      <Stack mb={1} direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <BackToHistoryPage marginDisabled />
          <Stack direction="row">
            <Typography
              fontSize={14}
              fontWeight={600}
              color={'primary.main'}
              sx={{ textTransform: 'uppercase' }}
            >
              {!isCanEdit ? 'Просмотр заказа № ' : 'Редактирование заказа № '}
            </Typography>
            <Typography fontSize={14}>{meta.header.id}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography
              fontSize={14}
              fontWeight={600}
              sx={{ textTransform: 'uppercase' }}
              color={'primary.main'}
            >
              Поставщик:
            </Typography>

            <Typography fontSize={14}>{meta.header.supplierName}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography
              fontSize={14}
              fontWeight={600}
              sx={{ textTransform: 'uppercase' }}
              color={'primary.main'}
            >
              Грузополучатель:
            </Typography>
            <Typography fontSize={14}>{meta.header.departmentName}</Typography>
          </Stack>
        </Stack>
        {isCanEdit && (
          <Button
            variant="contained"
            endIcon={<AddCircleOutlineIcon />}
            onClick={addItem}
          >
            Добавить товар
          </Button>
        )}
      </Stack>

      {meta.description?.length > 0 && (
        <Box
          sx={{
            mb: 2,
            padding: '1rem 1rem',
            color: 'primary.main',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '5px',
          }}
        >
          {meta.description}
        </Box>
      )}

      <TableContainer
        sx={{
          border: '1px solid #E1E3E6',
          boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
          borderRadius: '8px',
          pb: 2,
          overflowX: 'initial',
        }}
      >
        <Table
          sx={{ tableLayout: 'auto' }}
          size="small"
          aria-label="a alternative-product table"
        >
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  whiteSpace: 'nowrap',
                  fontSize: '0.75rem',
                  color: 'primary.main',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  borderBottom: 0,
                  borderRadius: '8px',
                  backgroundColor: 'background.paper',
                  position: 'sticky',
                  top: 65,
                  zIndex: 1,
                },
              }}
            >
              {headCellsArray.map((item) => (
                <TableCell
                  key={item.name}
                  align={item.align}
                  sx={{ padding: '0.5rem 0 0 0' }}
                >
                  <TableSortLabel
                    active={orderBy === item.field}
                    direction={orderBy === item.field ? order : 'asc'}
                    onClick={() => sortHandler(item.field)}
                  >
                    <Box sx={{ pl: item.enablePadding ? '18px' : 'unset' }}>
                      {item.name}
                    </Box>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .slice()
              .sort(getComparator(order, orderBy))
              .map((row) => (
                <React.Fragment key={row.id}>
                  <SubHistoryRow
                    row={row}
                    isCanEdit={isCanEdit}
                    changeItemAmount={changeItemAmount}
                    deleteItem={deleteItem}
                  />
                </React.Fragment>
              ))}
            <TableRow>
              <TableCell colSpan={7} />
              <TableCell align="center">
                <Typography
                  fontWeight={600}
                  fontSize={12}
                  color={'primary.main'}
                  sx={{ textTransform: 'uppercase' }}
                >
                  Общая сумма:
                </Typography>
              </TableCell>
              <TableCell align="right">
                <FormattedPrice1 price={meta.totalSum} />
              </TableCell>
              <TableCell colSpan={3} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default SubHistoryMain
