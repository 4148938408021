import React from 'react'

// mui
import { Box, Button, Stack, Typography, Switch } from '@mui/material'
import { styled } from '@mui/material/styles'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

// project
import FormattedPrice1 from '../../../shared/components/FormattedPrice1'
import { TypographyStyled } from '../../Sale/styled/saleStyled'
import { useActions } from '../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { BackPageTypography } from '../../../shared/components/styled/BackPageTypography'

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#139409' : '#09cb13',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

const AutoOrderFinalBar = () => {
  const {
    showAutoOrderFieldsModal,
    saveAutoOrderProducts,
    showAutOrderDeleteModal,
    sendAutoOrderProducts,
    showPreOrder,
    tempShow,
  } = useActions()

  const totalSum = useTypedSelector((state) => state.autoOrderProducts.totalSum)
  const claims = useTypedSelector((state) => state.auth.claims)

  const showPreOrderCheck = useTypedSelector(
    (state) => state.autoOrderFinalDepartments.showPreOrder
  )

  const selectPreOrder = useTypedSelector(
    (state) => state.autoOrderFinalDepartments.selectPreOrder
  )

  const handleChangeChecked = () => {
    showPreOrder(!showPreOrderCheck)
    tempShow(false)
  }

  const handleShowTempTable = () => {
    if (showPreOrderCheck) return
    tempShow(true)
  }

  return (
    <Box sx={{ backgroundColor: 'background.paper', mb: 1 }}>
      <Box display={'flex'} alignItems={'center'} columnGap={'1.34rem'}>
        <Box
          display="flex"
          alignItems="center"
          columnGap={'1.34rem'}
          mt={1}
          sx={{
            cursor: 'pointer',
          }}
          onClick={handleShowTempTable}
        >
          <Box
            sx={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}
            marginBottom={1}
          >
            <BackPageTypography>
              {selectPreOrder?.selectPreOrder.supplierName}
            </BackPageTypography>
          </Box>
          <Box
            sx={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}
            marginBottom={1}
          >
            <BackPageTypography>
              {selectPreOrder?.selectPreOrder.departmentName}
            </BackPageTypography>
          </Box>
          <Box
            sx={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}
            marginBottom={1}
          >
            <BackPageTypography>
              {selectPreOrder?.selectPreOrder.priceListTypeName}
            </BackPageTypography>
          </Box>
          <Box
            sx={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}
            marginBottom={1}
          >
            <BackPageTypography>
              {selectPreOrder?.selectPreOrder.orderCost}
            </BackPageTypography>
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}
          marginBottom={1}
          mt={1}
        >
          <AntSwitch
            checked={showPreOrderCheck}
            onChange={handleChangeChecked}
          />
          <TypographyStyled>Вкл доп.инфо</TypographyStyled>
        </Box>
      </Box>
      <Stack
        mt={4}
        direction="row"
        justifyContent="space-between"
        columnGap={1}
        ml={2}
      >
        <Box>
          <TypographyStyled>общая сумма:</TypographyStyled>
          <Typography fontSize={'1.1rem'}>
            <FormattedPrice1 price={totalSum} bold={false} />
          </Typography>
        </Box>

        <Stack
          direction="row"
          justifyContent="start"
          columnGap={2}
          marginBottom={1}
        >
          <Button
            disabled={
              !(
                claims['promarket.order.create'] &&
                claims['promarket.auto_order.save_to_orders']
              )
            }
            variant="contained"
            sx={{ flexShrink: 0, fontSize: '0.775rem', maxHeight: 40 }}
            onClick={saveAutoOrderProducts}
          >
            Сохранить
          </Button>
          <Button
            disabled={
              !(
                claims['promarket.order.create'] &&
                claims['promarket.order.send_supplier'] &&
                claims['promarket.auto_order.save_to_orders']
              )
            }
            variant="contained"
            sx={{ flexShrink: 0, fontSize: '0.775rem', minHeight: 40 }}
            onClick={sendAutoOrderProducts}
          >
            Отправить
          </Button>
          <Button
            variant="outlined"
            color="error"
            sx={{ flexShrink: 0, fontSize: '0.775rem', minHeight: 40 }}
            onClick={showAutOrderDeleteModal}
          >
            Отменить
          </Button>
          <Button
            endIcon={<SettingsOutlinedIcon />}
            variant="outlined"
            sx={{
              flexShrink: 0,
              fontSize: '0.775rem',
              maxHeight: 40,
            }}
            onClick={showAutoOrderFieldsModal}
          >
            Настройки
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default AutoOrderFinalBar
