import React, { useEffect } from 'react'

// mui
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

// imports
import PriceListDeliveryScheduleTableContainer from './PriceListDeliveryScheduleTableContainer'
import PriceDeliveryCustomTabs from '../PriceDeliveryCustomTabs'
import CustomVerticalTabs from '../../../../../../shared/components/styled/CustomVerticalTabs'
import PriceListDeliveryScheduleCalendar from './PriceListDeliveryScheduleCalendar'

// styled
import { PaperStyle } from '../../PriceList'

// hooks
import { useActions } from '../../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../../shared/hooks/useTypedSelector'

// types
import { DeliveryMode } from '../../types/priceListTypes'
import { useParams } from 'react-router-dom'

// const
const labelsForTabs = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

const PriceListDeliverySchedule = () => {
  const {
    setCurrentVerticalTabAction,
    setPriceListDeliveryModeAction,
    fetchPLDeliveryDefaultSettingsAction,
  } = useActions()

  const { supplierId }: { supplierId: string } = useParams()

  const currentTabDay = useTypedSelector(
    (state) => state.priceList.currentTabDay
  )
  const deliveryMode = useTypedSelector((state) => state.priceList.deliveryMode)
  const isReady = useTypedSelector((state) => state.priceList.defaultDays)

  const changeMode = (event: React.SyntheticEvent, newValue: number) => {
    setPriceListDeliveryModeAction(newValue)
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentVerticalTabAction(newValue)
  }

  useEffect(() => {
    if (deliveryMode === DeliveryMode.default)
      fetchPLDeliveryDefaultSettingsAction(supplierId)
  }, [deliveryMode])

  useEffect(() => {
    fetchPLDeliveryDefaultSettingsAction(supplierId)
    setPriceListDeliveryModeAction(0)
  }, [supplierId])

  return (
    <>
      <Box
        component="div"
        sx={{
          ...PaperStyle,
          my: 2,
        }}
      >
        <Typography
          fontWeight={600}
          fontSize={12}
          color="primary.main"
          sx={{ textTransform: 'uppercase' }}
        >
          Расписание доставки
        </Typography>
        <Grid container spacing={1} marginY={1}>
          <Grid item xs={5}>
            {Object.keys(isReady).length > 0 && (
              <>
                <PriceDeliveryCustomTabs
                  value={deliveryMode}
                  changeMode={changeMode}
                />
                <CustomVerticalTabs
                  labels={labelsForTabs}
                  children={<PriceListDeliveryScheduleCalendar />}
                  handleChange={handleChangeTab}
                  tabValue={currentTabDay}
                />
              </>
            )}
          </Grid>
          <Grid item xs={7}>
            {deliveryMode === DeliveryMode.withDepartments && (
              <PriceListDeliveryScheduleTableContainer />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default PriceListDeliverySchedule
