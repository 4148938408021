import { useEffect, useRef } from "react"
import { useTypedSelector } from "../hooks/useTypedSelector"
import { useActions } from "../hooks/useActions"

export const useScrollController = () => {
  const { saveScrollPosition } = useActions()
  const { scrollPosition } = useTypedSelector(state => state.app)
  const refContainer = useRef(0)

  const handleScroll = () => {
    refContainer.current = window.pageYOffset //запомним позицию скролла
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (scrollPosition > 0) window.scrollTo(0, Number(scrollPosition))
    return () => {
      saveScrollPosition(refContainer.current)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}