import React from 'react'

// mui
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'

// libs
import { Link as RouterLink, useLocation } from 'react-router-dom'

// types
import { IOptionsProps } from '../../../shared/types/types'
import { SnackTypes } from '../../../shared/store/application/applicationReduxTypes'

// libs
import cloneDeep from 'lodash/cloneDeep'

// api
import { postUserSupplierPList } from '../api/supplier'

// hooks
import { useActions } from '../../../shared/hooks/useActions'

interface ISupplierListProps {
  path: string
  items: IOptionsProps[]
  changeItems: (val: IOptionsProps[]) => void
}

const SupplierList = ({ path, items, changeItems }: ISupplierListProps) => {
  const location = useLocation()
  const { showSnack } = useActions()

  const handleOnChangeList = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id1 = Number(event.target.id)
    const checked = event.target.checked
    let tempArr = cloneDeep(items)
    let changeItem = tempArr.find((item) => item.id === id1)
    if (changeItem) {
      changeItem.isActive = checked
    }
    postUserSupplierPList(Number(id1), checked)
      .then(() => changeItems(tempArr))
      .catch(
        () => ({})
        // showSnack({
        //   typeSnack: SnackTypes.ERROR,
        //   messageSnack: 'Поставщик не сохранен',
        // })
      )
  }

  return (
    <>
      {items.map((item) => {
        let to = `${path}/${item.id}`
        return (
          <ListItem
            key={item.id}
            disablePadding
            selected={to === location.pathname}
            sx={{
              '&:hover': {
                backgroundColor: 'action.selected',
              },
            }}
          >
            <ListItemIcon
              sx={{
                ml: 1,
                minWidth: '31px',
              }}
            >
              <Checkbox
                edge="start"
                id={String(item.id)}
                onChange={handleOnChangeList}
                checked={item.isActive}
                disableRipple
              />
            </ListItemIcon>
            <Box
              component={RouterLink}
              to={to}
              sx={{
                width: '100%',
                pr: 1,
                paddingY: '4px',
                fontWeight: 600,
                fontSize: 12,
                color: 'primary.main',
                textDecoration: 'unset',
                wordBreak: 'break-word',
              }}
            >
              {item.name}
            </Box>
          </ListItem>
        )
      })}
    </>
  )
}

export default SupplierList
