import React, { useEffect, useMemo, useState } from 'react'
import { useActions } from '../../../shared/hooks/useActions'
import { BasketArray } from '../types/basketTypes'
import {
  getPageCount,
  transformOrders,
} from '../../../shared/services/basketService'
import debounce from 'lodash/debounce'
import {
  deleteBasketArray,
  getBasket,
  postOrder,
  putBasketItem,
} from '../api/basket'
import { postApproveOrders } from '../../../shared/api/order'
import groupBy from 'lodash/groupBy'
import { SnackTypes } from '../../../shared/store/application/applicationReduxTypes'
import cloneDeep from 'lodash/cloneDeep'
import { BASKET_PAGINATION } from '../consts/basket'
import { getBasketPrimaryKey } from '../../../shared/services/commonService'

function transformBeforeSend(arr: BasketArray) {
  const tempArray = cloneDeep(arr)
  let params: number[] = []
  tempArray.forEach(({ items }) => {
    params.push(
      ...items.filter((i) => i.matchProductCount).map((i) => i.basketItemId)
    )
  })
  return params
}

export const useBasketController = () => {
  const [tabValue, setTabValue] = useState(0)
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }
  const [checked, setChecked] = useState(false)

  const [page, setPage] = React.useState(1)
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const [totalPages, setTotalPages] = useState(1)

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const {
    showSnack,
    setLoadingDisable,
    setLoadingEnable,
    saveSuppliersIds,
    storeBasketRecordsInCloud,
    fetchBasketRecords,
    deleteBasketRecord,
    deleteBasketRecords,
    deleteAllBasketRecords,
  } = useActions()

  const [orders, setOrders] = useState([] as BasketArray)
  const [supSetting, setSupSettings] = useState<any>([]) // todo ts

  useEffect(() => {
    fetchBasketRecords()
  }, [orders])

  const debouncedRequest = useMemo(
    () =>
      debounce(
        (
          primaryKey: string | number,
          basketItemId: number,
          basketAmount: number,
          tempItemsArr: BasketArray
        ) =>
          putBasketItem(basketItemId, basketAmount).then(() => {
            setOrders(tempItemsArr)
            storeBasketRecordsInCloud({
              [primaryKey]: {
                basketId: basketItemId,
                amount: basketAmount,
              },
            })
          }),
        300
      ),
    []
  )

  const saveOrderSubmit = () => {
    const params = transformBeforeSend(orders)
    postOrder(params)
      .then(() =>
        showSnack({
          typeSnack: SnackTypes.SUCCESS,
          messageSnack: 'Заказы оформлены',
        })
      )
      .then(() => {
        setOrders([])
        deleteAllBasketRecords()
      })
      .catch((e) =>({})
        // showSnack({
        //   typeSnack: SnackTypes.ERROR,
        //   messageSnack: 'Ошибка выгрузки',
        // })
      )
  }

  const sendToCustomer = () => {
    const params = transformBeforeSend(orders)
    postOrder(params)
      .then((res) => {
        const orderId = res.data.content.orders[0].id
        postApproveOrders([orderId]).then(() =>
          showSnack({
            typeSnack: SnackTypes.SUCCESS,
            messageSnack: 'Выгружено поставщику',
          })
        )
      })
      .then(() => {
        setOrders([])
        deleteAllBasketRecords()
      })
      .catch(() =>({})
        // showSnack({
        //   typeSnack: SnackTypes.ERROR,
        //   messageSnack: 'Ошибка выгрузки',
        // })
      )
  }

  const partHandleSubmit = (parentKey: number) => {
    const tempArray = cloneDeep(orders)
    const params = tempArray[parentKey].items
      .filter((i) => i.matchProductCount)
      .map((item) => item.basketItemId)

    const deletedRecords = tempArray[parentKey].items.map((item) => {
      const primaryKey = getBasketPrimaryKey(item)
      return String(primaryKey)
    })
    deleteBasketRecords(deletedRecords)

    tempArray.splice(parentKey, 1)
    postOrder(params)
      .then(() =>
        showSnack({
          typeSnack: SnackTypes.SUCCESS,
          messageSnack: 'Заказ оформлен',
        })
      )
      .then(() => {
        setOrders(tempArray)
      })
      .catch(() =>({})
        // showSnack({
        //   typeSnack: SnackTypes.ERROR,
        //   messageSnack: 'Ошибка выгрузки',
        // })
      )
  }

  const partHandleDelete = (parentKey: number) => {
    const tempArray = cloneDeep(orders)
    const params = tempArray[parentKey].items.map((item) => item.basketItemId)
    const deletedRecords = tempArray[parentKey].items.map((item) => {
      const primaryKey = getBasketPrimaryKey(item)
      return String(primaryKey)
    })
    deleteBasketRecords(deletedRecords)
    tempArray.splice(parentKey, 1)
    deleteBasketArray(params)
      .then(() => {
        setOrders(tempArray)
      })
      .then(() =>
        showSnack({
          typeSnack: SnackTypes.SUCCESS,
          messageSnack: 'Позиции удалены',
        })
      )
  }

  const deleteItem = (parentKey: number, childId: number) => {
    const tempArray = cloneDeep(orders)
    const items = [...tempArray[parentKey].items]
    const deleteItem = items.find((item) => item.basketItemId === childId)
    if (deleteItem) {
      deleteBasketArray([deleteItem.basketItemId])
        .then(() => {
          tempArray[parentKey].items = items.filter(
            (item) => item.basketItemId !== childId
          )
          if (tempArray[parentKey].items.length === 0) {
            tempArray.splice(parentKey, 1)
          }
          setOrders(tempArray)
          const primaryKey = getBasketPrimaryKey(deleteItem)
          deleteBasketRecord(String(primaryKey))
        })
        .then(() =>
          showSnack({
            typeSnack: SnackTypes.WARNING,
            messageSnack: 'Позиция удалена',
          })
        )
    }
  }

  const changeItemAmount = (
    value: string,
    parentKey: number,
    childId: number
  ) => {
    const tempArray = cloneDeep(orders)
    const items = [...tempArray[parentKey].items]
    const changeItem = items.find((item) => item.basketItemId === childId)
    if (changeItem) {
      changeItem.amount = Number(value)
      changeItem.priceAmountMatch =
        Number(value) * Number(changeItem.price.match)
      changeItem.priceAmountInit =
        Number(value) * Number(changeItem.price.initial)
      tempArray[parentKey].items = items
      const primaryKey = getBasketPrimaryKey(changeItem)
      const { basketItemId, amount } = changeItem
      debouncedRequest(primaryKey, basketItemId, amount, tempArray)
    }
  }

  useEffect(() => {
    const fetchBasket = async () => {
      try {
        setLoadingEnable()
        const {
          data: {
            content: { data, supplierSettings, total },
          },
        } = await getBasket(true, page)
        setTotalPages(getPageCount(total, BASKET_PAGINATION.MAX_PRODUCT_ITEMS))
        setLoadingDisable()

        if (!data) return
        if (data.length === 0) return
        const newArray = transformOrders(data)
        let groupedByField: any
        switch (tabValue) {
          case 0:
            groupedByField = checked
              ? groupBy(newArray, (item) => item.supplierAndPriceList)
              : groupBy(newArray, (item) => item.supplier)
            break
          case 1:
            groupedByField = checked
              ? groupBy(newArray, (item) => item.departmentAndPriceList)
              : groupBy(newArray, (item) => item.department)
            break
          case 2:
            groupedByField = checked
              ? groupBy(newArray, (item) => item.supplierAndDepartmentAndPL)
              : groupBy(newArray, (item) => item.supplierAndDepartment)
            break
          default:
            return null
        }
        const result = Object.keys(groupedByField).map((key) => {
          return { uniqKey: key, items: groupedByField[key] }
        })

        setOrders(result)

        if (!(supplierSettings && supplierSettings.length > 0)) {
          let tempSupplierIds = newArray.map((i) => i.supplierId)
          saveSuppliersIds(tempSupplierIds)
          return
        }
        setSupSettings(supplierSettings)
      } catch (e) {
        // showSnack({
        //   typeSnack: SnackTypes.ERROR,
        //   messageSnack: 'ошибка в парсинге запроса',
        // })
      }
    }
    fetchBasket()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, checked, page])

  return {
    basketProps: {
      deleteItem,
      partHandleSubmit,
      partHandleDelete,
      changeItemAmount,
      saveOrderSubmit,
    },
    basketColumnProps: {
      orders,
      saveOrderSubmit,
      sendToCustomer,
    },
    tabValue,
    handleChangeTab,
    handleChangeCheckBox,
    checked,
    orders,
    supSetting,
    handleChange,
    page,
    totalPages,
  }
}
