import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import BackToLastPage from '../../shared/components/BackToLastPage'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/material'
import { TextFieldCustom } from '../../shared/components/styled/TextFieldCustom'
import Button from '@mui/material/Button'

function createData(
  date: string,
  type: string,
  name: string,
  supplier: string,
  country: string,
  status: string,
  author: string
) {
  return {
    date,
    type,
    name,
    supplier,
    country,
    status,
    author,
  }
}

const rows = [
  createData(
    '03.03.2021',
    'Привязка',
    'Аквамарис спрей назальный',
    'Ядран Галенский',
    'Хорватия',
    'Принят',
    'Иванов'
  ),
  createData(
    '03.03.2021',
    'Привязка',
    'Аквамарис спрей назальный',
    'Ядран Галенский',
    'Хорватия',
    'Принят',
    'Иванов'
  ),
  createData(
    '03.03.2021',
    'Привязка',
    'Аквамарис спрей назальный',
    'Ядран Галенский',
    'Хорватия',
    'Принят',
    'Иванов'
  ),
  createData(
    '03.03.2021',
    'Привязка',
    'Аквамарис спрей назальный',
    'Ядран Галенский',
    'Хорватия',
    'Принят',
    'Иванов'
  ),
  createData(
    '03.03.2021',
    'Привязка',
    'Аквамарис спрей назальный',
    'Ядран Галенский',
    'Хорватия',
    'Принят',
    'Иванов'
  ),
  createData(
    '03.03.2021',
    'Привязка',
    'Аквамарис спрей назальный',
    'Ядран Галенский',
    'Хорватия',
    'Принят',
    'Иванов'
  ),
]

export default function BindsMain() {
  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: '65px',
          zIndex: 1,
          backgroundColor: 'background.paper',
          pb: 2,
        }}
      >
        <BackToLastPage text={'Запросы на привязку'} />

        <Stack
          direction="row"
          justifyContent="start"
          alignItems="stretch"
          spacing={3}
          marginBottom={3}
        >
          <TextFieldCustom
            sx={{ width: '230px' }}
            label="Товар"
            name="name"
            variant="outlined"
            size="small"
          />

          {/*<StatusAutoComplete handleAutoCompleteFields={() => console.log()} />*/}

          <TextFieldCustom
            sx={{ width: '230px' }}
            label="Тип"
            name="internationalName"
            variant="outlined"
            size="small"
          />
          <Button variant="contained" sx={{ padding: '6px 44px' }}>
            Поиск
          </Button>
          <Button variant="outlined" sx={{ padding: '6px 44px' }}>
            {' '}
            Сброс
          </Button>
        </Stack>
      </Box>

      <TableContainer
        sx={{
          border: '1px solid',
          borderColor: 'action.disabled',
          boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
          borderRadius: '8px',
          pb: 2,
        }}
      >
        <Table size="small" aria-label="a binds-product table">
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  whiteSpace: 'nowrap',
                  fontSize: '0.75rem',
                  color: 'primary.main',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  borderBottom: 0,
                },
              }}
            >
              <TableCell align="center">Дата отправки</TableCell>
              <TableCell align="center">Тип</TableCell>
              <TableCell align="center">Наименование</TableCell>
              <TableCell align="center">Производитель</TableCell>
              <TableCell align="center">Страна</TableCell>
              <TableCell align="center">Статус</TableCell>
              <TableCell align="center">Автор</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  '& td': { fontSize: '0.875rem' },
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="center">{row.type}</TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.supplier}</TableCell>
                <TableCell align="center">{row.country}</TableCell>
                <TableCell align="center">{row.status}</TableCell>
                <TableCell align="center">{row.author}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
