import React from 'react'
import Box from '@mui/material/Box'
import { IProduct } from '../../types/productTypes'
import { Stack } from '@mui/material'
import FormattedPrice1 from '../../../../shared/components/FormattedPrice1'
import Typography from '@mui/material/Typography'

const PriceField = ({ card }: { card: IProduct }) => {
  return (
    <Stack alignItems="center">
      <Typography
        sx={{
          overflowWrap: { xs: 'break-word', xl: 'unset' },
          textTransform: 'uppercase',
          color: 'primary.main',
          fontWeight: 'bold',
          fontSize: { xs: '9px', xl: '10px' },
          mb: '5px',
        }}
      >
        Цена
      </Typography>

      <Box
        sx={{
          height: '24px',
          paddingX: '5px',
          lineHeight: '24px',
          borderRadius: '15px',
          fontSize: { xs: '11px', xl: '12px' },
          color: 'common.white',
          textAlign: 'center',
          background: '#40AC58',
        }}
      >
        <FormattedPrice1 price={card.price} />
      </Box>
    </Stack>
  )
}

export default PriceField
