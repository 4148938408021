import { ShowSnackAction } from './../../../../../shared/store/application/applicationReduxTypes'
import { Dispatch } from 'react'
import {
  deleteAutoOrdersProductsReq,
  deletePosition,
  getAutOrderActiveKeys,
  getAutoOrderCost,
  getAutoOrderProducts,
  postOrderApprove,
  putOrderQuantity,
  saveAutoOrderProductRequest,
  setAutoOrderActiveFields,
} from '../api/autoOrderProducts'
import {
  AutoOrderFinalAction,
  AutoOrderFinalActionTypes,
  IChangeAmountResponse,
} from './autoOrderFInalReduxTypes'
import {
  IFilterProperty,
  IKeyValueType,
} from '../../DepartmentsPreOrder/types/departmentsPreOrderTypes'
import { AUTO_ORDER_FIElDS_KEY } from '../consts/autoOrderConsts'
import {
  ApplicationAction,
  ApplicationActionTypes,
  SnackTypes,
} from '../../../../../shared/store/application/applicationReduxTypes'
import {
  DepartmentPreOrdersActions,
  DepartmentsPreOrdersActionTypes,
} from '../../DepartmentsPreOrder/model/departmentsPreOrdersReduxTypes'
import { AppStateType } from '../../../../../shared/store'

export const fetchAutoOrderProducts = (
  filters: IFilterProperty[],
  pageNumber: number,
  orders: string = '',
  limit: number,
  loadFlag: boolean = true
) => {
  return async (
    dispatch: Dispatch<
      AutoOrderFinalAction | ApplicationAction | DepartmentPreOrdersActions
    >
  ) => {
    dispatch({
      type: AutoOrderFinalActionTypes.AUTO_ORDER_REQ,
      payload: {
        loading: true,
        loadFlag: true,
      },
    })
    try {
      dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })
      const response = await getAutoOrderProducts(
        pageNumber,
        limit,
        filters,
        orders
      )
      const content = response.data.content

      const isEnough = content.items.length >= limit
      dispatch({
        type: AutoOrderFinalActionTypes.AUTO_ORDER_PRODUCTS_ENOUGH,
        payload: isEnough,
      })
      dispatch({
        type: AutoOrderFinalActionTypes.SAVE_AUTO_ORDER_PRODUCTS,
        payload: {
          content,
          loadFlag: true,
          loading: false,
        },
      })
    } catch (e) {
      // alert('Ошибка получении автоказов')
    } finally {
      dispatch({
        type: AutoOrderFinalActionTypes.LOADING_AUTO_ORDER_PRODUCT_DSB,
        payload: false,
      })
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })

      dispatch({
        type: DepartmentsPreOrdersActionTypes.UPDATE_REQUEST,
        payload: false,
      })
    }
  }
}

export function saveAutoOrderAcitveFields(filters: { [key: string]: boolean }) {
  return async () => {
    try {
      await setAutoOrderActiveFields(filters)
    } catch(e) {
      // alert('Ошибка при сохранении полей')
    }
  }
}

export const fetchAutoOrderActiveFields = () => {
  return async (
    dispatch: Dispatch<AutoOrderFinalAction>,
    getState: () => AppStateType
  ) => {
    const deafultActiveFields = getState().autoOrderProducts.activeFields
    try {
      const response = await getAutOrderActiveKeys()

      const content: IKeyValueType[] = response.data.content.keyValueSettings

      const activeKey = content.find(
        (field) => field.key === AUTO_ORDER_FIElDS_KEY
      )?.value as string

      if (!content.length || !activeKey) {
        setAutoOrderActiveFields(deafultActiveFields)
        return
      }
      const activeFields = JSON.parse(activeKey)
      dispatch({
        type: AutoOrderFinalActionTypes.SET_AUTO_ORDER_ACTIVE_FIELS,
        payload: activeFields,
      })
    } catch(e) {
      // alert('Ошибка при получении полей')
    }
  }
}

export const fethcAutoOrderSum = () => {
  return async (dispatch: Dispatch<AutoOrderFinalAction>) => {
    try {
      const response = await getAutoOrderCost()
      const content = response.data.content.orderCost
      dispatch({
        type: AutoOrderFinalActionTypes.SAVE_AUTO_ORDER_SUM,
        payload: content,
      })
    } catch(e) {
      // alert('Ошибка при получении суммы заказа')
    }
  }
}

export const setNewQuantityOrder = (
  newQuantityToOrder: number,
  autoOrderBidSimilarProductsGroupId: string
) => {
  return async (
    dispatch: Dispatch<
      AutoOrderFinalAction | DepartmentPreOrdersActions | ApplicationAction
    >
  ) => {
    if (newQuantityToOrder <= 0) return
    try {
      const response = await putOrderQuantity(
        newQuantityToOrder,
        autoOrderBidSimilarProductsGroupId
      )
      const content: IChangeAmountResponse = response.data.content

      const newPreOrder = content.departmentPreOrder

      dispatch({
        type: AutoOrderFinalActionTypes.CHANGE_AMOUNT_RESPONSE,
        payload: content,
      })

      dispatch({
        type: DepartmentsPreOrdersActionTypes.SET_NEW_PRE_ORDER,
        payload: newPreOrder,
      })
    } catch {
      // alert('Ошибку при изменения колчиества заказа')
    }
  }
}

export function saveAutoOrderProducts() {
  return async (dispatch: Dispatch<ApplicationAction>) => {
    try {
      dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })
      await saveAutoOrderProductRequest().then(() => {
        dispatch({
          type: ApplicationActionTypes.SHOW_SNACK,
          payload: {
            typeSnack: SnackTypes.SUCCESS,
            messageSnack: 'Успешно сохранено',
          },
        })

        //todo : очистка setTimeout || лучше через then обработать
        setTimeout(() => {
          window.location.href = '/store/new-history'
        }, 1000)
      })
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    } 
    catch(e) {
      // dispatch({
      //   type: ApplicationActionTypes.SHOW_SNACK,
      //   payload: {
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Ошибка при сохранении заказов',
      //   },
      // })
    } finally {
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    }
  }
}
export const sendAutoOrderProducts = () => {
  return async (dispatch: Dispatch<ApplicationAction>) => {
    try {
      dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })
      const response = await saveAutoOrderProductRequest()
      const createdProMarketOrderIds: number[] =
        response.data.content.createdProMarketOrderIds
      await postOrderApprove(createdProMarketOrderIds).then(() => {
        dispatch({
          type: ApplicationActionTypes.SHOW_SNACK,
          payload: {
            typeSnack: SnackTypes.SUCCESS,
            messageSnack: 'Заказы успешно отправлены',
          },
        })
        setTimeout(() => {
          window.location.href = '/store/new-history'
        }, 1000)
      })
    } catch(e) {
      // dispatch({
      //   type: ApplicationActionTypes.SHOW_SNACK,
      //   payload: {
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Ошибка при отправке заказов',
      //   },
      // })
    } finally {
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    }
  }
}
export const deleteAutoOrderProducts = () => {
  return async (dispatch: Dispatch<ApplicationAction>) => {
    try {
      dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })
      await deleteAutoOrdersProductsReq().then(() => {
        dispatch({
          type: ApplicationActionTypes.SHOW_SNACK,
          payload: {
            typeSnack: SnackTypes.SUCCESS,
            messageSnack: 'Успешно отменено',
          },
        })
        setTimeout(() => {
          window.location.href = '/auto-order-settings'
        }, 1000)
      })
    } catch(e) {
      // alert('Ошибка при удалении заказов')
    } finally {
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    }
  }
}

export function clearAutoOrders() {
  return {
    type: AutoOrderFinalActionTypes.CLEAR_AUTO_ORDERS,
  }
}

export function setAutoOrdersFinalSort(payload: string) {
  return async (dispatch: Dispatch<AutoOrderFinalAction>) => {
    dispatch({
      type: AutoOrderFinalActionTypes.SET_AUTO_ORDER_FINAL_SORT,
      payload: payload,
    })
  }
}

export function setAutoOrderFields(filters: { [key: string]: boolean }) {
  return {
    type: AutoOrderFinalActionTypes.SET_AUTO_ORDER_ACTIVE_FIELS,
    payload: filters,
  }
}
export function setAutoOrdreFinalSetPage(payload: number) {
  if (payload === 0) payload = 1
  return {
    type: AutoOrderFinalActionTypes.SET_AUTO_ORDER_FINAL_PAGE,
    payload: payload,
  }
}

export function deletedPosition(id: string) {
  return {
    type: AutoOrderFinalActionTypes.SAVE_DELTED_POSITION,
    payload: id,
  }
}

export function deletAutoOrderPosition(id: string) {
  return async (
    dispatch: Dispatch<
      | AutoOrderFinalAction
      | DepartmentPreOrdersActions
      | ApplicationAction
      | ShowSnackAction
    >
  ) => {
    dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })
    try {
      const response = await deletePosition(id)
      const content = response.data.content
      const newPreOrder = content.departmentPreOrder
      const newTotalSum = content.autoOrderCost.orderCost

      dispatch({
        type: AutoOrderFinalActionTypes.DELETE_POSITION,
        payload: id,
      })

      dispatch({
        type: AutoOrderFinalActionTypes.SAVE_AUTO_ORDER_SUM,
        payload: newTotalSum,
      })

      dispatch({
        type: DepartmentsPreOrdersActionTypes.SET_NEW_PRE_ORDER,
        payload: newPreOrder,
      })
      dispatch({ type: ApplicationActionTypes.SHOW_SNACK, payload: {
        typeSnack: SnackTypes.SUCCESS,
        messageSnack: 'Позиция успешно удалена'
      } })
    } catch(e) {
      // dispatch({ type: ApplicationActionTypes.SHOW_SNACK, payload: {
      //   typeSnack: SnackTypes.ERROR,
      //   messageSnack: 'Ошибки при удалении позиции'
      // } })
    } finally {
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    }
  }
}
