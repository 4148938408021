import React from 'react'
import AppBar from '@mui/material/AppBar'
import HeaderToolbar from './components/HeaderToolbar/HeaderToolbar'

const HeaderCustom = ({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => void
}) => {
  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <HeaderToolbar handleDrawerToggle={handleDrawerToggle} />
      </AppBar>
    </>
  )
}

export default HeaderCustom
