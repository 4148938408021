import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"

// export const PaperCustom = styled(Paper)({
//   boxShadow: "0px 8px 16px rgba(171, 190, 209, 0.4)",
//   borderRadius: "10px",
//   marginBottom:"16px"
// })

export const PaperCustom = styled(Paper)(
  {
    boxShadow: "0px 8px 16px rgba(171, 190, 209, 0.4)",
    borderRadius: "10px",
    marginBottom: "16px"
  },
  ({ theme }) => ({
    "&.active": {
      boxShadow: "0px 0px 8px rgba(95, 108, 191, 0.8)", // изменяем box-shadow для эффекта подсветки
      border: "2px solid ${theme.palette.primary.main}", // добавляем покрывающую границу для четкости
    },
    // "&:focus": {
    //   outline: "none", // удаляем стандартную обводку при фокусе
    //   boxShadow: "0px 0px 8px rgba(95, 108, 191, 0.8)", // добавляем эффект подсветки при фокусе
    //   border: "2px solid ${theme.palette.primary.main}", // добавляем границу при фокусе для четкости
    // },
  })
);