import {
  SaleDetailsAction,
  SaleDetailsActionTypes,
  SaleDetailsState,
} from './saleDetailsReduxTypes'
import { SALE_DETAILS } from '../../consts/saleDetails'
import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'
import cloneDeep from 'lodash/cloneDeep'

const initialFilter = {
  departmentIdRange: [],
}

const initialState: SaleDetailsState = {
  enough: false,
  direction: LoadDirections.DOWN,
  loading: false,
  error: {} as ErrorInfo,
  page: 1,
  limit: 30,
  total: 30,
  filter: initialFilter,
  products: [],
  orderBy: 'productEsCode',
  order: 'asc',
  startSearch: false,
}

export const saleDetailsReducer = (
  state = initialState,
  action: SaleDetailsAction
): SaleDetailsState => {
  switch (action.type) {
    case SaleDetailsActionTypes.START_SALE_DETAILS_SEARCH:
      return {
        ...state,
        products: [],
        page: 1,
        enough: false,
        startSearch: !state.startSearch,
      }

    case SaleDetailsActionTypes.RESET_FILTER_PRODUCTS:
      return {
        ...state,
        filter: initialFilter,
        products: [],
        page: 1,
        enough: false,
        startSearch: !state.startSearch,
      }

    case SaleDetailsActionTypes.SET_FILTER_PRODUCTS:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload },
      }

    case SaleDetailsActionTypes.FETCH_PRODUCTS_SUCCESS:
      const { content, loadFlag } = action.payload

      const resultArray = content.departmentNeedsDetails

      const productsArray = loadFlag
        ? state.products.concat(resultArray)
        : resultArray.concat(state.products)

      // удаляем дубликаты
      const productFilteredArray = productsArray.filter(
        //@ts-ignore
        (v, i, a) => a.findIndex((t: any) => t.id === v.id) === i
      )
      const productSlicedArray = loadFlag
        ? productFilteredArray.slice(-SALE_DETAILS.MAX_PRODUCT_ITEMS)
        : productFilteredArray.slice(0, SALE_DETAILS.MAX_PRODUCT_ITEMS)

      return {
        ...state,
        products: productSlicedArray,
        error: {},
        direction: loadFlag ? LoadDirections.DOWN : LoadDirections.UP,
      }

    case SaleDetailsActionTypes.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case SaleDetailsActionTypes.SET_PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case SaleDetailsActionTypes.FETCH_PRODUCTS_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }

    case SaleDetailsActionTypes.SET_PRODUCT_SORT:
      const order = action.payload.order
      const orderBy = action.payload.orderBy
      const newOrder = order === 'asc' ? '+' : '-'
      return {
        ...state,
        filter: { ...state.filter, order: newOrder + orderBy },
        products: [],
        page: 1,
        enough: false,
        startSearch: !state.startSearch,
        orderBy,
        order,
      }

    case SaleDetailsActionTypes.SAVE_SALE_DETAILS_QUANTITY_FOR_ORDER: {
      let tempArr = cloneDeep(state.products)
      const detailsItem = tempArr.find(
        (item: any) => item.productEsCode === action.payload.productEscode
      )
      if (detailsItem) {
        detailsItem.neededQuantityForOrder = action.payload.amount
      }
      return {
        ...state,
        products: tempArr,
      }
    }

    case SaleDetailsActionTypes.ADD_SALE_DETAILS_STOP_LIST_ITEM: {
      let tempArr = cloneDeep(state.products)
      const detailsItems = tempArr.filter(
        (item: any) =>
          item.productEsCode === action.payload.productEscode &&
          item.departmentId === action.payload.depId
      )

      if (detailsItems.length > 0) {
        detailsItems.map((item: any) => (item.isInStopList = true))
      }

      return {
        ...state,
        products: tempArr,
      }
    }

    case SaleDetailsActionTypes.DELETE_SALE_DETAILS_STOP_LIST_ITEM: {
      let tempArr = cloneDeep(state.products)
      const detailsItems = tempArr.filter(
        (item: any) =>
          item.productEsCode === action.payload.productEscode &&
          item.departmentId === action.payload.depId
      )

      if (detailsItems.length > 0) {
        detailsItems.map((item: any) => (item.isInStopList = false))
      }

      return {
        ...state,
        products: tempArr,
      }
    }

    case SaleDetailsActionTypes.LOADING_ENABLE:
      return { ...state, loading: true }
    case SaleDetailsActionTypes.LOADING_DISABLE:
      return { ...state, loading: false }

    default:
      return state
  }
}
