import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { SxProps, Theme } from '@mui/material'
import Typography from '@mui/material/Typography'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { CloseModal } from '../../../types/types'
import { useTypedSelector } from '../../../hooks/useTypedSelector'

const styleHeaderModal: SxProps<Theme> = {
  textAlign: 'center',
  textTransform: 'uppercase',
  fontWeight: '600',
  fontSize: '1.3rem',
  mb: '1.7rem',
}

const styleHeaderInfo: SxProps<Theme> = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '14px',
}

const styleDetailInfo: SxProps<Theme> = {
  lineHeight: '1.5',
  color: 'text.secondary',
  textTransform: 'uppercase',
  overflowWrap: 'break-word',
  fontWeight: 'normal',
  fontSize: '12px',
}

const ErrorDetailInfoContent = ({ handleClose }: CloseModal) => {
  const { messageSnack, messageSnackErrorInfo, messageSnackErrorGuide } =
    useTypedSelector((state) => state.app.snack)

  return (
    <Box>
      <Box display="flex" justifyContent="center" marginTop={3}>
        <ErrorOutlineIcon color="error" sx={{ fontSize: '60px' }} />
      </Box>

      <Typography component="h6" color={'error'} sx={styleHeaderModal}>
        Детальная информация
      </Typography>

      <Grid container spacing={'1.25rem'}>
        <Grid item xs={12}>
          <Box>
            <Typography component="div" sx={styleHeaderInfo}>
              {'Ошибка:'}
            </Typography>
            <Typography component="div" sx={styleDetailInfo}>
              {messageSnack}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography component="div" sx={styleHeaderInfo}>
              {'Причина:'}
            </Typography>
            <Typography component="div" sx={styleDetailInfo}>
              {messageSnackErrorInfo}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="caption" component="div" sx={styleHeaderInfo}>
              {'Что делать:'}
            </Typography>
            <Typography component="div" sx={styleDetailInfo}>
              {messageSnackErrorGuide}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ErrorDetailInfoContent
