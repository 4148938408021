import { IOtherHeadCells } from '../types/otherTypes'

export const OTHERS = {
  MAX_PRODUCT_ITEMS: 60, //120?
}

export const headCellsArray1: IOtherHeadCells[] = [
  {
    name: 'КОД УС',
    align: 'left',
    field: 'producerName',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'НАИМЕНОВАНИЕ ТОВАРА',
    align: 'center',
    field: 'name',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'name',
    align: 'center',
    field: 'name',
    enablePadding: true,
    disableField: true,
  },
  {
    name: 'ПРОИЗВОДИТЕЛЬ',
    align: 'center',
    field: 'producerName',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'ПОСТАВЩИК',
    align: 'center',
    field: 'supplierName',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'СТРАНА',
    align: 'center',
    field: 'countryName',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'МИН КОЛ-ВО',
    align: 'center',
    field: 'minimalQuantity',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'КРАТНОСТЬ',
    align: 'center',
    field: 'boxQuantity',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'ДОСТУПНО',
    align: 'center',
    field: 'quantity',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'СРОК ГОДНОСТИ',
    align: 'center',
    field: 'expirationDate',
    enablePadding: true,
    disableField: false,
  },
  {
    name: 'ЦЕНА',
    align: 'center',
    field: 'price',
    enablePadding: true,
    disableField: false,
  },
]
