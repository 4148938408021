import http from '../../../../../shared/api/config/instance'
import { IDepartmentProps, ISupplierSettingsOptions } from '../types/supplierSettingsTypes'
import { SupplierSettingsState } from '../model/supplierSettings/supplierSettingsReduxTypes'

export const getDepartment = () =>
  http.httpCore.get<{ content: { items: IDepartmentProps[] } }>(
    'auto-order/dictionary/bid-departments'
  )

export const getSupplierSettingsOptions = (departmentId: number | null) => {
  return http.httpCore.get<{
    content: ISupplierSettingsOptions
    description: string
    isError: boolean
  }>(`auto-order/department-settings/${departmentId}`)
}

export const putSupplierSettingsOptions = (supplerSettings: SupplierSettingsState) => {
  
  const {
    considerMinimumQuantity,
    considerQuantityAvailabilityFactor,
    factors,
    departmentId,
    considerMultiplicityOfBoxQuantity,
    prioritiesOrder,
    onlyProductsWithBestRating,
    remainingShelfLifeInDays,
    remainingShelfLifeInclude,
    remainingShelfLifePercent,
    remainingShelfLifePercentInclude,
    distributeSupplierOrderIfCostLessThanMinimumCost,
    supplierOrderMinimumCostPercentage,
    updateByUserName,
    updatedAt
  } = supplerSettings

  const model: ISupplierSettingsOptions = 
    {
      departmentId: departmentId,
      remainingShelfLifeDuration: {
        remainingShelfLifeInclude: remainingShelfLifeInclude,
        remainingShelfLifeInDays: remainingShelfLifeInDays
      },
      remainingShelfLifePercent: {
        remainingShelfLifePercentInclude: remainingShelfLifePercentInclude,
        remainingShelfLifePercent: remainingShelfLifePercent
      },
      productRating: {
        onlyProductsWithBestRating: onlyProductsWithBestRating
      },
      availabilityByQuantity: {
        considerMinimumQuantity: considerMinimumQuantity,
        considerQuantityAvailabilityFactor: considerQuantityAvailabilityFactor,
        quantityAvailabilityFactorDictionary: {factors}
      },
      supplierOrderCost: {
        distributeSupplierOrderIfCostLessThanMinimumCost: distributeSupplierOrderIfCostLessThanMinimumCost,
        supplierOrderMinimumCostPercentage: supplierOrderMinimumCostPercentage
      },
      multiplicityOfBoxQuantity: {
        considerMultiplicityOfBoxQuantity: considerMultiplicityOfBoxQuantity
      },
      prioritiesOrder: {
        prioritiesOrder: prioritiesOrder
      },
      updateByUserName,
      updatedAt
    }

    return http.httpCore.put<{
      content: ISupplierSettingsOptions
    }>('auto-order/department-settings/update', [model])
}

export const deleteSupplierSettingsOptions = (departmentId: number) => {
  return http.httpCore.delete<{
    content: ISupplierSettingsOptions
  }>(`auto-order/department-settings/${departmentId}`)
}