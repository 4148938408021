import { useEffect, useRef } from 'react'
import { useActions } from '../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import { DEPARTMENT_CONSTS } from '../consts/departmentsPreOrder'
import { LoadDirections } from '../../../../../shared/types/types'
import { useObserver } from '../../../../../shared/hooks/useObserver'
import { checkLastPage } from '../../../../../shared/helpers'
import { clearDepartment } from '../model/departmentsPreOrdersAction'

type argsType = [string, number, number, boolean]
export const useDepartMentsPreOrderController = () => {
  const {
    fetchDepartmentsPreOrder,
    setPageDepartmentPage,
    clearAutoOrders,
    fethcAutoOrderSum
  } = useActions()
  const {
    preOrderSort,
    loading,
    page,
    limit,
    enough,
    total,
    preOrders,
    updateSelect,
  } = useTypedSelector((state) => state.autoOrderFinalDepartments)

  const savePageNum = useRef(page)
  const firstElement = useRef<HTMLTableRowElement>(null)
  const lastElement = useRef<HTMLTableRowElement>(null)
  const inputRef = useRef<null | HTMLSpanElement[]>([])

  useObserver(firstElement, page > 1, loading, () => {
    setPageDepartmentPage(
       page - 1
    )
  })
  useObserver(
    lastElement,
    enough && !checkLastPage(page, limit, total),
    loading,
    () => {
      setPageDepartmentPage(
       page + 1
      )
    }
  )

  useEffect(() => {
    if(!preOrders.length) return
    fethcAutoOrderSum()
  }, [ preOrders.length ])
  

  useEffect(() => {
    if (!preOrderSort) return
    if(!updateSelect) return
    clearAutoOrders()
    const args: argsType = [preOrderSort, page, limit, true]
    const fetch = async (...args: argsType) => fetchDepartmentsPreOrder(...args)
    fetch(...args).then(() => {
      if (!(inputRef.current !== null)) {
        savePageNum.current = page
        return
      }
      if (savePageNum.current < page) {
        inputRef?.current?.[
          preOrders[preOrders.length - 1].id as any
        ]?.scrollIntoView() // скролл вниз
      }

      savePageNum.current = page
    })
  }, [preOrderSort, page, updateSelect])

  return {
    savePageNum,
    firstElement,
    lastElement,
    loading,
    inputRef,
  }
}
