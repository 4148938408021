import { TableAlignType } from '../../../shared/types/types'
import { AutoOrderOrientation } from "../model/autoOrder/autoOrderReduxTypes";

export interface IAutoOrder {
  items: IAutoOrderItem[]
  pagination: IAutoOrderPagination
  isError: boolean
  description: string
  order: string
}

export interface IAutoOrderItem {
  creationDate: string
  code: string
  departmentId: number
  departmentName: string
  status: number
  processDate: string
  description: string
  ids: string[]
  id: string
  enableToDeleteSummaryBid: boolean
  enableToDeleteUserAutoOrderSummaryBid: boolean
}

export interface IAutoOrderPagination {
  pageNumber: number
  pageSize: number
  total: number
}

export interface IAutoOrderFilterItem {
  propertyName: string
  operation: string,
  values: any[]
}

export interface IAutoOrderStatusProps {
  id: number
  alias: string
}

export interface IAutoOrderCondition {
  calculationStatus: number | null, 
  editAvailabilityStatus: number | null, 
  userBidsStatus: number | null
}

export interface IAutoOrderHeadCells {
  name: string
  align: TableAlignType
  field: string
  enableLeftPadding?: boolean
  enablePaddings?: boolean
  disableField?: boolean
  disableSort?: boolean
}

export type AutoOrderFieldType =
  | 'creationDate'
  | 'code'
  | 'departmentName'
  | 'status'
  | 'processDate'
  | 'description'

interface IFieldsTypeMap {
  value: string,
  asc: AutoOrderOrientation
  desc: AutoOrderOrientation
}

export const AutoOrderFieldTypeMap: Record<AutoOrderFieldType, IFieldsTypeMap> = {
  creationDate: {
    value: 'creationDate',
    asc: AutoOrderOrientation.StartDate,
    desc: AutoOrderOrientation.EndDate
  },
  code: {
    value: 'code',
    asc: AutoOrderOrientation.CodesAsc,
    desc: AutoOrderOrientation.CodesDesc
  },
  departmentName: {
    value: 'departmentName',
    asc: AutoOrderOrientation.DepartmentNameAsc,
    desc: AutoOrderOrientation.DepartmentNameDesc
  },
  status: {
    value: 'status',
    asc: AutoOrderOrientation.StatusAsc,
    desc: AutoOrderOrientation.StatusDesc
  },
  processDate: {
    value: 'processDate',
    asc: AutoOrderOrientation.ProcessDateAsc,
    desc: AutoOrderOrientation.ProcessDateDesc
  },
  description: {
    value: 'description',
    asc: AutoOrderOrientation.DescriptionsAsc,
    desc: AutoOrderOrientation.DescriptionsDesc
  }
}


