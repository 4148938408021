import http from './config/instance'
import { ISideBarFilter } from '../store/application/applicationReduxTypes'
import { IOptionsProps } from '../types/types'
import { onlyNotEmpty } from '../services/commonService'
import { DEFAULT_DEPARTMENT_STORAGE_KEY } from '../consts/filter'

export const postFilterStructure = (params: ISideBarFilter) =>
  http.httpCore.post('/settings/product-filters', {
    filterStructure: JSON.stringify(params),
  })

export const postFilterValues = (params: Object) => {
  const newFilters = onlyNotEmpty(params)
  return http.httpCore.post('/settings/product-filters', {
    filterValues: JSON.stringify(newFilters),
  })
}

export const getFilter = () => http.httpCore.get('/settings/product-filters')

export const getDefaultDepartment = () =>
  http.httpCore.get<any>('frontend/settings/key-value')

export const postDefaultDepartment = (params: IOptionsProps | null) =>
  http.httpCore.post('frontend/settings/key-value/upsert', {
    keyValueSettings: [
      {
        key: DEFAULT_DEPARTMENT_STORAGE_KEY,
        value: JSON.stringify(params),
      },
    ],
  })
