import { Dispatch } from 'redux'
import { ModalAction } from '../../../../shared/store/modal/modalsReduxTypes'
import { showSupplierBasketModal } from '../../../../shared/store/modal/modalAction'
import { SupplierAction, SupplierActionTypes } from './supplierReduxTypes'

export const saveSuppliersIds = (ids: number[]) => {
  return (dispatch: Dispatch<SupplierAction | ModalAction>) => {
    dispatch({
      type: SupplierActionTypes.SAVE_SUPPLIERS_IDS,
      payload: ids,
    })
    dispatch(showSupplierBasketModal())
  }
}

export const resetSuppliersIds = (): SupplierAction => ({
  type: SupplierActionTypes.RESET_SUPPLIERS_IDS,
})

export const toggleWithPLButtonAction = (state: boolean): SupplierAction => ({
  type: SupplierActionTypes.TOGGLE_WITH_PL_BUTTON_STATE,
  payload: state
})