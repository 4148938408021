import http from '../../../shared/api/config/instance'
import {
  IPlistItems,
  IUserPlistQueryProps,
} from '../components/PriceList/types/priceListTypes'

export const getSupplierPListById = (
  supplierId: number,
  priceListName?: string,
  pageSize = 20,
  pageNumber = 1,
  showActivity = true,
) =>
  http.httpCore.get<{ content: { data: IPlistItems[] } }>(
    'suppliers/price-lists',
    {
      params: {
        supplierId,
        pageSize,
        pageNumber,
        showActivity,
        ...(priceListName ? { priceListName } : {}),
      },
    }
  )

export const postUserPList = (params: IUserPlistQueryProps[]) =>
  http.httpCore.post('user/price-lists', {
    items: [...params],
  })

export const postUserSupplierALlPList = (activeAll: boolean) => {
  return http.httpCore.post('user/supplier-price-lists', {
    activeAllSuppliers: activeAll,
  })
}

export const postUserSupplierPList = (supplierId: number, active: boolean) =>
  http.httpCore.post('user/supplier-price-lists', {
    items: [
      {
        supplierId,
        active,
      },
    ],
  })

export const getSupplierSettings = (
  supplierId: number,
  ) =>
  http.httpCore.get<any>('user/supplier-settings', {
    params: {
      supplierIdRange: supplierId,
    },
  })

export const postSupplierSettings = (
  supplierId: number,
  minSum: string | number,
) =>
  http.httpCore.post('user/supplier-settings', {
    supplierId,
    minSum,
  })


export const getSupplierPriority = (supplierId: number) => http.httpCore.get<any>(`auto-order/supplier/settings/${supplierId}`)

export const putSupplierPriority = (params: { supplierPriority: number,isActive: boolean, supplierId: number, supplierName: string }) => http.httpCore.put<any>('auto-order/supplier/settings', {
    settings: [{ ...params }]
})