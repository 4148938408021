import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Link as RouterLink, Route, useRouteMatch } from 'react-router-dom'
import { getThemesRoute } from '../../shared/services/routeService'
import { DRAWER } from '../../shared/consts/drawer'
import RouteWarning from '../../shared/components/RouteWarning'
import { ThemeContext } from '../../shared/theme/ThemeInjector'
import { Tooltip, Typography } from '@mui/material'
import { themeMap } from '../../shared/theme/base'

const OptionsMain = () => {
  let { path } = useRouteMatch()
  const setThemeName = useContext(ThemeContext)
  const themes1 = { ...themeMap }
  let arrTest = Object.entries(themes1)

  return (
    <>
      <Box display="flex">
        <Box width={DRAWER.WIDTH} marginTop={'1rem'}>
          <Typography
            to={getThemesRoute()}
            component={RouterLink}
            sx={{
              color: 'primary.main',
              fontSize: '16px',
              fontWeight: '400',
              textDecoration: 'unset',
              ml: '2.875rem',
              '&:hover': {
                color: '#5868B4',
                cursor: 'pointer',
                textDecoration: 'underline',
              },
            }}
          >
            Темы оформления
          </Typography>
        </Box>

        <Route path={`${path}/themes`}>
          <Box>
            <Typography>Выберите тему</Typography>
            <Box mt={2} display="flex" columnGap="15px">
              {arrTest.map(([nameTheme, v]) => (
                <React.Fragment key={nameTheme}>
                  <Tooltip title={String(v.textTooltip)}>
                    <Box
                      onClick={() => setThemeName(nameTheme)}
                      sx={{
                        width: '80px',
                        height: '80px',
                        backgroundColor: v.palette.primary.main,
                        borderRadius: '12px',
                        transition: 'all .2s ease-in-out',
                        '&:hover': {
                          cursor: 'pointer',
                          transform: 'scale(1.1)',
                        },
                      }}
                    />
                  </Tooltip>
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Route>
        <Route path={path} exact>
          <RouteWarning text={'Выберите раздел настройки'} />
        </Route>
      </Box>
    </>
  )
}

export default OptionsMain
