// import types
import { TableAlignType } from "../../../../../shared/types/types"
import { IAutoOrderPagination } from "../../../../AutoOrder/types/autoOrderTypes"


// enum for sorting

export enum DepartmentsPreOrderOrientation {
  DepartmentNameAsc = 'DepartmentName, SupplierName',
  DepartmentNameDesc = '-DepartmentName, -SupplierName',
  OrderCostAsc = 'OrderCost',
  OrderCostDesc = '-OrderCost'
}


// type header data
export interface IDepartmentsPreOrderHeadCells {
  name: string
  align: TableAlignType
  field: string
  enableLeftPadding?: boolean
  enablePaddings?: boolean
  disableField?: boolean
  disableSort?: boolean,
  minWidth?: string
}

export interface IFieldsTypeMap {
  value: string,
  asc: DepartmentsPreOrderOrientation
  desc: DepartmentsPreOrderOrientation
}

export const DepartmentsPreOrderFieldTypeMap: Record<DepartmentsPreOrderFieldType, IFieldsTypeMap> = {
  departmentName: {
    value: 'departmentName',
    asc: DepartmentsPreOrderOrientation.DepartmentNameAsc,
    desc: DepartmentsPreOrderOrientation.DepartmentNameDesc
  },
  orderCost: {
    value: 'orderCost',
    asc: DepartmentsPreOrderOrientation.OrderCostAsc,
    desc: DepartmentsPreOrderOrientation.OrderCostDesc
  },
}


export interface IDepartmentParams {
  pagination: {
    pageNumber: number,
    pageSize: number,
  },
  order: string,
}


export interface IPreOrder {
    id: string;
    departmentId: number;
    departmentName: string;
    supplierId: number;
    supplierName: string;
    priceListTypeId: number;
    priceListTypeName: string;
    priceListTypeDate: string;
    initialOrderCost: number;
    orderCost: number;
}

export interface IFilterProperty {
  propertyName: string,
  operation: string,
  values: string[]
}

export interface IKeyValueType {
  key: string,
  value: string,
}

export interface IPreOrderSorts {
  filter: IFilterProperty[],
  id: string,
  selectPreOrder: IPreOrder
}

export interface IPreOrders {
  items: IPreOrder[],
  pagination: IAutoOrderPagination,
}

export type DepartmentsPreOrderFieldType =
  | 'departmentName'
  | 'orderCost'


