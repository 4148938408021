import { IProductData, IProducts } from '../../../Product/types/productTypes'
import { Order } from '../../../../shared/services/commonService'
import { SimilarFieldType } from '../../types/similarTypes'
import { ErrorInfo, LoadDirections } from '../../../../shared/types/types'

export interface SimilarState {
  enough: boolean
  direction: LoadDirections.DOWN | LoadDirections.UP
  error: ErrorInfo | {}
  loading: boolean
  page: number
  limit: number
  total: number
  filter: Object
  products: IProductData[]
  orderBy: SimilarFieldType
  order: Order
}

export enum SimilarActionTypes {
  SET_FILTER_PRODUCTS = 'SET_FILTER_SIMILAR',
  RESET_FILTER_PRODUCTS = 'RESET_FILTER_SIMILAR',
  FETCH_PRODUCTS = 'FETCH_SIMILAR',
  FETCH_PRODUCTS_SUCCESS = 'FETCH_SIMILAR_SUCCESS',
  FETCH_PRODUCTS_ERROR = 'FETCH_SIMILAR_ERROR',
  SET_PRODUCT_PAGE = 'SET_SIMILAR_PAGE',
  FETCH_PRODUCTS_ENOUGH = 'FETCH_SIMILAR_ENOUGH',
  SET_PRODUCT_SORT = 'SET_SIMILAR_SORT',
}

interface FetchSimilarEnoughAction {
  type: SimilarActionTypes.FETCH_PRODUCTS_ENOUGH
  payload: boolean
}

interface SetFilterSimilarAction {
  type: SimilarActionTypes.SET_FILTER_PRODUCTS
  payload: Object
}

interface ResetFilterSimilarAction {
  type: SimilarActionTypes.RESET_FILTER_PRODUCTS
}

interface FetchSimilarAction {
  type: SimilarActionTypes.FETCH_PRODUCTS
}

export interface FetchSimilarSuccessAction {
  type: SimilarActionTypes.FETCH_PRODUCTS_SUCCESS
  payload: { content: IProducts; loadFlag: boolean }
}

export interface FetchSimilarErrorAction {
  type: SimilarActionTypes.FETCH_PRODUCTS_ERROR
  payload: ErrorInfo | {}
}

interface SetSimilarPage {
  type: SimilarActionTypes.SET_PRODUCT_PAGE
  payload: number
}

export interface ISimilarPayload {
  orderBy: SimilarFieldType
  order: Order
}

interface SetSimilarSort {
  type: SimilarActionTypes.SET_PRODUCT_SORT
  payload: ISimilarPayload
}

export type SimilarAction =
  | FetchSimilarEnoughAction
  | SetFilterSimilarAction
  | ResetFilterSimilarAction
  | FetchSimilarAction
  | FetchSimilarSuccessAction
  | FetchSimilarErrorAction
  | SetSimilarPage
  | SetSimilarSort
