import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from './reducers'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['basket', 'alternative', 'app', 'supplier'],
}
type RootReducerType = typeof rootReducer
export type AppStateType = ReturnType<RootReducerType>

const persistedReducer = persistReducer(persistConfig, rootReducer)
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
  let store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
  )
  let persistor = persistStore(store)
  return { store, persistor }
}
