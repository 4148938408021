import { Theme } from '@mui/material/styles'
import { blackTheme } from './blackTheme'
import { mainTheme } from './mainTheme'
import { pinkTheme } from './pinkTheme'
import { greenTheme } from './greenTheme'
import { darkTheme } from './darkTheme'

export function getThemeByName(theme: string): Theme {
  return themeMap[theme]
}

export const themeMap: { [key: string]: Theme } = {
  mainTheme,
  blackTheme,
  pinkTheme,
  greenTheme,
  darkTheme,
}

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary']
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    textTooltip?: string
  }
  interface ThemeOptions {
    textTooltip?: string
  }
}
