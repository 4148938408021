import React, { Suspense, useRef, useEffect, useState } from 'react'

// types
import { IProduct, IProductData } from '../../types/productTypes'

// mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'
import Link from '@mui/material/Link'

// styled
import { PaperCustom } from '../../../../shared/components/styled/PaperCustom'

//project imports
import NewHistoryTable from '../../../NewHistory/components/NewHistoryTable'
import ProductWideCardRow from './ProductWideCardRow'

// hooks
import { useCollapse } from '../../../../shared/hooks/useCollapse'
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'

// services
import { getOtherRouteWithPayload } from '../../../../shared/services/routeService'

// third libs
import { useHistory } from 'react-router-dom'
import { qsStringify } from '../../../../shared/services/commonService'

const NewRelatedTable = React.lazy(() => import('../NewRelatedTable'))

interface ProductWideCardProps {
  card: IProductData
  index: number
  currentCardId: number
  setCurrentCardId: (id: number) => void
}
const ProductWideCard: React.FC<ProductWideCardProps> = ({
  card,
  index,
  currentCardId,
  setCurrentCardId,
}) => {
  const { product } = card.productWithInfo
  const { expanded, handleExpandClick } = useCollapse()
  const cutRecommended = card.recommended.slice(0, 5)
  const history = useHistory()

  const productWideCardRef = useRef<HTMLDivElement>(null)
  const amountFieldRef = useRef<HTMLInputElement>(null)

  const [activeCard, setActiveCard] = useState(false)

  const filteredProducts = useTypedSelector((state) => state.product.filter)
  const isSearchEnabled = Object.keys(filteredProducts).length > 0

  useEffect(() => {
    if (isSearchEnabled && index === 0) {
      setCurrentCardId(card.productWithInfo.product.priceListItemId)
      amountFieldRef?.current?.focus()
    }
  }, [isSearchEnabled])

  useEffect(() => {
    if (currentCardId !== card.productWithInfo.product.priceListItemId) {
      setActiveCard(false)
      return
    }
    setActiveCard(true)
  }, [currentCardId, amountFieldRef])

  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setCurrentCardId(product.priceListItemId)
    amountFieldRef?.current?.focus()
    event.stopPropagation()
  }

  // const saveOtherItemAndPush = (esCode: number) => {
  //   const requestString = qsStringify({ esCode })
  //   history.push(getOtherRouteWithPayload(requestString))
  // }

  const saveOtherItemAndPush = (product: IProduct) => {
    const { esCode, esName, name } = product

    // console.log('product', product)
    const requestString = qsStringify({ esCode, productTitle: esName || name })
    history.push(getOtherRouteWithPayload(requestString))
  }

  return (
    <PaperCustom
      ref={productWideCardRef}
      className={activeCard ? 'active' : ''}
      onClick={handleCardClick}
    >
      <Box sx={{ padding: '10px 16px' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          columns={15}
        >
          <ProductWideCardRow
            {...{ card, expanded, handleExpandClick, amountFieldRef }}
          />
        </Grid>
      </Box>
      <Collapse in={expanded} timeout={'auto'} unmountOnExit>
        <NewHistoryTable card={product} />
      </Collapse>
      <Suspense
        fallback={
          <p style={{ marginLeft: '16px', fontSize: '600', color: '#909DB5' }}>
            Загрузка...
          </p>
        }
      >
        <NewRelatedTable
          card={cutRecommended}
          isActive={activeCard}
          productWideCardRef={productWideCardRef}
        />
      </Suspense>
      <Box sx={{ backgroundColor: 'inherit', padding: '13px' }}>
        <Link
          component="button"
          sx={{
            color: '#5F6CBF',
            fontSize: '12px',
            fontWeight: '500',
          }}
          // onClick={() => saveOtherItemAndPush(product.esCode)}
          onClick={() => saveOtherItemAndPush(product)}
        >
          Показать все
        </Link>
      </Box>
    </PaperCustom>
  )
}

export default ProductWideCard
