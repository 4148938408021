import http from '../../../../../shared/api/config/instance'
import { ISupplierListItem, ISupplierListPL } from '../types/supplierListTypes'

export const getSupplierList = (
  pageNumber = 1,
  pageSize = 100,
) =>
  http.httpCore.get<{ content: { items: ISupplierListItem[] } }>(
    'auto-order/user-settings/suppliers',
    {
      params: {
        pageNumber,
        pageSize,
      },
    }
  )

  export const postSupplierListSettings = (supplier: ISupplierListItem) => {
    return http.httpCore.post(
      'auto-order/user-settings/suppliers', [supplier] 
    )
  }

    export const deleteSupplierListSettings = (supplierId: string) => {
    return http.httpCore.delete<{
      content: object
    }>(`auto-order/user-settings/supplier/${supplierId}`)
  }

  export const postSupplierPriceListSettings = (supplierId: number, activePriceLists: ISupplierListPL[]) => {
    return http.httpCore.post(
      'auto-order/user-settings/suppliers/price-lists', [
        {
          supplierId: supplierId,
          priceListTypes: activePriceLists
        }
      ]
    )
  }

  export const deleteSupplierListPriceListSettings = (supplierId: number, priceListTypes: ISupplierListPL[]) => {
    const priceListTypesString = priceListTypes.map(pl => `priceListTypes=${pl.id}`).join('&');

    return http.httpCore.delete<{
      content: object
    }>(`auto-order/user-settings/supplier/price-lists?supplierId=${supplierId}&${priceListTypesString}`)
  }