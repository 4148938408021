import { IAutoOrderPagination } from '../../../../AutoOrder/types/autoOrderTypes'

export enum AutoOrderFinalOrientation {
  EsCodeAsc = 'EsCode',
  EsCodeDesc = '-EsCode',
  BitProductNameAsc = 'BitProductName',
  BitProductNameDesc = '-BitProductName',
  PriceAsc = 'Price',
  PriceDesc = '-Price',
  StatusAsc = 'Status',
  StatusDesc = '-Status',
  MultiplicityOfBoxQuantityAsc = 'MultiplicityOfBoxQuantity',
  MultiplicityOfBoxQuantityDesc = '-MultiplicityOfBoxQuantity',
  MinimalQuantityAsc = 'MinimalQuantity',
  MinimalQuantityDesc = '-MinimalQuantity',
  DepartmentNameAsc = 'DepartmentName',
  DepartmentNameDesc = '-DepartmentName',
  DescriptionsAsc = 'Descriptions',
  DescriptionsDesc = '-Descriptions',
  OrderCostAsc = 'OrderCost',
  OrderCostDesc = '-OrderCost',
  QuantityToOrderAsc = 'QuantityToOrder',
  QuantityToOrderDesc = '-QuantityToOrder',
  ExpirationDateAsc = 'ExpirationDate',
  ExpirationDateDesc = '-ExpirationDate',
  ValueAddedTaxPercentageAsc = 'ValueAddedTaxPercentage',
  ValueAddedTaxPercentageDesc = '-ValueAddedTaxPercentage',
  RegisteredPriceAsc = 'RegisteredPrice',
  RegisteredPriceDesc = '-RegisteredPrice',
  IsVitalMedicinalProductAsc = 'IsVitalMedicinalProduct',
  IsVitalMedicinalProductDesc = '-IsVitalMedicinalProduct',
  QuantitySoldAsc = 'QuantitySold',
  QuantitySoldDesc = '-QuantitySold',
  QuantityInPriceListAsc = 'QuantityInPriceList',
  QuantityInPriceListDesc = '-QuantityInPriceList',
  ProducerNameAsc = 'ProducerName',
  ProducerNameDesc = '-ProducerName',
  CostAsc = 'Cost',
  CostDesc = '-Cost',
  RemainingQuantityAsc = 'RemainingQuantity',
  RemainingQuantityDesc = '-RemainingQuantity',
  ProductNameAsc = 'ProductName',
  ProductNameDesc = '-ProductName',
}

export interface IAutoOrderProduct {
  id: string
  autoOrderProductId: string
  autoOrderBidSimilarProductsGroupId: string
  esCode: number
  isActive: boolean
  hasSimilarProducts: boolean
  bidSimilarProductsGroupProductName: string
  bitProductName: string
  productName: string
  producerName: string
  supplierId: number
  supplierName: string
  priceListTypeId: number
  priceListTypeName: string
  priceListTypeExtendedName: string
  departmentId: number
  departmentName: string
  registeredPricequantityToOrder: number
  multiplicityOfBoxQuantity: number
  price: number
  cost: number
  expirationDate: string
  valueAddedTaxPercentage: number
  valueAddedTaxCost: number
  registeredPrice: number
  isVitalMedicinalProduct: boolean
  minimalQuantity: number
  quantityInPriceList: number
  remainingQuantity: number
  quantitySold: number
  quantityToOrder: number
}

export const AutoOrderFinalFieldTypeMap: Record<
  AutoOrderFinalFieldType,
  IFieldsTypeMap
> = {
  esCode: {
    value: 'esCode',
    asc: AutoOrderFinalOrientation.EsCodeAsc,
    desc: AutoOrderFinalOrientation.EsCodeDesc,
  },
  multiplicityOfBoxQuantity: {
    value: 'multiplicityOfBoxQuantity',
    asc: AutoOrderFinalOrientation.MultiplicityOfBoxQuantityAsc,
    desc: AutoOrderFinalOrientation.MultiplicityOfBoxQuantityDesc,
  },
  quantityToOrder: {
    value: 'quantityToOrder',
    asc: AutoOrderFinalOrientation.QuantityToOrderAsc,
    desc: AutoOrderFinalOrientation.QuantityToOrderDesc,
  },
  expirationDate: {
    value: 'expirationDate',
    asc: AutoOrderFinalOrientation.ExpirationDateAsc,
    desc: AutoOrderFinalOrientation.ExpirationDateDesc,
  },
  valueAddedTaxPercentage: {
    value: 'valueAddedTaxPercentage',
    asc: AutoOrderFinalOrientation.ValueAddedTaxPercentageAsc,
    desc: AutoOrderFinalOrientation.ValueAddedTaxPercentageDesc,
  },
  minimalQuantity: {
    value: 'minimalQuantity',
    asc: AutoOrderFinalOrientation.MinimalQuantityAsc,
    desc: AutoOrderFinalOrientation.MinimalQuantityDesc,
  },
  registeredPrice: {
    value: 'registeredPrice',
    asc: AutoOrderFinalOrientation.RegisteredPriceAsc,
    desc: AutoOrderFinalOrientation.RegisteredPriceDesc,
  },
  isVitalMedicinalProduct: {
    value: 'isVitalMedicinalProduct',
    asc: AutoOrderFinalOrientation.IsVitalMedicinalProductAsc,
    desc: AutoOrderFinalOrientation.IsVitalMedicinalProductDesc,
  },
  quantitySold: {
    value: 'quantitySold',
    asc: AutoOrderFinalOrientation.QuantitySoldAsc,
    desc: AutoOrderFinalOrientation.QuantitySoldDesc,
  },
  quantityInPriceList: {
    value: 'quantityInPriceList',
    asc: AutoOrderFinalOrientation.QuantityInPriceListAsc,
    desc: AutoOrderFinalOrientation.QuantityInPriceListDesc,
  },
  producerName: {
    value: 'producerName',
    asc: AutoOrderFinalOrientation.ProducerNameAsc,
    desc: AutoOrderFinalOrientation.ProducerNameDesc,
  },
  price: {
    value: 'price',
    asc: AutoOrderFinalOrientation.PriceAsc,
    desc: AutoOrderFinalOrientation.PriceDesc,
  },
  cost: {
    value: 'cost',
    asc: AutoOrderFinalOrientation.CostAsc,
    desc: AutoOrderFinalOrientation.CostDesc,
  },
  remainingQuantity: {
    value: 'remainingQuantity',
    asc: AutoOrderFinalOrientation.CostAsc,
    desc: AutoOrderFinalOrientation.CostDesc,
  },
  bitProductName: {
    value: 'bitProductName',
    asc: AutoOrderFinalOrientation.BitProductNameAsc,
    desc: AutoOrderFinalOrientation.BitProductNameDesc,
  },
  productName: {
    value: 'productName',
    asc: AutoOrderFinalOrientation.ProductNameAsc,
    desc: AutoOrderFinalOrientation.ProductNameDesc,
  },
}

export type AutoOrderFinalFieldType =
  | 'esCode'
  | 'quantityToOrder'
  | 'expirationDate'
  | 'valueAddedTaxPercentage'
  | 'registeredPrice'
  | 'isVitalMedicinalProduct'
  | 'minimalQuantity'
  | 'quantitySold'
  | 'quantityInPriceList'
  | 'multiplicityOfBoxQuantity'
  | 'producerName'
  | 'price'
  | 'valueAddedTaxPercentage'
  | 'cost'
  | 'remainingQuantity'
  | 'bitProductName'
  | 'productName'

export interface IFieldsTypeMap {
  value: string
  asc: AutoOrderFinalOrientation
  desc: AutoOrderFinalOrientation
}

export interface IAutoOrderProducts {
  items: IAutoOrderProduct[]
  pagination: IAutoOrderPagination
}

export interface IAutoOrderHeadCell {
  name: string
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  field: string
  disableField: boolean
  disableSort: boolean
  enableLeftPadding: boolean
  enablePaddings: boolean
  maxWidth: number
}
