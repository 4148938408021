import * as React from 'react'
import { useEffect, useState } from 'react'

// mui imports
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { CircularProgress } from '@mui/material'

// libs
import { useActions } from '../../../../../shared/hooks/useActions'

// project imports
import ErrorPage from '../../../../Product/components/ErrorPage'
import SaleComplexTableRow from './SaleComplexTableRow'
import ElementWatch from '../../../../Product/components/ElementWatch'

// consts
import { headCellsSaleComplex } from '../consts/saleComplex'

// controller
import { useSaleComplexController } from '../controllers/useSaleComplexController'

// types
import { SaleComplexFieldType } from '../types/saleComplexTypes'

const SaleComplexTable = () => {
  const {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    products,
    order,
    orderBy,
  } = useSaleComplexController()

  const {
    fetchCostBonusesAction,
    setSaleComplexSort,
    fetchSaleDepartmentsProducts,
    resetSaleDepartmentsItems,
  } = useActions()

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (!mounted && products.length > 0) {
      resetSaleDepartmentsItems()
      const fetchBonuses = async () => fetchCostBonusesAction()
      const fetchDepartments = async () => fetchSaleDepartmentsProducts()
      fetchBonuses().then(() => fetchDepartments())
      setMounted(true)
    }
  }, [products])

  const sortHandler = (field: SaleComplexFieldType) => {
    const isAsc = orderBy === field && order === 'asc'
    setSaleComplexSort({ orderBy: field, order: isAsc ? 'desc' : 'asc' })
  }

  if (Object.keys(error).length) {
    return (
      <ErrorPage
        {...{
          error,
          firstElement,
          lastElement,
        }}
      />
    )
  }

  if (loading && products.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: 'calc(100vh - 370px)',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={120} color="secondary" />
      </Box>
    )
  }

  return (
    <>
      <TableContainer
        sx={{
          border: '1px solid #E1E3E6',
          boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
          borderRadius: '8px',
          height: 'calc(100vh - 340px)',
          overflowY: 'auto',
          overflowX: 'initial',
        }}
      >
        <Table size="small" aria-label="a complex-order table">
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  fontSize: '0.6rem',
                  lineHeight: '1rem',
                  color: 'primary.main',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  borderBottom: 0,
                  backgroundColor: 'background.paper',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                },
              }}
            >
              {headCellsSaleComplex.map((item) => (
                <TableCell
                  key={item.name}
                  align={item.align}
                  sx={{
                    padding: item.enablePaddings
                      ? '0.5rem 1rem 0 1rem'
                      : '0.5rem 0 0 0',
                  }}
                >
                  {!item.disableField && (
                    <TableSortLabel
                      active={orderBy === item.field}
                      direction={orderBy === item.field ? order : 'asc'}
                      onClick={() => sortHandler(item.field)}
                    >
                      <Box
                        sx={{ pl: item.enableLeftPadding ? '18px' : 'unset' }}
                      >
                        {item.name}
                      </Box>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {products.length > 0 && (
              <ElementWatch
                componentNode={'tr'}
                element={firstElement}
                isLoading={loading}
              />
            )}
            {products.map((row: any) => (
              <React.Fragment key={row.productEsCode}>
                <SaleComplexTableRow row={row} />
                <tr
                  // @ts-ignore
                  ref={(el) => (inputRef.current[row.productEsCode] = el)}
                />
              </React.Fragment>
            ))}
            {products.length > 0 && (
              <ElementWatch
                componentNode={'tr'}
                element={lastElement}
                isLoading={loading}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default SaleComplexTable
