import React, { useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import { useActions } from '../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'
import { CloseModal } from '../../../../shared/types/types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { FILTER_OPTIONS } from '../../../../shared/consts/filter'

const FilterCheckboxContent = ({ handleClose }: CloseModal) => {
  const { saveFilter } = useActions()
  const { sideBarFilter } = useTypedSelector((state) => state.app)
  const [filter, setFilter] = useState(sideBarFilter)

  const handleSubmit = () => {
    saveFilter(filter)
    handleClose()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.name
    setFilter({ ...filter, [name]: event.target.checked })
  }

  return (
    <>
      <Box
        sx={{
          height: 260,
          overflow: 'auto',
          paddingLeft: '15px',
        }}
      >
        <Grid container>
          {FILTER_OPTIONS.map(({ name, label }) => (
            <Grid item xs={4} key={name}>
              <FormControlLabel
                control={
                  <Checkbox
                    //@ts-ignore
                    checked={filter[name]}
                    onChange={handleChange}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#5F5F5F',
                    }}
                  >
                    {' '}
                    {label}
                  </Typography>
                }
                name={name}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Button
        sx={{ mt: 3 }}
        fullWidth
        type="button"
        onClick={handleSubmit}
        variant="contained"
      >
        Сохранить
      </Button>
    </>
  )
}

export default FilterCheckboxContent
