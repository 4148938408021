import { IHistoryHeadCells } from '../types/newHistoryTypes'

export const NEW_HISTORY = {
  MAX_PRODUCT_ITEMS: 60,
  HISTORY_LIMIT_PAGE: 60,
}

export enum HISTORY_STATUS {
  UNKNOWN,
  NEW,
  UPLOADED,
  SENT,
  PARTIALLY_AGREED,
  FULL_AGREED,
  CANCELED,
}

export enum TYPE_ID {
  MANUAL_ORDER = 1,
  COMPLEX_ORDER = 2,
  AUTO_ORDER = 3,
}

export const headCellsArray: IHistoryHeadCells[] = [
  {
    name: '№',
    align: 'inherit',
    field: 'id',
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
  {
    name: 'Дата заказа',
    align: 'center',
    field: 'createdAt',
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
  {
    name: 'Поставщик',
    align: 'center',
    field: 'supplier',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
    disableSort: true,
  },
  {
    name: 'Грузополучатель',
    align: 'center',
    field: 'department',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
    disableSort: true,
  },
  {
    name: 'Кем создан',
    align: 'center',
    field: 'authorName',
    enableLeftPadding: true,
    disableField: false,
    disableSort: true,
    enablePaddings: false,
  },
  {
    name: 'Прайс-лист',
    align: 'center',
    field: 'priceListName',
    enableLeftPadding: true,
    disableField: false,
    disableSort: true,
    enablePaddings: false,
  },
  {
    name: 'Сумма',
    align: 'center',
    field: 'totalSum',
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
  {
    name: 'Кол-во',
    align: 'center',
    field: 'totalCount',
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
  {
    name: 'Статус заказа',
    align: 'center',
    field: 'statusId',
    enableLeftPadding: true,
    disableField: false,
    disableSort: false,
    enablePaddings: false,
  },
]
