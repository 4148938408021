import { Dispatch } from 'redux'
import {
  ApplicationAction,
  ApplicationActionTypes,
} from '../../../../shared/store/application/applicationReduxTypes'
import {
  ISimilarPayload,
  SimilarAction,
  SimilarActionTypes,
} from './similarReduxTypes'

import noResultImage from '../../../../shared/assets/images/search.png'
import { getSimilarItems } from '../../api/similar'

export const fetchSimilarProducts = (
  supplierGoodId: number,
  priceListTypeId: number,
  page = 1,
  limit = 30,
  filter = {},
  loadFlag = true
) => {
  return async (dispatch: Dispatch<SimilarAction | ApplicationAction>) => {
    try {
      dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })
      const { data } = await getSimilarItems(
        supplierGoodId,
        priceListTypeId,
        page,
        limit,
        filter
      )
      const content = data.content
      const isEmpty = content.data.length === 0
      if (isEmpty) {
        dispatch({
          type: SimilarActionTypes.FETCH_PRODUCTS_ERROR,
          payload: { message: 'Поиск не дал результатов', img: noResultImage },
        })
        return
      }
      const isEnough = content.data.length >= limit
      dispatch({
        type: SimilarActionTypes.FETCH_PRODUCTS_ENOUGH,
        payload: isEnough,
      })
      dispatch({
        type: SimilarActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: { content, loadFlag },
      })
    } catch (e) {
      dispatch({
        type: SimilarActionTypes.FETCH_PRODUCTS_ERROR,
        payload: { message: 'Поиск завершился ошибкой', img: noResultImage },
      })
    } finally {
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    }
  }
}

export function setSimilarPage(page: number): SimilarAction {
  if (page === 0) page = 1
  return { type: SimilarActionTypes.SET_PRODUCT_PAGE, payload: page }
}

export function setSimilarFilterProducts(payload: Object): SimilarAction {
  return {
    type: SimilarActionTypes.SET_FILTER_PRODUCTS,
    payload,
  }
}

export function resetSimilarFilterProducts(): SimilarAction {
  return { type: SimilarActionTypes.RESET_FILTER_PRODUCTS }
}

export function setSimilarSort(payload: ISimilarPayload): SimilarAction {
  return { type: SimilarActionTypes.SET_PRODUCT_SORT, payload }
}
