import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useTranslation } from 'react-i18next'
import { IOrderStatusProps } from '../../../features/NewHistory/types/newHistoryTypes'

export default function StatusAutoComplete({
  value,
  handleAutoCompleteFields,
}: {
  value: IOrderStatusProps | null
  handleAutoCompleteFields: (newValue: any) => void
}) {
  const { t } = useTranslation()

  return (
    <Autocomplete
      value={value}
      size="small"
      sx={{
        width: '230px',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#B5C3DC',
        },
        '& .MuiInputLabel-formControl': {
          fontSize: '0.875rem',
          mt: '2px',
        },
      }}
      onChange={(event: any, newValue: any) => {
        handleAutoCompleteFields(newValue)
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.alias}
      options={[
        { id: 1, alias: t('dialog.new') },
        { id: 2, alias: t('dialog.completed') },
        { id: 3, alias: t('dialog.sended') },
        { id: 4, alias: t('dialog.parted_approved') },
        { id: 5, alias: t('dialog.approved') },
        { id: 6, alias: t('dialog.refused') },
      ]}
      renderInput={(params) => <TextField {...params} label="Статус" />}
    />
  )
}
