import React, { ChangeEvent, useEffect, useMemo } from 'react'

// mui
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import Button from '@mui/material/Button'

// hooks
import { useActions } from '../../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../../shared/hooks/useTypedSelector'

// styled
import { TextFieldCustom } from '../../../../../../shared/components/styled/TextFieldCustom'

// libs
import debounce from 'lodash/debounce'

// api
import { postAllSaleDepartments } from '../../../../api/sale'

// project
import ItemsLoader from '../../../../../../shared/components/ItemsLoader'

const SaleDepartmentsContent = ({
  handleClose,
}: {
  handleClose: () => void
}) => {
  const {
    submitDepartmentsAction,
    fetchDepartmentsAction,
    handleSaleDepartmentCheckBox,
    fetchSavedDeps,
    resetSaleDepartmentsItems,
    fetchSaleDepartmentsProducts,
    resetDepartmentsCopyAction,
  } = useActions()

  const isCheckedAll = JSON.parse(
    localStorage.getItem('DEPARTMENTS_IS CHECKED_ALL') || 'false'
  )

  const departments = useTypedSelector(
    (state) => state.saleDepartments.departments
  )
  const checkedSaleDepartments = useTypedSelector(
    (state) => state.saleDepartments.checkedSaleDepartments
  )

  const loading = useTypedSelector((state) => state.saleDepartments.loading)

  useEffect(() => {
    fetchDepartmentsAction(undefined, 100)
    fetchSavedDeps()
  }, [])

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value
    debounceRequest(val.trim().length ? val : undefined)
  }

  const debounceRequest = useMemo(
    () => debounce((val?: string) => fetchDepartmentsAction(val), 800),
    []
  )

  const selectAllDepartments = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    localStorage.setItem('DEPARTMENTS_IS CHECKED_ALL', String(isChecked))
    postAllSaleDepartments(isChecked).then(() => fetchSavedDeps())
  }

  const handleSubmit = async () => {
    resetSaleDepartmentsItems()
    const submitDepartments = async () => submitDepartmentsAction()
    const fetchDepartments = async () => fetchSaleDepartmentsProducts()
    await submitDepartments()
    handleClose()
    fetchDepartments()
  }

  const cancelEdit = () => {
    resetDepartmentsCopyAction()
    handleClose()
  }

  return (
    <>
      <Box
        sx={{
          my: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '14px',
          backgroundColor: 'background.paper',
          zIndex: 1,
          position: 'sticky',
          top: '0',
        }}
      >
        <Typography
          sx={{
            color: 'primary.main',
            fontWeight: 'bold',
            fontSize: '16px',
            textTransform: 'uppercase',
            textAlign: 'left',
          }}
        >
          ВЫБЕРИТЕ ГРУЗОПОЛУЧАТЕЛЯ
        </Typography>

        <TextFieldCustom
          fullWidth
          onChange={handleSearchInput}
          placeholder="Поиск грузополучателя"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Stack direction="row" columnGap={3} fontSize={14} alignItems="center">
          <Checkbox
            edge="start"
            disableRipple
            checked={isCheckedAll}
            onChange={(e) => selectAllDepartments(e)}
          />
          <Typography fontSize={'inherit'}>Все</Typography>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ height: 290, overflow: 'auto' }}>
        <ItemsLoader loading={loading} isEmpty={departments.length === 0}>
          <List>
            {departments.length > 0 &&
              departments.map((item) => {
                return (
                  <ListItem key={item.id} disablePadding>
                    <ListItemButton
                      disableGutters
                      dense
                      onClick={() =>
                        handleSaleDepartmentCheckBox(item.id, item.name)
                      }
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          disableRipple
                          id={String(item.id)}
                          checked={checkedSaleDepartments.some(
                            (item2) => item2.departmentId === item.id
                          )}
                        />
                      </ListItemIcon>

                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              width: '100%',
                              pr: 1,
                              paddingY: '4px',
                              fontWeight: 600,
                              fontSize: 12,
                              color: 'primary.main',
                              textDecoration: 'unset',
                              wordBreak: 'break-word',
                            }}
                          >
                            {item.name}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )
              })}
          </List>
          <Box
            sx={{
              width: 355,
              backgroundColor: 'background.paper',
              borderRadius: '0 0 10px 10px',
              position: 'absolute',
              bottom: 0,
              left: 0,
              py: 1,
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="end"
              columnGap={1}
            >
              <Button variant="contained" size="small" onClick={handleSubmit}>
                Подтвердить
              </Button>
              <Button variant="outlined" size="small" onClick={cancelEdit}>
                Отменить
              </Button>
            </Stack>
          </Box>
        </ItemsLoader>
      </Box>
    </>
  )
}

export default SaleDepartmentsContent
