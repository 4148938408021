import * as React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

export const RedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: 'rgba(255, 233, 233, 1)',
    borderRadius: '6px',
    color: 'red',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))
