import {
  SaleDepartmentsAction,
  SaleDepartmentsActionTypes,
  SaleDepartmentsState,
} from './saleDepartmentsReduxTypes'
import {
  getComparator,
  onlyNotEmpty,
} from '../../../../../../shared/services/commonService'
import { SALE_DEPARTMENTS } from '../../consts/saleDepartments'
import { ErrorInfo, LoadDirections } from '../../../../../../shared/types/types'
import cloneDeep from 'lodash/cloneDeep'

const initialState: SaleDepartmentsState = {
  enough: false,
  direction: LoadDirections.DOWN,
  loading: false,
  error: {} as ErrorInfo,
  page: 1,
  limit: 30,
  total: 30,
  filter: {},
  products: [],
  orderBy: 'departmentName',
  order: 'asc',
  departments: [],
  checkedSaleDepartments: [],
  checkedSaleDepartmentsCopy: [],
  selectedStopListDepartment: '',
  isComplexTableShowed: false,
}

export const saleDepartmentsReducer = (
  state = initialState,
  action: SaleDepartmentsAction
): SaleDepartmentsState => {
  switch (action.type) {
    case SaleDepartmentsActionTypes.SET_FILTER_PRODUCTS:
      return {
        ...state,
        products: [],
        page: 1,
        filter: { ...action.payload },
      }
    case SaleDepartmentsActionTypes.RESET_FILTER_PRODUCTS:
      return {
        ...state,
        products: [],
        page: 1,
        filter: {},
      }
    case SaleDepartmentsActionTypes.FETCH_PRODUCTS_SUCCESS:
      const { content, loadFlag } = action.payload

      const sortedArray = content.departments.sort(
        getComparator(state.order, state.orderBy)
      )

      const productsArray = loadFlag
        ? state.products.concat(sortedArray)
        : sortedArray.concat(state.products)

      // удаляем дубликаты
      const productFilteredArray = productsArray.filter(
        //@ts-ignore
        (v, i, a) => a.findIndex((t) => t.departmentId === v.departmentId) === i
      )
      const productSlicedArray = loadFlag
        ? productFilteredArray.slice(-SALE_DEPARTMENTS.MAX_PRODUCT_ITEMS)
        : productFilteredArray.slice(0, SALE_DEPARTMENTS.MAX_PRODUCT_ITEMS)

      return {
        ...state,
        products: productSlicedArray,
        error: {},
        total: content.total,
        direction: loadFlag ? LoadDirections.DOWN : LoadDirections.UP,
      }

    case SaleDepartmentsActionTypes.FETCH_PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case SaleDepartmentsActionTypes.SET_PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case SaleDepartmentsActionTypes.SHOW_SALE_COMPLEX_TABLE:
      return {
        ...state,
        isComplexTableShowed: !state.isComplexTableShowed,
      }

    case SaleDepartmentsActionTypes.UPDATE_COPY_DEPARTMENTS:
      return {
        ...state,
        checkedSaleDepartmentsCopy: state.checkedSaleDepartments,
      }

    case SaleDepartmentsActionTypes.RESET_COPY_DEPARTMENTS:
      return {
        ...state,
        checkedSaleDepartments: state.checkedSaleDepartmentsCopy,
      }

    case SaleDepartmentsActionTypes.DELETE_SALE_DEPARTMENT_ITEM:
      return {
        ...state,
        products: state.products.filter(
          (item: any) => item.departmentId !== action.payload
        ),
        checkedSaleDepartments: state.checkedSaleDepartments.filter(
          (item) => item.departmentId !== action.payload
        ),
      }

    case SaleDepartmentsActionTypes.FETCH_PRODUCTS_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }

    case SaleDepartmentsActionTypes.SET_PRODUCT_SORT:
      const order = action.payload.order
      const orderBy = action.payload.orderBy
      return {
        ...state,
        products: state.products.slice().sort(getComparator(order, orderBy)),
        orderBy,
        order,
      }

    case SaleDepartmentsActionTypes.SAVE_DEPARTMENTS_LIST:
      return { ...state, departments: action.payload }

    case SaleDepartmentsActionTypes.RESET_SALE_DEPARTMENTS_ITEMS:
      return {
        ...state,
        ...initialState,
        departments: state.departments,
        checkedSaleDepartments: state.checkedSaleDepartments,
        checkedSaleDepartmentsCopy: state.checkedSaleDepartmentsCopy,
        selectedStopListDepartment: state.selectedStopListDepartment,
        isComplexTableShowed: state.isComplexTableShowed,
      }

    case SaleDepartmentsActionTypes.SET_CHECKED_ITEMS:
      return {
        ...state,
        checkedSaleDepartments: action.payload,
      }

    case SaleDepartmentsActionTypes.SET_COPY_CHECKED_ITEMS:
      return {
        ...state,
        checkedSaleDepartmentsCopy: action.payload,
      }

    case SaleDepartmentsActionTypes.RESET_SD_CHECKED_ITEMS:
      return {
        ...state,
        checkedSaleDepartments: [],
      }

    case SaleDepartmentsActionTypes.ADD_CHECKED_ITEMS:
      return {
        ...state,
        checkedSaleDepartments: [
          ...state.checkedSaleDepartments,
          ...action.payload,
        ],
      }

    case SaleDepartmentsActionTypes.SAVE_STOP_LIST_DEPARTMENT:
      return {
        ...state,
        selectedStopListDepartment: action.payload,
      }

    case SaleDepartmentsActionTypes.RESET_STOP_LIST_DEPARTMENT:
      return {
        ...state,
        selectedStopListDepartment: '',
      }

    case SaleDepartmentsActionTypes.LOADING_ENABLE:
      return { ...state, loading: true }

    case SaleDepartmentsActionTypes.LOADING_DISABLE:
      return { ...state, loading: false }

    case SaleDepartmentsActionTypes.UPDATE_SALE_DEPARTMENTS_ITEMS: {
      const tempArray = cloneDeep(state.products)
      const changedDepartments = [...action.payload]
      changedDepartments.map((newItem) => {
        const searchIndex = tempArray.findIndex(
          (product) => product.departmentId === newItem.departmentId
        )
        if (searchIndex === -1) return
        tempArray[searchIndex] = {
          ...tempArray[searchIndex],
          ...onlyNotEmpty(newItem),
        }
      })

      return {
        ...state,
        products: tempArray,
      }
    }

    default:
      return state
  }
}
