import React, { useRef, useState } from 'react'

// mui imports
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Checkbox } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

// project imports
import FormattedDate1 from '../../../../../shared/components/FormattedDate1'

// hooks
import { useActions } from '../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'

// types
import { IDepartmentsNeedsParams } from '../../../../../shared/types/types'

const style = {
  pointerEvents: 'none' as 'none',
  opacity: '0.5',
}

const DepartmentsWithNeededProducts = ({ product }: { product: any }) => {
  const { stopListHandlerAction, setQuantityForOrderAction } = useActions()

  const isBlockedRowsActive = useTypedSelector(
    (state) => state.saleComplex.isBlockedTable
  )
  const bonusPriceActive = useTypedSelector(
    (state) =>
      state.saleComplex.options.bonusInPrice.applyBonusInPriceToProductsPrice
  )
  const isConsigneeDepartmentActive = useTypedSelector(
    (state) =>
      state.saleComplex.options.consigneeDepartment?.departmentName.length > 0
  )

  const [prevAmountValue, setPrevAmountValue] = useState(
    product.neededQuantityForOrder
  )

  const isTprBlocked =
    isBlockedRowsActive || bonusPriceActive || isConsigneeDepartmentActive

  const minVal = product.minimalQuantity || 1

  const maxVal = product.initialNeededQuantityForOrder

  const quantity = product.boxQuantitiy || 1

  const proAptPrice = product.proAptekaPrice

  const [proAptCost, setProAptCost] = useState(product.proAptekaCost)

  const handleQuantityForOrder = (params: IDepartmentsNeedsParams) => {
    const { depId, productEscode, amount, id } = params
    let tempResult = prevAmountValue
    if (prevAmountValue < amount) tempResult = prevAmountValue + quantity
    if (prevAmountValue < amount && amount < minVal) tempResult = minVal

    if (prevAmountValue > amount) tempResult = prevAmountValue - quantity

    const validatedAmount =
      tempResult > maxVal // ограничение по максимуму и по минимальному значению и увеличение по кратности
        ? maxVal
        : tempResult < minVal
        ? 0
        : tempResult
    if (prevAmountValue === validatedAmount) return

    setPrevAmountValue(validatedAmount) // сохранение предыдущего значение между ререндерами

    const newProAptCost = Math.round(proAptPrice * validatedAmount * 100) / 100 // считаем сумму в рельном времени

    setProAptCost(newProAptCost)
    setQuantityForOrderAction({
      depId,
      productEscode,
      amount: validatedAmount,
      id,
    })
  }

  return (
    <>
      <TableRow
        sx={{
          ...(isTprBlocked ? style : {}),
          '& td': {
            fontSize: '0.725rem',
          },
          '&:last-child td': { border: 0 },
          '& td:not(:first-child)': {
            ...(product.isInStopList ? style : {}),
          },
        }}
      >
        <TableCell sx={{ padding: '0px 0px 0px 4px' }}>
          <Checkbox
            sx={{
              pointerEvents: !isTprBlocked ? 'auto' : 'inherit',
            }}
            color="error"
            edge="start"
            disableRipple
            size="small"
            checked={product.isInStopList}
            onChange={(e) =>
              stopListHandlerAction(e, {
                depId: product.departmentId,
                productEscode: product.productEsCode,
              })
            }
          />
        </TableCell>
        <TableCell align="left" sx={{ padding: '6px 8px' }}>
          {product.departmentName}
        </TableCell>
        <TableCell align="center">
          <Tooltip
            title={
              <>
                <p>Мин кол: {minVal}</p>
                <p>Кратность: {quantity}</p>
                <p>Макс кол: {maxVal}</p>
              </>
            }
          >
            <input
              type="number"
              value={prevAmountValue}
              onChange={(e) =>
                handleQuantityForOrder({
                  depId: product.departmentId,
                  productEscode: product.productEsCode,
                  amount: Number(e.target.value),
                  id: product.id,
                })
              }
              style={{
                backgroundColor: 'inherit',
                color: 'inherit',
                fontSize: 'inherit',
                height: '28px',
                lineHeight: '28px',
                textAlign: 'center',
                width: '55px',
                border: 0,
                outline: 0,
                marginLeft: '12px',
              }}
            />
          </Tooltip>
        </TableCell>
        <TableCell align="center">{minVal}</TableCell>
        <TableCell align="center">{quantity}</TableCell>
        <TableCell align="center">{proAptPrice}</TableCell>
        <TableCell align="center">{proAptCost}</TableCell>
        <TableCell align="center">
          <Checkbox size="small" checked={product.isVitalMedicinalProduct} />
        </TableCell>
        <TableCell align="center">
          <FormattedDate1 date={product.expirationDate} />
        </TableCell>
        <TableCell align="center">
          {product.remainingShelfLifePercent}
        </TableCell>
        <TableCell align="center">{product.minimumCompetitorPrice}</TableCell>
        <TableCell align="center">{product.competitorName}</TableCell>
        <TableCell align="center">{product.delta}</TableCell>
      </TableRow>
    </>
  )
}

export default DepartmentsWithNeededProducts
