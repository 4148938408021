import React, { Suspense } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import SearchPanel from '../SearchPanel'
import BarCustom from '../BarCustom'
import ProgressBar from '../../../../shared/components/UI/ProgressBar'
import LogoAndName from './LogoAndName'
import NavMenu from './NavMenu'
import { useLocation } from 'react-router-dom'
import { getHomeRoute } from '../../../../shared/services/routeService'
const Avatar = React.lazy(() => import('../AvatarCustom'))

const HeaderToolbar = ({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => void
}) => {
  const location = useLocation()
  const isMainPage = location.pathname === getHomeRoute()

  const style = !isMainPage
    ? { marginLeft: 'auto' }
    : {
        width: '100%',
        justifyContent: 'flex-end',
      }

  return (
    <>
      <Toolbar disableGutters style={{ padding: '0 1rem' }}>
        <LogoAndName handleDrawerToggle={handleDrawerToggle} />
        <NavMenu />
        <Box display="flex" alignItems="center" columnGap="10px" {...style}>
          {isMainPage && <SearchPanel />}
          <BarCustom />
          <Suspense
            fallback={<p style={{ marginLeft: '16px' }}> Загрузка...</p>}
          >
            <Avatar />
          </Suspense>
        </Box>
      </Toolbar>
      <ProgressBar />
    </>
  )
}

export default HeaderToolbar
