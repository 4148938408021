import { useEffect, useRef } from 'react'

import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import { useObserver } from '../../../../../shared/hooks/useObserver'

import { checkLastPage } from '../../../../../shared/helpers'
import { useActions } from '../../../../../shared/hooks/useActions'

export const useProcessingAutoOrderController = () => {
  const {
    items,
    error,
    page,
    enough,
    order,
    orderBy,
    listOrientation,
    limit,
    total,
    loading,
  } = useTypedSelector((state) => state.processingAutoOrder)

  const {
    fetchProcessingAutoOrder,
    resetProccesingAutoOrderItems,
    setProcessingAutoOrderPage,
    fetchAutoOrderCondition,
  } = useActions()

  const savePageNum = useRef(page)
  const firstElement = useRef<HTMLDivElement>(null)
  const lastElement = useRef<HTMLDivElement>(null)
  const inputRef = useRef<null | Record<string, HTMLSpanElement>>({})

  useEffect(() => {
    const fetch = async () => fetchProcessingAutoOrder(page, limit)

    fetch().then(() => {
      if (savePageNum.current !== page) {
        const trElement = inputRef.current?.[items[items.length - 1].id as any]
        console.log(trElement)
        trElement?.scrollIntoView()
      }
      savePageNum.current = page
    })
  }, [page, listOrientation])

  useEffect(() => {
    fetchAutoOrderCondition()
    return () => {
      resetProccesingAutoOrderItems()
    }
  }, [])

  // down
  useObserver(
    lastElement,
    enough && !checkLastPage(page, limit, total) && inputRef.current !== null,
    loading,
    () => {
      setProcessingAutoOrderPage(page + 1)
    }
  )

  // up
  useObserver(
    firstElement,
    enough && page > 1 && inputRef.current !== null,
    loading,
    () => {
      setProcessingAutoOrderPage(page - 1)
    }
  )

  return {
    error,
    firstElement,
    lastElement,
    inputRef,
    loading,
    items,
    orderBy,
    order,
    listOrientation,
  }
}
