import React, { useMemo } from "react";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { orderSum } from "../../../shared/services/basketService";
import { BasketArray } from "../types/basketTypes";
import { useTypedSelector } from "../../../shared/hooks/useTypedSelector";

const BasketSumColumn = ({
                           orders,
                           saveOrderSubmit,
                           sendToCustomer
                         }: {
  orders: BasketArray
  saveOrderSubmit: () => void
  sendToCustomer: () => void
}) => {
  const orderSumTemplate = useMemo(() => {
    return window.numeral(orderSum(orders)).format("0,0.00").replace(",", " ");
  }, [orders]);

  const claims = useTypedSelector((state) => state.auth.claims);

  return (
    <Stack direction="column" sx={{ mt: 8 }}>
      <Box
        component={Paper}
        sx={{
          p: 2,
          boxShadow: "4px 6px 5px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          mb: 5
        }}
      >
        <Typography align="left" fontSize="14px" fontWeight="600">
          Сумма заказа
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography fontSize="14px" align="left">
          К оплате: {orderSumTemplate} &#8381;
        </Typography>

        <Button
          disabled={
            !claims["promarket.order.create"] && orders.length === 0
          }
          sx={{ my: 1 }}
          variant="outlined"
          color="primary"
          fullWidth
          onClick={saveOrderSubmit}
        >
          Сохранить
        </Button>

        <Button
          sx={{ my: 1 }}
          disabled={
            !claims["promarket.order.create"] &&
            !claims["promarket.order.send_supplier"] &&
            orders.length === 0
          }
          variant="contained"
          color="primary"
          fullWidth
          onClick={sendToCustomer}
        >
          Отправить поставщику
        </Button>
      </Box>
    </Stack>
  );
};

export default BasketSumColumn;
