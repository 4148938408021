import http from '../../../../../shared/api/config/instance'
import { PriceListDeliveryItems } from '../model/priceList/priceListReduxTypes'

export const getPriceListDelivery = (
  page?: number,
  limit?: number,
  filter?: any
) => {
  return http.httpCore.post(
    'auto-order/delivery-schedule/settings/filter/with-departments',
    {
      pagination: {
        pageSize: limit,
        pageNumber: page,
      },
      ...filter,
    }
  )
}

export const savePriceListDelivery = (items: PriceListDeliveryItems[]) => {
  return http.httpCore.post('auto-order/delivery-schedule/settings', {
    settings: items,
  })
}

export const getPriceListDeliveryDefault = (
  supplierId: string,
  pageSize = 20,
  pageNumber = 1,
  ) => {

  return http.httpCore.post('auto-order/delivery-schedule/settings/filter', {
    pagination: {
      pageSize,
      pageNumber,
    },
    filter: {
      departmentIds: [0],
      supplierIds: [supplierId],
    }
  }
  )
}
