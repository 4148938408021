import React from 'react'

// hooks
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'

const PriceListDeliveryFloatDay = ({ row }: { row: any }) => {
  const currentTabDay = useTypedSelector(
    (state) => state.priceList.currentTabDay
  )
  let content

  switch (currentTabDay) {
    case 0:
      content = row.deliveryDaysNumberFromMonday
      break
    case 1:
      content = row.deliveryDaysNumberFromTuesday
      break

    case 2:
      content = row.deliveryDaysNumberFromWednesday
      break

    case 3:
      content = row.deliveryDaysNumberFromThursday
      break

    case 4:
      content = row.deliveryDaysNumberFromFriday
      break

    case 5:
      content = row.deliveryDaysNumberFromSaturday
      break

    case 6:
      content = row.deliveryDaysNumberFromSunday
      break

    default:
      content = row.deliveryDaysNumberFromMonday
  }

  return <span>{content}</span>
}

export default PriceListDeliveryFloatDay
