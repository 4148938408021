import React from 'react'
import Box from '@mui/material/Box'
import { IProduct } from '../../types/productTypes'
import FormattedDate1 from '../../../../shared/components/FormattedDate1'

const PriceListField = ({ card }: { card: IProduct }) => {
  return (
    <Box sx={{ flexBasis: 0 }}>
      <Box
        sx={{
          overflowWrap: { xs: 'break-word', xl: 'unset' },
          textTransform: 'uppercase',
          color: 'primary.main',
          fontWeight: 'bold',
          fontSize: { xs: '9px', xl: '10px' },
          mb: 1,
        }}
      >
        Прайс-лист
      </Box>
      <Box
        sx={{
          textTransform: 'uppercase',
          overflowWrap: 'break-word',
          fontWeight: 'bold',
          fontSize: { xs: '9px', xl: '10px' },
        }}
      >
        {card.priceListName}
      </Box>
      <Box
        sx={{
          fontWeight: 'bold',
          fontSize: '9px',
        }}
      >
        <FormattedDate1 date={card.priceListDate} />
      </Box>
    </Box>
  )
}

export default PriceListField
