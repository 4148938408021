import { DEPARTMENT_CONSTS } from '../consts/departmentsPreOrder'
import { IPreOrderSorts } from '../types/departmentsPreOrderTypes'
import {
  DepartmentsPreOrderState,
  DepartmentPreOrdersActions,
  DepartmentsPreOrdersActionTypes,
} from './departmentsPreOrdersReduxTypes'
import { filterKeys, nameFilterKeys } from '../consts/departmentsPreOrder'
import { LoadDirections } from '../../../../../shared/types/types'

const inititalState: DepartmentsPreOrderState = {
  preOrders: [],
  preOrderSort: 'DepartmentName',
  selectPreOrder: null,
  loading: false,
  limit: DEPARTMENT_CONSTS.PROUDCT_COUNT,
  total: 0,
  enough: false,
  direction: LoadDirections.DOWN,
  page: 1,
  showPreOrder: true,
  tempShow: false,
  updateSelect: true,
}

export const departmentsPreOrdersReducer = (
  state = inititalState,
  action: DepartmentPreOrdersActions
) => {
  switch (action.type) {
    case DepartmentsPreOrdersActionTypes.DEPARTEMNT_REQ:
      return {
        ...state,
        loading: action.payload.loading,
        enough: false,
        direction: action.payload.loadFlag
          ? LoadDirections.DOWN
          : LoadDirections.UP,
      }
    case DepartmentsPreOrdersActionTypes.SAVE_DEPARTMENT_PRE_ORDERS:
      const { content, loadFlag, loading } = action.payload

      const {
        MIN_ITEMS_AFTER_LOADING,
        START_DOWN_INDEX,
        END_DOWN_INDEX,
        START_UP_INDEX,
        END_UP_INDEX,
      } = DEPARTMENT_CONSTS

      if (state.preOrders.length > MIN_ITEMS_AFTER_LOADING) {
        if (state.direction === LoadDirections.DOWN) {
          const itemsCopy = state.preOrders.slice(
            START_DOWN_INDEX,
            END_DOWN_INDEX
          )
          return {
            ...state,
            preOrders: [...itemsCopy, ...content.items],
            total: content.pagination.total,
            loading,
          }
        } else if (state.direction === LoadDirections.UP) {
          const itemsCopy = state.preOrders.slice(START_UP_INDEX, END_UP_INDEX)
          return {
            ...state,
            preOrders: [...content.items, ...itemsCopy],
            total: content.pagination.total,
          }
        }
      }
      return {
        ...state,
        preOrders: [...state.preOrders, ...content.items],
        total: content.pagination.total,
        direction: loadFlag ? LoadDirections.DOWN : LoadDirections.UP,
      }
    case DepartmentsPreOrdersActionTypes.SAVE_PREORDER_SORT:
      return {
        ...state,
        preOrderSort: action.payload,
      }
    case DepartmentsPreOrdersActionTypes.SELECT_PRE_ORDER:
      const selectPreOrder = action.payload
      const filtersAutoOrder: IPreOrderSorts = {
        filter: filterKeys.map((key, idx) => {
          return {
            propertyName: nameFilterKeys[idx],
            operation: DEPARTMENT_CONSTS.OPERATION,
            values: [
              selectPreOrder[key as keyof typeof selectPreOrder].toString(),
            ],
          }
        }),
        id: selectPreOrder.id,
        selectPreOrder
      }
      return {
        ...state,
        selectPreOrder: { ...filtersAutoOrder },
      }
    case DepartmentsPreOrdersActionTypes.LOADING_DEPARMENTS_PRE_ORDER_ENB:
      return {
        ...state,
        loading: true,
      }
    case DepartmentsPreOrdersActionTypes.SET_NEW_PRE_ORDER:
      const newPreOrder = action.payload

      const copyPreOrders = [...state.preOrders].map((preOrder) => {
        if (preOrder.id === newPreOrder.id)
          return {
            ...preOrder,
            orderCost: newPreOrder.orderCost,
          }
        return preOrder
      })
      return {
        ...state,
        preOrders: copyPreOrders,
      }
    case DepartmentsPreOrdersActionTypes.LOADING_DEPARMENTS_PRE_ORDER_DSB:
      return {
        ...state,
        loading: false,
      }
    case DepartmentsPreOrdersActionTypes.SET_ENOUGH:
      return {
        ...state,
        enough: action.payload,
      }
    case DepartmentsPreOrdersActionTypes.SET_DEPARTMENT_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    case DepartmentsPreOrdersActionTypes.CLEAR_DEPARTMENT:
      return {
        ...state,
        preOrders: [],
      }
    case DepartmentsPreOrdersActionTypes.SHOW_PRE_ORDERS:
      return {
        ...state,
        showPreOrder: action.payload,
      }
    case DepartmentsPreOrdersActionTypes.UPDATE_REQUEST:
      return {
        ...state,
        updateSelect: action.payload
      }
    case DepartmentsPreOrdersActionTypes.TEMP_SHOW:
      return {
        ...state,
        tempShow: action.payload
      }
    default:
      return state
  }
}
