import React from 'react'
import { getProducers } from '../../api/hint'
import { IAutoCompleteProps } from '../../types/types'
import { useAutoCompleteController } from '../../controllers/useAutoCompleteController'
import AutoCompleteTemplate from './AutoCompleteTemplate'

const ProducerAutoComplete = ({
  variant,
  value,
  handleAutoCompleteFields,
}: IAutoCompleteProps) => {
  const { options, debouncedRequest, loading, handleOpen, handleClose } =
    useAutoCompleteController(getProducers, 'content', true)

  return (
    <AutoCompleteTemplate
      variant={variant}
      {...(value ? { value } : {})}
      label={'Производитель'}
      options={options}
      debouncedRequest={debouncedRequest}
      loading={loading}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleAutoCompleteFields={handleAutoCompleteFields}
    />
  )
}

export default ProducerAutoComplete
