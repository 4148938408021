import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const BackPageTypography = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '16px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
}))
