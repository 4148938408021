import { Dispatch } from 'redux'
import {
  ApplicationAction,
  SnackTypes,
} from '../../../../shared/store/application/applicationReduxTypes'
import {
  ISaleDetailsPayload,
  SaleDetailsAction,
  SaleDetailsActionTypes,
} from './saleDetailsReduxTypes'

import noResultImage from '../../../../shared/assets/images/search.png'
import {
  deleteStopListItem,
  getSaleDetailsItems,
  putChangeDepartmentsNeeds,
  putStopListItem,
} from '../../../Sale/api/sale'
import {
  SaleComplexAction,
  SaleComplexActionTypes,
} from '../../../Sale/components/SaleComplex/model/saleComplex/saleComplexReduxTypes'
import { SaleCostBonusesAction } from '../../../Sale/components/SaleCostBonuses/model/saleCostBonuses/saleCostBonusesReduxTypes'
import { SaleDepartmentsAction } from '../../../Sale/components/SaleDepartments/model/saleDepartments/saleDepartmentsReduxTypes'
import debounce from 'lodash/debounce'
import { updateCostBonusesAction } from '../../../Sale/components/SaleCostBonuses/model/saleCostBonuses/saleCostBonusesAction'
import { updateSaleDepartmentsAction } from '../../../Sale/components/SaleDepartments/model/saleDepartments/saleDepartmentsAction'
import { showSnack } from '../../../../shared/store/application/applicationAction'
import {
  IDepartmentsNeedsParams,
  IStopListParams,
} from '../../../../shared/types/types'
import { ChangeEvent } from 'react'
import {
  addStopListItemAction,
  deleteSaleComplexStopListItemAction,
} from '../../../Sale/components/SaleComplex/model/saleComplex/saleComplexAction'
import { transformRequest } from '../../../../shared/services/commonService'

export const fetchSaleDetailsProductsAction = (
  page = 1,
  limit = 30,
  filter = {},
  loadFlag = true
) => {
  return async (dispatch: Dispatch<SaleDetailsAction | ApplicationAction>) => {
    try {
      dispatch({ type: SaleDetailsActionTypes.LOADING_ENABLE })
      const filterTransformed = Object.fromEntries(
        Object.entries(filter).map(([k, v]) => [
          k,
          transformRequest(v, 'departmentId'),
        ])
      )
      const { data } = await getSaleDetailsItems(page, limit, filterTransformed)
      const content = data.content
      const isEmpty = content.departmentNeedsDetails.length === 0 && page === 1

      if (isEmpty) {
        dispatch({
          type: SaleDetailsActionTypes.FETCH_PRODUCTS_ERROR,
          payload: { message: 'Поиск не дал результатов', img: noResultImage },
        })
        return
      }
      const isEnough = content.departmentNeedsDetails.length >= limit
      dispatch({
        type: SaleDetailsActionTypes.FETCH_PRODUCTS_ENOUGH,
        payload: isEnough,
      })
      dispatch({
        type: SaleDetailsActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: { content, loadFlag },
      })
    } catch (e) {
      dispatch({
        type: SaleDetailsActionTypes.FETCH_PRODUCTS_ERROR,
        payload: { message: 'Поиск завершился ошибкой', img: noResultImage },
      })
    } finally {
      dispatch({ type: SaleDetailsActionTypes.LOADING_DISABLE })
    }
  }
}

export function setSaleDetailsPage(page: number): SaleDetailsAction {
  if (page === 0) page = 1
  return { type: SaleDetailsActionTypes.SET_PRODUCT_PAGE, payload: page }
}

export function setSaleDetailsFilterProducts(
  payload: Object
): SaleDetailsAction {
  return {
    type: SaleDetailsActionTypes.SET_FILTER_PRODUCTS,
    payload,
  }
}

export function resetSaleDetailsFilterProducts(): SaleDetailsAction {
  return { type: SaleDetailsActionTypes.RESET_FILTER_PRODUCTS }
}

export function setSaleDetailsSortAction(
  payload: ISaleDetailsPayload
): SaleDetailsAction {
  return { type: SaleDetailsActionTypes.SET_PRODUCT_SORT, payload }
}

export const setDetailsQuantityForOrderAction =
  (params: IDepartmentsNeedsParams) =>
  (
    dispatch: Dispatch<
      | SaleDetailsAction
      | SaleComplexAction
      | SaleCostBonusesAction
      | SaleDepartmentsAction
      | ApplicationAction
    >
  ) =>
    debouncedDetailsQuantityForOrder(params, dispatch)

const debouncedDetailsQuantityForOrder = debounce(
  async (
    params,
    dispatch: Dispatch<
      | SaleDetailsAction
      | SaleComplexAction
      | SaleCostBonusesAction
      | SaleDepartmentsAction
      | ApplicationAction
    >
  ) => {
    try {
      let { data } = await putChangeDepartmentsNeeds(params)
      dispatch({
        type: SaleDetailsActionTypes.SAVE_SALE_DETAILS_QUANTITY_FOR_ORDER,
        payload: params,
      })
      dispatch({
        type: SaleComplexActionTypes.SAVE_SALE_COMPLEX_QUANTITY_FOR_ORDER,
        payload: params,
      })
      dispatch(updateCostBonusesAction(data.content.uiChanges.bonuses))
      dispatch(updateSaleDepartmentsAction(data.content.uiChanges.departments))
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     // messageSnack: 'Превышен лимит',
      //     messageSnack: 'Превышен лимит стоп-листа',
      //   })
      // )
    }
  },
  200
)

/////////////////////////////////////////////////////////////////////////

export const addDetailsStopListItemAction = (
  params: IStopListParams
): SaleDetailsAction => ({
  type: SaleDetailsActionTypes.ADD_SALE_DETAILS_STOP_LIST_ITEM,
  payload: params,
})

export const deleteDetailsStopListItemAction = (
  params: IStopListParams
): SaleDetailsAction => ({
  type: SaleDetailsActionTypes.DELETE_SALE_DETAILS_STOP_LIST_ITEM,
  payload: params,
})

const debouncedPutStopListItem = debounce(
  async (
    params,
    dispatch: Dispatch<
      | SaleComplexAction
      | SaleCostBonusesAction
      | SaleDepartmentsAction
      | SaleDetailsAction
      | ApplicationAction
    >
  ) => {
    try {
      let { data } = await putStopListItem(params)
      dispatch(updateCostBonusesAction(data.content.uiChanges.bonuses))
      dispatch(updateSaleDepartmentsAction(data.content.uiChanges.departments))
      dispatch(addStopListItemAction(params))
      dispatch(addDetailsStopListItemAction(params))
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Превышен лимит стоп-листа',
      //   })
      // )
    }
  },
  300
)

const debouncedDeleteStopListItem = debounce(
  async (
    params,
    dispatch: Dispatch<
      | SaleComplexAction
      | SaleCostBonusesAction
      | SaleDepartmentsAction
      | SaleDetailsAction
      | ApplicationAction
    >
  ) => {
    try {
      let { data } = await deleteStopListItem(params)
      dispatch(updateCostBonusesAction(data.content.uiChanges.bonuses))
      dispatch(updateSaleDepartmentsAction(data.content.uiChanges.departments))
      dispatch(deleteSaleComplexStopListItemAction(params))
      dispatch(deleteDetailsStopListItemAction(params))
    } catch (e) {
      // dispatch(
      //   showSnack({
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Превышен лимит стоп-листа',
      //   })
      // )
    }
  },
  200
)

export const stopDetailsListHandlerAction = (
  event: ChangeEvent<HTMLInputElement>,
  params: IStopListParams
) => {
  return async (
    dispatch: Dispatch<
      | SaleDetailsAction
      | SaleComplexAction
      | SaleCostBonusesAction
      | SaleDepartmentsAction
      | ApplicationAction
    >
  ) => {
    const isChecked = event.target.checked

    isChecked
      ? debouncedPutStopListItem(params, dispatch)
      : debouncedDeleteStopListItem(params, dispatch)
  }
}

export const handleSaleDetailsAutoComplete = (
  newValue: any, // todo
  key: string
) => {
  return async (dispatch: Dispatch<SaleDetailsAction>) => {
    if (Array.isArray(newValue)) {
      dispatch(setSaleDetailsFilterProducts({ [key]: newValue }))
    } else {
      dispatch(setSaleDetailsFilterProducts({ [key]: [] }))
    }
  }
}

export function startSaleDetailsSearching(): SaleDetailsAction {
  return { type: SaleDetailsActionTypes.START_SALE_DETAILS_SEARCH }
}

export function resetSaleDetailsFilter(): SaleDetailsAction {
  return { type: SaleDetailsActionTypes.RESET_FILTER_PRODUCTS }
}
