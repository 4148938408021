import React from 'react'

// assets
import SvgHospital from '../../shared/components/UI/svgIcons/SvgHospital'
import SvgMolecule from '../../shared/components/UI/svgIcons/SvgMolecule'
import SvgBad from '../../shared/components/UI/svgIcons/SvgBad'
import SvgAccounting from '../../shared/components/UI/svgIcons/SvgAccounting'
import SvgDocument from '../../shared/components/UI/svgIcons/SvgDocument'
import SvgCheck from '../../shared/components/UI/svgIcons/SvgCheck'
import SvgBlueLogo from '../../shared/components/UI/svgIcons/SvgBlueLogo'
import SvgOrangeLogo from '../../shared/components/UI/svgIcons/SvgOrangeLogo'
import SvgGreenLogo from '../../shared/components/UI/svgIcons/SvgGreenLogo'
import SvgPlus from '../../shared/components/UI/svgIcons/SvgPlus'
import AutorenewIcon from '@mui/icons-material/Autorenew'

// project imports
import FormattedDate1 from '../../shared/components/FormattedDate1'
import { onlyNumbers } from '../../shared/services/commonService'
import FormattedPrice1 from '../../shared/components/FormattedPrice1'

//mui imports
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import TableRow from '@mui/material/TableRow'
import InputBase from '@mui/material/InputBase'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'

const SimilarTableRow = ({ row }: { row: any }) => {
  const {
    isBad,
    isBenefit,
    isFirstHundredBonus,
    isImmunoBiological,
    isJvnls,
    isMyHealth,
    isOa,
    isPrPkkn,
    isProApt,
    isReceipt,
  } = row.marketingEventStatuses || {}

  const initialIconSizes = {
    width: '20px',
    height: '20px'
  }

  return (
    <TableRow
      sx={{
        '& td,& td div': {
          fontSize: '0.725rem',
        },
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell>
        <Box>
          <Box display="flex">
            <SvgHospital initialSizes={initialIconSizes}  renderCondition={isJvnls} />
            <SvgMolecule initialSizes={initialIconSizes}  renderCondition={isImmunoBiological} />
            <SvgBad initialSizes={initialIconSizes}  renderCondition={isBad} />
          </Box>
          <Box display="flex">
            <SvgAccounting initialSizes={initialIconSizes}  renderCondition={isPrPkkn} />
            <SvgDocument initialSizes={initialIconSizes}  renderCondition={isReceipt} />
            <SvgCheck initialSizes={initialIconSizes}  renderCondition={isOa} />
          </Box>
        </Box>
      </TableCell>
      <TableCell align="left" sx={{ paddingLeft: '8px' }}>
        {row.name}
      </TableCell>
      <TableCell>
        <Box>
          <Box display="flex">
            <SvgBlueLogo initialSizes={initialIconSizes}  renderCondition={isProApt} />
            <SvgOrangeLogo initialSizes={initialIconSizes}  renderCondition={isFirstHundredBonus} />
          </Box>
          <Box display="flex">
            <SvgGreenLogo initialSizes={initialIconSizes}  renderCondition={false} />
            <SvgPlus renderCondition={isMyHealth} />
          </Box>
        </Box>
      </TableCell>
      <TableCell align="center">{row.minimalQuantity}</TableCell>
      <TableCell align="center">{row.boxQuantity}</TableCell>
      <TableCell align="center">{row.quantity}</TableCell>
      <TableCell align="center">{row.priceListName}</TableCell>
      <TableCell align="center">
        <FormattedDate1 date={row.expirationDate} />
      </TableCell>

      <TableCell align="center">
        <InputBase
          // disabled={!row.matchProductCount}
          defaultValue="0"
          onInput={onlyNumbers}
          inputProps={{ min: 1 }}
          sx={{
            '&.MuiInputBase-root': {
              '& input': {
                textAlign: 'center',
                fontSize: '0.725rem',
                marginLeft: '8px',
                width: '70px',
              },
            },
          }}
          type="number"
          onChange={(event) => console.log(event.target.value)}
        />
      </TableCell>
      <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
        {/*<FormattedPrice1 price={row.price * row.amount} />*/}
        <FormattedPrice1 price={row.price} />
      </TableCell>

      <TableCell align="center">
        <Tooltip title="Заменить">
          <IconButton onClick={() => console.log(row)}>
            <AutorenewIcon fontSize="small" sx={{ color: 'neutral.main' }} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default SimilarTableRow
