import React from "react";
import Box from "@mui/material/Box";

const Dash = () => {
  return (
    <>
      <Box sx={{
        flexShrink: 0,
        margin: "0 0.4rem",
        backgroundColor: "#b5b5b9",
        width: "1.1rem",
        height: "0.2rem"
      }} />
    </>
  );
};

export default Dash;