import Tooltip from '@mui/material/Tooltip'
import BarChartIcon from '@mui/icons-material/BarChart'
import SortIcon from '@mui/icons-material/Sort'

const RenderIcons = ({ condition }: { condition: string | number }) => {
	return (
		<>
			{condition === '' && (
				<Tooltip title={'без сортировки'} placement={'right'}>
					<BarChartIcon />
				</Tooltip>
			)}
			{condition === -1 && (
				<Tooltip title={'сортировать по убыванию'} placement={'right'}>
					<SortIcon />
				</Tooltip>
			)}
			{condition === 1 && (
				<Tooltip title={'сортировка по возрастанию'} placement={'right'}>
					<SortIcon sx={{ transform: 'scaleY(-1)' }} />
				</Tooltip>
			)}
		</>
	)
}

export default RenderIcons
