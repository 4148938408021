import React from "react"
import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const ModalCloseButton = ({ handleClose }: { handleClose: () => void }) => {
  return (
    <>
      <IconButton
        onClick={handleClose}
        size={"small"}
        sx={{
          position: "absolute",
          top: "5px",
          right: "5px"
        }}
      >
        <CloseIcon fontSize={"small"} />
      </IconButton>
    </>
  )
}

export default ModalCloseButton