import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'

// mui imports
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

// styled
import { TextFieldCustom } from '../../../../../../shared/components/styled/TextFieldCustom'

// hooks
import { useActions } from '../../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../../shared/hooks/useTypedSelector'

// libs
import debounce from 'lodash/debounce'

// project
import ItemsLoader from '../../../../../../shared/components/ItemsLoader'

export interface IConsigneeDepartment {
  departmentId: number
  departmentName: string
}

const UnionDepartmentContent = ({
  handleClose,
}: {
  handleClose: () => void
}) => {
  const {
    fetchStandardDepartments,
    enableConsigneeDepartmentAction,
    fetchSaleComplexOptionsAction,
  } = useActions()

  const departments = useTypedSelector(
    (state) => state.saleCostBonuses.departments
  )

  const consigneeDepartment = useTypedSelector(
    (state) => state.saleComplex.options.consigneeDepartment
  )

  const loading = useTypedSelector((state) => state.saleCostBonuses.loading)

  const [checkedDepartment, setCheckedDepartment] =
    useState<IConsigneeDepartment>(consigneeDepartment)

  const handleSaleDepartmentCheckBox = (params: IConsigneeDepartment) => {
    setCheckedDepartment(params)
  }

  useEffect(() => {
    fetchStandardDepartments(undefined, 100)
  }, [])

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value
    debounceRequest(val.trim().length ? val : undefined)
  }

  const debounceRequest = useMemo(
    () => debounce((val?: string) => fetchStandardDepartments(val), 800),
    []
  )

  const handleSubmit = async () => {
    if (checkedDepartment !== undefined) {
      const fetch = async () =>
        enableConsigneeDepartmentAction(checkedDepartment)
      await fetch()
      fetchSaleComplexOptionsAction()
      handleClose()
    }
  }
  return (
    <>
      <Box
        sx={{
          my: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '14px',
          backgroundColor: 'background.paper',
          zIndex: 1,
          position: 'sticky',
          top: '0',
        }}
      >
        <Typography
          sx={{
            color: 'primary.main',
            fontWeight: 'bold',
            fontSize: '16px',
            textTransform: 'uppercase',
            textAlign: 'left',
          }}
        >
          ВЫБЕРИТЕ ГРУЗОПОЛУЧАТЕЛЯ
        </Typography>

        <TextFieldCustom
          fullWidth
          onChange={handleSearchInput}
          placeholder="Поиск грузополучателя"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Divider />
      <Box sx={{ height: 320, overflow: 'auto' }}>
        <ItemsLoader loading={loading} isEmpty={departments.length === 0}>
          <List>
            {departments.map((item) => {
              return (
                <ListItem key={item.id} disablePadding>
                  <ListItemButton
                    dense
                    disableGutters
                    onClick={() =>
                      handleSaleDepartmentCheckBox({
                        departmentId: item.id,
                        departmentName: item.name,
                      })
                    }
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        disableRipple
                        id={String(item.id)}
                        checked={item.id === checkedDepartment?.departmentId}
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            width: '100%',
                            pr: 1,
                            paddingY: '4px',
                            fontWeight: 600,
                            fontSize: 12,
                            color: 'primary.main',
                            textDecoration: 'unset',
                            wordBreak: 'break-word',
                          }}
                        >
                          {item.name}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
          <Box
            sx={{
              width: 355,
              backgroundColor: 'background.paper',
              borderRadius: '0 0 10px 10px',
              position: 'absolute',
              bottom: 0,
              left: 0,
              py: 1,
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="end"
              columnGap={1}
            >
              <Button
                disabled={checkedDepartment === undefined}
                variant="contained"
                size="small"
                onClick={() => handleSubmit()}
              >
                Подтвердить
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleClose()}
              >
                Отменить
              </Button>
            </Stack>
          </Box>
        </ItemsLoader>
      </Box>
    </>
  )
}

export default UnionDepartmentContent
