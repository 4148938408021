import React from 'react'

// mui imports
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'

// consts
import { DRAWER } from '../../shared/consts/drawer'

// project imports
import DrawerCustomContent from './components/DrawerCustomContent'
import Divider from '@mui/material/Divider'

const DrawerCustom = ({
  mobileOpen,
  handleDrawerToggle,
}: {
  mobileOpen: boolean
  handleDrawerToggle: () => void
}) => {
  const drawer = (
    <div>
      <Toolbar>Content</Toolbar>
      <Divider />
      <DrawerCustomContent />
    </div>
  )

  return (
    <>
      <Box
        sx={{ width: { lg: DRAWER.WIDTH }, flexShrink: { lg: 0 } }}
        aria-label="main filters"
      >
        <Drawer
          container={undefined}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: false,
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER.WIDTH,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', lg: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER.WIDTH,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  )
}

export default DrawerCustom
