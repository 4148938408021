import React, { useState, useEffect } from 'react'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { IAutoOrderStatusProps } from '../../../features/AutoOrder/types/autoOrderTypes'
import { getAutoOrderStatus } from '../../../features/AutoOrder/api/autoOrder'

export default function AutoOrderStatusAutoComplete({
  value,
  handleStatusValue,
  handleAutoCompleteFields,
}: {
  value: IAutoOrderStatusProps | null
  handleAutoCompleteFields: (newValue: any) => void
  handleStatusValue: (newValue: any) => void
}) {
  const [options, setOptions] = useState<IAutoOrderStatusProps[]>([])

  useEffect(() => {
    getAutoOrderStatus().then((response) => {
      const items = response.data.content.items
      setOptions(items)
    })
  }, [])

  return (
    <Autocomplete
      value={value}
      size="small"
      sx={{
        width: '230px',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#B5C3DC',
        },
        '& .MuiInputLabel-formControl': {
          fontSize: '0.875rem',
          mt: '2px',
        },
      }}
      onChange={(event: any, newValue: any) => {
        handleStatusValue(newValue)
        handleAutoCompleteFields(newValue)
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.alias}
      options={options}
      renderInput={(params) => <TextField {...params} label="Статус" />}
    />
  )
}
