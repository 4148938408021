import React from 'react'

// third libs
import { Dispatch } from 'redux'
import dayjs from 'dayjs'
// actions
import {
  IAutoOrderPayload,
  AutoOrderAction,
  AutoOrderActionTypes,
  AutoOrderState,
} from './autoOrderReduxTypes'

// api
import {
  deleteAutoOrder,
  deleteAutoOrderUserSettings,
  getAutoOrderCondition,
  postAutoOrder,
  postAutoOrderUserSettings,
} from '../../api/autoOrder'

// types
import { IOptionsProps } from '../../../../shared/types/types'
import {
  IAutoOrderStatusProps,
  IAutoOrderFilterItem,
} from '../../types/autoOrderTypes'

// project imports
import noResultImage from '../../../../shared/assets/images/search.png'
import { AppStateType } from '../../../../shared/store'
import {
  ApplicationAction,
  ApplicationActionTypes,
} from '../../../../shared/store/application/applicationReduxTypes'

const dispatchAutoOrderError = (dispatch: Dispatch<AutoOrderAction>) => {
  dispatch({
    type: AutoOrderActionTypes.FETCH_PRODUCTS_ERROR,
    payload: { message: 'Нет данных', img: noResultImage, loadFlag: false },
  })
}

export const fetchAutoOrder = (currentPage = 1, limit = 30) => {
  return async (
    dispatch: Dispatch<AutoOrderAction | ApplicationAction>,
    getState: () => AppStateType
  ) => {
    dispatch({
      type: AutoOrderActionTypes.FETCH_PRODUCTS_REQUEST,
      payload: {
        loading: true,
        loadFlag: true,
      },
    })

    try {
      dispatch({ type: ApplicationActionTypes.LOADING_ENABLE })
      const state = getState()
      const { filter, listOrientation } = state.autoOrder

      const response = await postAutoOrder(
        currentPage,
        limit,
        filter,
        listOrientation
      )
      const content = response?.data?.content || null

      const isEmpty = content
        ? content?.items?.length === 0 && currentPage === 1
        : true

      if (isEmpty) {
        dispatchAutoOrderError(dispatch)
      }

      const isEnough = content?.items?.length > 0

      dispatch({
        type: AutoOrderActionTypes.FETCH_PRODUCTS_ENOUGH,
        payload: isEnough,
      })

      dispatch({
        type: AutoOrderActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: { content, loadFlag: true, loading: false },
      })
    } catch (e) {
      dispatchAutoOrderError(dispatch)
    } finally {
      dispatch({ type: ApplicationActionTypes.LOADING_DISABLE })
    }
  }
}

export function setAutoOrderPage(page: number): AutoOrderAction {
  const defaultPage = 1

  if (page === 0) {
    return {
      type: AutoOrderActionTypes.SET_AUTO_ORDER_PAGE,
      payload: defaultPage,
    }
  }

  return { type: AutoOrderActionTypes.SET_AUTO_ORDER_PAGE, payload: page }
}

export function setAutoOrderSort(payload: IAutoOrderPayload): AutoOrderAction {
  return { type: AutoOrderActionTypes.SET_PRODUCT_SORT, payload }
}

export function setAutoOrderFilter(
  payload: IAutoOrderFilterItem | IAutoOrderFilterItem[]
): AutoOrderAction {
  return { type: AutoOrderActionTypes.SET_FILTER_PRODUCTS, payload }
}

export function resetAutoOrderFilter(): AutoOrderAction {
  return { type: AutoOrderActionTypes.RESET_FILTER_AUTO_ORDER }
}

export function resetAutoOrderItems(): AutoOrderAction {
  return { type: AutoOrderActionTypes.RESET_AUTO_ORDER_ITEMS }
}

export function startAutoOrderSearching(): AutoOrderAction {
  return { type: AutoOrderActionTypes.START_AUTO_ORDER_SEARCH }
}

export const sendAutoOrderDeleteRequest = async (id: string) => {
  try {
    await deleteAutoOrder(id)
  } catch (e) {
    // console.error(e)
  }
}

export function removeAutoOrderFilterWithEmptyValue(
  payload: IAutoOrderFilterItem[]
): AutoOrderAction {
  return { type: AutoOrderActionTypes.REMOVE_FILTER_WITH_EMPTY_VALUE, payload }
}

export const handleAutoOrderDepartment = (
  newValue: IOptionsProps[] | null,
  key: string
) => {
  return async (
    dispatch: Dispatch<AutoOrderAction>,
    getState: () => AppStateType
  ) => {
    if (newValue === null || newValue.length === 0) {
      const state = getState()
      const { filter } = state.autoOrder

      const existingFilterIndex = filter.findIndex(
        (filterItem) => filterItem.propertyName === key
      )

      if (existingFilterIndex !== -1 && filter.length > 0) {
        const filterCopy = filter.slice()
        filterCopy.splice(existingFilterIndex, 1)

        dispatch(removeAutoOrderFilterWithEmptyValue(filterCopy))
      }
    } else {
      const departmentFilter: IAutoOrderFilterItem = {
        propertyName: key,
        operation: 'In',
        values: newValue?.map((elem) => elem.id.toString()) || [],
      }
      dispatch(setAutoOrderFilter(departmentFilter))
    }
  }
}

export const handleAutoOrdersComboBox = (
  newValue: IAutoOrderStatusProps | null,
  key: string
) => {
  return async (
    dispatch: Dispatch<AutoOrderAction>,
    getState: () => AppStateType
  ) => {
    if (newValue === null) {
      const state = getState()
      const { filter } = state.autoOrder

      const existingFilterIndex = filter.findIndex(
        (filterItem) => filterItem.propertyName === key
      )

      if (existingFilterIndex !== -1 && filter.length > 0) {
        const filterCopy = filter.slice()
        filterCopy.splice(existingFilterIndex, 1)

        dispatch(removeAutoOrderFilterWithEmptyValue(filterCopy))
      }
    } else {
      const statusFilter: IAutoOrderFilterItem = {
        propertyName: key,
        operation: 'In',
        values: [newValue.id.toString()],
      }
      dispatch(setAutoOrderFilter(statusFilter))
    }
  }
}

export const handleAutoOrderDate = (
  newValue: [Date | null, Date | null],
  key: string
) => {
  return async (
    dispatch: Dispatch<AutoOrderAction>,
    getState: () => AppStateType
  ) => {
    const state = getState()
    const { filter } = state.autoOrder
    if (newValue === null || newValue[0] === null || newValue[1] === null) {
      const existingFilterIndex = filter.findIndex(
        (filterItem) => filterItem.propertyName === key
      )

      if (existingFilterIndex !== -1 && filter.length > 0) {
        const filterCopy = filter.slice()
        filterCopy.splice(existingFilterIndex, 1)
        dispatch(removeAutoOrderFilterWithEmptyValue(filterCopy))
      }
    } else {
      const formattedDates = newValue.map((date) => {
        return date ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss') : null
      })

      const startDateFilter: IAutoOrderFilterItem = {
        propertyName: key,
        operation: 'GreaterOrEqualsThan',
        values: formattedDates[0] ? [formattedDates[0]] : [],
      }
      const endDateFilter: IAutoOrderFilterItem = {
        propertyName: key,
        operation: 'LessOrEqualsThan',
        values: formattedDates[1] ? [formattedDates[1]] : [],
      }
      dispatch(setAutoOrderFilter([startDateFilter, endDateFilter]))
    }
  }
}

export const handleAutoOrderCheckBox = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  return async (
    dispatch: Dispatch<AutoOrderAction | ApplicationAction>,
    getState: () => AutoOrderState
  ) => {
    const id = event.target.id
    const checked = event.target.checked

    try {
      if (checked) {
        await postAutoOrderUserSettings(id)
        dispatch({
          type: AutoOrderActionTypes.ADD_CHECKED_ITEMS,
          payload: id,
        })
      } else {
        const res = await deleteAutoOrderUserSettings(id)
        const responseArray = res.data.content.deletedAutoOrderSummaryBids
        const currentItemStatus = responseArray[0].status

        dispatch({
          type: AutoOrderActionTypes.SET_CHECKED_ITEMS,
          payload: { id: id, status: currentItemStatus },
        })
      }
    } catch (e) {
      // dispatch({
      //   type: ApplicationActionTypes.SHOW_SNACK,
      //   payload: {
      //     typeSnack: SnackTypes.ERROR,
      //     messageSnack: 'Заказ уже рассчитан',
      //   },
      // })
    }
  }
}

export const fetchAutoOrderCondition = () => {
  return async (dispatch: Dispatch<AutoOrderAction>) => {
    try {
      const response = await getAutoOrderCondition()
      const condition = response.data.content
      dispatch({
        type: AutoOrderActionTypes.SAVE_AUTO_ORDER_CONDITION,
        payload: condition,
      })
    } catch {}
  }
}
