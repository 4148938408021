import React from 'react'
import { format } from 'date-fns'

const FormattedDate1 = ({ date }: { date: string }) => {
  let content
  if (date === null) {
    content = ''
  } else {
    content = format(new Date(date), 'dd.MM.yyyy')
  }
  return <span>{content}</span>
}

export default FormattedDate1
