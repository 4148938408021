import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import ErrorIcon from '@mui/icons-material/Error'
import { IBasketVal } from '../types/basketTypes'
import FormattedDate1 from '../../../shared/components/FormattedDate1'

const BasketComparisonDateCell = ({ row }: { row: IBasketVal }) => {
  return (
    <Stack>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={row.expirationDate.isChanged ? 'green' : 'unset'}
      >
        <FormattedDate1 date={String(row.expirationDate.match)} />

        {row.expirationDate.isChanged && (
          <Tooltip title="Значение изменилось">
            <ErrorIcon sx={{ color: 'green', fontSize: '12px' }} />
          </Tooltip>
        )}
      </Box>

      {row.expirationDate.isChanged && (
        <Box
          marginRight={row.expirationDate.isChanged ? '12px' : 0}
          color={'action.disabled'}
        >
          <FormattedDate1 date={String(row.expirationDate.initial)} />
        </Box>
      )}
    </Stack>
  )
}

export default BasketComparisonDateCell
