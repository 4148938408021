// mui
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// mui styled
import { ButtonStyled, TypographyStyled } from '../../../styled/saleStyled'

// hooks
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import { useActions } from '../../../../../shared/hooks/useActions'

// project
import FormattedPrice1 from '../../../../../shared/components/FormattedPrice1'

// api
import { postSaleSubmit, postSendSubmit } from '../api/saleCostBonuses'

// types
import { SnackTypes } from '../../../../../shared/store/application/applicationReduxTypes'

const style = {
  pointerEvents: 'none' as 'none',
  opacity: '0.5',
}

const SaleCostBonuses = () => {
  const bonuses = useTypedSelector((state) => state.saleCostBonuses.bonuses)
  const claims = useTypedSelector((state) => state.auth.claims)

  const {
    showBonusModal,
    fetchSaleComplexOptionsAction,
    showComplexTable,
    resetSaleDepartmentsItems,
    resetSaleComplexState,
    resetCostBonusesStateAction,
    fetchDepartmentsAction,
    showUnionDepartmentModal,
    showSnack,
  } = useActions()

  const bonusPriceActive = useTypedSelector(
    (state) =>
      state.saleComplex.options.bonusInPrice.applyBonusInPriceToProductsPrice
  )

  const isComplexTableShowed = useTypedSelector(
    (state) => state.saleDepartments.isComplexTableShowed
  )

  const isLoadingComplexOrder = useTypedSelector(
    (state) => state.saleComplex.loading
  )

  const handleSaveSubmit = () => {
    postSaleSubmit()
      .then(() => {
        showComplexTable()
        fetchSaleComplexOptionsAction()
        resetSaleDepartmentsItems()
        resetSaleComplexState()
        resetCostBonusesStateAction()
        fetchDepartmentsAction()
      })
      .catch((e) => {
        // showSnack({
        //   typeSnack: SnackTypes.ERROR,
        //   messageSnack: "Ошибка выгрузки"
        // });
      })
  }

  const handleSendSubmit = async () => {
    try {
      const res = await postSaleSubmit()
      const createdOrdersIds = res.data.content.createdOrdersIds
      await postSendSubmit(createdOrdersIds)
      showComplexTable()
      fetchSaleComplexOptionsAction()
      resetSaleDepartmentsItems()
      resetSaleComplexState()
      resetCostBonusesStateAction()
      fetchDepartmentsAction()
    } catch {
      // showSnack({
      //   typeSnack: SnackTypes.ERROR,
      //   messageSnack: 'Ошибка выгрузки',
      // })
    }
  }

  return (
    <Box mt={2} display="flex" justifyContent="space-between">
      <Stack direction={'row'} spacing={4}>
        <Stack spacing={1}>
          <Box>
            <TypographyStyled>Сумма заказа:</TypographyStyled>
            <Typography fontSize={'0.875rem'}>
              <FormattedPrice1 price={bonuses.orderCost} bold={false} />
            </Typography>
          </Box>
          <Box>
            <TypographyStyled>выгода:</TypographyStyled>
            <Typography fontSize={'0.875rem'} color={'#40AC58'}>
              <FormattedPrice1
                price={
                  bonuses.minimalCompetitorPriceOrderCost - bonuses.orderCost
                }
                bold={false}
              />
            </Typography>
          </Box>
        </Stack>

        <Stack spacing={1}>
          <Box>
            <TypographyStyled>бонус за комплексный заказ:</TypographyStyled>
            <Typography fontSize={'0.875rem'}>
              <FormattedPrice1
                price={bonuses.bonusForImplementationCost}
                bold={false}
              />
            </Typography>
          </Box>
          <Box>
            <TypographyStyled>
              бонус план за комплексный заказ:
            </TypographyStyled>
            <Typography fontSize={'0.875rem'}>
              {bonuses.bonusPlanForImplementationPercentage} %
            </Typography>
          </Box>
        </Stack>

        <Stack spacing={1}>
          <Box>
            <TypographyStyled>общий бонус:</TypographyStyled>
            <Typography fontSize={'0.875rem'}>
              <FormattedPrice1 price={bonuses.totalBonusCost} bold={false} />
            </Typography>
          </Box>
          <Box>
            <TypographyStyled>общий бонус план:</TypographyStyled>
            <Typography fontSize={'0.875rem'}>
              {bonuses.totalBonusPlanPercentage} %
            </Typography>
          </Box>
        </Stack>

        <Stack spacing={1}>
          <Box>
            <TypographyStyled>бонус за ММ:</TypographyStyled>
            <Typography fontSize={'0.875rem'}>
              <FormattedPrice1
                price={bonuses.bonusForMarketingEventCost}
                bold={false}
              />
            </Typography>
          </Box>
          <Box>
            <TypographyStyled>бонус план за ММ:</TypographyStyled>
            <Typography fontSize={'0.875rem'}>
              {bonuses.bonusPlanForMarketingEventPercentage} %
            </Typography>
          </Box>
        </Stack>

        <Stack spacing={1}>
          <Box>
            <TypographyStyled>Сумма заказа:</TypographyStyled>
            <Typography fontSize={'0.875rem'} color={'rgba(70, 70, 70, 0.5)'}>
              <FormattedPrice1
                price={bonuses.minimalCompetitorPriceOrderCost}
                bold={false}
              />
            </Typography>
          </Box>
          <Box>
            <TypographyStyled>бонус:</TypographyStyled>
            <Typography fontSize={'0.875rem'} color={'rgba(70, 70, 70, 0.5)'}>
              <FormattedPrice1
                price={bonuses.minimalCompetitorPriceBonusCost}
                bold={false}
              />
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <Box
        sx={{
          ...(!isComplexTableShowed || isLoadingComplexOrder ? style : {}),
        }}
      >
        <Stack alignSelf="flex-start" direction="row" columnGap={1.5}>
          <ButtonStyled
            variant="contained"
            onClick={() => showUnionDepartmentModal()}
          >
            Объединить
          </ButtonStyled>
          <ButtonStyled
            sx={{ ...(bonusPriceActive ? style : {}) }}
            variant="contained"
            onClick={() => showBonusModal()}
          >
            Бонус в цене
          </ButtonStyled>
          <ButtonStyled
            variant="contained"
            onClick={() => handleSendSubmit()}
            disabled={
              !claims['promarket.order.create'] &&
              !claims['promarket.order.send_supplier'] &&
              !claims['promarket.complex_order.save_to_orders']
            }
          >
            Отправить
          </ButtonStyled>
          <ButtonStyled
            disabled={
              !claims['promarket.order.create'] &&
              !claims['promarket.complex_order.save_to_orders']
            }
            onClick={() => handleSaveSubmit()}
            variant="contained"
          >
            Сохранить
          </ButtonStyled>
        </Stack>
      </Box>
    </Box>
  )
}

export default SaleCostBonuses
