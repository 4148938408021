import { ISaleComplexHeadCells } from '../types/saleComplexTypes'

export const SALE_COMPLEX = {
  MAX_PRODUCT_ITEMS: 60,
}

export const headCellsSaleComplex: ISaleComplexHeadCells[] = [
  {
    name: '',
    align: 'center',
    field: 'productName',
    enableLeftPadding: false,
    disableField: true,
    enablePaddings: false,
  },
  {
    name: 'КОД_УС',
    align: 'center',
    field: 'productEsCode',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'КАТЕГОРИЯ',
    align: 'left',
    field: 'category',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'НАИМЕНОВАНИЕ',
    align: 'left',
    field: 'productName',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'ПРОИЗВОДИТЕЛЬ',
    align: 'left',
    field: 'producerName',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'ТЕКУЩИЙ ОСТАТОК',
    align: 'center',
    field: 'departmentsStockQuantity',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'Закупки за 30д.,уп',
    align: 'center',
    field: 'departmentsQuantityPurchased',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'продажи за 30д.,уп',
    align: 'center',
    field: 'departmentsSoldIn30Days',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
  {
    name: 'Товар в пути,шт',
    align: 'center',
    field: 'quantityProductOnWay',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },

  {
    name: 'кол-во для заказа,шт.',
    align: 'center',
    field: 'neededQuantityForOrder',
    enableLeftPadding: true,
    disableField: false,
    enablePaddings: false,
  },
]
