import { createSelector } from 'reselect'

import { AUTO_ORDER_STATUS } from '../consts/autoOrder'
import { AppStateType } from '../../../shared/store'

export const selectAutoOrderItems = (state: AppStateType) => state.autoOrder.items
export const selectItemId = ( state: AppStateType, id: string) => id

const selectAutoOrderRowData = createSelector(
  [selectAutoOrderItems, selectItemId],
  (items, id) => {
    const currentItem = items.find(el => el.id === id)
    const checkboxState = currentItem?.status === AUTO_ORDER_STATUS.PROCESSED
      || (currentItem?.status === AUTO_ORDER_STATUS.IN_PROCESSING && !currentItem?.enableToDeleteUserAutoOrderSummaryBid)

    const isCheckboxChecked = currentItem?.status === AUTO_ORDER_STATUS.IN_PROCESSING && currentItem?.enableToDeleteUserAutoOrderSummaryBid

    return {
      isCheckboxDisabled: checkboxState,
      isCheckboxChecked
    };
  },
);

export default selectAutoOrderRowData