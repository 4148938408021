import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@mui/material'

const TableNoItems = ({
  isEmpty,
  loading,
}: {
  isEmpty: boolean
  loading: boolean
}) => {
  return (
    <>
      <TableRow>
        {isEmpty && !loading && (
          <TableCell colSpan={13}>
            <Typography
              marginTop="16px"
              align="center"
              fontSize={'inherit'}
              fontWeight={'bold'}
            >
              Нет результатов
            </Typography>
          </TableCell>
        )}
        {loading && (
          <TableCell colSpan={13} align={'center'}>
            <CircularProgress size={40} color="secondary" />
          </TableCell>
        )}
      </TableRow>
    </>
  )
}

export default TableNoItems
