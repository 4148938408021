import http from '../../../../../shared/api/config/instance'

export const getDepartmentPreOrders = (
  pageNumber: number,
  pageSize: number,
  order: string
) =>
  http.httpCore.post('auto-order/departments-pre-orders/filter', {
    pagination: {
      pageNumber,
      pageSize,
    },
    order,
  })
