import React from "react"
import { Autocomplete, TextField, Typography } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export enum DIRECTIONS {
  FROM = "FROM",
  TO = "TO"
}

const FromToAutoComplete = ({ handleDirection }: { handleDirection: (newValue: any) => void }) => {
  return (
    <>
      <Autocomplete
        sx={{
          "& .MuiInputLabel-formControl": {
            fontSize: "0.875rem",
            mt: "3px"
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#B5C3DC"
          },
          width: 230,
          mr: 1,
        }}
        disablePortal
        size="small"
        getOptionLabel={(option) => option.desc}
        isOptionEqualToValue={(option, value) =>
          option.val === value.val
        }
        onChange={(event: any, newValue: any) => {
          handleDirection(newValue)
        }}
        options={
          [
            { val: DIRECTIONS.FROM, desc: "От" },
            { val: DIRECTIONS.TO, desc: "До" }
          ]
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 5 }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
              checked={selected}
            />
            <Typography style={{ fontSize: "12px" }}>{option.desc}</Typography>
          </li>
        )}
        renderInput={(params) => <TextField {...params} label="Значение" />}
      />
    </>
  )
}

export default FromToAutoComplete