import React, { ChangeEvent } from 'react'

import { Box, InputAdornment, SxProps, Theme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { TextFieldCustom } from '../../../../../shared/components/styled/TextFieldCustom'

const containerStyle: SxProps<Theme> = {
  p: 1,
  borderRadius: '8px',
  height: '50px',
  overflowY: 'hidden',
  backgroundColor: 'background.paper',
  zIndex: 1,
  position: 'sticky',
  top: '0',
  mb: 1,
}

const SupplierListSerchPanel = ({
  handleSearchInput,
}: {
  handleSearchInput: (e: ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <Box sx={containerStyle}>
      <TextFieldCustom
        fullWidth
        onChange={handleSearchInput}
        placeholder="Поиск поставщика"
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}

export default SupplierListSerchPanel
