import React from 'react'
import { Slider, Stack } from '@mui/material'
import RangeInputs from './RangeInputs/RangeInputs'
import { PRICE_LIST_RANGE } from '../../../../../shared/consts/priceListRange'
import { DRAWER } from '../../../../../shared/consts/drawer'

const { MAX_RANGE, MIN_RANGE } = PRICE_LIST_RANGE

function valuetext(value: number) {
  return `${value} ₽`
}

interface ISliderProps {
  value1: number[]
  handleChangeSlider: (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => void
  handleRangeChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SliderCustom = ({
  value1,
  handleChangeSlider,
  handleRangeChange,
}: ISliderProps) => {
  return (
    <>
      <Stack>
        <RangeInputs handleRangeChange={handleRangeChange} value1={value1} />
        <Slider
          getAriaLabel={() => 'Minimum distance'}
          sx={{
            mt: '1rem',
            ml: '0.6rem',
            width: `calc(${DRAWER.WIDTH}px - 5.2rem)`,
          }}
          value={value1}
          onChange={handleChangeSlider}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
          min={MIN_RANGE}
          max={MAX_RANGE}
        />
      </Stack>
    </>
  )
}

export default SliderCustom
