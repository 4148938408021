import React, { useCallback } from 'react'

// hooks
import { useActions } from '../../../../../../shared/hooks/useActions'
import { useTypedSelector } from '../../../../../../shared/hooks/useTypedSelector'

// project
import ModalCloseButton from '../../../../../../shared/components/UI/ModalCloseButton'

// mui
import Backdrop from '@mui/material/Backdrop'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid',
  borderColor: 'action.disabled',
  boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: '3.75rem',
}

const BonusModal = () => {
  const bonusInPriceCost = useTypedSelector(
    (state) => state.saleCostBonuses.bonuses.bonusInPriceCost
  )
  const { active } = useTypedSelector((state) => state.modal)

  const {
    fetchSaleDepartmentsProducts,
    resetSaleDepartmentsItems,
    fetchCostBonusesAction,
    fetchSaleComplexProductsAction,
    modalDisable,
    enableSaleComplexBonusPrice,
    resetSaleComplexFilterProducts,
    fetchSaleComplexOptionsAction,
  } = useActions()

  const fetchUpdatedData = useCallback(async () => {
    resetSaleDepartmentsItems()
    resetSaleComplexFilterProducts()
    const fetchSaleComplex = async () => fetchSaleComplexProductsAction()
    const fetchBonuses = async () => fetchCostBonusesAction()
    const fetchDepartments = async () => fetchSaleDepartmentsProducts()
    await fetchSaleComplex()
    await fetchBonuses()
    await fetchDepartments()
    fetchSaleComplexOptionsAction()
  }, [])

  const handleClose = () => {
    modalDisable()
  }

  const handleSubmit = async () => {
    const fetch = async () => enableSaleComplexBonusPrice()
    await fetch()
    fetchUpdatedData()
    handleClose()
  }

  return (
    <>
      <Modal
        open={active}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={active}>
          <Box sx={style}>
            <Box display="flex" justifyContent="center" marginTop={3}>
              <ErrorOutlineIcon color="error" sx={{ fontSize: '60px' }} />
            </Box>

            <Typography
              component="h3"
              color={'error'}
              sx={{
                textAlign: 'center',
                textTransform: 'uppercase',
                fontWeight: '600',
                fontSize: '1.563rem',
              }}
            >
              внимание
            </Typography>

            <Typography
              component="h4"
              sx={{
                color: 'secondary.main',
                textAlign: 'center',
                textTransform: 'uppercase',
                fontWeight: '600',
                fontSize: '1.25rem',
                my: 3,
              }}
            >
              бонус в размере 80% от бонуса за комплексный заказ составляет{' '}
              {bonusInPriceCost} рублей и будет включен в цену всех позиций
              (кроме списка жнвлп)
            </Typography>

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              columnGap="1rem"
            >
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ borderRadius: '3px', width: '141.5px' }}
              >
                Подтвердить
              </Button>

              <Button
                onClick={handleClose}
                sx={{ borderRadius: '3px', width: '141.5px' }}
                variant="outlined"
              >
                ОТМЕНИТЬ
              </Button>
            </Stack>
            <ModalCloseButton handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default BonusModal
