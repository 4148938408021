import { useActions } from '../../../../../shared/hooks/useActions'
import { Backdrop, Box, Divider, Fade, Modal, Typography } from '@mui/material'
import { useTypedSelector } from '../../../../../shared/hooks/useTypedSelector'
import ModalCloseButton from '../../../../../shared/components/UI/ModalCloseButton'
import AutoOrderFieldsContent from './AutoOrderFieldsContent'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 720,
  bgcolor: 'background.paper',
  border: '2px solid',
  borderColor: 'action.disabled',
  boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: '2rem 2rem',
}

const AutoOrderFieldsModal = () => {
  const active = useTypedSelector((state) => state.modal.active)
  const { modalDisable } = useActions()

  const handleClose = () => {
    modalDisable()
  }
  return (
    <>
      <Modal
        open={active}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={active}>
          <Box sx={style}>
            <Typography
              id="filters-modal"
              align="center"
              variant="h6"
              component="h2"
            >
              Поля для отображения
            </Typography>
            <Divider />
            <AutoOrderFieldsContent onClose={handleClose} />
            <ModalCloseButton handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default AutoOrderFieldsModal
