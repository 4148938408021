export const transformList = (value: string ) => {
  let splitted = value.split(" ")
  let tempArray: string[] = []

  splitted.forEach((item: string) => {
    let pEl = tempArray[tempArray.length - 1]
    if (pEl) {
      tempArray.push(pEl.concat(" ", item))
      return
    }
    tempArray.push(item)
  })
  return tempArray
}