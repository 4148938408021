import React, { memo, useState } from 'react'
import Box from '@mui/material/Box'
import { onlyNumbers } from '../../../../shared/services/commonService'
import Typography from '@mui/material/Typography'
import { ProductInfo } from '../../types/productTypes'
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'

const AmountField = ({
  changeItemAmount,
  primaryKey,
  amountFieldRef,
  basketInfo,
}: {
  changeItemAmount: (val: number, basketId: number) => void
  basketInfo: ProductInfo
  primaryKey: string | number
  amountFieldRef: React.RefObject<HTMLInputElement>
}) => {
  const basketRecords = useTypedSelector((state) => state.basket.basketRecords)
  const basketId = basketRecords?.[primaryKey]?.basketId
  const defaultValue = basketRecords?.[primaryKey]?.amount

  const [amount, setAmount] = useState<string | number>(defaultValue)

  const handleChangeAmount = (newValue: string) => {
    let converted =
      newValue.startsWith('0') && newValue.length >= 2
        ? newValue.slice(1)
        : !newValue
        ? '0'
        : newValue
    setAmount(converted)
    changeItemAmount(+converted, basketId)
  }

  return (
    <Box mx={1} display="flex" flexDirection="column" alignItems="center">
      <Typography
        sx={{
          overflowWrap: { xs: 'break-word', xl: 'unset' },
          textTransform: 'uppercase',
          textAlign: 'center',
          color: 'primary.main',
          fontWeight: 'bold',
          fontSize: { xs: '9px', xl: '10px' },
        }}
      >
        кол-во
      </Typography>
      <input
        ref={amountFieldRef}
        type="number"
        placeholder={'0'}
        onInput={onlyNumbers}
        onChange={(event) => handleChangeAmount(event.target.value)}
        min={0}
        value={amount}
        style={{
          backgroundColor: 'inherit',
          color: 'inherit',
          lineHeight: '28px',
          fontSize: '14px',
          height: '28px',
          textAlign: 'center',
          width: '55px',
          border: 0,
          outline: 0,
          marginLeft: '12px',
        }}
      />
    </Box>
  )
}

export default AmountField
