import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ISupplierDetails } from '../../features/Basket/types/basketTypes'
import FormattedPrice1 from './FormattedPrice1'
import { RedTooltip } from './styled/RedToolTip'

export interface ISupplierSumsListProps {
  items: ISupplierDetails[]
  border?: boolean
}

const SupplierSumsList = ({ items, border = true }: ISupplierSumsListProps) => {
  return (
    <>
      <Box
        sx={{
          ...(border
            ? {
                border: '1px solid #E1E3E6',
                boxShadow: '0px 8px 24px -4px rgba(27, 46, 94, 0.08)',
                borderRadius: '8px',
              }
            : {}),
        }}
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontSize: '12px',
            fontWeight: 600,
            padding: '12px',
            textAlign: 'center',
            color: 'primary.main',
          }}
        >
          Общая сумма по поставщикам
        </Typography>

        {items.length > 0 ? (
          items.map((item) => {
            let content
            if (item.minSupplierSum > item.totalSum) {
              content = (
                <RedTooltip title={'Недостаточно для мин. заказа'}>
                  <Box
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}
                  >
                    <FormattedPrice1 price={item.totalSum} />
                    <Box sx={{ fontSize: '10px', color: 'neutral.main' }}>
                      <FormattedPrice1
                        price={item.minSupplierSum - item.totalSum}
                      />
                      /
                      <FormattedPrice1 price={item.minSupplierSum} />
                    </Box>
                  </Box>
                </RedTooltip>
              )
            } else {
              content = (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <FormattedPrice1 price={item.totalSum} />
                  <Box sx={{ fontSize: '10px', color: 'neutral.main' }}>
                    <FormattedPrice1 price={item.minSupplierSum} />
                  </Box>
                </Box>
              )
            }

            return (
              <Box
                key={item.supplierId}
                display="flex"
                justifyContent="space-between"
                borderBottom="1px solid rgba(125, 144, 178, 0.2);"
                color={item.minSupplierSum > item.totalSum ? 'red' : 'none'}
                paddingX="1rem"
                paddingY="0.3rem"
                fontSize="12px"
                fontWeight="400"
                gap={'1rem'}
              >
                <Typography
                  fontSize="inherit"
                  fontFamily={'Inter'}
                  fontWeight="bold"
                >
                  {item.supplierName}
                </Typography>

                <Box
                  display="flex"
                  gap="5px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {content}
                </Box>
              </Box>
            )
          })
        ) : (
          <Typography sx={{ p: 2 }} fontSize="inherit" align={'center'}>
            нет данных
          </Typography>
        )}
      </Box>
    </>
  )
}

export default SupplierSumsList
