import http from '../../../shared/api/config/instance'

export const postSubAutoOrders = (
    pageNumber: number,
    pageSize: number,
    filter: any[] = [],
    order: string = '',
) => {
    return http.httpCore.post(
        'auto-order/summary-bids/similar-products-groups/filter',
        {
            pagination: {
                pageNumber,
                pageSize
            },
            filter: filter,
            order: order,
        }
    )
}
